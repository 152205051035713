export enum EventSocialShareStatus
{
    ACTIVE = 1,
    DELETED = 2,
}

export enum EventSocialShareType
{
    EVENT_SPEAKER = 1,
    EVENT_SPONSOR = 2,
    EVENT_REGISTRANT = 3,
    EVENT_HOST = 4
}

export enum EventSocialShareTemplateType
{
    CUSTOM = 1,
    LINKEDIN_POST = 2,
    LINKEDIN_SINGLE_IMAGE = 3,
    INSTAGRAM_POST = 4,
    INSTAGRAM_STORY = 5,
    TWITTER_POST = 6,
    META_IMAGE = 7,
}

export enum EventSocialShareTemplateWidth
{
    META_LINKEDIN = 1200,
    INSTAGRAM = 1080,
    TWITTER_POST = 1600,
}

export enum EventSocialShareTemplateHeight
{
    META_IMAGE = 628,
    LINKEDIN_POST = 1200,
    LINKEDIN_SINGLE_IMAGE = 627,
    INSTAGRAM_POST = 1350,
    INSTAGRAM_STORY = 1920,
    TWITTER_POST = 900,
}

export enum EventSocialShareTemplateLayerType
{  
    TEXT = 'title',
    PICTURE= 'img',
    SHAPE = 'shape',
    BROWSER_FRAME= 'screenshot',
    BARCODE = 'barcode',
    RATING = 'rating',
    SUBTITLE = 'subtitle',
    RECTANGLE = 'bg',
}