import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { createFilterOptions, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import _, { debounce } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { addSponsors } from '../../redux/sponsors/Sponsors';
import { getSponsorActivityLogs } from '../../scripts/apis/sponsors';
import { createEventSponsors, getAllSponsorTiers, sponsorTierCount, updateEventSponsorV1, uploadEventSponsorImage } from '../../scripts/apis/eventSponsors';
import { getAllEventRegistrants, searchEventRegistrant } from '../../scripts/apis/eventRegistrants';
import { decompressImage } from '../../common/ImageProcessing';
import { isImageBuffer } from '../../scripts/helpers';
import { sponsorsActivityLog } from '../../scripts/activityLogGenerator/activitylog-generator';
import { CONTENT } from '../../scripts/i18n';
import { CustomFieldLocations, customFieldCountByLocation, customFieldsByLocation } from '../../pages/Settings/CustomField/CustomField.helper';
import { renderCheckBoxComponent, renderDateComponent, renderDropDownComponent, renderInputComponent, renderRadioGroupComponent } from '../../pages/Settings/CustomField/CustomTypeComponent';
import { EventRegistrantStatus } from '../../pages/Events/enum/event-registrant.enum';
import { EventHQEventLimit, ProductType } from '../../enums/billing.enum';

import { SponsorsProps } from './ISponsor';
import { Sponsor } from '../../pages/Sponsors/interfaces';
import { CustomField } from '../../pages/Registration/interfaces/custom-field_interface';
import { CustomFieldType } from '../../pages/Registration/enum/custom-field.enum';
import { EventSponsorTier } from '../../pages/Events/interfaces';
import { EventRegistrant } from '../../pages/Events/interfaces/event-registrant_interface';
import { IBillingInfo } from '../../redux/billing/billing-redux_interface';
import { ActivityLogResponse } from '../../interfaces/activity-log_interface';

import './styles.scss';

import { AutocompleteComponentWithFilterOption, DebouncedAutocomplete, FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../common/FormComponents/Buttons';
import MuiChip from '../../common/FormComponents/MuiChip';
import CustomSpinner from '../../common/CustomSpinner';
import CustomTooltip from '../../common/Tooltip';
import ActivityLogComponent from '../../common/ActivityLog/ActivityLogComponent';
import PopupDialog from '../../common/PopupDialog';
import CreateSponsorGroup from '../../pages/Events/EventDetails/People/EventSponsors/CreateSponsorGroup';

const CreateSponsor = (props: SponsorsProps):React.JSX.Element => 
{
    const sponsorData = props?.sponsorData;
    const addEventSponsor = props?.addEventSponsor;
    const eventId = props?.eventId;
    const heading = props?.heading;
    const handlePopupClose = props.handlePopupClose;
    const disabled = props.disabled;

    const csrfTokenData = useSelector((state: any) => 
    { 
        return state['csrfTokenValue'].value.csrfToken ;
    });

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const [customFieldsSpinner, setCustomFieldsSpinner] = useState(false);
    const [customFormState, setCustomFormState] = useState<Record<string, any>>({});
    const [sponsorImage, setSponsorImage] = useState<{
        file: File | undefined,
        blob: string
    }>({
        file: undefined,
        blob: ''
    });
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    const [showActivityLog, setShowActivityLog] = useState(false);
    const [sponsorTiers, setSponsorTiers] = useState<EventSponsorTier[]>([]);
    const [registrants, setRegistrants] = useState<EventRegistrant[]>([]);
    const [sponsorTierPopup, setSponsorTierPopup] = useState<boolean>(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        multiple: false,
        onDrop: (acceptedFiles): void => 
        {

            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = (): void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    setSponsorImage({
                        file,
                        blob: URL.createObjectURL(file)
                    })
                } 
                else 
                {
                    setSponsorImage({
                        file: undefined,
                        blob: ''
                    });
                    toast.error(fileSizeInMB > 10 ? 'Image size should be less than 10 MB' : 'Please provide a valid image')
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        }
    });

    const filter = createFilterOptions();

    const Preview = ():React.JSX.Element => 
    {
        return (
            <div className="preview-container">
                <img
                    src={(sponsorImage?.blob || '')}
                />
            </div>
        );
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        pocName: Yup.string()
            .matches(/^[a-zA-Z ]*$/, 'Enter a valid POC Name.')
            .required('POC Name is required'),
        pocEmail: Yup.string()
            .email('Enter a valid email.')
            .required('POC Email is required'),
        websiteLink: Yup.string()
            .required('Website URL is required')
            .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please enter a valid url'),
        linkedIn: Yup.string()
            .matches(/(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/, 'Please enter a valid LinkedIn URL'),
        twitter: Yup.string()
            .matches(/(https:\/\/x.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/, 'Please enter a valid Twitter URL'),
        sponsorTier: Yup.mixed().test(
            'sponsorTier-required',
            'Sponsor Tier is required',
            function (value) {
                if (!value && (orgBillingInfo?.productType === ProductType.EVENTHQ || orgBillingInfo?.eventhq === EventHQEventLimit.THREE)) {
                    return false;
                }
                if (orgBillingInfo?.productType === ProductType.EVENTHQ || orgBillingInfo?.eventhq === EventHQEventLimit.THREE) {
                    return typeof value === 'object' && (value as EventSponsorTier)?.name ? true : false;
                }
                return true;
            }
        ),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        initialValues: {
            name: sponsorData?.name || '',
            pocName: sponsorData?.pocName || '',
            pocEmail: sponsorData?.pocEmail || '',
            websiteLink: sponsorData?.websiteLink || '',
            linkedIn: sponsorData?.social?.linkedIn || '',
            twitter: sponsorData?.social?.twitter || '',
            sponsorTier: undefined as EventSponsorTier | undefined,
            newTierName: '',
            registrants: [] as EventRegistrant[],
            registrantSearchInput: '',
        },
        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);

            const sponsorDetails = {
                name: formik.values?.name,
                pocName: formik.values?.pocName,
                pocEmail: formik.values?.pocEmail,
                websiteLink: formik.values?.websiteLink,
                social: {
                    twitter: formik.values?.twitter,
                    linkedIn: formik.values?.linkedIn,
                },
            } as Partial<Sponsor>;

            if(orgBillingInfo?.productType === ProductType.EVENTHQ || orgBillingInfo?.eventhq === EventHQEventLimit.THREE)
            {
                sponsorDetails.sponsorTierId = formik.values?.sponsorTier?.id;
                sponsorDetails.registrantIds = formik.values?.registrants?.map((registrant) => registrant.id);
            }

            if(eventId)
            {
                if (!sponsorData) {
                    const newSponsorInfo = {
                        ...sponsorDetails,
                        additionalFieldData: customFormState ? { ...customFormState } : undefined,
                    } as any;
    
                    try {
                        // Await the speaker creation API
                        const response = await createEventSponsors(eventId, newSponsorInfo, csrfTokenData);
                    
                        // If there is an image, upload it
                        if (sponsorImage?.file) {
                            const image = new FormData();
                            image.append('file', sponsorImage?.file);
                    
                            try {
                                const imageUploaded = await uploadEventSponsorImage(response?.sponsorId, image, csrfTokenData);
                                if (!imageUploaded) {
                                    toast.error('Image upload failed.');
                                }
                            } catch (error) {
                                console.error('Error during image upload:', error);
                                toast.error('Image upload failed.');
                            }
                        }
                    
                        props?.setRefresh && props?.setRefresh(true);
                        toast.success('Sponsor created successfully');
                        handleDrawerClose();
                    } catch (error) {
                        console.error('Error creating speaker:', error);
                        toast.error((error as Error)?.message || 'Error creating speaker');
                    } finally {
                        setSpinner(false);
                    }
                } else if(sponsorData) {
                    const newSpeakerInfo = {
                        ...sponsorDetails,
                        additionalFieldData: customFormState ? { ...customFormState } : undefined,
                    } as any;
    
                    try {
                        // Await the speaker creation API
                        await updateEventSponsorV1(eventId, sponsorData?.id as string, newSpeakerInfo);
    
                        // If there is an image, upload it
                        if (sponsorImage?.file) {
                            const image = new FormData();
                            image.append('file', sponsorImage?.file);
    
                            try {
                                const imageUploaded = await uploadEventSponsorImage(sponsorData?.id as string, image, csrfTokenData);
                                if (!imageUploaded) {
                                    toast.error('Image upload failed.');
                                }
                            } catch (error) {
                                toast.error('Image upload failed.');
                            }
                        }
    
                        props?.setRefresh && props?.setRefresh(true);
                        toast.success('Speaker created successfully');
                        handleDrawerClose();
                    } catch (error) {
                        console.log(error);
                        toast.error((error as Error)?.message || 'Error creating speaker');
                    } finally {
                        setSpinner(false);
                    }
                }
            }
        }
    });

    const handleDrawerClose = (): void => 
    {
        setShowActivityLog(false);
        handlePopupClose();

        if (props?.routeFromId) 
        {
            navigate('/sponsors');
        }
        if (props?.routeFromEventSponsor && eventId) 
        {
            navigate('/events/' + eventId + '/people/sponsors');
        }
    };

    const getImageUrl = async (imageUrl: string) : Promise<void> => 
    {
        const updatedImage = await decompressImage(imageUrl, 1500, 1500);
        if (updatedImage) 
        {
            setSponsorImage({
                blob: updatedImage,
                file: undefined
            });
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, id: string | number): void => 
    {
        const { name, value } = event.target;
        setCustomFormState((prevState):{[key:string]:string|number|string[]|number[]|boolean} => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleDropDownInputChange = (name: string, value: string | number, id: string | number): void => 
    {
        setCustomFormState((prevState):{[key:string]:string|number|string[]|number[]|boolean} => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleBoxChange = (name: string, value: string | number, id: string): void => 
    {
        setCustomFormState((prevState):{[key:string]:string|number|string[]|number[]|boolean} => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleCheckboxChange = (item: any, data: CustomField): void => 
    {

        if(customFormState[data.label + ' ' + data.id])
        {
            const selectedIndex = customFormState[data.label + ' ' + data.id]?.indexOf(item.name);
            const updatedSelectedOptions = [...customFormState[data.label + ' ' + data.id]];

            if (selectedIndex === -1) 
            {
                updatedSelectedOptions.push(item.name);
            }
            else 
            {
                updatedSelectedOptions.splice(selectedIndex, 1);
            }

            setCustomFormState((prevState):{[key:string]:string|number|string[]|number[]|boolean} => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: updatedSelectedOptions,
                }; 
            });
        }
        else
        {
            setCustomFormState((prevState):{[key:string]:string|number|string[]|number[]|boolean} => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: [item.name],
                }; 
            });
        }
        
    };

    const handleNestedSidebarOpen = (): void =>
        {
            setShowActivityLog(true);
    };

    const handleTierSelectChange = (event: any, newValue: string | string[] | null):void => 
    {
        if (newValue && newValue.includes('Add :')) 
        {
            const tierName = typeof newValue === 'string' ? newValue?.split('Add : ')[1] : '';
            formik.setFieldValue('newTierName', tierName);
            handleSponsorTierPopup();            
        }
        else 
        {
            const selectedSponsorTier = _.filter(sponsorTiers, function (tier):boolean 
            {
                return newValue === tier?.name;
            })[0];
            formik.setFieldValue('sponsorTier', selectedSponsorTier);
        }
    };

    const handleTierFilterOptions = (options: any[], params: any):unknown[] => 
    {
        const filtered = filter(options, params);

        if (params.inputValue !== '') 
        {
            filtered.push('Add : ' + params.inputValue);
        }

        return filtered;
    };
    
    const handleEditSponsorTier = (): void => 
    {
        handleSponsorTierPopup();
    };

    const handleSponsorTierUpdates = (newSponsorTier?: EventSponsorTier): void => {
        if(newSponsorTier)
        {
            setSponsorTiers([...sponsorTiers, newSponsorTier]);
            formik.setFieldValue('sponsorTier', newSponsorTier);
        }
        else 
        {
            formik.setFieldValue('sponsorTier', undefined);
            formik.setFieldValue('newTierName', '');
        }

    }

    const handleSponsorTierPopup = (): void => {
        setSponsorTierPopup(!sponsorTierPopup);
    }

    const delayDebounceRegistrantSearch = debounce(async(newValue: string): Promise<void> =>
    {
        if (newValue?.length > 2) 
        {
            try 
            {

                const params = {
                    email: newValue,
                };

                const registrantData = await searchEventRegistrant(
                    eventId as string,
                    params?.email ? params.email : undefined,
                    undefined,
                    undefined,
                );

                if (registrantData) {
                    const mergedRegistrants = [...registrants];
                    for (const data of registrantData) {
                        if (!mergedRegistrants.some(registrant => registrant.id === data.id)) {
                            mergedRegistrants.push(data);
                        }
                    }
                    setRegistrants(mergedRegistrants);
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    }, 300);

    const handleRegistrantChange = (newValue: EventRegistrant[] | null): void => {
        if (newValue) {
            const updatedRegistrants = newValue?.flat(Infinity);
            formik.setFieldValue('registrants', updatedRegistrants);
        } else {
            formik.setFieldValue('registrants', []);
        }
    };

    const handleRegistrantSearch = (newValue: string):void => 
    {
        formik.setFieldValue('registrantSearchInput', newValue);
        if(newValue === '')
        {
            const mergedRegistrants = [...registrants];
            if(formik.values?.registrants && formik.values?.registrants?.length > 0)
            {
                for (const selectedOption of formik.values?.registrants) {
                    if (!mergedRegistrants.some(registrant => registrant?.id === selectedOption?.id)) {
                        mergedRegistrants.push(selectedOption);
                    }
                }
                setRegistrants(mergedRegistrants);
            }
        }
        else
        {
            delayDebounceRegistrantSearch(newValue);
        }
    };

    const fetchInitialRegistrantData = async ():Promise<void> => 
    {
        try 
        {
            const registrantData = await getAllEventRegistrants(eventId as string, 25, 0, ['status', EventRegistrantStatus.CONFIRMED]);
            if(registrantData)
            {
                setRegistrants(registrantData);
            }

            if (sponsorData && sponsorData?.registrants && sponsorData?.registrants?.length > 0)
            {
                const filteredRegistrants = _.filter(registrantData, function (registrant) {
                    return !sponsorData?.registrants?.some((element: EventRegistrant):boolean => element.id == registrant.id);
                });
    
                if (filteredRegistrants?.length <= 10)
                {
                    const registrantDataNextBatch = await getAllEventRegistrants(eventId as string, 25, 1, ['status', EventRegistrantStatus.CONFIRMED]);
                    if(registrantDataNextBatch?.length > 0)
                    {
                        setRegistrants(registrantDataNextBatch);
                    }
                }
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching registrants', error);
        }
    };

    const fetchSponsorsCustomFieldData = async ():Promise<void> => 
    {
        try 
        {
            setCustomFieldsSpinner(true);
            const cfcount = await customFieldCountByLocation(CustomFieldLocations.SPONSOR);
            if(cfcount)
            {
                try 
                {
                    const customFields = await customFieldsByLocation(cfcount,0,CustomFieldLocations.SPONSOR);
                    if(customFields)
                    {
                        setCustomFields(customFields as any);
                        setCustomFieldsSpinner(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setCustomFieldsSpinner(false);
                }
            }
            
        }
        catch (error) 
        {
            console.log(error);
            setCustomFieldsSpinner(false);
        }
        
    };

    const fetchSponsorActivityLogs = async (): Promise<void> =>
    {
        try 
        {
            const activityLogs = await getSponsorActivityLogs(sponsorData?.id);
            if(activityLogs)
            {
                setActivityLogs(activityLogs);
            }   
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const handleBackToPopup = (): void =>
    {
        setShowActivityLog(false);
    };

    const fetchSponsorTiers = async (): Promise<void> => {
        try 
        {
            const tierCount = await sponsorTierCount(eventId as string);
            const tiers = await getAllSponsorTiers(tierCount, 0, eventId as string);
            if(tiers)
            {
                setSponsorTiers(tiers);
                if(sponsorData)
                {
                    const selectedSponsorTier = _.filter(tiers, function (tier):boolean 
                    {
                        return sponsorData.sponsorTierId === tier?.id;
                    })[0];
                    formik.setFieldValue('sponsorTier', selectedSponsorTier);
                }
            }
        } 
        catch (error) 
        {
            console.log(error, 'Error fetching sponsor tiers');
        }
    };

    useEffect(():void => 
    {
        fetchSponsorsCustomFieldData();

        if (sponsorData) 
        {
            fetchSponsorActivityLogs();

            if(sponsorData?.registrants)
            {
                formik.setFieldValue('registrants', sponsorData?.registrants);
            }

            setSponsorImage({
                file: undefined,
                blob: sponsorData?.logo || '',
            })

            dispatch(addSponsors({
                logo: sponsorData.logo !== null ? sponsorData.logo : '',
                name: sponsorData.name,
                pocName: sponsorData.pocName ? sponsorData.pocName : '',
                pocEmail: sponsorData.pocEmail ? sponsorData.pocEmail : '',
                websiteLink: sponsorData.websiteLink,
                social: sponsorData?.social,
            }));

            if (sponsorData?.logo) 
            {
                getImageUrl(sponsorData?.logo);
            }
            if(sponsorData?.additionalFieldData)
            {
                setCustomFormState({
                    ...sponsorData?.additionalFieldData
                });
            
            }
        }
    }, [sponsorData]);

    // UseEffect to call registrants and sponsor tier apis
    useEffect(() => {
        if(orgBillingInfo?.productType === ProductType.EVENTHQ || orgBillingInfo?.eventhq === EventHQEventLimit.THREE)
        {
            fetchSponsorTiers();
            fetchInitialRegistrantData();
        }
    }, [orgBillingInfo]);

    const renderFormFields = ()=>
    {
        return (
            <>
                {
                    (orgBillingInfo?.productType === ProductType.EVENTHQ || orgBillingInfo?.eventhq === EventHQEventLimit.THREE) &&
                    <>
                        {/* Sponsor tier select */}
                        {renderSponsorTier()}

                        {/* Registrants */}
                        {renderSponsorRegistrants()}
                    </>
                }

                {/* Logo */}
                <div className="popup-container-spacing">
                    <div className="image-activity-log-container">
                        <div>
                            <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.LOGO} />
                            {
                                sponsorImage?.blob !== '' ?
                                    // (!addEventSponsor && (logo !== '' || sponsorReduxData.logo !== '')) &&
                                    <div className="preview-upload-box">
                                        <Preview />
                                        {
                                            !addEventSponsor || !disabled ?
                                                <React.Fragment>
                                                    <div className="icon-box">
                                                        <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={():void => 
                                                        {
                                                            setSponsorImage({
                                                                file: undefined,
                                                                blob: ''
                                                            })
                                                        }} />
                                                    </div>
                                                </React.Fragment>
                                                : null
                                        }
                                        {/* <Typography>{'Dimension : 82px X 82px , Size < 3MB'}</Typography> */}
                                    </div>
                                    :
                                    <React.Fragment>
                                        {
                                            !addEventSponsor || !disabled ?
                                                <React.Fragment>
                                                    <div {...getRootProps()} className="empty-upload-box">
                                                        <input {...getInputProps()} hidden accept="image/*" type="file" />
                                                        <FontAwesomeIcon className="upload-icon" icon={['fal', 'cloud-arrow-up']} />
                                                        <Typography>{'Browse File'}</Typography>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                null
                                        }
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </div>

                {/* Sponsor name */}
                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.TITLE} required />
                    <FormControlComponent
                        value={formik.values.name}
                        type="text"
                        placeholder={CONTENT.SPONSORS_PAGE.FORMS.PLACEHOLDER}
                        disabled={disabled || false}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('name', event.target.value);
                        }}
                        required
                        isInvalid={!!formik.errors.name && formik.touched.name}
                    />
                    {formik.errors.name && formik.touched.name ? <Typography className="error-msg">{formik.errors.name}</Typography> : null}
                </div>

                {/* POC Name */}
                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.POC_NAME} required />
                    <FormControlComponent
                        value={formik.values.pocName}
                        type="text"
                        disabled={disabled || false}
                        placeholder="Enter POC Name"
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('pocName', event.target.value);
                        }}
                        required
                        isInvalid={!!formik.errors.pocName && formik.touched.pocName}
                    />
                    {formik.errors.pocName && formik.touched.pocName ? <Typography className="error-msg">{formik.errors.pocName}</Typography> : null}
                </div>

                {/* POC Email */}
                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.POC_EMAIL} required />
                    <FormControlComponent
                        value={formik.values.pocEmail}
                        type="text"
                        placeholder={'Enter POC Email'}
                        disabled={disabled || false}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('pocEmail', event.target.value?.toLowerCase());
                        }}
                        required
                        isInvalid={!!formik.errors.pocEmail && formik.touched.pocEmail}
                    />
                    {formik.errors.pocEmail && formik.touched.pocEmail ? <Typography className="error-msg">{formik.errors.pocEmail}</Typography> : null}
                </div>

                {/* Website */}
                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.WEBSITE} required />
                    <FormControlComponent
                        value={formik.values.websiteLink}
                        placeholder={'Enter Sponsor Website'}
                        disabled={disabled || false}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('websiteLink', event.target.value);
                        }}
                        required
                        type="text"
                        isInvalid={!!formik.errors.websiteLink && formik.touched.websiteLink}
                    />
                    {formik.errors.websiteLink && formik.touched.websiteLink ? <Typography className="error-msg">{formik.errors.websiteLink}</Typography> : null}
                </div>

                {/* LinkedIn */}
                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.LINKEDIN} />
                    <FormControlComponent
                        value={formik.values.linkedIn}
                        placeholder={'URL'}
                        type="text"
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('linkedIn', event.target.value);
                        }}
                        disabled={disabled || false}
                        isInvalid={!!formik.errors.linkedIn && formik.touched.linkedIn}
                    />
                    {formik.errors.linkedIn && formik.touched.linkedIn ? <Typography className="error-msg">{formik.errors.linkedIn}</Typography> : null}
                </div>

                {/* Twitter */}
                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.TWITTER} />
                    <FormControlComponent
                        type="text"
                        value={formik.values.twitter}
                        placeholder={'URL'}
                        disabled={disabled || false}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('twitter', event.target.value);
                        }}
                        isInvalid={!!formik.errors.twitter && formik.touched.twitter}
                    />
                    {formik.errors.twitter && formik.touched.twitter ? <Typography className="error-msg">{formik.errors.twitter}</Typography> : null}
                </div>

                {/* Custom Fields */}
                {customFields?.length > 0 &&
                <div className="custom-field-container">
                    {customFields.length > 0 &&
                    customFieldsSpinner ? <CustomSpinner height={'100%'}/> :
                        customFields?.map((item) => 
                        {
                            if (item.type === CustomFieldType.NUMBER) 
                            {
                                return renderInputComponent(item, 'number', handleInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                            }
                            else if (item.type === CustomFieldType.SINGLE_LINE) 
                            {
                                return renderInputComponent(item, 'text', handleInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                            }
                            else if (item.type === CustomFieldType.MULTI_LINE) 
                            {
                                return renderInputComponent(item, 'textarea', handleInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                            }
                            else if (item.type === CustomFieldType.CHECKBOXES) 
                            {
                                return renderCheckBoxComponent(item, handleCheckboxChange, customFormState?.[item.label + ' ' + item.id], disabled);
                            }
                            else if (item.type === CustomFieldType.DROPDOWN) 
                            {
                                return renderDropDownComponent(item, handleDropDownInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                            }
                            else if (item.type === CustomFieldType.RADIO) 
                            {
                                return renderRadioGroupComponent(item, handleBoxChange, customFormState?.[item.label + ' ' + item.id], disabled);
                            }
                            else if (item.type === CustomFieldType.DATE) 
                            {
                                return renderDateComponent(item, handleInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                            }
                        })
                    }
                </div>
                }
            </>
        )
    }

    const renderHeader = () => {

        if(disabled || props?.isEventSponsorCreate) {
            return <></>
        }

        return (
            <div className='popup-header'>
                <div className='popup-header-content'>
                    {showActivityLog ? (
                        <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} onClick={handleBackToPopup} />
                    ) : (
                        <div className='popup-header-icon'><FontAwesomeIcon icon={['fal', 'dollar-sign']} /></div>
                    )}

                    <div className='popup-header-text'>
                        <h3>
                            {showActivityLog ? 'Activty' : heading}
                        </h3>
                        <p>
                            {showActivityLog ? sponsorData?.name : 'Add sponsor name and other details.'}
                        </p>
                    </div>
                </div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => props.handlePopupClose()} />
            </div>
        );
    }

    const renderSponsorTier = () => {
        return (
            <div className="popup-container-spacing">
                <FormLabelComponent label="Add or Create a Sponsor Tier" required />
                <div className="sponsor-tier-dropdown-container">
                    <div style={{ width: '100%' }}>
                        <AutocompleteComponentWithFilterOption value={formik.values.sponsorTier?.name || ''} onChange={handleTierSelectChange} keyToShow='name' optionsArr={sponsorTiers} filterOptions={handleTierFilterOptions} placeholder={sponsorTiers?.length > 0 ? 'Add a Sponsor Tier' : 'Type something to create a Sponsor Tier'} />
                    </div>
                    <CustomButton btnType='secondary' name='Edit' onClick={handleEditSponsorTier} disabled={!formik.values.sponsorTier} />
                </div>
                {formik.errors.sponsorTier && formik.touched.sponsorTier ? <Typography className="error-msg">{formik.errors.sponsorTier}</Typography> : null}
            </div>
        )
    };

    const renderSponsorRegistrants = () => {
        return (
            <div className="sponsor-attendee-container">
                <div className="sponsor-attendee-label">
                    <FormLabelComponent label='Sponsor Attendee' />
                    <CustomTooltip title={'Attendees who are confirmed can be added'}><FontAwesomeIcon icon={['fal', 'info-circle']} /></CustomTooltip>
                </div>
                <DebouncedAutocomplete 
                    value={formik.values?.registrants || []}
                    onChange={(event, newValue): void => {
                        handleRegistrantChange(newValue);
                    }}
                    noOptionsText="No Registrants"
                    inputValue={formik.values?.registrantSearchInput || ''}
                    onInputChange={(event, value) => handleRegistrantSearch(value)}
                    options={registrants}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.id}>
                            {option?.email || 'No Email'}
                        </li>
                    )}
                    isOptionEqualToValue={(option, value): boolean => option?.email === value?.email}
                    getOptionLabel={(option): string => option?.email || 'No Email'}
                    placeholder="Search for a Registrant"
                    className="registrant-select"
                />

                {
                    formik.values?.registrants && formik.values?.registrants?.length > 0 &&
                    <div className="registrant-selected-container">
                        {
                            formik.values?.registrants?.map((element: EventRegistrant): React.JSX.Element => 
                            {
                                const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
                                return (
                                    <MuiChip
                                        circleIcon 
                                        chipColor={chipColors[Number(element?.id) % chipColors.length] as any}
                                        label={<Stack direction={'row'} spacing={1} key={element.id} className="registrant-selected-label">
                                        {element?.firstName}
                                        <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-btn" onClick={(): void => 
                                        {
                                            const filteredRegistrants = formik.values?.registrants.filter((registrant): boolean => registrant.id !== element.id);
                                            formik.setFieldValue('registrants', filteredRegistrants);
                                        }} />
                                    </Stack>}
                                    />
                                    
                                );
                            })
                        }
                    </div>
                }
            </div>
        )
    };

    const renderMainContent = () => {
        if(showActivityLog && sponsorData){
            return <ActivityLogComponent activityLog={sponsorsActivityLog(activityLogs, sponsorData)} />
        }else {
            return renderFormFields();
        }
    }

    const renderFooter = () => {

        if(disabled || showActivityLog) {
            return <></>
        }
            

        return (
            <div className="popup-footer">
                {(!sponsorData) ? <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                    <CustomButton btnType='secondary' name={CONTENT.SIDE_DRAWER.CLOSE_BTN} type='button' onClick={handleDrawerClose} />
                    <CustomButton onClick={(e): void => { e.preventDefault(); formik.handleSubmit(); }} name={'Save'} type="submit" btnType='primary' loading={spinner} />
                </Stack>
                    : (sponsorData) &&
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        {
                            !props?.tableView ?
                            <div className='popup-footer-left'>
                                <div className="ticket-activity-log-button">
                                    <CustomButton
                                        name=""
                                        btnType="secondary"
                                        startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                                        style={{ width: '32px' }}
                                        onClick={handleNestedSidebarOpen}
                                    />
                                </div>
                            </div> : <div />
                        }

                        <Stack direction={'row'} spacing={1} display={'flex'}>
                            <CustomButton btnType='secondary' name={CONTENT.SIDE_DRAWER.CLOSE_BTN} type='button' onClick={handleDrawerClose} />
                            <CustomButton onClick={(e): void => { e.preventDefault(); formik.handleSubmit(); }} name={'Save'} type="submit" btnType='primary' loading={spinner} />
                        </Stack>
                    </Stack>
                }
            </div>
        )
    };

    return (
        <div id="createSponsor">
            {heading && renderHeader()}
            {/* {!props.addEventSponsor && <Stack className="required-icon-stack" direction={'row'}><Typography className="required-icon">*</Typography><Typography className="required-field-text">{CONTENT.SIDE_DRAWER.FORM_REQUIRED_TEXT}</Typography></Stack>} */}
            <Form noValidate autoComplete="off" onSubmit={(values): void => 
            {
                return formik.handleSubmit(values);
            }}>

                <div className={props?.tableView ? 'sponsor-form-drawer' : 'popup-container'}>
                    {renderMainContent()}
                </div>

                {renderFooter()}

                {/* sponsor tier popup */}
                {
                    sponsorTierPopup && (
                        <PopupDialog 
                            show={sponsorTierPopup}
                            modalContent={<CreateSponsorGroup
                                eventId={eventId as string}
                                onClose={handleSponsorTierPopup}
                                existingSponsorTier={formik.values.sponsorTier} 
                                tierName={formik.values.newTierName || ''}
                                onUpdate={handleSponsorTierUpdates}
                            />}
                            cancelClick={handleSponsorTierPopup}
                        />
                    )
                }

            </Form >
        </div >
    );
};

export default CreateSponsor;