import { useSelector } from "react-redux";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { AdditionalInfoComponent, AutocompletewithTags, FormControlComponent, FormLabelComponent, SelectComponent, ToggleButtonGroupComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { useEffect, useState } from "react";
import { EventTicket } from "../../../pages/Events/interfaces";
import { getAlleventTickets, ticketsCount } from "../../../scripts/apis/eventTickets";
import eventBus from "../../../scripts/event-bus";
import moment from "moment";
import { FormControlLabel, Stack } from "@mui/material";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { CONTENT } from "../../../scripts/i18n";
import { EventCoupon } from "../../../pages/Events/interfaces/event-coupon_interface";
import { createCoupon, deleteCoupon, getEventCouponActivityLogs, updateCoupon } from "../../../scripts/apis/eventTicketCoupons";
import _ from "lodash";
import { Currency, TicketType } from "../../../pages/Events/enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeletePopup from "../../../common/DeletePopup";
import toast from "react-hot-toast";
import { IEventReduxValues, IEventsDispatch } from "../interfaces/create-events_interface";
import DateTimePicker from "../../../common/FormComponents/DateTimePicker";
import { combineDateTime, currencyOptionLabel } from "../../../scripts/helpers";
import getSymbolCurrency from "currency-symbol-map";
import { getEmojiByCurrencyCode } from "country-currency-emoji-flags";
import './styles.scss';
import { ActivityLogResponse } from "../../../interfaces/activity-log_interface";
import ActivityLogComponent from "../../../common/ActivityLog/ActivityLogComponent";
import { eventCouponsActivityLog } from "../../../scripts/activityLogGenerator/activitylog-generator";
import { CustomSwitch } from "../../../common/StyledComponents/Switch.styled";

interface IAddCouponProps
{
    eventId: string | number;
    existingCouponData?: EventCoupon | undefined | null;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    isTableView?: boolean;
    handleCouponPopupToggle: () => void;
}

const AddCoupon: React.FC<IAddCouponProps> = (props): React.JSX.Element => 
{

    const { eventId, existingCouponData, setRefresh, isTableView } = props;

    const isCouponDataEditable = existingCouponData ? Number(existingCouponData?.couponsCount) !== 0 : false;

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues =>
    {
        return state.events.value;
    });

    const [eventTickets, setEventTickets] = useState<EventTicket[]>([]);
    const [eventTicketOptions, setEventTicketOptions] = useState<EventTicket[]>([]);
    const [expiredTickets, setExpiredTickets] = useState<string[]>([]);
    const [spinner, setSpinner] = useState<boolean>(false);
    const [minMaxTime, setMinMaxTime] = useState<{min: number, max: number}>({min: 0, max: 0});
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    const [showActivityLog, setShowActivityLog] = useState<boolean>(false);
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const [showCapacityTime, setShowCapacityTime] = useState<boolean>(false);
    const [showSaleStartComponent, setShowSaleStartComponent] = useState<boolean>(false);
    const [showSaleEndComponent, setShowSaleEndComponent] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState(moment());
    const [headerText, setHeaderText] = useState<string>('Create Coupon');
    const [headerDescription, setHeaderDescription] = useState<string>('Create exclusive deals for your audience.');

    const ticketTypeOptions = [
        {
            name: 'All', value: 'all', description: 'All Tickets will be available for this coupon code', icon: <FontAwesomeIcon icon={['fal', 'layer-group']} />
        },
        {
            name: 'Custom', value: 'custom', description: 'Select specific tickets for this coupon code', icon: <FontAwesomeIcon icon={['fal', 'diamond']} />
        }
    ];

    const couponTypeOptions = [
        {
            name: 'Currency', value: 'currency', description: 'Coupon Value in Currency', icon: <FontAwesomeIcon icon={['fal', 'dollar-sign']} />
        },
        {
            name: 'Percentage', value: 'percentage', description: 'Coupon Value in Percentage', icon: <FontAwesomeIcon icon={['fal', 'percent']} />
        }
    ];

    const generateCurrencyOptions = () => {
        const currencyOptions =
            [
                {
                    id: Currency.CANADIAN_DOLLAR, name: getSymbolCurrency(Currency.CANADIAN_DOLLAR) as string, startIcon: getEmojiByCurrencyCode(Currency.CANADIAN_DOLLAR)
                },
                {
                    id: Currency.US_DOLLAR, name: getSymbolCurrency(Currency.US_DOLLAR) as string, startIcon: getEmojiByCurrencyCode(Currency.US_DOLLAR)
                },
                {
                    id: Currency.INDIAN_RUPEE, name: getSymbolCurrency(Currency.INDIAN_RUPEE) as string, startIcon: getEmojiByCurrencyCode(Currency.INDIAN_RUPEE)
                },
                {
                    id: Currency.EURO, name: getSymbolCurrency(Currency.EURO) as string, startIcon: getEmojiByCurrencyCode(Currency.EURO)
                },
                {
                    id: Currency.BRITISH_POUND, name: getSymbolCurrency(Currency.BRITISH_POUND) as string, startIcon: getEmojiByCurrencyCode(Currency.BRITISH_POUND)
                }
            ];
        return currencyOptions;
    };

    const handleBackToPopup = (): void => 
    {
        setShowActivityLog(false);
        setShowCapacityTime(false);

        setHeaderText(existingCouponData ? 'Edit Coupon' : 'Create Coupon');
        setHeaderDescription(existingCouponData ? 'Change discount values, coupon name, or validity dates.' : 'Create exclusive deals for your audience.');
    }

    const handleValuesChange = (key: string, value: string | number | any, markAsTouched: boolean = true, customHandler?: () => void) => {
        if (customHandler) {
            customHandler();
        } else {
            formik.setFieldValue(key, value);
            if (markAsTouched) {
                formik.setFieldTouched(key, true, true);
            }
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[a-zA-Z0-9-_]*$/, 'Only alphabets and numbers are allowed')
            .required('Name is required'),
        // totalCouponsAvailable: Yup.number().required('Total Coupons Available is required'),
        ticketSelection: Yup.string(),
        ticketNames: Yup.array().of(Yup.string()).test('isTicketNamesRequired', 'Select atleast one ticket', function (value) {
            if (eventTickets?.length > 0 && value?.length === 0 && this.parent.ticketSelection === 'custom') {
                return false;
            }
            return true;
        }),
        description: Yup.string(),
        // couponStartDateTime: Yup.string().required('Coupon Start Date Time is required'),
        // couponEndDateTime: Yup.string().required('Coupon End Date Time is required'),
        couponValueInCurrency: Yup.string().test('isCurrencyValueRequired', 'Coupon Value is required', function (value) {
            if (this.parent.couponType === 'currency' && !value) {
                return false;
            }
            return true;
        }),
        couponValueInPercentage: Yup.string().test('isPercentageValueRequired', 'Coupon Percentage is required', function (value) {
            if (this.parent.couponType === 'percentage' && !value) {
                return false;
            }
            return true;
        }),
    });

    const handleDrawerClose = (): void => {
        props?.handleCouponPopupToggle();
        eventBus.dispatch('selected-row-id', null);
    };

    const formik = useFormik({
        // enableReinitialize: true, 
        validationSchema: validationSchema,
        initialValues: {
            name: existingCouponData?.name || '',
            totalCouponsAvailable: existingCouponData?.totalCouponsAvailable || '',
            ticketSelection: existingCouponData?.ticketIds?.length === eventTicketOptions?.length ? 'all' : 'custom',
            ticketNames: existingCouponData ? existingCouponData?.tickets?.map((ticket) => ticket?.name) || [] : [],
            description: existingCouponData?.description || '',
            couponStartDate: existingCouponData && existingCouponData?.couponCodeStartDateTime ? moment.unix(Number(existingCouponData?.couponCodeStartDateTime)) : currentTime,
            couponEndDate: existingCouponData && existingCouponData?.couponCodeCloseDateTime ? moment.unix(Number(existingCouponData?.couponCodeCloseDateTime)) : moment.unix(Number(eventReduxData?.closingDateTime)),
            couponStartTime: existingCouponData && existingCouponData?.couponCodeStartDateTime ? moment.unix(Number(existingCouponData?.couponCodeStartDateTime)).format('HH:mm') : moment().format('HH:mm'),
            couponEndTime: existingCouponData && existingCouponData?.couponCodeCloseDateTime ? moment.unix(Number(existingCouponData?.couponCodeCloseDateTime)).format('HH:mm') : moment.unix(Number(eventReduxData?.closingDateTime)).format('HH:mm'),
            couponType: existingCouponData?.couponPercentage ? 'percentage' : 'currency',
            couponValueInCurrency: existingCouponData?.couponValue || '',
            couponValueInPercentage: existingCouponData?.couponPercentage || '',
            currency: existingCouponData && existingCouponData?.currency !== Currency.NONE ? existingCouponData?.currency : Currency.INDIAN_RUPEE,
        },
        onSubmit: async (values) => {

            if (!showSaleStartComponent) {
                values.couponStartDate = moment.unix(minMaxTime.min);
                values.couponStartTime = moment.unix(minMaxTime.min).format('HH:mm');
            }

            if (!showSaleEndComponent) {
                values.couponEndDate = moment.unix(minMaxTime.max);
                values.couponEndTime = moment.unix(minMaxTime.max).format('HH:mm');
            }

            const couponCodeStartDateTime = combineDateTime(moment(values.couponStartDate), values.couponStartTime);
            const couponCodeCloseDateTime = combineDateTime(moment(values.couponEndDate), values.couponEndTime);

            if (couponCodeStartDateTime > couponCodeCloseDateTime || couponCodeCloseDateTime < couponCodeStartDateTime) {
                toast.error('Invalid Coupon Date Time');
                return;
            }

            if ((couponCodeStartDateTime < minMaxTime.min) || (couponCodeCloseDateTime > minMaxTime.max)) {
                toast.error('Coupon Date Time should be within Ticket Sale Date Time');
                return;
            }

            setSpinner(true);

            const filteredEventTicketIds = eventTicketOptions?.filter((ticket) => !expiredTickets?.includes(ticket?.name))?.map((ticket) => ticket?.id);

            const ticketIds = values?.ticketSelection === 'all' ? filteredEventTicketIds : eventTickets.filter((ticket) => values.ticketNames?.includes(ticket?.name)).map((ticket) => ticket.id);

            const data = {
                name: values.name,
                totalCouponsAvailable: values.totalCouponsAvailable,
                ticketIds: ticketIds || [],
                description: values.description,
                couponCodeStartDateTime,
                couponCodeCloseDateTime,
                couponValue: values.couponValueInCurrency !== '' ? values.couponValueInCurrency : null,
                couponPercentage: values.couponValueInPercentage !== '' ? values.couponValueInPercentage : null,
                ...(values.couponType === 'currency' && { currency: values.currency }),
            };

            const updatedPayload = _.pickBy(data, (value, key) => {
                if (key === 'couponValue' || key === 'couponPercentage') {
                    return true;
                }
                return value !== null && value !== undefined && value !== '';
            });

            if (existingCouponData) {
                try {
                    const couponCreated = await updateCoupon(eventId, existingCouponData?.id || '', updatedPayload);
                    if (couponCreated) {
                        setRefresh(true);
                        handleDrawerClose();
                        toast.success('Coupon Details Updated');
                    }
                }
                catch (error) {
                    console.log(error, 'Error in updating coupon');
                }
                finally {
                    setSpinner(false);
                }
            }
            else {
                try {
                    const couponCreated = await createCoupon(eventId, updatedPayload, csrfTokenData);
                    if (couponCreated) {
                        setRefresh(true);
                        handleDrawerClose();
                        toast.success('Coupon Created Successfully');
                    }
                }
                catch (error) {
                    console.log(error, 'Error in creating coupon');
                }
                finally {
                    setSpinner(false);
                }
            }
        }
    });

    const handleCouponTypeChange = (
        event: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
        values: any
    ): void => 
    {
        handleValuesChange('couponType', (event.target as HTMLInputElement).value);

        if ((event.target as HTMLInputElement).value === 'percentage') {
            setEventTicketOptions(eventTickets);
        }
        else 
        {
            const ticketsBasedOnSelectedCurrency = eventTickets?.length > 0 && eventTickets?.filter((ticket) => ticket?.currency === values.currency);
            if (ticketsBasedOnSelectedCurrency && ticketsBasedOnSelectedCurrency?.length > 0) {
                setEventTicketOptions(ticketsBasedOnSelectedCurrency);
            }
            else 
            {
                setEventTicketOptions([]);
            }
        }
    };

    const handleTicketChange = (event: any, newValue: string[]): void =>
    {
        handleValuesChange('ticketNames', newValue);

        if (newValue?.length === eventTicketOptions?.length) {
            handleValuesChange('ticketSelection', 'all');
        }
    };

    const handleTicketSelection = (event: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>, values: any): void => 
    {
        handleValuesChange('ticketSelection', (event.target as HTMLInputElement).value);
        const ticketsBasedOnSelectedCurrency = eventTickets?.length > 0 && eventTickets?.filter((ticket) => ticket?.currency === values.currency);
        if (ticketsBasedOnSelectedCurrency && ticketsBasedOnSelectedCurrency?.length > 0) {
            handleValuesChange('ticketNames', ticketsBasedOnSelectedCurrency?.map((ticket) => ticket?.name));
        }
        else {
            handleValuesChange('ticketNames', []);
        }
    };

    const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>): void => 
    {
        handleValuesChange('currency', event.target.value);
        handleValuesChange('ticketNames', []);
        const ticketsBasedOnSelectedCurrency = eventTickets?.length > 0 && eventTickets?.filter((ticket) => ticket?.currency === event.target.value);
        if (ticketsBasedOnSelectedCurrency && ticketsBasedOnSelectedCurrency?.length > 0) {
            setEventTicketOptions(ticketsBasedOnSelectedCurrency);
        }
        else {
            setEventTicketOptions([]);
        }
    };



    const toggleComponent = (component: string, value?: boolean) => 
    {
        switch (component) {
            case 'description':
                setShowDescription(prev => !prev);
                break;
            case 'capacityTime':
                setShowCapacityTime(prev => !prev);
                break;
            case 'saleStart':
                setShowSaleStartComponent(value as boolean);
                break;
            case 'saleEnd':
                setShowSaleEndComponent(value as boolean);
                break;
            default:
                break;
        }
    };

    const deleteCouponFn = async (coupon: EventCoupon): Promise<void> =>
    {    
        try 
        {
            const couponDeleted = await deleteCoupon(eventId, coupon.id as string);
            if (couponDeleted) 
            {
                setShowDeletePopup(false);
                handleDrawerClose();
                toast.success('Coupon Deleted Successfully');
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }   
    };

    const handleDeleteCoupon = (): void =>
    {
        setShowDeletePopup(true);
    };

    const handleNestedSidebarOpen = (): void => {
        setHeaderText('Activity');
        setHeaderDescription(existingCouponData?.name || '');
        setShowActivityLog(true);
        // eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.UPDATE_EVENT, {
        //     component: <ActivityLogViewer><ActivityLogComponent activityLog={eventCouponsActivityLog(activityLogs, existingCouponData, eventTickets)} /></ActivityLogViewer>
        // });
        // eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.OPEN_EVENT, {
        //     open: true,
        //     right: '501'
        // });
    };

    const setCouponData = (couponData: EventCoupon): void =>
    {
        if(couponData?.description)
        {
            setShowDescription(true);
        }
        if(couponData?.couponCodeStartDateTime)
        {
            setShowSaleStartComponent(true);
        }
        if(couponData?.couponCodeCloseDateTime)
        {
            setShowSaleEndComponent(true);
        }
    }

    const fetchActivityLogs = async (): Promise<void> =>
    {
        try 
        {
            const activityLogs = await getEventCouponActivityLogs(eventId, Number(existingCouponData?.id));
            if (activityLogs) {
                setActivityLogs(activityLogs);
            }
        }
        catch (error) {
            console.log('Error in fetching activity logs');
        }
    };

    const fetchEventTickets = async (): Promise<void> =>
    {
        try 
        {
            const count = await ticketsCount(eventId);
            if(count)
            {
                const tickets = await getAlleventTickets(count, 0, Number(eventId));
                if(tickets)
                {
                    const ticketsWithPrice = tickets?.filter((ticket) => ticket?.type === TicketType.PAID);
                    setEventTickets(ticketsWithPrice);

                    const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
                    const expiredTickets = ticketsWithPrice?.filter((ticket) => ticket?.saleCloseDateTime < currentTimestamp)?.map((ticket) => ticket?.name) || [];
                    setExpiredTickets(expiredTickets);

                    const validTickets = ticketsWithPrice.filter(ticket => !expiredTickets.includes(ticket.name));

                    if(existingCouponData && existingCouponData?.ticketIds?.length > 0 && existingCouponData?.couponValue === 0)
                    {
                        setEventTicketOptions(validTickets);
                    }
                    else
                    {
                        if(existingCouponData && existingCouponData?.currency !== Currency.NONE)
                        {
                            const ticketOptions = validTickets.filter((ticket) => ticket?.currency === existingCouponData?.currency);
                            setEventTicketOptions(ticketOptions);
                        }
                        else
                        {
                            const ticketOptions = validTickets.filter((ticket) => ticket?.currency === Currency.INDIAN_RUPEE);
                            setEventTicketOptions(ticketOptions);
                        }
                    }

                    const earliestTicket = validTickets.reduce((prev, current) => (prev.saleCloseDateTime < current.saleStartDateTime ? prev : current), validTickets[0]);
                    const latestTicket = validTickets.reduce((prev, current) => (prev.saleCloseDateTime > current.saleStartDateTime ? prev : current), validTickets[0]);
 
                    setMinMaxTime({
                        min: Number(earliestTicket.saleStartDateTime),
                        max: Number(latestTicket.saleCloseDateTime)
                    })
                }
            }
        } 
        catch (error) 
        {
            console.log(error, 'Error in fetching event tickets')
        }
    };

    useEffect(() => {
        fetchEventTickets();
        if(existingCouponData)
        {
            setCouponData(existingCouponData);
            fetchActivityLogs();
        }
    }, []);

    const renderActivityLog = () => 
    {
        return (
            <ActivityLogComponent activityLog={eventCouponsActivityLog(activityLogs, existingCouponData || undefined, eventTickets)} />
        );
    }

    const renderAdvancedInfo = () => 
    {
        return (
            <>
                {!showCapacityTime ? (
                    <div className="popup-container-spacing">
                        <AdditionalInfoComponent
                            title="Advanced"
                            description={existingCouponData ? `Total Coupons: ${existingCouponData.couponsCount} • ${moment.unix(existingCouponData.couponCodeStartDateTime).format('DD/MM/YY')} - ${moment.unix(existingCouponData.couponCodeCloseDateTime).format('DD/MM/YY')} ` : "Restrict by Capacity, Date or Stage"}
                            onClick={() => toggleComponent('capacityTime')}
                        />
                    </div>
                ) : (
                    <>
                        {/* Total Coupons Available */}
                        <div className="popup-container-spacing">
                            <FormLabelComponent label="Total Coupons Available" />
                            <FormControlComponent
                                type="number"
                                name="totalCouponsAvailable"
                                disabled={isCouponDataEditable}
                                placeholder="100"
                                value={formik.values.totalCouponsAvailable}
                                onChange={formik.handleChange}
                                isInvalid={(formik.touched.totalCouponsAvailable && formik.errors.totalCouponsAvailable) as boolean}
                            />
                            {formik.errors.totalCouponsAvailable && formik.touched.totalCouponsAvailable && <div className="error-msg">{formik.errors.totalCouponsAvailable}</div>}
                            <p className="description-text">{'Total number of tickets that can be purchased with this code'}</p>
                        </div>


                        {/* Coupon Date Time */}
                        <div className="popup-container-spacing">
                            <div className="time-container">
                                <div className='time-approval-container'>
                                    <div className="approval-icon-label-container">
                                        <FontAwesomeIcon icon={['fal', 'clock']} className="approval-icon" />
                                        <FormLabelComponent label={'Sale Start'} noBottomMargin />
                                    </div>
                                    <FormControlLabel
                                        control={<CustomSwitch
                                            checked={showSaleStartComponent}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                toggleComponent('saleStart', event.target.checked);
                                            }}
                                        />}
                                        label={''}
                                    />
                                </div>
                                {showSaleStartComponent && <div>
                                    <DateTimePicker
                                        dateValue={moment(formik.values.couponStartDate, 'DD/MM/YYYY').unix()}
                                        timeValue={formik.values.couponStartTime}
                                        onDateChange={(date) => handleValuesChange('couponStartDate', date)}
                                        onTimeChange={(time) => handleValuesChange('couponStartTime', time)}
                                        dateFormat='DD/MM/YYYY'
                                        minDate={minMaxTime?.min}
                                        maxDate={minMaxTime?.max}
                                    />
                                </div>}
                            </div>
                        </div>

                        <div className="popup-container-spacing">
                            <div className="time-container">
                                <div className='time-approval-container'>
                                    <div className="approval-icon-label-container">
                                        <FontAwesomeIcon icon={['fal', 'clock']} className="approval-icon" />
                                        <FormLabelComponent label={'Sale End'} noBottomMargin />
                                    </div>
                                    <FormControlLabel
                                        control={<CustomSwitch
                                            checked={showSaleEndComponent}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                toggleComponent('saleEnd', event.target.checked);
                                            }}
                                        />}
                                        label={''}
                                    />
                                </div>
                                {showSaleEndComponent && <div>
                                    <DateTimePicker
                                        dateValue={moment(formik.values.couponEndDate, 'DD/MM/YYYY').unix()}
                                        timeValue={formik.values.couponEndTime}
                                        onDateChange={(date) => handleValuesChange('couponEndDate', date)}
                                        onTimeChange={(time) => handleValuesChange('couponEndTime', time)}
                                        dateFormat='DD/MM/YYYY'
                                        minDate={moment(formik.values.couponStartDate).unix()}
                                        maxDate={minMaxTime?.max}
                                    />
                                </div>}
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }

    const renderCouponName = () => 
    {
        return (
            <div className="popup-container-spacing">
                <FormLabelComponent label="Coupon Name" required />
                <FormControlComponent
                    type="text"
                    name="name"
                    placeholder="General"
                    value={formik.values.name}
                    disabled={isCouponDataEditable}
                    onChange={(event) => {
                        handleValuesChange('name', event.target.value.toUpperCase())
                    }}
                    isInvalid={(formik.touched.name && formik.errors.name) as boolean}
                />
                {formik.errors.name && formik.touched.name ? <div className="error-msg">{formik.errors.name}</div> : undefined}
                <p className="description-text">{'Customers can also access this code via custom URL'}</p>
            </div>)
    }

    const renderDescription = () =>
    {
        return (
            <>
                {!showDescription && (
                    <div className="popup-container-spacing">
                        <CustomButton
                            name="+ Add Description"
                            btnType="tertiary"
                            onClick={() => toggleComponent('description')}
                        />
                    </div>
                )}
                {/* Description */}
                {showDescription && (
                    <div className="popup-container-spacing">
                        <FormLabelComponent label="Description" />
                        <FormControlComponent
                            type="text"
                            name="description"
                            placeholder="Enter a description"
                            value={formik.values.description}
                            onChange={(event) => {
                                handleValuesChange('description', event.target.value)
                            }}
                            isInvalid={(formik.touched.description && formik.errors.description) as boolean}
                        />
                        {formik.errors.description && formik.touched.description && <div className="error-msg">{formik.errors.description}</div>}
                    </div>
                )}
            </>
        );
    }

    const renderCouponType = () =>
    {
        return (
            <div className="popup-container-spacing">
                <FormLabelComponent label="Coupon Type" required />
                <ToggleButtonGroupComponent
                    value={formik.values.couponType}
                    onChange={function (event: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>): void {
                        handleCouponTypeChange(event, formik.values);
                    }}
                    // onChange={(event) => {
                    //     handleValuesChange('type', values)
                    // }}
                    options={couponTypeOptions}
                />
                <div className="popup-container-spacing pt-8">
                    {
                        formik.values.couponType === "currency" ?
                            <div>
                                <div className="coupon-value-container">
                                    <SelectComponent
                                        options={generateCurrencyOptions()}
                                        optionLabels={currencyOptionLabel}
                                        value={formik.values.currency}
                                        selectedAsString
                                        onChange={(event): void => {
                                            handleCurrencyChange(event);
                                        }}
                                        customValueRender={(value) => {
                                            return (
                                                <div className="currency-select">
                                                    <p>{getEmojiByCurrencyCode(value as string)}</p>
                                                    <p>{getSymbolCurrency(value as string)}</p>
                                                </div>
                                            )
                                        }}
                                        defaultPlaceholder="Select Currency"
                                    />
                                    <FormControlComponent
                                        type="number"
                                        name="couponValueInCurrency"
                                        value={formik.values.couponValueInCurrency}
                                        onChange={(event) => {
                                            handleValuesChange('couponValueInCurrency', event.target.value);
                                            handleValuesChange('couponValueInPercentage', '');
                                        }}
                                        disabled={isCouponDataEditable}
                                        required
                                        isInvalid={(formik.touched.couponValueInCurrency && formik.errors.couponValueInCurrency) as boolean}
                                    />
                                </div>
                                {formik.errors.couponValueInCurrency && formik.touched.couponValueInCurrency && <div className="error-msg">{formik.errors.couponValueInCurrency}</div>}
                            </div>
                            :
                            <div>
                                <FormControlComponent
                                    type="number"
                                    name="couponValueInPercentage"
                                    value={formik.values.couponValueInPercentage}
                                    onChange={(event) => {
                                        handleValuesChange('couponValueInPercentage', event.target.value);
                                        handleValuesChange('couponValueInCurrency', '');
                                    }}
                                    disabled={isCouponDataEditable}
                                    required
                                    asInputGroup
                                    inputGroupText="%"
                                    isInvalid={(formik.touched.couponValueInPercentage && formik.errors.couponValueInPercentage) as boolean}
                                />
                                {formik.errors.couponValueInPercentage && formik.touched.couponValueInPercentage && <div className="error-msg">{formik.errors.couponValueInPercentage}</div>}
                            </div>
                    }
                    {/* {formik.errors.couponValueInCurrency || formik.errors.couponValueInPercentage ? <div className="error-msg">{'Either one value is required'}</div> : undefined} */}
                </div>
            </div>
        )
    }

    const renderTickets = () =>
    {
        return (
            <div className="popup-container-spacing">
                <FormLabelComponent label="Tickets" required />
                <ToggleButtonGroupComponent
                    disabled={isCouponDataEditable}
                    value={formik.values.ticketSelection}
                    onChange={function (event: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>): void {
                        handleTicketSelection(event, formik.values);
                    }}
                    options={ticketTypeOptions}
                />

                {formik.values.ticketSelection === 'custom' && <div className="popup-container-spacing pt-8">
                    <AutocompletewithTags
                        defaultValue={formik.values?.ticketNames}
                        disabled={isCouponDataEditable}
                        value={formik.values?.ticketNames}
                        options={eventTicketOptions}
                        keyToShow='name'
                        onChange={(event, newValue) => handleTicketChange(event, newValue)}
                        placeholder='Select Tickets'
                        disabledOptions={expiredTickets}
                        onRemoveClick={(index): void => {
                            if (isCouponDataEditable) {
                                return;
                            }
                            const newValues = [...formik.values?.ticketNames];
                            newValues.splice(index, 1);
                            handleValuesChange('ticketNames', newValues);
                        }}
                    />
                    {formik.errors.ticketNames && formik.touched.ticketNames ? <div className="error-msg">{formik.errors.ticketNames}</div> : undefined}
                </div>}
            </div>
        )
    }

    const renderHeader = () =>
    {
        return (
            <div className='popup-header'>
                <div className='popup-header-content'>
                    {showCapacityTime || showActivityLog ? (
                        <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} onClick={handleBackToPopup} />
                    ) : (
                        <div className='popup-header-icon'><FontAwesomeIcon icon={['fal', 'ticket-simple']} /></div>
                    )}

                    <div className='popup-header-text'>
                        <h3>
                            {headerText}
                        </h3>
                        <p>
                            {headerDescription}
                        </p>
                    </div>
                </div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => props?.handleCouponPopupToggle()} />
            </div>
        );
    }

    const renderMainContent = () =>
    {
        if (showActivityLog) {
            return renderActivityLog();
        } else if (showCapacityTime) {
            return renderAdvancedInfo();
        } else {
            return (
                <>
                    {renderCouponName()}
                    {renderDescription()}
                    {renderCouponType()}
                    {renderTickets()}
                    {renderAdvancedInfo()}
                </>
            );
        }
    };

    const renderFooter = () =>
    {

        if (showActivityLog || showCapacityTime) 
        {
            return <></>
        }

        return (
            <>
                <div style={{ width: '500px' }} className="popup-footer">
                    {(!existingCouponData) ? <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton btnType='secondary' onClick={handleDrawerClose} name={'Cancel'} />
                        <CustomButton btnType='primary' loading={spinner} name={'Save'} type='submit' />
                    </Stack>
                        : (existingCouponData) &&
                        <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                            <div className="popup-footer-left">
                                {!isTableView && <FontAwesomeIcon className="popup-delete-icon" icon={['fal', 'trash']} onClick={handleDeleteCoupon} />}
                                {
                                    <div className="coupon-activity-log-button">
                                        <CustomButton
                                            name=""
                                            btnType="secondary"
                                            startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                                            style={{ width: '32px' }}
                                            onClick={handleNestedSidebarOpen}
                                        />
                                    </div>
                                }
                            </div>
                            <Stack direction={'row'} spacing={1} display={'flex'}>
                                <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                                <CustomButton btnType='primary' loading={spinner} name={!existingCouponData ? 'Update Ticket' : 'Save'} type='submit' />
                            </Stack>
                        </Stack>
                    }
                </div>
            </>
        )
    }

    return (
        <div id="addCoupon">
            {renderHeader()}
            
            <Form
                onSubmit={(values): void => {
                    return formik.handleSubmit(values);
                }}
                noValidate
                autoComplete="off">
                <div className="popup-container">
                    {renderMainContent()}
                </div>

                {renderFooter()}
            </Form>

            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={() => deleteCouponFn(existingCouponData as EventCoupon)}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                    }}
                    modalContent={`Are you sure you want to delete ${existingCouponData?.name}?`}
                    modalTitle='Delete Coupon'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='trash'
                />
            }
        </div>
    );
};

export default AddCoupon;