import { useEffect, useState } from "react";
import { useTablePagination } from "../../../../contexts/TablePaginationContext";
import { getAllLeadsInSponsorPortal, getSponsorPortalLeadsCount } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import _ from "lodash";
import { jsonToCSV } from "react-papaparse";
import { saveAs } from 'file-saver';
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { buildInitialsForPicture, convertKeysToTitleCase } from "../../../../scripts/helpers";
import { LocalStorage } from "../../../../scripts/LocalStorage";
import { registrantFilterColumnOptions } from "../../../Events/EventDetails/People/EventRegistrants/registrantColumn.helper";
import { EventLeadCapture } from "../../../Events/interfaces/event-lead-capture_interface";
import { useLocation, useNavigate } from "react-router-dom";
import './styles.scss';
import TanstackToolbarWithServerFilter from "../../../../common/TanstackTable/TanstackToolbarWithServerFilter";
import TablePopupDetails from "../../../../common/TablePopup/TablePopupDetails";
import TablePopup from "../../../../common/TablePopup/TablePopup";
import LeadsDetails from "../../Components/Audience/LeadsDetails";
import LeadsForm from "../../Components/Audience/LeadsForm";
import { createColumnHelper } from "@tanstack/react-table";
import CustomTooltip from "../../../../common/Tooltip";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import { EventLeadCaptureLeadType } from "../../../Events/enum/event-lead-capture.enum";
import { Avatar } from "@mui/material";

const PortalLeads: React.FC = (): React.JSX.Element => {

    const searchParams = new URLSearchParams();
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const [leadCount, setLeadCount] = useState<number>(0);
    const [leadRows, setLeadRows] = useState<EventLeadCapture[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [currentUrl, setCurrentUrl] = useState<string>('');
    const [selectedLead, setSelectedLead] = useState<EventLeadCapture | null>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    

    const [csvConversionSpinner, setCsvConversionSpinner] = useState<boolean>(false);

    // const columns =
    //     useRegistrantColumnHelper(
    //         registrantInitialColumnData.filter(
    //             item => !['email', 'status', 'paymentStatus', 'attendeeStatus', 'ticket', 'shareLink', 'actions'].includes(item.field)), undefined, undefined, undefined, undefined, undefined, leadRows);
    const columnHelper = createColumnHelper<EventLeadCapture>();

    const columns = [
        columnHelper.accessor('companyLogo' as any, {
            cell: (row) => {
                const clearbitFName = row?.row?.original?.clearbitData?.givenName;
                const clearbitLName = row?.row?.original?.clearbitData?.familyName;
                const name = clearbitFName && clearbitLName ? buildInitialsForPicture('', clearbitFName, clearbitLName) : row?.row?.original?.firstName ? buildInitialsForPicture('', row?.row?.original?.firstName, '') : buildInitialsForPicture('', row?.row?.original?.firstName, '');

                return (
                    <Avatar className="logo-avatar-img" src={row?.row?.original?.clearbitData && row?.row?.original?.clearbitData?.companyLogo ? row?.row?.original?.clearbitData?.companyLogo : ''}>
                        {!row?.row?.original?.clearbitData?.companyLogo ? name : ''}
                    </Avatar>
                );
            },
            size: 40,
            minSize: 40,
            maxSize: 40,
            header: '',
            enableSorting: false,
            id: 'companyLogo',
        }),
        columnHelper.accessor('name' as any, {
            cell: (row) => {
                return (
                    <div>
                        <p className="bolded-cellContent">{`${row.row.original.firstName} ${row.row.original?.lastName || ''}`}</p>
                        {/* <p className="cellContent">{row.row.original?.email}</p> */}
                    </div>
                )
            },
            header: 'Name',
            size: 250,
            minSize: 250,
            maxSize: 250,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'firstName'
        }),
        columnHelper.accessor('status', {
            cell: (row) => {
                const statusOptions = () => [
                    {
                        name: 'Cold',
                        value: EventLeadCaptureLeadType.COLD
                    },
                    {
                        name: 'Warm',
                        value: EventLeadCaptureLeadType.WARM
                    },
                    {
                        name: 'Hot',
                        value: EventLeadCaptureLeadType.HOT
                    }
                ];
        
                const [selectedOption, setSelectedOption] = useState<any>(statusOptions().find((option) => option.value == row.row.original.status));
        
                const chipColor = () => {
                    switch (selectedOption?.value) {
                        case EventLeadCaptureLeadType.COLD:
                            return 'blue';
                        case EventLeadCaptureLeadType.WARM:
                            return 'orange';
                        case EventLeadCaptureLeadType.HOT:
                            return 'red';
                        default:
                            return 'grey';
                    }
                };
        
                useEffect(() => {
                    setSelectedOption(statusOptions().find((option) => option.value == row.row.original.status));
                }, [row.row.original.status]);
        
                return (
                    <div>
                        <MuiChip 
                            chipColor={chipColor()}
                            label={selectedOption?.name}
                        />
                    </div>
                )
            },
            header: 'Status',
            size: 100,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'status'
        }),
        columnHelper.accessor('note' as any, {
            cell: (row) => {
                return (
                    <div>
                        <p className="cellContent">{`${row.row.original?.leadData?.notes}`}</p>
                        {/* <p className="cellContent">{row.row.original?.email}</p> */}
                    </div>
                )
            },
            header: 'Note',
            size: 250,
            minSize: 250,
            maxSize: 250,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'firstName'
        }),
        columnHelper.accessor('icp', {
            cell: (row: any) => {
                const icpChipColor = 'blue';

                return (
                    <div>
                        <CustomTooltip title={row?.getValue()?.length > 0 && row?.getValue()?.map((value: string) => value).join(', ')}>
                            <div>
                                {row?.getValue()?.length > 0 ? (
                                    row?.getValue()?.length > 1 ? (
                                        <div className="table-chip-cell">
                                            <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                            <p className="table-chip-count">
                                                +{row?.getValue()?.length - 1}
                                            </p>
                                        </div>
                                    ) : (
                                        <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                    )
                                ) : null}
                            </div>
                        </CustomTooltip>
                        {/* )} */}
                    </div>
                );
            },
            header: 'ICP',
            enableSorting: false,
            id: 'icp',
        }),
        columnHelper.accessor('buyerPersona', {
            cell: (row: any) => {
                const icpChipColor = 'red';

                return (
                    <div>
                        <CustomTooltip title={row?.getValue()?.length > 0 && row?.getValue()?.map((value: string) => value).join(', ')}>
                            <div>
                                {row?.getValue()?.length > 0 ? (
                                    row?.getValue()?.length > 1 ? (
                                        <div className="table-chip-cell">
                                            <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                            <p className="table-chip-count">
                                                +{row?.getValue()?.length - 1}
                                            </p>
                                        </div>
                                    ) : (
                                        <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                    )
                                ) : null}
                            </div>
                        </CustomTooltip>
                        {/* )} */}
                    </div>
                );
            },
            header: 'Buyer Persona',
            enableSorting: false,
            id: 'buyerPersona',
        }),
    ];
    const sponsorData = LocalStorage.get('@SponsorDetails');

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const LeadsToolbar = () => {
        const [selectedTab, setSelectedTab] = useState<'all'>('all');
        const tabs = [
            {
                tabName: 'All',
                selectedTab: selectedTab === 'all',
                count: leadCount,
                navigation: (): void => {
                    setSelectedTab('all');
                }
            },
        ];

        return (
            <div className="leads-toolbar">
                {/* <FormTabs tabs={tabs} /> */}
                {sponsorData && 
                    <CustomTooltip title='Export Leads' placement="top">
                        <div>
                            <CustomButton
                                loading={csvConversionSpinner}
                                onClick={fetchAllLeadsData}
                                name='' btnType='tertiary'
                                startIcon={<FontAwesomeIcon icon={['fal', 'file-export']} />} /> 
                        </div> 
                    </CustomTooltip>
                }
                {
                    <TanstackToolbarWithServerFilter
                        columns={columns}
                        filterColumns={registrantFilterColumnOptions(undefined, undefined, undefined, true, true)}
                        handleApplyFilters={handleApplyFilters}
                        setCurrentUrl={setCurrentUrl}
                        minimalView={true}
                    />
                }
            </div>
        )
    };

    const fetchData = async (): Promise<void> => {
        if (!pageSize) return;
        try {
            const params: [string, any][] = [];
            const query = new URLSearchParams(currentUrl.split('?')[1]);
            query.forEach((value, key) => {
                params.push([key, value]);
            });

            const encodedParams: [string, any][] = params?.map(([key, value]) => [key, encodeURIComponent(value)]);

            const count = await getSponsorPortalLeadsCount(...encodedParams);
            if (count) {
                setLeadCount(count);

                const leads = await getAllLeadsInSponsorPortal(pageSize, (currentPage - 1), undefined, undefined, undefined, ...encodedParams);

                if (leads) {
                    setIsEmpty(leads.length === 0);
                    setLeadRows(leads);

                    const initialColumnKeys = ['firstName', 'lastName'];
                    const initialColumns = initialColumnKeys.map((field) => ({
                        field,
                        headerName: _.startCase(field),
                        disableColumnMenu: true,
                    }));

                    if (leads?.length > 0 && leads.some(obj => 'leadData' in obj) || leads.some(obj => 'registrantData' in obj)) {
                        const uniqueKeysSet = new Set();

                        leads.forEach(obj => {
                            if (obj?.leadData) {
                                Object.keys(obj.leadData).forEach(key => {
                                    uniqueKeysSet.add(key); // Adds key to set, ensuring uniqueness
                                });
                            }

                            if (obj?.registrantData) {
                                Object.keys(obj.registrantData).forEach(key => {
                                    uniqueKeysSet.add(key); // Adds key to set, ensuring uniqueness
                                });
                            }
                        });

                        const uniqueKeys = Array.from(uniqueKeysSet);

                        // const leadData = leads.map(obj => obj?.leadData);
                        // const leadDataKeys = Object.keys(leadData[0]);
                        // const leadDataColumns = leadDataKeys.map((field) => ({
                        //     field,
                        //     headerName: _.startCase(field),
                        //     disableColumnMenu: true,
                        // }));
                    }
                }
            }
        }
        catch (error) {

        }
        finally {
            setShowSpinner(false);
        }
    };

    const fetchAllLeadsData = async (): Promise<void> => {
        setCsvConversionSpinner(true);
        try {

            const count = await getSponsorPortalLeadsCount();
            if (count) {
                const leads = await getAllLeadsInSponsorPortal(count, 0);

                if (leads) {
                    const mergedArr = _.map(leads, (obj): any => {
                        const { leadData, registrantData, ...prevItems } = obj;

                        const registrantDataWithoutGdpr = _.omit(registrantData, ['gdprData']);

                        const capturedKey = {
                            capturedAt: moment(obj?.created).format('DD/MM/YYYY HH:mm A'),
                        }

                        const mergedObj = _.merge(prevItems, capturedKey, registrantDataWithoutGdpr, obj?.leadData);

                        const omittedKeys = ['id', 'sponsorId', 'capturedAudienceId', 'additionalData', 'userId', 'type', 'audienceId', 'eventTicketId', 'eventId', 'utmId', 'icp', 'buyerPersona', 'modified', 'created', 'orgId', 'status', 'gdprData', 'refundSuccess', 'refundStatus', 'refund', 'paymentSuccess', 'paymentStatus', 'paymentLink', 'lumaRegistrantId', 'eventSpeakerIdMap', 'eventSponsorIdMap', 'confirmedId', 'activityLog', 'couponId', 'checkedInUserId', 'checkedInAccessControlId'];
                        const objAfterOmittedKeys = _.omit(mergedObj, omittedKeys);
                        return convertKeysToTitleCase(objAfterOmittedKeys);
                    });

                    if (mergedArr) {

                        const allKeys = mergedArr.reduce((keys, obj) => {
                            Object.keys(obj).forEach(key => {
                                keys.add(key);
                            });
                            return keys;
                        }, new Set());

                        // Ensure each object has all keys
                        const completeData = mergedArr.map(obj => {
                            allKeys.forEach((key: string | number) => {
                                if (!obj.hasOwnProperty(key)) {
                                    obj[key] = ''; // Add missing keys as empty strings
                                }
                            });
                            return obj;
                        });

                        // function toNormalCaseWithSpaces(key: any) {
                        //     // Convert snake_case to camelCase for uniformity, then to normal case with spaces
                        //     return _.startCase(_.camelCase(key));
                        // }

                        // // Function to process the array of objects (e.g., leads)
                        // const processArray = (array: any) => {
                        //     return array.map((item: string) => {
                        //         // Convert each key of the object
                        //         return _.mapKeys(item, (value, key) => toNormalCaseWithSpaces(key));
                        //     });
                        // };

                        // const caseConvertedData = processArray(completeData);

                        // if(caseConvertedData)
                        // {
                        //     const csvData = jsonToCSV(caseConvertedData);
                        //     const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                        //     saveAs(blob, 'leads.csv');
                        // }

                        const csvData = jsonToCSV(completeData);
                        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                        saveAs(blob, 'leads.csv');

                    }
                }
            }

        }
        catch (error) {

        }
        finally {
            setCsvConversionSpinner(false);
        }
    };

    const handlePopupClose = (): void =>
    {
        setSelectedLead(null);
        setDrawerOpen(false);
    };

    const handleRowClick = (row: EventLeadCapture): void => {
        setDrawerOpen(true);
        setSelectedLead(row);
        // await fetchSponsorActivityLogs(row);
    }

    const tablePopupTabData = 
    [
        {
            title: 'Lead Audios',
            data: <LeadsDetails handlePopupClose={handlePopupClose} leadData={selectedLead} />
        },
       
        {
            title: 'Lead Form',
            data: <LeadsForm handlePopupClose={handlePopupClose} leadData={selectedLead} />
        }
    ];

    useEffect((): void => {
        fetchData();
    }, [currentPage, pageSize, currentUrl]);

    const getCornerProfileBadge = (leadStatus: EventLeadCaptureLeadType) => {
        switch (leadStatus) {
            case EventLeadCaptureLeadType.COLD:
                return {
                    icon: ['fal', 'snowflake'],
                    bgColor: '#2E90FA'
                };
            case EventLeadCaptureLeadType.WARM:
                return {
                    icon: ['fal', 'fire-flame-simple'],
                    bgColor: '#F79009'
                };
            case EventLeadCaptureLeadType.HOT:
                return {
                    icon: ['fal', 'fire-flame-curved'],
                    bgColor: '#F04438'
                };
            default:
                return {
                    icon: ['fal', 'snowflake'],
                    bgColor: '#2E90FA'
                };
        }
    };

    const profileBadgeProps = selectedLead?.leadType ? getCornerProfileBadge(selectedLead.leadType) : { icon: ['fal', 'snow-flake'], bgColor: '#2E90FA' };

    return (
        <div id="portalLeads">
            <div style={{ height: 'calc(100vh - 300px)' }}>
                {/* {!isEmpty ? ( */}
                <div className="leads-table">
                    <LeadsToolbar />
                    <TablePopup
                        height="calc(100vh - 280px)"
                        rows={leadRows}
                        columns={columns}
                        count={leadCount}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        updatePageSize={updatePageSize}
                        updateCurrentPage={updateCurrentPage as any}
                        onRowClick={(row) => handleRowClick(row)}
                        selectedRow={selectedLead}
                        drawerOpen={drawerOpen}
                        rightPinnedColumns={['actions']}
                        popupContent={
                            <TablePopupDetails
                                onPopupClose={handlePopupClose}
                                title={`${selectedLead?.firstName} `}
                                subTitle={`${selectedLead?.clearbitData ? selectedLead?.clearbitData?.company : ''}`}
                                email={`${selectedLead?.email || ''}`}
                                imageSrc={`${selectedLead?.clearbitData ? selectedLead?.clearbitData?.companyLogo : ''}`}
                                altImage={`${buildInitialsForPicture(selectedLead?.firstName, selectedLead?.lastName)}`}
                                icp={selectedLead?.icp}
                                buyerPersona={selectedLead?.buyerPersona || []}
                                tabData={tablePopupTabData}
                                profileBadge={<FontAwesomeIcon icon={profileBadgeProps.icon as any} className="corner-profile-badge" style={{ backgroundColor: profileBadgeProps.bgColor }} />}
                            />
                                }
                            />
                </div>
                {/* ) : (
                    <TableEmptyComponent
                        infoText={'No Leads available'}
                    />
                )
                } */}
            </div>
        </div>
    );
};

export default PortalLeads;