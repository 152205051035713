import { useEffect, useState } from "react";
import _ from "lodash";
import { useTablePagination } from "../../../../contexts/TablePaginationContext";
import { createColumnHelper } from "@tanstack/react-table";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { MinimalViewComponent } from "../../../../common/MinimalView/MinimalViewComponent";
import EventsHeaderBar from "../../../../common/EventsHeaderBar";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import TablePopup from "../../../../common/TablePopup/TablePopup";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import { getAllEventProspects, getEventProspectCount, getEventProspectTypeCount, syncProspects } from "../../../../scripts/apis/eventProspects";
import { EventProspect } from "../../interfaces/event-prospect_interface";
import { EventProspectSource, EventProspectType } from "../../enum/event-prospect.enum";
import { CONTENT } from "../../../../scripts/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { getEncodedParams } from "../../../../scripts/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventGraphObject, EventProspectJobStatus } from "../../interfaces";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../../../components/Events/interfaces/create-events_interface";
import CustomTooltip from "../../../../common/Tooltip";
import moment from "moment";
import { EventTypes } from "../../enum";
import { IBillingInfo } from "../../../../redux/billing/billing-redux_interface";
import { EventHQEventLimit, ProductType } from "../../../../enums/billing.enum";

import './styles.scss';

const EventProspectsPage: React.FC<{ eventId: string | number }> = ({ eventId }): React.JSX.Element => {

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const location = useLocation();
    const navigate = useNavigate();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const isAFreeAccount = !(orgBillingInfo?.productType === ProductType.EVENTHQ || orgBillingInfo?.eventhq === EventHQEventLimit.THREE);

    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [registrantCount, setRegistrantCount] = useState<number>(0);
    const [typeCounts, setTypeCounts] = useState<EventGraphObject>({});
    const [rows, setRows] = useState<EventProspect[]>([]);
    const [selectedRegistrant, setSelectedRegistrant] = useState<EventProspect | null>(null);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showBulkDeletePopup, setShowBulkDeletePopup] = useState<boolean>(false);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [syncSpinner, setSyncSpinner] = useState<boolean>(false);
    const [isSyncing, setIsSyncing] = useState<boolean>(false);

    const columnHelper = createColumnHelper<EventProspect>();
    const minimalColumns = [
        columnHelper.accessor('name' as any, {
            cell: (row) => {
                return (
                    <p className="bolded-cellContent">{`${row.row.original.name || ''}`}</p>
                )
            },
            header: 'Name',
            size: 250,
            minSize: 250,
            maxSize: 250,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        // columnHelper.accessor('email' as any, {
        //     cell: (row) => {
        //         return (
        //             <p className="cellContent">{row.row.original?.email || ''}</p>
        //         )
        //     },
        //     header: 'Email',
        //     size: 250,
        //     minSize: 250,
        //     maxSize: 250,
        //     sortingFn: 'alphanumericCaseSensitive',
        //     id: 'email'
        // }),
        columnHelper.accessor('profileUsername', {
            cell: (row) => {
                return (
                    <p className="cellContent">{row?.getValue()}</p>
                )
            },
            header: 'Username',
            size: 250,
            minSize: 250,
            maxSize: 250,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'profileUsername'
        }),
        columnHelper.accessor('profileUrl', {
            cell: (row) => {
                return (
                    <div className="url-cell">
                        <p className="cellContent">{row?.getValue()}</p>
                        <CustomButton 
                            name=""
                            startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />}
                            btnType="tertiary_grey"
                            onClick={() => window.open(row?.getValue(), '_blank')}
                        />
                    </div>
                )
            },
            header: 'Profile URL',
            size: 250,
            minSize: 250,
            maxSize: 250,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'profileUrl'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                const statusOptions = () => {
                    const baseOptions = [
                        {
                            name: 'Attended',
                            value: EventProspectType.ATTENDED
                        },
                        {
                            name: 'Attending',
                            value: EventProspectType.ATTENDING
                        },
                        {
                            name: 'Speaking',
                            value: EventProspectType.SPEAKING
                        },
                        {
                            name: 'Sponsoring',
                            value: EventProspectType.SPONSORING
                        },
                        {
                            name: 'Promoting',
                            value: EventProspectType.PROMOTING
                        },
                        {
                            name: 'Selling',
                            value: EventProspectType.SELLING
                        },
                        {
                            name: 'Other',
                            value: EventProspectType.OTHER
                        }
                    ];
                    return baseOptions;
                };

                const [selectedOption, setSelectedOption] = useState<any>(statusOptions().find((option) => option.value == row.row.original.type));

                const chipColor = () => {
                    switch (selectedOption?.value) { 
                        case EventProspectType.ATTENDED:
                            return 'red';
                        case EventProspectType.ATTENDING:
                            return 'green'; 
                        case EventProspectType.SPEAKING:
                            return 'yellow';
                        case EventProspectType.SPONSORING:
                            return 'blue';
                        case EventProspectType.PROMOTING:
                            return 'red';
                        case EventProspectType.SELLING:
                            return 'grey';
                        case EventProspectType.OTHER:
                            return 'grey';
                        default:
                            return 'grey';
                    }
                };

                useEffect(() => {
                    setSelectedOption(statusOptions().find((option) => option.value == row.row.original.type));
                }, [row.row.original.status]);

                return (
                    <div>
                        <MuiChip 
                            chipColor={chipColor()}
                            label={selectedOption?.name}
                            circleIcon
                        />
                    </div>
                )
            },
            header: 'Type',
            size: 150,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'status'
        }),
        columnHelper.accessor('source', {
            cell: (row) => {
                const label = CONTENT.EVENTS_MODULE.PROSPECTS.PROSPECT_SOURCE[row.row.original?.source];
                const chipColor = row?.getValue() === EventProspectSource.LINKEDIN ? 'blue' : 'grey';
                return (
                    <MuiChip 
                        label={label}
                        chipColor={chipColor}
                        circleIcon
                    />
                )
            },
            header: 'Source',
            size: 100,
            minSize: 100,
            maxSize: 100,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'source'
        }),
        columnHelper.accessor('sourcePostUrl', {
            cell: (row) => {
                return (
                    <div className="url-cell">
                        <p className="cellContent">{row?.getValue()}</p>
                        <CustomButton 
                            name=""
                            startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />}
                            btnType="tertiary_grey"
                            onClick={() => window.open(row?.getValue(), '_blank')}
                        />
                    </div>
                )
            },
            header: 'Source Post URL',
            size: 250,
            minSize: 250,
            maxSize: 250,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'sourcePostUrl'
        }),
        // columnHelper.accessor('profileUrl', {
        //     cell: (row) => {
        //         return (
        //             <p className="cellContent">{row?.getValue()}</p>
        //         )
        //     },
        //     header: 'Profile URL',
        //     size: 250,
        //     minSize: 250,
        //     maxSize: 250,
        //     sortingFn: 'alphanumericCaseSensitive',
        //     id: 'profileUrl'
        // }),
        // columnHelper.accessor('profileUsername', {
        //     cell: (row) => {
        //         return (
        //             <p className="cellContent">{row?.getValue()}</p>
        //         )
        //     },
        //     header: 'Profile Username',
        //     size: 250,
        //     minSize: 250,
        //     maxSize: 250,
        //     sortingFn: 'alphanumericCaseSensitive',
        //     id: 'profileUsername'
        // }),
    ];

    const searchParams = new URLSearchParams(location.search);

    const tabs = [
        {
            tabName: 'All',
            count: registrantCount,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/prospects`);
                navigate(`/events/${eventId}/people/prospects`);
            },
            selectedTab: window.location.search.length == 0,
        },
        {
            tabName: 'Attending',
            count: Number(typeCounts[EventProspectType.ATTENDING]) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/prospects?type=${EventProspectType.ATTENDING}`);
                navigate(`/events/${eventId}/people/prospects?type=${EventProspectType.ATTENDING}`);
            },
            selectedTab: searchParams?.get('type') == `${EventProspectType.ATTENDING}`,
        },
        {
            tabName: 'Attended',
            count: Number(typeCounts[EventProspectType.ATTENDED]) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/prospects?type=${EventProspectType.ATTENDED}`);
                navigate(`/events/${eventId}/people/prospects?type=${EventProspectType.ATTENDED}`);
            },
            selectedTab: searchParams?.get('type') == `${EventProspectType.ATTENDED}`,
        },
        {
            tabName: 'Speaking',
            count: Number(typeCounts[EventProspectType.SPEAKING]) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/prospects?type=${EventProspectType.SPEAKING}`);
                navigate(`/events/${eventId}/people/prospects?type=${EventProspectType.SPEAKING}`);
            },
            selectedTab: searchParams?.get('type') == `${EventProspectType.SPEAKING}`,
        },
        {
            tabName: 'Sponsoring',
            count: Number(typeCounts[EventProspectType.SPONSORING]) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/prospects?type=${EventProspectType.SPONSORING}`);
                navigate(`/events/${eventId}/people/prospects?type=${EventProspectType.SPONSORING}`);
            },
            selectedTab: searchParams?.get('type') == `${EventProspectType.SPONSORING}`,
        },
        {
            tabName: 'Others',
            count: Number(typeCounts[EventProspectType.OTHER]) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/prospects?type=${EventProspectType.OTHER}`);
                navigate(`/events/${eventId}/people/prospects?type=${EventProspectType.OTHER}`);
            },
            selectedTab: searchParams?.get('type') == `${EventProspectType.OTHER}`,
        }
    ];

    const handleMinimalViewRowClick = (row: EventProspect): void =>
    {
        setDrawerOpen(true);
        setSelectedRegistrant(row);
    };

    const handleSync = async (): Promise<void> => {
        setSyncSpinner(true);
        try 
        {
            const syncInitiated = await syncProspects(eventId);
            if(syncInitiated) 
            {
                toast.success('Sync initiated successfully');
                setIsSyncing(true);
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message || 'Failed to initiate sync');
        }
        finally
        {
            setSyncSpinner(false);
        }
    };

    const registrantHeaderActions = (): React.JSX.Element =>
    {
        return (
            <div className="bulk-actions">
                <div className="bulk-actions-buttons">
                    <CustomButton 
                        name='Cancel'
                        btnType='secondary'
                        onClick={() => setSelectedRowIds([])}
                    />
                    <CustomButton 
                        name='Delete'
                        btnType='danger'
                        onClick={() => setShowBulkDeletePopup(!showBulkDeletePopup)}
                    />
                </div>
                <p className="rows-selected-text">{`${selectedRowIds?.length} records selected`}</p>
            </div>
        )
    }

    const getStatusCountForSelectedTab = () => {
        if (orgBillingInfo?.productType === ProductType.EVENTHQ || orgBillingInfo?.eventhq === EventHQEventLimit.THREE) {
            switch(Number(searchParams?.get('type'))) {
                case EventProspectType.ATTENDING:
                    return Number(typeCounts[EventProspectType.ATTENDING]) || 0;
                case EventProspectType.ATTENDED:
                    return Number(typeCounts[EventProspectType.ATTENDED]) || 0;
                case EventProspectType.SPEAKING:
                    return Number(typeCounts[EventProspectType.SPEAKING]) || 0;
                case EventProspectType.SPONSORING:
                    return Number(typeCounts[EventProspectType.SPONSORING]) || 0;
                case EventProspectType.OTHER:
                    return Number(typeCounts[EventProspectType.OTHER]) || 0;
                default:
                    return registrantCount;
            }
        } else if (orgBillingInfo?.productType === ProductType.INTELLIGENCE && (orgBillingInfo?.eventhq && orgBillingInfo?.eventhq !== undefined && orgBillingInfo?.eventhq !== EventHQEventLimit.THREE as EventHQEventLimit)) {
            const count = (() => {
                switch(Number(searchParams?.get('type'))) {
                    case EventProspectType.ATTENDING:
                        return Number(typeCounts[EventProspectType.ATTENDING]) || 0;
                    case EventProspectType.ATTENDED:
                        return Number(typeCounts[EventProspectType.ATTENDED]) || 0;
                    case EventProspectType.SPEAKING:
                        return Number(typeCounts[EventProspectType.SPEAKING]) || 0;
                    case EventProspectType.SPONSORING:
                        return Number(typeCounts[EventProspectType.SPONSORING]) || 0;
                    case EventProspectType.OTHER:
                        return Number(typeCounts[EventProspectType.OTHER]) || 0;
                    default:
                        return registrantCount;
                }
            })();
            return count > 10 ? 10 : count;
        }
    }

    const syncButton = (): React.JSX.Element => {
        if(eventReduxData && eventReduxData?.timeZone)
        {
            const currentTimestamp = moment().tz(eventReduxData?.timeZone).unix();
            if(Number(eventReduxData?.type) !== EventTypes.HOST)
            {
                if(eventReduxData?.prospectJobStatus?.linkedInJobStatus === EventProspectJobStatus.COMPLETED || eventReduxData?.prospectJobStatus?.twitterJobStatus === EventProspectJobStatus.COMPLETED) {
                    if(eventReduxData?.prospectJobStatus?.timestamp === null || (currentTimestamp - Number(eventReduxData?.prospectJobStatus.timestamp)) >= 8 * 3600) { 
                        return (
                            <CustomButton 
                                name=""
                                btnType="secondary"
                                startIcon={<FontAwesomeIcon icon={['fal', 'sync']} />}
                                key={1}
                                onClick={handleSync}
                                loading={syncSpinner}
                                disabled={syncSpinner}
                            />
                        )
                    }
                    else if(eventReduxData?.prospectJobStatus && eventReduxData?.prospectJobStatus?.timestamp || isSyncing) {
                        return (
                            <CustomTooltip title={`Next sync available in ${8 - Math.floor((currentTimestamp - eventReduxData?.prospectJobStatus?.timestamp) / (60 * 60 * 1000))} hours`}>
                                <div>
                                    <CustomButton 
                                        name=""
                                        btnType="secondary"
                                        startIcon={<FontAwesomeIcon icon={['fal', 'sync']} />}
                                        key={1}
                                        disabled={true}
                                    />
                                </div>
                            </CustomTooltip>
                        )
                    }
                }
                else {
                    return (
                        <MuiChip 
                            label="In progress"
                            chipColor="green"
                        />
                    )
                }
            }
            else {
                return <></>;
            }
        }
        return <></>;
    }

    const renderMinimalViewForEventRegistrants = (): React.JSX.Element => {         
        return (
            <div id="eventProspectsPageMinimalView">
                <MinimalViewComponent
                    title={
                        <EventsHeaderBar 
                            title={'Prospects'} 
                            showBorder 
                            buttons={[
                                syncButton()
                            ]}
                        />
                    }
                    titleBarStyle={{ padding: '0px' }}
                    additionalHeaderContent={
                        selectedRowIds && selectedRowIds?.length > 0 ?
                        registrantHeaderActions()
                        : undefined
                    }
                    tabData={tabs}
                    // uncomment and add relevant actions
                    // actions={[
                    //     isEnableInvoiceDownload && 
                    //     <CustomButton
                    //         key={2} 
                    //         name=""
                    //         btnType='secondary'
                    //         onClick={handleInvoiceDownload}
                    //         startIcon={<CustomTooltip title={<p>{'Download invoices'}</p>}><FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} /></CustomTooltip>}
                    //         loading={invoiceDownloadSpinner}
                    //     />,
                    //     <CustomTooltip title={isPublished ? "Import CSV" : "Event is not published"}>
                    //         <div>
                    //             {dynamicColumns?.length > 0 && <DataImport onImportSuccess={handleImportCsvSuccess} options={csvoptions} dynamicColumns={dynamicColumns} licenseKey={import.meta.env.VITE_REGISTRANT_SHEET_ID} eventId={eventId} iconButton disabled={!isPublished}/>}
                    //         </div>
                    //     </CustomTooltip>,
                    //     !isEmpty && <CustomTooltip title="Export CSV">
                    //         <div>
                    //             <CustomButton loading={csvConversionSpinner} onClick={handleDownloadCSV} name='' btnType='secondary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-down-to-bracket']} />} />
                    //         </div>
                    //     </CustomTooltip>,
                    //         !isEmpty && <TanstackToolbarWithServerFilter
                    //         key={1}
                    //         columns={minimalColumns}
                    //         filterColumns={registrantFilterColumnOptions(tickets, registrantStatusCount, eventReduxData)}
                    //         handleApplyFilters={handleApplyFilters}
                    //         setCurrentUrl={setCurrentUrl}
                    //         minimalView={true}
                    //     />,
                    //     !isEmpty && <ColumnVisibilityDropdown 
                    //         key={2}
                    //         columns={minimalColumns}
                    //         setColumns={setAllColumns}
                    //         showIconButton={true}
                    //     />,
                    // ]}
                    component={
                        <div className="h-100">
                            {
                                isEmpty ?
                                    <div style={{ height: 'calc(100vh - 340px)' }}>
                                        <TableEmptyComponent
                                            emptyImg={''}
                                            infoText={'No Prospects'}
                                        />
                                    </div>
                                    :
                                    <TablePopup 
                                        rows={rows}
                                        columns={minimalColumns}
                                        count={getStatusCountForSelectedTab()}
                                        pageSize={pageSize}
                                        currentPage={currentPage}
                                        updatePageSize={updatePageSize}
                                        updateCurrentPage={updateCurrentPage as any}
                                        height={'100%'}
                                        drawerOpen={drawerOpen}
                                        selectedRow={selectedRegistrant}
                                        selectedRows={selectedRowIds}
                                        setSelectedRows={setSelectedRowIds}
                                        hidePagination={isAFreeAccount}
                                        popupContent={
                                            <div>
                                                Event Prospects
                                            </div>
                                        }
                                    />
                            }
                        </div>
                    }
                    componentType='table'
                />
            </div>
        );
    };

    const fetchCounts = async (): Promise<void> => {
        try 
        {
            const count = await getEventProspectCount(eventId);
            setRegistrantCount(count);
            const typeCounts = await getEventProspectTypeCount(eventId);
            setTypeCounts(typeCounts);
        } 
        catch (error) 
        {
            console.log(error);
        }
    }

    const fetchData = async (): Promise<void> => {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const eventProspects = await getAllEventProspects(eventId, pageSize, currentPage-1, ...encodedParams);
            if(eventProspects)
            {
                setRows(eventProspects);
            }
            
        } 
        catch (error) 
        {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCounts();
    }, []);

    useEffect(() => {
        fetchData();
        fetchCounts();
    }, [pageSize, currentPage, currentUrl]);

    return (
        <>
            {renderMinimalViewForEventRegistrants()}
        </>
    )
};

export default EventProspectsPage;