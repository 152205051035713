import React, { Suspense, useContext } from 'react';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { CONTENT } from '../scripts/i18n';
import AppLayout from '../layouts/AppLayout';
import ProtectedRoute from '../layouts/ProtectedRoute';
import AuthenticatorRoute from '../layouts/AuthenticatorRoute';
import UserLoginPage from '../pages/Authentication/UserLoginPage';
import UserFlowPage from '../pages/Authentication/UserFlowPage';
import SponsorPortalProtectedRoute from '../layouts/SponsorPortalProtectedRoute';
import OpenPreview from '../pages/Registration/Preview/CheckPreviewLogic';
import PortalProvider from '../contexts/Portal/PortalContext';
import { BillingContext } from '../contexts/Billing/BillingContext';

const AppRouter = (): React.JSX.Element => {

    const [searchParams] = useSearchParams();

    const {
        loading,
        ProductRoutes
    } = useContext(BillingContext);

    return (
        <Suspense fallback={<div />}>
            {
                loading ? <></> :
                    <Routes>
                        <Route path="/" element={<AppLayout />}>

                            {/* initial path */}
                            <Route element={<ProtectedRoute />}>
                                <Route path={'/'} element={<Navigate to={CONTENT.PATHS.ROOT.TO} replace />} />
                            </Route>

                            {/* signup */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/signup" element={<UserLoginPage />} />
                            </Route>

                            {/* login */}
                            <Route element={<AuthenticatorRoute />}>
                                <Route path="/login" element={<UserLoginPage token={searchParams.get('token') || ''} />} />
                            </Route>

                            {/* register as new user */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/register" element={searchParams.get('token') ? <UserFlowPage token={searchParams.get('token') || ''} component='register' /> : <Navigate to="/login" replace />} />
                            </Route>

                            {/* forgot password */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/forgot-password" element={searchParams.get('token') ? <UserFlowPage token={searchParams.get('token') || ''} component='forgot-password' /> : <Navigate to="/login" replace />} />
                            </Route>

                            {/* onboard / accept invite */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/onboard" element={searchParams.get('token') ? <UserFlowPage token={searchParams.get('token') || ''} component='onboard' /> : <Navigate to="/login" replace />} />
                            </Route>

                            {ProductRoutes}
                        </Route>

                        {/* Sponsor portal pages */}
                        <Route path="/:orgLink/:eventLink/portal" element={<PortalProvider><SponsorPortalProtectedRoute side-bar={true} /></PortalProvider>} />

                        <Route path={'*'} element={<OpenPreview />} />
                    </Routes>
            }
        </Suspense>
    );
};
export default AppRouter;