import { EventGraphObject } from '../../pages/Events/interfaces';
import { EventProspect } from '../../pages/Events/interfaces/event-prospect_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

// GET Methods

export const getEventProspectCount = async (eventId: string | number, queryParams: [string, any][] = []):Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/' + eventId +  '/count' + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventProspectTypeCount = async (eventId: string | number):Promise<EventGraphObject> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/' + eventId + '/type-count';
    return triggerApi(API_URL, 'GET', '');
}

export const getAllEventProspects = async (eventId: string | number, pageSize: number, offset: number, ...queryParams: [string, any][]): Promise<EventProspect[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-prospects/${eventId}?pageSize=${pageSize}&offset=${offset}&${query}`;
    return triggerApi(API_URL, 'GET', '');
};

export const syncProspects = (eventId: string | number): Promise<boolean> => 
{
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-prospects/${eventId}/sync-prospects`;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventProspectById = async (eventId: number, prospectId: number):Promise<EventProspect> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/' + eventId + '/' + prospectId;
    return triggerApi(API_URL, 'GET', '');
};

// export const searchEventProspect = async (eventId: string, email?: string, firstName?: string, eventTicketIds?: any[], status?: number, paymentStatus?: number, attendeeStatus?: number): Promise<EventProspect[]> =>
// {
//     const statusParams = {
//         status,
//         attendeeStatus,
//         paymentStatus
//     };

//     const searchParams = {
//         email,
//         firstName,
//         eventTicketIds
//     };

//     const filteredstatusParams = Object.fromEntries(Object.entries(statusParams).filter(([key, value]) => value !== undefined));
//     const filteredSearchParams = Object.fromEntries(Object.entries(searchParams).filter(([key, value]) => value !== undefined));

//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/' + eventId + '/search?' + convertJSONToGETParams(filteredSearchParams) + '&' + convertJSONToGETParams(filteredstatusParams);
//     return triggerApi(API_URL, 'GET', '');
// }; 

// export const getEventProspectActivityLogs = async (eventId: string | number, prospectId: string | number): Promise<ActivityLogResponse[]> =>
// {
//     const query = convertJSONToGETParams({
//         prospectId
//     });
//     const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-prospects/${eventId}/activity-logs?${query}`;
//     return triggerApi(API_URL, 'GET', '');
// };

// // POST Methods

// export const getAllProspectsByEvents = async (payload: any, csrfToken: string): Promise<EventProspectByEvents> => 
// {
//     const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-prospects/events`;
//     return triggerApi(API_URL, 'POST', payload, csrfToken);
// };

// export const uploadProspects = async (EVENT_ID: number, CSV_DATA, csrfToken: string):Promise<BulkEventProspect> => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/bulk/' + EVENT_ID;
//     return triggerApi(API_URL, 'POST', CSV_DATA, csrfToken);
// };

// // PUT Methods

// export const updateEventProspect = async (eventId: string | number, prospectId: string | number, prospectData):Promise<EventProspect> => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/' + eventId + '/' + prospectId;
//     return triggerApi(API_URL, 'PUT', prospectData);
// };

// export const updateEventProspectComments = async (eventId: number, prospectId: number, data: { comments: string }):Promise<EventProspect> => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/' + eventId + '/' + prospectId + '/comment';
//     return triggerApi(API_URL, 'PUT', data);
// };

// export const mapEventProspectToSponsor = async (eventId: number, prospectId: number, data: {sponsorId: number | string }):Promise<EventProspect> =>  
// { 
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/' + eventId + '/' + prospectId + '/sponsor';
//     return triggerApi(API_URL, 'PUT', data);
// };

// export const checkUpdatedProspectData = async (eventId: string | number, prospectId: string | number, data: { firstName: string, email:string ,additionalData:{[key:string]: string| number| boolean| object} }):Promise<EventProspect> => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/' + eventId + '/' + prospectId + '/info';
//     return triggerApi(API_URL, 'PUT', data);
// };

// export const bulkUpdateProspectStatus = async (eventId: number | string, data: { prospectIds: number[] | string[], status: EventProspectStatus }):Promise<boolean> =>
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/' + eventId + '/bulk-update-status';
//     return triggerApi(API_URL, 'PUT', data);
// };

// // DELETE methods

// export const deleteEventProspect = async (eventId: string | number, prospectId: string | number):Promise<boolean> => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-prospects/' + eventId + '/' + prospectId;
//     return triggerApi(API_URL, 'DELETE', '');
// };