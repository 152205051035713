import { PaginatedRequest } from '../../../interfaces/paginated-request';
import { RegistrationForm } from '../../Registration/interfaces/registration-form_interface';
import { Speaker } from '../../Speakers/interfaces';
import { CalendarInvitation, DailyOrder, EnableAuthenticationStatus, EnableTransactionalMails, EnableWaitlistStatus, EventCategoryCountType, EventCategoryStatus, EventIntegrationType, EventLandingPageTheme, EventLocationType, EventNpsScheduleType, EventRegistrationTypes, EventSessionFeedbackScheduleType, EventStatus, EventTypes, FontSize, LandingPageType, QrCodeDisplay, QrCodeSize, SyncCrm, VoiceFeedback, ZoomWebinarAction } from '../enum';
import { EventExpo } from '.';
import { EventSchedule } from '.';
import { SponsorsOnTier } from '.';
import { EventStage } from '.';
import { EventTicket } from '.';
import { Organization } from '../../../interfaces/settings/organization_interface';
import { IntegrationTypes } from '../../Settings/enum/integrations.enum';
import { MapEvent } from './event-registration-tools_interface';
import { EventRegistrant } from './event-registrant_interface';
import { EventCoupon } from './event-coupon_interface';

export interface EventAreas {

    stage: boolean;

    session: boolean;

    peopleArea: boolean;

    expo: boolean;
}
export interface EventFooterLinks {

    name?: string;

    url?: string;
}

export interface EventProperties {
    tickets: number;
    ticketGroups: number;
    sessions: number;
    stages: number;
    expos: number;
    schedules: number;
    sponsors: number;
    sponsorTiers: number;
    speakers: number;
    vendors: number;
    budgets: number;

}

export interface EventLocationTypeProperties {
    onsite: number;
    hybrid: number;
    webinar: number;
}

export interface EventRegisterOptions {

    redirectionUrl: string | null;

    message: string | null;

    paymentUrl?: string;
}

export interface EventLocation
{
    name: string;
    url: string;
    longitude: string;
    latitude: string;
    pincode: string;
    city: string;
}

export interface EventBadgeSetting
{
    qrcode: QrCodeDisplay;
    qrCodeSize: QrCodeSize;
    fontSize: FontSize;
    components: string[][];
}

export interface EventVcardSetting
{
    phonenumber?: string;
    nickname?: string;
    role?: string;
    social?: string[];
    birthday?: string;
    url?: string[];
    company?: string;
    note?: string;
}

export interface EventAdditionalForms
{
    npsForm: EventNpsFormObj;
    sessionFeedbackForm: EventSessionFeedbackFormObj;
    speakerNominationForm: any;
    sponsorNominationForm: any;
}

export interface EventNpsFormObj
{
    id: string;
    voiceFeedback: VoiceFeedback;
    npsDateTime: EventNpsScheduleType;
    timestamp: number;
}

export interface EventSessionFeedbackFormObj
{
    id: string;
    voiceFeedback: VoiceFeedback;
    feedbackDateTime: EventSessionFeedbackScheduleType;
}

export interface LandingPageSections {
    speakers: boolean;
    sponsors: boolean;
    schedule: boolean;
}

export interface Event {
    id: string;

    title?: string;

    link?: string;

    type: EventTypes;

    locationType: EventLocationType;

    registrationType?: EventRegistrationTypes;

    description?: string;

    about?: string;

    eventDates?: string;

    eventStartDateTime: number;

    eventEndDateTime: number;

    status?: EventStatus;

    timezone?: string;

    eventCategoryId?: string;

    country?: string;

    state?: string;

    maximumRegistration?: number;

    closingDateTime?: number;

    registrationClosingDateTimezone?: string;

    message?: string;

    // maximumTickets?: number;

    enableWaitlistStatus?: EnableWaitlistStatus;

    enableAuthenticationStatus?: EnableAuthenticationStatus;

    maximumWaitlist?: number;

    waitlistClosingDateTime?: number;

    waitlistSuccessMessage?: string;

    waitlistUnSuccessMessage?: string;

    registerOptions?: EventRegisterOptions;
    calendarInvitation: CalendarInvitation;
    calendarDescription?: string;

    registerUnSuccessMessage?: string;

    additionalFieldData?: EventAdditionalFieldData;

    addTeamMember?: boolean;
    
    registrationFormId?: string;

    leadCaptureFormId?: string;

    additionalForms: EventAdditionalForms;

    eventAreas?: EventAreas;

    eventLogo?: string;

    eventBanner?: string;

    brandColor?: string;

    icps?: string[];
    buyerPersonas: string[];
    moderators: string[];
    enableTransactionalMails?: EnableTransactionalMails;

    landingPageLayout?: number;

    eventFooterLinks?: EventFooterLinks[];

    landingPageType?: LandingPageType;

    landingPageTheme?: EventLandingPageTheme;

    landingPageSections: LandingPageSections;

    locationDetails?: EventLocation;

    lumaEventId?: string;

    integrationType?: EventIntegrationType;
    badgeSetting?: EventBadgeSetting;
    invoiceDetails: EventInvoiceDetails;
    vcardSetting: EventVcardSetting;

    eventPhase?: string;

    syncCrm?: SyncCrm;

    roi?: EventRoi;
    budget?: number;
    userId?: string;
    orgId?: string;
    created?: Date;
    modified?: Date;

    webinarStartUrl?: string;
    virtualLink?: string;
    referenceEventId?: string;

    zoomWebinarAction?: ZoomWebinarAction;

    keywords?: string[];
    keyword?: string;

    prospectJobStatus: EventProspectJobStatusObj;
}

export interface EventProspectJobStatusObj
{
    linkedInJobStatus: EventProspectJobStatus;
    twitterJobStatus: EventProspectJobStatus;
    timestamp: number;
}

export enum EventProspectJobStatus
{
    YET_TO_BE_INITIATED = 1,
    IN_PROGRESS = 2,
    COMPLETED = 3,
    FAILED = 4
}

export interface EventInvoiceDetails
{
    invoicePrefix: string;
    invoiceNumber: number;
}

export interface RegistrationEvent 
{
    id?: string;
    title: string;
    link: string;
    type: EventTypes;
    locationType: EventLocationType;
    registrationType: EventRegistrationTypes;
    description?: string;
    about?: string;
    eventStartDateTime: number;
    eventEndDateTime: number;
    status: EventStatus;

    calendarDescription?: string;

    enableWaitlistStatus: EnableWaitlistStatus;
    maximumWaitlist?: number;
    waitlistClosingDateTime?: number;
    waitlistSuccessMessage?: string;
    waitlistUnSuccessMessage?: string;
    registerOptions?: EventRegisterOptions;
    registerUnSuccessMessage?: string;
    additionalFieldData?: EventAdditionalFieldData;

    coupon?: EventCoupon;

    timezone: string;
    country: string;
    state: string;
    locationDetails: EventLocation;

    message?: string;

    maximumRegistration: number;
    closingDateTime: number;

    
    registrationFormId?: string;

    eventAreas: EventAreas;
    eventLogo: string;
    eventBanner: string;
    brandColor: string;

    invoiceDetails: EventInvoiceDetails;

    syncCrm?: SyncCrm;

    roi: EventRoi;
    shareId?: string;
    lumaId?: string;
    budget?: number;
    userId: string;
    orgId: string;
    created?: Date;
    modified?: Date;
}

export interface EventDetails extends RegistrationEvent
{
    org: Organization;
    registrationForm?: RegistrationForm;
    schedules?: EventSchedule[];
    speakers?: Speaker[] | Event[];
    sponsors?: SponsorsOnTier[];
    stages?: EventStage[];
    expos?: EventExpo[];
    eventTickets?: EventTicket[];
    events?: Event[];
    token?: string;
}

export interface PaymentSuccessPageData
{
    eventDetails?: EventPreview;
    paymentId?: string;

}

export interface EventCategory {
    id?: string;

    name: string;

    description?: string;

    status?: EventCategoryStatus;

    userId?: string;
    orgId?: string;
    created?: Date;
    modified?: Date;
}

export interface EventCategoryCountFilter {
    type?: EventCategoryCountType;
    userId?: string;
    status?: EventCategoryStatus;
}

export interface EventAdditionalFieldData
{
    [key: string]: string | number | string[] | number[] | boolean;   
}

export interface RegistrationEvent 
{
    id?: string;
    
    title: string;

    link: string;

    type: EventTypes;

    locationType: EventLocationType;

    calendarDescription?: string;

    registrationType: EventRegistrationTypes;
    // maximumTickets?: number,
    enableAuthenticationStatus?: number,

    description?: string;

    about?: string;

    eventStartDateTime: number;

    eventEndDateTime: number;

    status: EventStatus;

    eventFooterLinks: EventFooterLinks[]

    enableWaitlistStatus: EnableWaitlistStatus;

    maximumWaitlist?: number;

    waitlistClosingDateTime?: number;

    waitlistSuccessMessage?: string;

    waitlistUnSuccessMessage?: string;

    registerOptions?: EventRegisterOptions;

    registerUnSuccessMessage?: string;

    landingPageTheme?: EventLandingPageTheme;


    timezone: string;

    country: string;

    state: string;


    message?: string;


    maximumRegistration: number;

    closingDateTime: number;

    registrationClosingDateTimezone?: string;

    additionalFieldData?: EventAdditionalFieldData;

    landingPageLayout?:number;
    
    registrationFormId?: string;

    locationDetails: EventLocation;

    integrationType?: EventIntegrationType;

    eventAreas: EventAreas;

    eventLogo: string;

    eventBanner: string;

    brandColor: string;

    roi: EventRoi;
    userId: string;
    orgId: string;
    created?: Date;
    modified?: Date;
}

export interface EventRegistrationDetails {

    maximumRegistration: number;

    closingDateTime: number;

    message?: string;

    // maximumTickets: number;

    enableWaitlistStatus: EnableWaitlistStatus;

    enableAuthenticationStatus: EnableAuthenticationStatus;

    maximumWaitlist?: number;

    waitlistClosingDateTime?: number;

    waitlistSuccessMessage?: string;

    waitlistUnSuccessMessage?: string;
}

export interface EventsCountFilter {

    userId?: string;
    status?: EventStatus;
    type?: EventTypes;
    locationType?: EventLocationType;
}

export interface EventSearchRequest extends PaginatedRequest {
    status?: EventStatus;
    userId?: string;
    type?: EventTypes;
    locationType?: EventLocationType;
    eventStartDateTime?: number;
    eventEndDateTime?: number;
    order: DailyOrder;
}

export interface EventSearch {

    title?: string;
}

export interface UpdateEventRequest {

    title?: string;

    link?: string;

    type?: EventTypes;

    registrationType: EventRegistrationTypes;
    locationType?: EventLocationType;
    description?: string;

    about?: string;

    eventStartDateTime?: number;

    eventEndDateTime?: number;

    status?: EventStatus;

    timezone?: string;

    country?: string;

    state?: string;

    calendarDescription?: string;


    maximumRegistration?: number;

    closingDateTime?: number;

    message?: string;

    // maximumTickets?: number;

    enableWaitlistStatus?: EnableWaitlistStatus;

    enableAuthenticationStatus?: EnableAuthenticationStatus;

    maximumWaitlist?: number;

    waitlistClosingDateTime?: number;

    waitlistSuccessMessage?: string;

    waitlistUnSuccessMessage?: string;

    registerOptions?: EventRegisterOptions;

    registerUnSuccessMessage?: string;

    registrationFormId?: string;

    eventAreas?: EventAreas;

    additionalFieldData?: EventAdditionalFieldData;
    
    eventLogo: string;

    eventBanner: string;

    brandColor: string;

    syncCrm?: SyncCrm;

    virtualLink?: string;

    referanceEventId?: string;
}

export interface EventRoiTrend {
    id?: string;
    title: string;
    locationType: EventLocationType;
    type: EventTypes;
    roi: EventRoi;
}

export interface EventRoiOrder {
    topData: EventRoiTrend[] | [];
    bottomData: EventRoiTrend[] | [];
}

export interface EventRoi {
    pipeline: number;
    revenueRecognized: number;
    roi?: number;
    id?: string;
}

export interface EventPreview extends RegistrationEvent {
    org: Organization;
    registrationForm?: RegistrationForm;
    schedules?: EventSchedule[];
    speakers?: Speaker[] | Event[];
    sponsors?: SponsorsOnTier[];
    stages?: EventStage[];
    expos?: EventExpo[];
    eventTickets?: EventTicket[];
    coupon?: EventCoupon;
    token?: string;
}

export interface EventAnalyticsTrend
{
    registrants: number;
    approved: number;
    pipeline: number;
    revenue: number;
}

export interface EventIntegrationMap
{
    eventId: string;
    integrationMapId: string;
}

export interface EventIntegrationMapRequest
{
    type: IntegrationTypes;
    mapEvent: EventIntegrationMap[];
}

export interface EventMap
{
    type: IntegrationTypes;
}

export interface EventMapObj
{
    events: Event[];
    mapEvents: MapEvent[];
}

export interface EventRegistrantInvoiceDetails
{
    org: Organization;
    event?: Event;
    registrant?: EventRegistrant;
}

export interface EventModerators
{
    registrantIds: string[];
}