import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getAllRegistrantsByEvents } from '../../../../../../scripts/apis/eventRegistrants';
import { EventRegistrant } from '../../../../interfaces/event-registrant_interface';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton } from '../../../../../../common/FormComponents/Buttons';
import TanstackTable from '../../../../../../common/TanstackTable/TanstackTable';
import { generateAudienceColumnsForTanstackTable } from '../../../../../Audience/audienceColumn.helper';
import TableEmptyComponent from '../../../../../../common/TableEmptyComponent';
import { addTargetList } from '../../../../../../redux/events/eventTargetList/targetList';
import './styles.scss';
import { EventRegistrantFilterData, IEventTargetListDispatch, IEventTargetListReduxData } from '../../../../interfaces/event-target-list-interface';
import TanstackToolbarWithServerFilter from '../../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { useTablePagination } from '../../../../../../contexts/TablePaginationContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { audienceFilterColumns } from '../../helpers/TargetListContants';
import DeletePopup from '../../../../../../common/DeletePopup';
import { UserViewType } from '../../../../../Settings/enum/users.enum';
import { UserContext } from '../../../../../../contexts/UserContext';

const BuildTargetListComponent: React.FC = (): JSX.Element => 
{
  const [eventRegistrant, setEventRegistrant] = useState<EventRegistrant[]>([]);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [audienceIds, setAudienceIds] = useState<string[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const [registrantCount, setRegistrantCount] = useState<number>(0);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const audienceColumns = useMemo(() => generateAudienceColumnsForTanstackTable(), []);
  const [allColumns, setAllColumns] = useState(audienceColumns);
  
  const currentpath = useLocation().pathname;
  const searchParams = new URLSearchParams();

  const { userDetails } = useContext(UserContext);
  const minimalView = userDetails?.viewType === UserViewType.MINIMAL;

  const csrfTokenData = useSelector((state: any): string => 
  {
      return state['csrfTokenValue'].value.csrfToken;
  });

  const eventTargetListReduxData = useSelector((state: IEventTargetListDispatch): IEventTargetListReduxData => 
  {
    return state.eventTargetList.value;
  });
  const eventRegistrantFilterData : EventRegistrantFilterData[] = eventTargetListReduxData?.filter;

  const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
    filters.forEach(filter => {
        searchParams.append(filter.selectedColumn, filter.inputValue);
    });
    setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
    navigate(`${currentpath}?${searchParams.toString()}`);
  };

  const handleDeleteClick = () => 
  {
      const selectedAudienceIds = eventRegistrant.filter((registrant) => selectedRowIds.includes(registrant.id)).map((registrant) => registrant.audienceId);
      const updatedEventRegistrant = eventRegistrant.filter(
          (registrant) => !selectedRowIds.includes(registrant.id)
      );
      const updatedAudienceIds = audienceIds.filter((id) => !selectedAudienceIds.includes(id));
      setEventRegistrant(updatedEventRegistrant);
      setAudienceIds(updatedAudienceIds);
      setRegistrantCount(updatedAudienceIds.length);
      setSelectedRowIds([]);
      setShowDeletePopup(false);
  };

  const handleCancelClick = () =>
  {
    setSelectedRowIds([]);
  };

  const fetchRegistrantData = async () => 
  {
    try
    {
      setShowSpinner(true);

      const filter: any[] = eventRegistrantFilterData.map(event => ({
        eventId: event.id,
        statusArr: event.approvalStatus ? event.approvalStatus.join(',') : '',
        attendeeStatusArr: event.attendedStatus ? event.attendedStatus.join(',') : '',
        ticketIdsArr: event.ticketId ? event.ticketId.join(',') : '',
      }));

      const query = new URLSearchParams(currentUrl.split('?')[1]);
      const queryParams: { [key: string]: string } = {};
      query.forEach((value, key) => {
        queryParams[key] = value;
      });

      const payload = {
        ...queryParams,
        pageSize: pageSize ? pageSize : 25,
        offset: currentPage - 1,
        filter,
      };

      const allRegistrantsByEvents = await getAllRegistrantsByEvents(payload, csrfTokenData);
      const allRegistrants = allRegistrantsByEvents.registrants;
      setAudienceIds(allRegistrantsByEvents.audienceIds);
      setRegistrantCount(allRegistrantsByEvents.audienceIds.length);
      setIsEmpty(allRegistrantsByEvents.audienceIds.length === 0 && query.toString().length === 0);
      setEventRegistrant(allRegistrants);
      setShowSpinner(false);
    }
    catch (error)
    {
      console.error(error);
      setShowSpinner(false);
    }
  };

  useEffect(() => 
  {
    dispatch(addTargetList({
      audienceIds: audienceIds,
    }))
  }, [audienceIds])

  useEffect((): void => 
  {
      if(!pageSize)
      {
          updatePageSize(25);
      }
      if (eventRegistrantFilterData.length > 0)
      {
        fetchRegistrantData();
      }
      else
      {
        setIsEmpty(true)
      }
  }, [pageSize, currentPage, currentUrl]); 

  const buildTargetListView = () => 
  {
    return (
      <div id="eventTargetListItemComponent">
          {isEmpty ? (
              <TableEmptyComponent infoText={"No event registrants exist"} />
          ) : (
              <div className="h-100 w-100 table-top-container">
                  {selectedRowIds.length > 0 ? (
                      <TanstackToolbarWithServerFilter
                        columns={audienceColumns}
                        setColumns={setAllColumns}
                        setCurrentUrl={setCurrentUrl}
                        leftActionButtons={[
                          <CustomButton
                            btnType="danger"
                            name="Delete"
                            onClick={() => setShowDeletePopup(true)}
                          />,
                          <CustomButton
                              btnType="secondary"
                              name="Cancel"
                              onClick={handleCancelClick}
                          />,
                          <div className="table-header-text">
                              {" "}
                              {selectedRowIds.length} records selected{" "}
                          </div>]}
                        handleApplyFilters={handleApplyFilters}
                        filterColumns={audienceFilterColumns}
                      />
                  ) : (         
                        <TanstackToolbarWithServerFilter
                          columns={audienceColumns}
                          setColumns={setAllColumns}
                          setCurrentUrl={setCurrentUrl}
                          tabs={[{ tabName: 'All', count: registrantCount, navigation: () => {}, selectedTab: true }]}
                          handleApplyFilters={handleApplyFilters}
                          filterColumns={audienceFilterColumns}
                        />
                  )}
                  {!isEmpty && (
                      <TanstackTable
                          // height='calc(100vh - 287px)'
                          checkboxSelection={true}
                          data={eventRegistrant}
                          initialColumns={allColumns}
                          rowCount={registrantCount}
                          showNoRowsOverlay={registrantCount == 0}
                          showSpinner={showSpinner}
                          selectedRows={selectedRowIds}
                          setSelectedRows={setSelectedRowIds}
                          pageSize={pageSize}
                          currentPage={currentPage - 1}
                          updateCurrentPage={updateCurrentPage as any}
                          updatePageSize={updatePageSize}
                      />
                  )}
              </div>
          )}
            {showDeletePopup && (
              <DeletePopup
                  acceptBtn='Delete'
                  acceptClick={handleDeleteClick}
                  cancelClick={() => setShowDeletePopup(false)}
                  modalContent={`Are you sure you want to delete the selected records?`}
                  modalTitle='Delete Records'
                  show={showDeletePopup}
                  rejectBtn='Cancel'
                  modalHeaderIcon='trash'
              />)}
      </div>
    );
  }

  const buildTargetListMinimalView = () =>
  {
    return (
      <div id="eventTargetListItemMinimalView">
          {isEmpty ? (
              <TableEmptyComponent infoText={"No event registrants exist"} />
          ) : (
              <div className="h-100 w-100 table-top-container">
                  {selectedRowIds.length > 0 ? (
                      <TanstackToolbarWithServerFilter
                        columns={audienceColumns}
                        setColumns={setAllColumns}
                        setCurrentUrl={setCurrentUrl}
                        leftActionButtons={[
                          <CustomButton
                            btnType="danger"
                            name="Delete"
                            onClick={() => setShowDeletePopup(true)}
                          />,
                          <CustomButton
                              btnType="secondary"
                              name="Cancel"
                              onClick={handleCancelClick}
                          />,
                          <div className="table-header-text">
                              {" "}
                              {selectedRowIds.length} records selected{" "}
                          </div>]}
                        handleApplyFilters={handleApplyFilters}
                        filterColumns={audienceFilterColumns}
                      />
                  ) : (         
                        <TanstackToolbarWithServerFilter
                          columns={audienceColumns}
                          setColumns={setAllColumns}
                          setCurrentUrl={setCurrentUrl}
                          tabs={[{ tabName: 'All', count: registrantCount, navigation: () => {}, selectedTab: true }]}
                          handleApplyFilters={handleApplyFilters}
                          filterColumns={audienceFilterColumns}
                        />
                  )}
                  {!isEmpty && (
                      <TanstackTable
                          // height='calc(100vh - 287px)'
                          checkboxSelection={true}
                          data={eventRegistrant}
                          initialColumns={allColumns}
                          rowCount={registrantCount}
                          showNoRowsOverlay={registrantCount == 0}
                          showSpinner={showSpinner}
                          selectedRows={selectedRowIds}
                          setSelectedRows={setSelectedRowIds}
                          pageSize={pageSize}
                          currentPage={currentPage - 1}
                          updateCurrentPage={updateCurrentPage as any}
                          updatePageSize={updatePageSize}
                      />
                  )}
              </div>
          )}
            {showDeletePopup && (
              <DeletePopup
                  acceptBtn='Delete'
                  acceptClick={handleDeleteClick}
                  cancelClick={() => setShowDeletePopup(false)}
                  modalContent={`Are you sure you want to delete the selected records?`}
                  modalTitle='Delete Records'
                  show={showDeletePopup}
                  rejectBtn='Cancel'
              />)}
      </div>
    );
  }

  return minimalView ? buildTargetListMinimalView() : buildTargetListView();

};

export default BuildTargetListComponent;
