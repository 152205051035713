import { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormTabs from "../../../../common/FormTabs";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import AddFolder from "../../../../components/Events/PhotoSharing/AddFolder";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import AddPhotos from "../../../../components/Events/PhotoSharing/AddPhotos";
import { EventPhotoSharingContext } from "../../../../contexts/EventPhotoSharing/EventPhotoSharingContext";
import { Grid, Tab, Tabs } from "@mui/material";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import _ from "lodash";
import Photos from "./Photos";
import { useLocation, useNavigate } from "react-router-dom";
import { EventPhotoSharingFolder } from "../../interfaces/event-photo-sharing_interface";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { IBillingInfo } from "../../../../redux/billing/billing-redux_interface";
import { UserContext } from "../../../../contexts/UserContext";
import { UserViewType } from "../../../Settings/enum/users.enum";
import { MinimalViewComponent } from "../../../../common/MinimalView/MinimalViewComponent";
import CustomTooltip from "../../../../common/Tooltip";
import AddPhotoDesign from "./AddPhotoDesign";

import './styles.scss';
import CardComponent from "../../../../components/Events/Registrations/CardComponent";
import { EventStatus } from "../../enum";
import EventsHeaderBar from "../../../../common/EventsHeaderBar";

const PhotosPage = (): React.JSX.Element =>
{

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);
    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const isFolders = currentpath?.endsWith('photo-sharing');
    const isHidden = currentpath?.includes('hidden');
    const isDefault = currentpath?.includes('default-photos');
    const isUserUploads = currentpath?.includes('user-uploads');
    const isApproved = currentpath?.includes('approved');
    const isPublished = eventReduxData?.status === EventStatus.PUBLISHED; 

    const { 
        eventId, 
        folders, 
        setRefresh, 
        selectedFolder, 
        setSelectedFolder, 
        isLoading, 
        photos, 
        setOffset, 
        setPhotos, 
        fetchPhotosForFolder, 
        hasMore, 
        foldersRefresh, 
        setFoldersRefresh, 
        eventLink, 
        folderData, 
        userUploadFolder, 
        orgLevelPhotosCount,
        photosCount 
    } = useContext(EventPhotoSharingContext);

    const {
        userDetails,
        orgDetails
    } = useContext(UserContext);

    const isMinimalView = userDetails?.viewType === UserViewType.MINIMAL;

    // variable to check if the action button should display or not
    const shouldActionButtonDisplay = (folders?.length > 0 || currentpath?.includes('default-photos')) && !isHidden && !isUserUploads && !isApproved;

    // tab data to render the list of folders present - empty by default
    const folderTabs: {[key: string]: string | number | undefined}[] = [];
    // const folderTabsUnique = _.uniqBy(folderTabs, 'id');


    useEffect(() => {
        setSelectedFolder('');
    }, []);

    const foldersTab = [
        {
            tabName: <div className="iconed-tab"><FontAwesomeIcon icon={['fal', 'folder']} /><p>Folders</p></div>,
            selectedTab: currentpath?.match(/photo-sharing(\/\d+)?$/),
            navigation: (): void => 
            {
                setSelectedFolder('');
                if(currentpath?.includes('photo-sharing'))
                {
                    setOffset(0);
                    return navigate(`/events/${eventId}/photo-sharing`);
                }
                setOffset(0);
                return navigate(`/events/${eventId}/photo-sharing`);
            }
        }
    ];

    // tab data to select the filter for folders: hidden and visible - visible will be selected by default
    const hiddenTabs = [
        {
            tabName: <div className="iconed-tab"><FontAwesomeIcon icon={['fal', 'eye-slash']} /><p>Hidden</p></div>,
            selectedTab: currentpath?.includes('hidden'),
            navigation: (): void => 
            {
                setPhotos([]);
                setOffset(0);
                setSelectedFolder('');
                navigate(`/events/${eventId}/photo-sharing/hidden`);
                // setSelectedFolder('');
                // if(currentpath?.includes('hidden'))
                // {
                //     setOffset(0);
                //     return navigate(`/events/${eventId}/photo-sharing/${selectedFolder || Number(folders[folders?.length - 1].id)}`);
                // }
                // setOffset(0);
                // navigate(`/events/${eventId}/photo-sharing/${selectedFolder || Number(folders[folders?.length - 1]?.id)}/hidden`);

            }
        }
    ];

    // tab data to render the default photos - unselected by default
    const defaultPhotosTabs = [
        {
            tabName: <div className="iconed-tab"><FontAwesomeIcon icon={['fal', 'calendar-image']} /><p>Default Photos</p></div>,
            selectedTab: currentpath?.includes('default-photos'),
            navigation: (): void => 
            {
                if(currentpath?.includes('default-photos'))
                {
                    setOffset(0);
                    return navigate(`/events/${eventId}/photo-sharing/${folders[folders?.length - 1].id}`);
                }
                setOffset(0);
                setSelectedFolder('');
                navigate(`/events/${eventId}/photo-sharing/default-photos`);
            }
        }
    ];

    const designPhotoTabs = [
        {
            tabName: <div className="iconed-tab"><FontAwesomeIcon icon={['fal', 'palette']} /><p>Design</p></div>,
            selectedTab: false,
            navigation: (): void => 
            {
                openAddDesignDrawer();
            }
        }
    ];

    // function to handle folder change
    const handleFolderChange = (value: any): void =>
    {
        setPhotos([]);
        setOffset(0);
        setSelectedFolder(value);
        navigate(`/events/${eventId}/photo-sharing/${value}`);
    };

    // function to open the add photos drawer
    const openAddPhotosDrawer = (): void =>
    {

        setTimeout((): void => 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                heading: <div><h3 style={{ color: 'var(--colors-text-text-primary-900, #101828)', fontSize: '18px', fontWeight: 600 }}>Upload Photos</h3><p style={{ color: 'var(--colors-text-text-tertiary-600, #475467)', fontSize: '14px', fontWeight: 400 }}>{`Please upload photos with file size 25MB. You can select a maximum of 200 files.`}</p></div>,
                component: <AddPhotos eventId={eventId} folderId={Number(selectedFolder) || ''} isDefault={isDefault} />,
                componentWidth: '600px',
                componentHeight: '600px'
            });

            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                open: true
            });
        });
    };

    // function to open the add folder drawer
    const openAddFolderDrawer = (folderData?: EventPhotoSharingFolder): void =>
    {
        setTimeout((): void => 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                iconHeading: 'folder',
                component: <AddFolder setRefresh={setRefresh} eventId={eventId} setFoldersRefresh={setFoldersRefresh} existingFolderData={folderData} />,
                componentWidth: '400px',
                componentHeight: '330'
            });

            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                open: true
            });
        });
    }

    const openAddDesignDrawer = (): void =>
        {
    
            setTimeout((): void => 
            {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, 
                {
                    heading: 'Design',
                    event: 'add-watermark',
                    component: <AddPhotoDesign eventId={eventId} existingFolderData={folderData}  setRefresh={setRefresh} />,
                });
                eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, 
                {
                    open: true,
                    width: '600px !important'
                });
            });
        };

    if (folders && folders?.length > 0 && folderTabs?.length <= 5) 
    {
        folders.forEach((folder) => {
            folderTabs.unshift({
                name: folder.name,
                id: folder.id,
                count: folder?.count
            });
        });
    }

    const renderHeader = (): React.JSX.Element => 
    {
        return (
            <EventsHeaderBar
                title="Photo Sharing"
                titleRightNode={
                    <div className="header-copy-icon">
                        <CustomTooltip
                            title={isPublished ? "Copy Link" : "Event is not published"}
                        >
                            <div>
                                <CustomButton
                                    name=""
                                    btnType="tertiary"
                                    onClick={() => {
                                        if (isPublished) {
                                            navigator.clipboard.writeText(
                                                `${orgDetails?.link}.${APP_CONSTANTS.APP_MAIN_DOMAIN}/${eventLink}/photos`
                                            );
                                            toast.success("Link copied to clipboard");
                                        }
                                    }}
                                    startIcon={
                                        <FontAwesomeIcon
                                            icon={["fal", "copy"]}
                                            style={{
                                                cursor: isPublished ? "pointer" : "not-allowed",
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </CustomTooltip>
                    </div>
                }
                buttons={[
                    orgBillingInfo?.billingModules &&
                        orgBillingInfo?.billingModules?.photoSharing.photoSharingLimit ==
                        50 ? (
                        <MuiChip
                            label="Free plan can only accomodate upto 50 photos"
                            chipColor="red"
                        />
                    ) : (
                        <></>
                    ),
                ]}
            />
        );
    }

    const renderFilters = (): React.JSX.Element => {
        return (
            <>
                {/* Filters and other buttons container */}
                {folders && folders?.length > 0 && 
                <div>
                    <div className="filters-wrapper">
                        <FormTabs tabs={foldersTab} />

                        {/* Upload filters */}
                        <div>
                            <FormTabs tabs={[
                                {
                                    tabName: <div className="iconed-tab"><FontAwesomeIcon icon={['fal', 'user-group']} /><p>User uploads</p></div>,
                                    selectedTab: currentpath?.includes('user-uploads'),
                                    navigation: () =>  { 
                                        // if(currentpath?.includes('user-uploads'))
                                        // {
                                        //     setOffset(0);
                                        //     return navigate(`/events/${eventId}/photo-sharing/${selectedFolder || Number(folders[folders?.length - 1].id)}`);
                                        // }
                                        setOffset(0);
                                        setSelectedFolder('');
                                        navigate(`/events/${eventId}/photo-sharing/user-uploads`);
                                    }
                                },
                                {
                                    tabName: <div className="iconed-tab"><FontAwesomeIcon icon={['fal', 'image']} /><p>Approved</p></div>,
                                    selectedTab: currentpath?.includes('approved'),
                                    navigation: () => { 
                                        // if(currentpath?.includes('approved'))
                                        // {
                                        //     setOffset(0);
                                        //     return navigate(`/events/${eventId}/photo-sharing/${selectedFolder || Number(folders[folders?.length - 1].id)}`);
                                        // }
                                        setOffset(0);
                                        setSelectedFolder('');
                                        navigate(`/events/${eventId}/photo-sharing/approved`);
                                    }
                                }
                            ]} 
                            />
                        </div>

                        {/* Hidden filter */}
                        {folders?.length > 0 && <div>
                            <FormTabs tabs={hiddenTabs} />
                        </div>}


                        {/* Default photo filter */}
                        {folders?.length > 0 && <div>
                            <FormTabs tabs={defaultPhotosTabs} />
                        </div>}

                        {folders?.length > 0 && orgBillingInfo?.billingModules && orgBillingInfo.billingModules.photoSharing.photoSharingWatermark && <div>
                            <FormTabs tabs={designPhotoTabs} />
                        </div>}

                        {/* Folders in tab View, Uncomment if you want this UI */}
                        {/* {isMinimalView ? <FolderDropdown initialSelectedFolder={selectedFolder} handleCreateFolder={() => openAddFolderDrawer() } folders={folders} handleFolderChange={(value) => { handleFolderChange(value) }} /> : <div className="folder-tabs">
                            {folders && <Filters tabData={folderTabs} selectedTab={selectedFolder} maxWidth={'240px'} maxLength={5} selectedIcon={'folder-open'} defaultIcon={'folder'} handleFilter={(value) => { handleFolderChange(value) }} handleRemoveFilter={() => { setSelectedFolder(''); }} />}
                            {folders && folders?.length < 5 && <div className="add-folder-btn"><FontAwesomeIcon icon={['fal', 'plus']} onClick={() => { openAddFolderDrawer() }} /></div>} 
                        </div>} */}
                    </div>

                    {/* {shouldActionButtonDisplay && orgBillingInfo?.billingModules && !(orgLevelPhotosCount >= orgBillingInfo?.billingModules?.photoSharing?.photoSharingLimit) && 
                        <div>
                            {
                                isMinimalView ?
                                    <CustomButton name="" btnType="primary" startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />} onClick={openAddPhotosDrawer} /> :
                                    <CustomButton style={{ width: '100px' }} name='Add Photos' btnType="secondary" onClick={openAddPhotosDrawer} />
                            }
                        </div>
                    }  */}
                </div>}
            </>
        )
    };

    const renderPhotoSharingContent = (): React.JSX.Element => {
        return (
            <>
                {/* {selectedFolder && !isFolders && !isHidden ? 
                    <div className={isMinimalView ? "header-minimal" : "header"}>
                        <h3>{folderData?.name}</h3>
                        <div className="action-buttons">
                            <FontAwesomeIcon onClick={() => {openAddFolderDrawer(folderData) }} className="edit-icon" icon={['fal', 'edit']} />
                        </div>
                    </div> : <div style={{ padding: '16px 0 0' }} ></div>
                } */}


                {isFolders && folders && folders?.length > 0 ?
                    <div className="folders-list">
                        <Grid container spacing={2}>
                            {folders.map((folder) => {
                                return (
                                    <Grid key={folder?.id} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <CardComponent
                                            cardMinHeight='101px'
                                            header=
                                            {
                                                <div className="header-icon">
                                                    <div className="folder-icon">
                                                        <FontAwesomeIcon icon={['fal', 'folder']} />
                                                    </div>
                                                    <div className="action-buttons">
                                                        <CustomButton name="" btnType="tertiary" startIcon={<FontAwesomeIcon icon={['fal', 'ellipsis-vertical']} />} onClick={(e) => {
                                                            e.stopPropagation();
                                                            openAddFolderDrawer(folder);
                                                        }}/>
                                                    </div>
                                                </div>
                                            }
                                            contentHeading={folder.name}
                                            contentHeadingBold
                                            gapNotRequired
                                            footerLeft={<p>{folder.count} Photos</p>}
                                            onClick={() => handleFolderChange(folder.id)}
                                        />
                                    </Grid>
                                )
                            })}
                             <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <CardComponent
                                    emptyText='+ Add Folder'
                                    onClick={() =>
                                    {
                                        openAddFolderDrawer();
                                    }}
                                    emptyCardHeight='100px' 
                                    cardMinHeight='98px'
                                />
                            </Grid>
                        </Grid>
                    </div>

                    : 
                    <>
                        {!isHidden && !isApproved && !isUserUploads && !isFolders && !photos && (<div className="empty-state">
                            <h3>{'No folders available'}</h3>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <CustomButton name={'Add Folder'} btnType="secondary" onClick={() => { openAddFolderDrawer() }} />
                            </div>
                        </div>)}
                    </>
                    
                }

                {/* photos?.length > 0 && */}
                {!isFolders && (
                    <div className="photos-component">
                        <Photos 
                            minimalView={isMinimalView} 
                            setRefresh={setRefresh} 
                            setFoldersRefresh={setFoldersRefresh} 
                            photos={photos} 
                            hasMore={hasMore} 
                            eventId={eventId} 
                            folderId={isUserUploads || isApproved ? userUploadFolder?.id : selectedFolder || ''} 
                            fetchPhotosForFolder={fetchPhotosForFolder} 
                            setOffset={setOffset} 
                            folderData={folderData}
                            openAddPhotosDrawer={openAddPhotosDrawer} 
                            photosCount={photosCount}      
                            isPublished={isPublished} 
                        />
                    </div>
                )}         
                {/* <>
                    {(!isHidden && !isApproved && !isUserUploads && !isFolders && !isDefault && orgBillingInfo?.billingModules && !(orgLevelPhotosCount >= orgBillingInfo?.billingModules?.photoSharing?.photoSharingLimit)) && (
                        <div className="empty-state">
                            <h3>{`Add Photos to get started !`}</h3>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <CustomButton name={'Add Photos'} btnType="secondary" onClick={() => { openAddPhotosDrawer() }} />
                            </div>
                        </div>
                    )}
                </> */}
            </>
        );
    };

    const renderNormalViewForPhotosPage = (): React.JSX.Element => { 
        return (
            <div id="eventPhotoSharing">
                <div className="heading-filter">
                    {/* header */}
                    {renderHeader()}

                    {renderFilters()}
                </div>

                {renderPhotoSharingContent()}
            </div>
        )
    };

    const renderMinimalViewForPhotosPage = (): React.JSX.Element => {
        return (
            <div id="eventPhotoSharingMinimalView">
                <MinimalViewComponent 
                    title={renderHeader()}
                    description={"Manage your Event photos here."}
                    component={
                        <div>
                            {renderFilters()}
                            {renderPhotoSharingContent()}
                        </div>
                    }
                    componentType="list"
                />
            </div>
        )
    };

    return(
        <>
            {
                isMinimalView ? renderMinimalViewForPhotosPage() : renderNormalViewForPhotosPage()
            }
        </>
    )
};

export default PhotosPage;

export const Filters: React.FC<{ tabData: { [key: string]: string | number | undefined }[], selectedTab: string | number, handleFilter: (value: any) => void, maxWidth?: string, maxLength?: number, removeIcon?: IconName, handleRemoveFilter: (value: any) => void, defaultIcon: IconName, selectedIcon: IconName }> = (props) =>
{
    const { tabData, selectedTab, handleFilter, maxWidth, maxLength, defaultIcon, selectedIcon } = props;

    return(
        <div id="filters" style={{ borderRadius: tabData?.length <= (maxLength || 5) ? '8px 0 0 8px' : '8px' }}>
            <Tabs style={{ maxWidth: maxWidth || '' }} variant='scrollable' className="agenda-tabs-component" value={selectedTab || false} onChange={(event, value) => { handleFilter(value) }}>
                {tabData?.map((item, index) => {
                    const label = (
                        <div className="iconed-tab">
                            <p>{item?.name}</p>
                            {item?.count && Number(item?.count) > 0 && <MuiChip label={Number(item?.count)} chipColor="grey" />}
                        </div>
                    )
                    return (
                        <Tab key={index} label={label} value={item.id || false} className="tab-component-button" icon={<FontAwesomeIcon icon={['fal', item?.id === selectedTab ? selectedIcon : defaultIcon]} /> || undefined} iconPosition="start" />
                    )
                })}
            </Tabs>
        </div>
    )
};
