import { useEffect, useState } from "react";
import { CustomField } from "../interfaces/custom-field_interface";
import { RegistrationForm } from "../interfaces/registration-form_interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControlLabel } from "@mui/material";
import { CustomSwitch } from "../../../common/StyledComponents/Switch.styled";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CustomFieldMandatory, CustomFieldRatingType } from "../enum/custom-field.enum";
import LexicalEditor from "../../../lexical";
import { FormControlComponent, FormLabelComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import eventBus from "../../../scripts/event-bus";
import { createRegistrationFormFields, updateRegistrationForm, updateRegistrationFormFields } from "../../../scripts/apis/registrationForm";
import toast from "react-hot-toast";

interface RatingSideDrawerProps {
    drawerTitle: string;
    formId: string | number;
    csrfTokenData: string;
    setRefresh: (refresh: boolean) => void;
    componentType: number;
    existingComponentData?: CustomField;
    RegistrationFormData: RegistrationForm;
}

const RatingSideDrawer: React.FC<RatingSideDrawerProps> = (props): React.JSX.Element =>
{
    const { drawerTitle, formId, csrfTokenData, setRefresh, componentType, existingComponentData, RegistrationFormData } = props;

    const isExistingField = RegistrationFormData?.formFields?.some((customField) => customField.id === existingComponentData?.id);

    const ratingTypeOptions = [
        {
            label: '1' as IconName,
            value: CustomFieldRatingType.NUMBER
        },
        {
            label: 'star' as IconName,
            value: CustomFieldRatingType.STAR
        },
        {
            label: 'face-smile' as IconName,
            value: CustomFieldRatingType.EMOJI
        }
    ]

    const [backBtnClicked, setBackBtnClicked] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    const createFormComponent = async (componentDetails: CustomField): Promise<void> => 
    {
        try 
        {
            const componentUpdated = await createRegistrationFormFields(Number(formId), componentDetails, csrfTokenData);
            if (componentUpdated) 
            {
                setSpinner(false);
                toast.success('Component successfully saved');
                setBackBtnClicked(true);
                setRefresh(true);
                eventBus.dispatch('event-update-form-component', true);
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
        }
    };

    const updateFormComponent = async (componentDetails: CustomField): Promise<void> => 
    {
        try 
        {
            const componentUpdated = await updateRegistrationFormFields(Number(formId), existingComponentData?.id as string, componentDetails);

            if (componentUpdated) 
            {
                setSpinner(false);
                toast.success('Component successfully updated');
                setBackBtnClicked(true);
                setRefresh(true);
                eventBus.dispatch('event-update-form-component', true);
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
        }
    };

    const updateForm = async (): Promise<void> =>
    {
        const fields = RegistrationFormData?.fields;
        fields.push(existingComponentData?.id);
        try 
        {
            const formUpdated = await updateRegistrationForm(Number(formId), {fields});
            if(formUpdated)
            {
                setSpinner(false);
                toast.success('Component successfully saved');
                setBackBtnClicked(true);
                setRefresh(true);
                eventBus.dispatch('event-update-form-component', true);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const validationSchema = Yup.object().shape({
        label: Yup.string().trim().required('This field is required'),
        helpText: Yup.string().trim(),
        ratingType: Yup.number().required('This field is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            label: existingComponentData?.fieldName ? existingComponentData.fieldName : '',
            helpText: existingComponentData?.helpText ? existingComponentData.helpText : '',
            ratingType: existingComponentData?.ratingType ? existingComponentData?.ratingType : CustomFieldRatingType.NUMBER,
            componentMandatory: existingComponentData?.fieldMandatory ? existingComponentData.fieldMandatory : CustomFieldMandatory.NOT_MANDATORY,
        },
        onSubmit: async (values): Promise<void> => 
        {
            const componentDetails = {
                type: componentType,
                label: values.label === '<p><br></p>' ? null : values.label,
                helpText: values.helpText === '<p><br></p>' ? null : values.helpText,
                fieldMandatory: values.componentMandatory,
                fieldName: existingComponentData?.fieldName ? existingComponentData.fieldName : values.label,
                ratingType: values.ratingType,
            };

            if (existingComponentData && isExistingField) 
            {
                setSpinner(true);
                updateFormComponent(componentDetails as CustomField);
            }
            else if (!isExistingField && existingComponentData)
            {
                setSpinner(true);
                // updateForm();
                updateFormComponent(componentDetails as CustomField);
            }
            else
            {
                setSpinner(true);
                createFormComponent(componentDetails as CustomField);
            }
        }
    });

    useEffect((): void => 
    {
        if (backBtnClicked) 
        {
            eventBus.dispatch('event-open-form-Component-drawer', {
                componentType: 0
            });
            setBackBtnClicked(false);
            eventBus.dispatch('event-update-form-component', true);
        }

    }, [backBtnClicked]);

    return (
        <div id="ratingSideDrawer">
            <div className="drawer-header">
                <div className="drawer-inner-cont" onClick={():void => 
                    {
                        setBackBtnClicked(true);
                        
                    }}>
                    <FontAwesomeIcon icon={['fal', 'arrow-left']} className='back-icon' />
                    <p className='component-type-name'>{drawerTitle}</p>
                </div>
            </div>
            <div className="drawer-content">
                <div className="required-block">
                    <p className='required-label'>{'Required'}</p>
                    <FormControlLabel
                        checked={formik.values.componentMandatory === 1}
                        control={
                            <CustomSwitch
                                sx={{
                                    m: 1
                                }}
                                name="required"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                                {
                                    formik.setFieldValue('componentMandatory', event.target.checked ? 1 : 2);
                                }}
                            />}
                        label={null}
                        className='captcha-switch'
                    />
                </div>
                <div className="editor-block">
                    <FormLabelComponent 
                        label="Label"
                        required
                    />
                    <FormControlComponent 
                        type="text"
                        placeholder="Label"
                        name="label"
                        value={formik.values.label}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={(formik.touched?.label && formik.errors?.label) as boolean}
                    />
                    {/* {formik.touched.labelHtmlText && formik.errors.labelHtmlText && <div className="error-msg">{formik.errors.labelHtmlText}</div>} */}
                </div>
                <div className="editor-block">
                    <LexicalEditor noDrag placeHolder='Description' toolBarOptions={['Bold', 'Italic', 'Underline']} minHeight='96px' label='Description' value={formik.values.helpText} handleChange={(html, json) => {
                        formik.setFieldValue('helpText', html);
                    }} />
                </div>
                <div className="rating-type-block">
                    <FormLabelComponent 
                        label="Type"
                        required
                    />
                    <div className="rating-type-radio">
                        {
                            ratingTypeOptions?.map((option) => {
                                return (
                                    <button 
                                        className={`rating-type-btn ${formik.values.ratingType === option.value ? 'selected' : ''}`}
                                        onClick={(): void => 
                                        {
                                            formik.setFieldValue('ratingType', option.value);
                                        }}
                                    >
                                        {<FontAwesomeIcon icon={['fal', option.label]} />}
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="bottom-btn-block">
                <CustomButton btnType='primary' name='Save' loading={spinner} type='submit' onClick={() => formik.handleSubmit()} />
            </div>
        </div>
    )
}

export default RatingSideDrawer