import { useContext } from "react";
import { useJsApiLoader, Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import { FormControlLabel, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Spinner } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import toast from "react-hot-toast";

import { CreateEventContext } from "../../../contexts/CreateEvent/CreateEventContext";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { AutocompleteComponent, FormControlComponent, FormLabelComponent, RadioGroupComponent, SelectComponent, TimezoneSelectComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { EventIntegrationType, EventLocationType, EventRegistrationTypes, EventTypes } from "../../../pages/Events/enum";
import EditableHeader from "../../../common/EditableHeader";
import DateTimePicker from "../../../common/FormComponents/DateTimePicker";
import { uniqueTimezoneArr } from "../timezoneGenerateFunction";
import { CustomSwitch } from "../../../common/StyledComponents/Switch.styled";
import ButtonGroup from "../../../common/ButtonGroup";
import { IntegrationTypes } from "../../../pages/Settings/enum/integrations.enum";

const CreateEventPopup: React.FC = (): React.JSX.Element => {
    const {
        formik,
        handleValuesChange,
        handleResetFields,
        eventTypeOptions,
        eventLocationTypeOptions,
        eventPlatFormOptions,
        eventsFromPlatform,
        fetchEventFromPlatform,
        fetchEventData,
        fetchEventDataFromWebsite,
        googleAutocompleteRef,
        handleCreatePopupClose,
        integrations,
        prevLink,
        setPrevLink
    } = useContext(CreateEventContext);

    const { isLoaded } = import.meta.env.VITE_LOCATION_API_KEY ? useJsApiLoader({
        googleMapsApiKey: import.meta.env.VITE_LOCATION_API_KEY,
        libraries: ['places'],
    }) : {
        isLoaded: false
    };

    const handleNext = () => { 
        const currentLink = formik.values?.eventLink?.trim();
        if(currentLink && currentLink !== '') {
            if (formik.errors.eventLink) {
                toast.error('Please enter a valid Event URL');
                return;
            }
            if (currentLink === prevLink) {
                formik.handleSubmit();
            } else {
                handleValuesChange('isFormSubmitting', true);
                fetchEventDataFromWebsite(currentLink);
                setPrevLink(currentLink); 
            }
        } else {
            formik.handleSubmit();
        }
    }

    const renderHeader = (): React.JSX.Element => (
        <div className="create-event-popup-header">
            <h3 className="heading">{'Create Event'}</h3>
            <CustomButton 
                onClick={() => handleCreatePopupClose()}
                name=""
                btnType="tertiary_grey"
                startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />}
            />
        </div>
    );

    const renderEventTypes = (): React.JSX.Element => (
        <div className="event-type-options-container">
            {eventTypeOptions?.map((option) => (
                <div 
                    key={option.value} 
                    className={`event-type-card ${formik.values.type === option.value ? 'selected' : ''}`} 
                    onClick={() => {
                        handleValuesChange('type', option.value);
                        handleValuesChange('showEventFormFields', option?.value === EventTypes.HOST);
                        handleValuesChange('currentView', 'eventInfo');
                    }}
                >
                    <div className="event-type-icon">
                        <FontAwesomeIcon icon={['fal', option?.icon]} />
                    </div>
                    <div className="event-type-name">
                        <h3 className="event-type">{option?.name}</h3>
                        <p className="type-description">{option?.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );

    const renderEventTypeHeader = (): React.JSX.Element => {
        const selectedOption = eventTypeOptions?.find((option) => option.value === formik.values.type);
        return (
            <div className="event-type-header">
                <div className="event-type-info">
                    <div className="event-type-icon">
                        <FontAwesomeIcon icon={['fal', selectedOption?.icon as IconName]} />
                    </div>
                    <div className="event-type-name">
                        <h3 className="event-type">{selectedOption?.name}</h3>
                        <p className="type-description">{selectedOption?.description}</p>
                    </div>
                </div>
                <CustomButton 
                    name=""
                    btnType="tertiary_grey"
                    onClick={() => {
                        handleValuesChange('currentView', 'eventType')
                        handleValuesChange('eventFormFieldsDisabled', false);
                    }}
                    startIcon={<FontAwesomeIcon icon={['fal', 'pen-to-square']} />}
                />
            </div>
        );
    };

    const renderEventLocationTypes = (): React.JSX.Element => (
        <div className="event-location-type-container">
            <RadioGroupComponent 
                options={eventLocationTypeOptions as any[]}
                value={formik.values.locationType}
                onChange={(event) => {
                    if(formik.values.locationType === EventLocationType.ONSITE) { 
                        handleValuesChange('eventFormFieldsDisabled', false);
                    }
                    handleValuesChange('locationType', Number(event.target.value))
                }}
                row
            />
        </div>
    );

    const renderEventLinkFields = (): React.JSX.Element => {
        return (
            <div className="event-link-fields">
                <div className="event-link">
                    <div className="w-100">
                        <FormLabelComponent label={'Event URL'} />
                        <FormControlComponent 
                            type="text"
                            name="eventLink"
                            value={formik.values.eventLink || ''}
                            placeholder="Enter Event URL and hit Enter"
                            onChange={(event) => handleValuesChange('eventLink', event.target.value)}
                            onKeyDown={(event) => { 
                                if(event.key === 'Enter') {
                                    handleNext();
                                }
                            }}
                        />
                        {formik.errors?.eventLink && formik.touched?.eventLink && <p className="error-msg">{formik.errors?.eventLink}</p>}
                    </div>
                    <p className="info-text">{'Enter the Event URL'}</p>
                </div>
                {
                    !formik.values.showEventFormFields &&
                    <div className="button-container">
                        <CustomButton 
                            name="Add more details"
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType="tertiary_grey"
                            onClick={() => handleValuesChange('showEventFormFields', true)}
                        />
                    </div>
                }
            </div>
        )
    };

    const renderEventDetails = (): React.JSX.Element => (
        <div className="event-details-container">
            {renderEventLocationTypes()}
            {formik.values.locationType === EventLocationType.VIRTUAL && formik.values.type === EventTypes.HOST && renderEventWebinarDetails()}
            {
                formik.values.type !== EventTypes.HOST && renderEventLinkFields() 
            }
            <>
                {
                    formik.values.showEventFormFields && renderEventFormFields()
                }
                {renderSubmissionButton()}
            </>
        </div>
    );

    const renderSubmissionButton = (): React.JSX.Element => (
        <div className="submission-container">
            <CustomButton 
                name="Create Event"
                btnType="primary"
                onClick={() => {
                    if(formik.values.type === EventTypes.HOST) {
                        formik.handleSubmit()
                    } 
                    else {
                        handleNext();
                    }
                }}
                width="100%"
                style={{ width: '100%' }}
                loading={formik.values.isFormSubmitting}
                disabled={formik.values.isFormSubmitting}
            />
        </div>
    );

    const renderEventFormFields = (): React.JSX.Element => {
        const isFieldsDisabled = formik.values.eventFormFieldsDisabled;
        const isDataBeingFetched = formik.values.isEventDataFetching;
        return (
            <div className={`event-form-fields ${isFieldsDisabled ? 'disabled' : ''}`}>
                <div className={`event-form-fields-spinner ${isDataBeingFetched ? 'show' : ''}`}> 
                    <Spinner role="status" size="sm" />
                </div>
                <div className="w-100">
                    <EditableHeader
                        fullWidth 
                        text={formik.values.title}
                        placeholder="Event Title"
                        onChange={(value) => handleValuesChange('title', value, false)}
                    />
                    {formik.errors.title && formik.touched.title && <p className="error-msg">{formik.errors.title}</p>}
                </div>
                {formik.values?.locationType === EventLocationType.VIRTUAL && formik.values?.eventIntegrationType === EventIntegrationType.ZOOM && (
                    <p className="event-title-info">{'*Any changes made here will also reflect in the Zoom webinar, ensuring a seamless two-way sync.'}</p>
                )}
                {renderEventTiming()}
                {formik.values.locationType === EventLocationType.ONSITE && renderEventLocation()}
                {formik.values.type !== EventTypes.ATTENDEE && renderEventApproval()}
            </div>
        );
    };

    const renderEventTiming = (): React.JSX.Element => {
        const handleTimezoneChange = (value: string) => {
            const timezone = _.find(uniqueTimezoneArr as any, (item: { name: string; value: string }): boolean => item.name === value);
            handleValuesChange('timezone', timezone);
        };

        return (
            <div className="event-timing-container">
                <div className="event-timing">
                    <DateTimePicker 
                        dateValue={moment(formik.values.startDate, 'DD/MM/YYYY').unix()}
                        timeValue={formik.values.startTime}
                        onDateChange={(newValue): void => {
                            formik.setFieldValue('startDate', newValue);

                            const currentEndDate = moment(formik.values.endDate, 'DD/MM/YYYY');
                            const newStartDate = moment(newValue, 'DD/MM/YYYY');

                            if (currentEndDate.isSameOrBefore(newStartDate)) {
                                formik.setFieldValue('endDate', newStartDate.add(1, 'days').format('DD/MM/YYYY'));
                            }
                        }}
                        onTimeChange={(newValue): void => { 
                            formik.setFieldValue('startTime', newValue);
                        }}
                        dateFormat='ddd, MMM D, YYYY'
                        showYearDropdown
                    />
                    <p>-</p>
                    <DateTimePicker 
                        dateValue={moment(formik.values.endDate, 'DD/MM/YYYY').unix()}
                        timeValue={formik.values.endTime}
                        onDateChange={(newValue): void => {
                            formik.setFieldValue('endDate', newValue);
                        }}
                        onTimeChange={(newValue): void => { 
                            formik.setFieldValue('endTime', newValue);
                        }}
                        dateFormat='ddd, MMM D, YYYY'
                        minDate={moment(formik.values.startDate, 'DD/MM/YYYY').unix()}
                        maxDate={moment(formik.values.startDate, 'DD/MM/YYYY').add(30, 'days').unix()}
                        showYearDropdown
                    />
                </div>
                <Stack className="w-100" direction={'row'} justifyContent={'space-between'}>
                    {formik.errors.startTime && formik.touched.startTime && <p className="error-msg">{formik.errors.startTime}</p>}
                    {formik.errors.endTime && formik.touched.endTime && <p className="error-msg">{formik.errors.endTime}</p>}
                </Stack>
                <div className="event-timezone">
                    <TimezoneSelectComponent 
                        defaultValue={formik.values?.timezone?.name} 
                        value={formik.values?.timezone?.name} 
                        optionsArr={uniqueTimezoneArr as any[]} 
                        onChange={(event, newValue) => handleTimezoneChange(newValue)} 
                        placeholder='Select a timezone' 
                    />
                </div>
            </div>
        );
    };

    const renderEventLocation = (): React.JSX.Element => {

        const onLoad = (autocomplete: any) => {
            googleAutocompleteRef.current = autocomplete;
        };

        const onPlaceChanged = () => {
            if (googleAutocompleteRef.current) {
                const selectedPlace = googleAutocompleteRef.current?.getPlace();
                if(selectedPlace) {
                    const countryComponent = selectedPlace.address_components?.find((component: any) => component.types.includes('country'));
                    if (countryComponent) {
                        const countryName = countryComponent.long_name;
                        formik.setFieldValue('country', countryName);
                    }
                    const addressComponents = selectedPlace.address_components;
                    const geometry = selectedPlace.geometry;
                    let cityName = '';
                    let latitude = '';
                    let longitude = '';
                    let stateName = '';
                    let pincode = '';
                    if(addressComponents) {
                        addressComponents.forEach((component: any) => {
                            if (component.types.includes('administrative_area_level_1')) {
                                stateName = component.short_name;
                            }
                            if (component.types.includes('locality')) {
                                cityName = component.long_name;
                            }
                            if (component.types.includes('postal_code')) {
                                pincode = component.long_name;
                            }
                        });
                    }
                    if (geometry && geometry.location) {
                        latitude = geometry.location.lat() as any;
                        longitude = geometry.location.lng() as any;
                    }
                    formik.setFieldValue('state', stateName);
                    formik.setFieldValue('locationDetails', {
                        name: selectedPlace.name,
                        url: selectedPlace.url,
                        city: cityName,
                        latitude: String(latitude),
                        longitude: String(longitude),
                        pincode: pincode
                    });
                }
            }
        };
        
        return (
            <div className="event-location">
                <FormLabelComponent label={'Event Location'} />
                {isLoaded && (
                    <GoogleAutocomplete className="google-autocomplete" onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        <FormControlComponent
                            value={formik.values?.locationDetails?.name || ''}
                            placeholder={'Search for a location...'}
                            type={'text'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                formik.setFieldValue('locationDetails', {
                                    ...formik.values.locationDetails,
                                    name: event.target.value
                                });
                            }}
                        />
                    </GoogleAutocomplete>
                )}
            </div>
        );
    };

    const renderEventApproval = (): React.JSX.Element => (
        <div className="event-registration-type">
            <div className="approval-icon-label-container">
                <FontAwesomeIcon icon={['fal', 'user-check']} className="approval-icon" />
                <FormLabelComponent label={'Requires Approval to attend'} noBottomMargin />
            </div>
            <FormControlLabel
                checked={formik.values.registrationType === EventRegistrationTypes.INVITE}
                control={<CustomSwitch
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                    {
                        handleValuesChange('registrationType', event.target.checked ? EventRegistrationTypes.INVITE : EventRegistrationTypes.OPEN);
                    }}
                />}
                label={''}
            />
        </div>
    );

    const renderEventWebinarDetails = (): React.JSX.Element => {
        const tabs = [
            {
                tabName: 'Create Webinar',
                selectedTab: formik.values.webinarType === 'create-webinar',
                navigation: (): void => handleWebinarTypeChange('create-webinar')
            },
            {
                tabName: 'Select Webinar',
                selectedTab: formik.values.webinarType === 'select-webinar',
                navigation: (): void => handleWebinarTypeChange('select-webinar')
            },
            {
                tabName: 'Webinar Link',
                selectedTab: formik.values.webinarType === 'webinar-link',
                navigation: (): void => handleWebinarTypeChange('webinar-link')
            }
        ];

        const handleWebinarTypeChange = (value: 'create-webinar' | 'select-webinar' | 'webinar-link') => {
            handleValuesChange('webinarType', value);
            handleResetFields(['title', 'startDate', 'endDate', 'startTime', 'endTime', 'timezone', 'locationDetails', 'eventIntegrationType', 'mapEvent', 'registrationType', 'eventFormFieldsDisabled', 'webinarLink']);
            if(value === 'select-webinar' && _.isEmpty(formik.values.mapEvent)) {
                handleValuesChange('eventFormFieldsDisabled', true);
            }
        };

        const webinarPlatformOptionLabels = eventPlatFormOptions && eventPlatFormOptions?.length > 0 ? eventPlatFormOptions?.reduce((obj, platform) => ({
            ...obj,
            [String(platform?.id)]: platform.name,
        }), {}) : [];

        const handleWebinarChange = (value: string | null) => {
            const selectedEvent = eventsFromPlatform?.find((event) => String(event.name) === String(value));
            handleValuesChange('mapEvent', selectedEvent, false);
            if(fetchEventData) {
                fetchEventData(formik.values.eventIntegrationType, selectedEvent?.id);
            }
        };

        const isVirtualEventIntegrationsAvailable = integrations && integrations?.some((integration) => integration.type === IntegrationTypes.ZOOM);

        return (
            <div className="event-webinar-details">
                {isVirtualEventIntegrationsAvailable && <ButtonGroup tabs={tabs} />}
                <div className="webinar-related-fields">
                    {(formik.values.webinarType === 'create-webinar' || formik.values.webinarType === 'select-webinar') ? (
                        <>
                            <div className="webinar-dropdowns">
                                <SelectComponent 
                                    options={eventPlatFormOptions as any}
                                    optionLabels={webinarPlatformOptionLabels}
                                    value={formik.values.eventIntegrationType || ''}
                                    onChange={(event) => {
                                        handleValuesChange('eventIntegrationType', Number(event.target.value), false);
                                        if(fetchEventFromPlatform && formik.values.webinarType === 'select-webinar') {
                                            fetchEventFromPlatform(Number(event.target.value) as EventIntegrationType);
                                        }
                                    }}
                                    defaultPlaceholder="Select a platform"
                                />
                                {formik.errors?.eventIntegrationType && formik.touched?.eventIntegrationType && <p className="error-msg">{formik.errors?.eventIntegrationType}</p>}
                            </div>
                            {formik.values.webinarType === 'select-webinar' && (
                                <div className="webinar-dropdowns">
                                    <AutocompleteComponent 
                                        value={formik.values.mapEvent?.name || ''}
                                        optionsArr={eventsFromPlatform || [] as any}
                                        onChange={(event, newValue) => handleWebinarChange(newValue as string | null)}
                                        placeholder="Select a webinar"
                                        keyToShow="name"
                                    />
                                    {formik.errors?.mapEvent && formik.touched?.mapEvent && <p className="error-msg">{formik.errors?.mapEvent}</p>}
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="w-100">
                            {!isVirtualEventIntegrationsAvailable && ( 
                                <FormLabelComponent label={'Event Link'} />
                            )}
                            <FormControlComponent 
                                type="text"
                                value={formik.values.webinarLink || ''}
                                placeholder={'Enter Link'}
                                onChange={(event) => handleValuesChange('webinarLink', event.target.value)}
                                // onKeyDown={(event) => { 
                                //     if(event.key === 'Enter' && isVirtualEventIntegrationsAvailable) {
                                //         fetchEventData(formik.values?.eventIntegrationType || undefined, undefined, formik.values?.webinarLink);
                                //     }
                                // }}
                            />
                            {formik.errors?.webinarLink && formik.touched?.webinarLink && <p className="error-msg">{formik.errors?.webinarLink}</p>}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="create-event-popup">
            {renderHeader()}
            {formik.values.currentView === 'eventType' ? renderEventTypes() : (
                <>
                    {renderEventTypeHeader()}
                    {renderEventDetails()}
                </>
            )}
        </div>
    );
};

export default CreateEventPopup;
