import React from 'react';
import { Tab, Tabs } from '@mui/material';
import './styles.scss';
import MuiChip from './FormComponents/MuiChip';

const TabPanel = (props: any): React.JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      display={'flex'}
      flexDirection={'column'}
      flex={'1'}
      {...other}
    >
      {value === index && <div className="tab-content-box">{children}</div>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

interface TabItem {
  title: string;
  data: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  count?: number;
  validate?: () => boolean; // Optional validation function for the tab
}

interface ValidatableTabsProps {
  value: number;
  tabData: TabItem[];
  onTabChange: (newTab: number) => void;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
}

const ValidatableTabs: React.FC<ValidatableTabsProps> = ({
  value,
  tabData,
  onTabChange,
  variant
}) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const currentTab = tabData[value];
    if (currentTab?.validate && !currentTab.validate()) {
      return; 
    }
    onTabChange(newValue);
  };

  return (
    <div id="validateableTabs" className="h-100 w-100">
      <div className="tab-header-container">
        <Tabs
          TabIndicatorProps={{
            style: {
              borderBottom: '2px solid #1570EF',
            },
          }}
          value={value}
          className="navigation-tabs"
          onChange={handleChange}
          indicatorColor="secondary"
          variant={variant || 'fullWidth'}
          textColor="inherit"
          aria-label="full width tabs example"
        >
          {tabData.map((item, key) => (
            <Tab
              className="navigation-tab-button"
              key={key}
              disabled={item?.disabled}
              disableRipple
              label={item.title}
              icon={
                item?.count ?
                item?.selected ?
                <MuiChip rounded chipColor='blue' label={item?.count} /> :
                <MuiChip chipColor='grey' label={item?.count} />
                :
                undefined
              }
              iconPosition='end'
              {...a11yProps(key)}
            />
          ))}
        </Tabs>
      </div>
      <div className="tabContentContainer">
        {tabData.map((item, key) => (
          <TabPanel value={value} index={key} key={key}>
            {item.data}
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default ValidatableTabs;