export enum EventProspectStatus 
    {
    ACTIVE = 1,
    INACTIVE = 2,
    DELETED = 3
}

export enum EventProspectSource
{
    LINKEDIN = 1,
    TWITTER = 2
}

export enum EventProspectType
{
    ATTENDING = 1,
    SPEAKING = 2,
    SPONSORING = 3,
    ATTENDED = 4,
    PROMOTING = 5,
    SELLING = 6,
    OTHER = 7
}