import React, { useState } from "react";
import ValidatableTabs from "../../../common/ValidateableTabs";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import * as Yup from 'yup';
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import EmailMarketingSettings from "./EmailMarketingSettings";
import { EmailMarketingFormData, EventMarketing, EventMarketingFilterData } from "../../../pages/Events/interfaces";
import EmailMarketingSchedule from "./EmailMarketingSchedule";
import moment from "moment";
import { EventMarketingNotificationType, EventMarketingTriggerType, EventMarketingType } from "../../../pages/Events/enum";
import { EventRegistrantAttendedStatus, EventRegistrantStatus, EventRegistrantStatusFilter } from "../../../pages/Events/enum/event-registrant.enum";
import { createMarketing, updateMarketing } from "../../../scripts/apis/eventMarketing";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { combineDateTime } from "../../../scripts/helpers";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import PushNotificationDesign from "./PushNotificationDesign";
import { EventRegistrant } from "../../../pages/Events/interfaces/event-registrant_interface";
import { CloseIconComponent } from "../../../common/FormComponents/ReusableFormComponents";
import EmailMarketingDesign from "./EmailMarketingDesign";

import './styles.scss';

interface EventMarketingStepsProps { 
  existingMarketingData?: EventMarketing;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  notificationType?: EventMarketingNotificationType;
}

const EventMarketingSteps: React.FC<EventMarketingStepsProps> = (props): React.JSX.Element => {
  const { existingMarketingData, setRefresh } = props;
  const notificationType = props?.notificationType || existingMarketingData?.notificationType;

  const { eventId } = useParams();

  const csrfTokenData = useSelector((state: any) => {
    return state['csrfTokenValue'].value.csrfToken;
  });

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [showSubmissionSpinner, setShowSubmissionSpinner] = useState<boolean>(false);

  const generateInitialFormValues = (): EmailMarketingFormData => 
  {
    if(notificationType === EventMarketingNotificationType.EMAIL) {
      return {
        name: existingMarketingData?.name || '',
        marketingJson: existingMarketingData?.marketingJson || '',
        emailContent: existingMarketingData?.marketingMail?.emailContent || '',
        replyTo: existingMarketingData?.replyTo || '',
        subject: existingMarketingData?.marketingMail?.subject || '',
        triggerType: existingMarketingData?.triggerType || EventMarketingTriggerType.IMMEDIATE,
        triggerDate: existingMarketingData?.triggerTime && String(existingMarketingData?.triggerTime) !== '0' ? existingMarketingData?.triggerTime : moment().unix(),
        triggerTime: existingMarketingData?.triggerTime && String(existingMarketingData?.triggerTime) !== '0' ? moment.unix(Number(existingMarketingData?.triggerTime))?.format('HH:mm A') : moment().add(2, 'minutes').format('HH:mm A'),
        ignoredRegistrants: [],
        filter: [
          {
            ticketNames: [],
            ticketIds: [],
            statuses: [],
          }
        ]
      };
    }
    else {
      return {
        name: existingMarketingData?.name || '',
        title: existingMarketingData?.marketingPushNotification?.title || '',
        body: existingMarketingData?.marketingPushNotification?.body || '',
        triggerType: existingMarketingData?.triggerType || EventMarketingTriggerType.IMMEDIATE,
        triggerDate: existingMarketingData?.triggerTime && String(existingMarketingData?.triggerTime) !== '0' ? existingMarketingData?.triggerTime : moment().unix(),
        triggerTime: existingMarketingData?.triggerTime && String(existingMarketingData?.triggerTime) !== '0' ? moment.unix(Number(existingMarketingData?.triggerTime))?.format('HH:mm A') : moment().add(2, 'minutes').format('HH:mm A'),
        ignoredRegistrants: [],
        filter: [
          {
            ticketNames: [],
            ticketIds: [],
            statuses: [],
          }
        ]
      }
    }
  };

  const validateFields = async (fields: string[], validateField: any, setTouched: any, errors: any, touched: any): Promise<boolean> => {
    let isValid = true;
    let touchedFields = { ...touched };
    for (const field of fields) {
      try {
        await validateField(field);
        touchedFields = { ...touchedFields, [field]: true };
      } catch (error) {
        isValid = false;
      }
    }
    setTouched(touchedFields);
    return isValid && !fields.some(field => errors[field]);
  };

  const fieldsToValidate = (): string[] => {
    if(notificationType === EventMarketingNotificationType.EMAIL) {
      switch(selectedTab) {
        case 0:
          return [];
        case 1:
          return ['name', 'replyTo', 'subject'];
        default:
          return [];
      }
    }
    else {
      switch(selectedTab) {
        case 0:
          return ['name', 'title', 'body'];
        default:
          return [];
      }
    }
  };

  const handleTabChange = async (newTab: number, validateField: (field: string) => Promise<void> | Promise<string | undefined>, setTouched: (touched: FormikTouched<EmailMarketingFormData>) => void, errors: FormikErrors<EmailMarketingFormData>, touched: FormikTouched<EmailMarketingFormData>) => {
    const isValid = await validateFields(fieldsToValidate(), validateField, setTouched, errors, touched);
    if (isValid) {
      setSelectedTab(newTab);
    }
  };

  const handleNext = async (values: EmailMarketingFormData, setTouched: (touched: FormikTouched<EmailMarketingFormData>) => void, validateField: (field: string) => Promise<void> | Promise<string | undefined>, errors: FormikErrors<EmailMarketingFormData>, touched: FormikTouched<EmailMarketingFormData>) => {
    const isValid = await validateFields(fieldsToValidate(), validateField, setTouched, errors, touched);
    if (isValid) {
      setSelectedTab(selectedTab + 1);
    }
  };

  const handleBack = () => {
    if (selectedTab > 0) {
      setSelectedTab(selectedTab - 1);
    }
  };

  const handleFinish = (values: EmailMarketingFormData) => {
    if(values && values?.filter && values?.filter?.some((filter) => filter?.statuses?.length === 0 && filter?.ticketIds?.length === 0)) {
      toast.error('Please select at least one Status or Ticket');
      return;
    }
    handleSubmit(values);
  };

  const preparePayloadForMarketing = (values: EmailMarketingFormData) => { 
    const filters: EventMarketingFilterData[] = [];
    
    for (const filter of values?.filter) {
      let statuses: EventRegistrantStatus[] = [];
      let attendedStatus: EventRegistrantAttendedStatus | undefined;

      if (filter?.statuses && filter?.statuses?.length > 0) {
        const statusMapping = {
          'In Review': EventRegistrantStatusFilter.INVITE,
          'Approved': EventRegistrantStatusFilter.APPROVED,
          'Rejected': EventRegistrantStatusFilter.REJECTED,
          'Waitlist': EventRegistrantStatusFilter.WAITLIST,
          'Deleted': EventRegistrantStatusFilter.DELETED,
          'Confirmed': EventRegistrantStatusFilter.CONFIRMED,
          'Invited': EventRegistrantStatusFilter.INVITED,
        };

        filter?.statuses.forEach((status) => {
          if (status === 'Attended' as any || status === 'Not Attended' as any) {
            if (status === 'Attended' as any) {
              attendedStatus = EventRegistrantAttendedStatus.YES;
            }
            if (status === 'Not Attended' as any) {
              attendedStatus = EventRegistrantAttendedStatus.NO;
            }
          } else {
            const mappedStatus = statusMapping[status as unknown as keyof typeof statusMapping];
            if (mappedStatus !== undefined) {
              statuses.push(mappedStatus as any);
            }
          }
        });
      }

      filters?.push({
        tickets: filter?.ticketIds as string[],
        statuses,
        attendeeStatus: attendedStatus,
      });
    }

    const triggerTime = combineDateTime(moment.unix(Number(values?.triggerDate)).format('DD/MM/YYYY'), String(values?.triggerTime));

    if(notificationType === EventMarketingNotificationType.PUSH_NOTIFICATION) 
    {
      return {
        name: values?.name,
        type: EventMarketingType.MARKETING,
        notificationType: EventMarketingNotificationType.PUSH_NOTIFICATION,
        triggerType: values?.triggerType,
        ...(values?.triggerType === EventMarketingTriggerType.SCHEDULED && { triggerTime }),
        filter: filters as any,
        ignoredRegistrants: values?.ignoredRegistrants?.map((registrant) => (registrant as EventRegistrant)?.id),
        marketingPushNotification: {
          title: values?.title,
          body: values?.body,
        }
      }
    }
    else if (notificationType === EventMarketingNotificationType.EMAIL) 
    {
      return {
        name: values?.name,
        type: EventMarketingType.MARKETING,
        notificationType: EventMarketingNotificationType.EMAIL,
        replyTo: values?.replyTo,
        triggerType: values?.triggerType,
        marketingJson: values?.marketingJson,
        marketingMail: {
          subject: values?.subject,
          emailContent: values?.emailContent,
        },
        ignoredRegistrants: values?.ignoredRegistrants?.map((registrant) => (registrant as EventRegistrant)?.id),
        filter: filters,
        ...(values?.triggerType === EventMarketingTriggerType.SCHEDULED && { triggerTime }),
      };
    }

  };

  const handleDrawerClose = (): void => {
    eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
      open: false,
    });
  };

  const handleSubmit = (values: EmailMarketingFormData): void => { 
    setShowSubmissionSpinner(true);
    if (existingMarketingData) {
      updateEmailMarketing(values as EmailMarketingFormData);
    } else {
      createEmailMarketing(values as EmailMarketingFormData);
    }
  };

  const createEmailMarketing = async (values: EmailMarketingFormData): Promise<void> => {
    const data = preparePayloadForMarketing(values);
    try {
      const marketingCreated = await createMarketing(data, eventId as string, csrfTokenData);
      if (marketingCreated) {
        handleDrawerClose();
        toast.success('Marketing created successfully');
        setRefresh(true);
      }
    } catch (error) {
      console.log(error);
      toast.error((error as Error)?.message || 'Error while creating marketing');
    } finally {
      setShowSubmissionSpinner(false);
    }
  };

  const updateEmailMarketing = async (values: EmailMarketingFormData): Promise<void> => {
    const data = preparePayloadForMarketing(values);
    try {
      const marketingUpdated = await updateMarketing(data, eventId as string, existingMarketingData?.id as string);
      if (marketingUpdated) {
        handleDrawerClose();
        toast.success('Marketing updated successfully');
        setRefresh(true);
      }
    } catch (error) {
      console.log(error);
      toast.error((error as Error)?.message || 'Error while updating marketing');
    } finally {
      setShowSubmissionSpinner(false);
    }
  };

  const emailMarketingTabData = (values: EmailMarketingFormData, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<EmailMarketingFormData>>, touched: any, errors: any) => {
    return [
      {
        title: 'Design',
        data: notificationType === EventMarketingNotificationType.EMAIL ? <EmailMarketingDesign formValues={values as EmailMarketingFormData} setFieldValue={setFieldValue} /> : <PushNotificationDesign setFieldValue={setFieldValue} formValues={values} touched={touched} errors={errors} /> ,
      },
      ...(notificationType === EventMarketingNotificationType.EMAIL ? [{
        title: 'Settings',
        data: <EmailMarketingSettings formValues={values as EmailMarketingFormData} setFieldValue={setFieldValue} touched={touched} errors={errors} />,
      }] : []),
      {
        title: 'Send or Schedule',
        data: <EmailMarketingSchedule formValues={values} setFieldValue={setFieldValue} existingMarketingData={existingMarketingData} />,
      },
    ]
  };

  const generateValidationSchema = () =>
  {
    if(notificationType === EventMarketingNotificationType.EMAIL) {
      return Yup.object().shape({
        name: Yup.string().required('Required'),
        replyTo: Yup.string().email('Invalid email').required('Required'),
        subject: Yup.string().required('Required'),
      })
    }
    else
    {
      return Yup.object().shape({
        name: Yup.string().required('Required'),
        title: Yup.string().required('Required'),
        body: Yup.string().required('Required'),
      })
    }
  };

  return (
    <div id="emailMarketingSteps" className="w-100">
      <CloseIconComponent onClick={handleDrawerClose} />
      <Formik
        validationSchema={generateValidationSchema()}
        initialValues={generateInitialFormValues()}
        validateOnMount
        onSubmit={async (values) => {
          if(values && values?.filter && values?.filter?.some((filter) => filter?.statuses?.length === 0 && filter?.ticketIds?.length === 0)) {
            toast.error('Please select at least one Status or Ticket');
            return;
          }
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue, validateField, errors, touched, setTouched }): React.ReactElement => {
          return (
            <Form className="h-100">
              <ValidatableTabs
                value={selectedTab}
                tabData={emailMarketingTabData(values, setFieldValue, touched, errors)}
                onTabChange={(value) => handleTabChange(value, validateField, setTouched, errors, touched)}
              />
              <div className="button-container">
                {selectedTab === 0 && <CustomButton
                  btnType="secondary"
                  onClick={handleDrawerClose}
                  name="Cancel"
                />}
                {selectedTab > 0 && (
                  <CustomButton
                    btnType="secondary"
                    onClick={handleBack}
                    name="Back"
                  />
                )}
                {selectedTab < emailMarketingTabData(values, setFieldValue, touched, errors).length - 1 ? (
                  <CustomButton
                    btnType="primary"
                    onClick={async () => await handleNext(values, setTouched as any, validateField, errors, touched)}
                    name="Next"
                  />
                ) : (
                  <CustomButton
                    btnType="primary"
                    onClick={() => handleFinish(values)}
                    name="Finish"
                    loading={showSubmissionSpinner}
                    disabled={showSubmissionSpinner}
                  />
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
};

export default EventMarketingSteps;