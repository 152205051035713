import * as Yup from 'yup';
import { FormikProps, useFormik } from "formik";
import { createContext, useEffect, useRef, useState } from "react";
import { EventIntegrationType, EventLocationType, EventRegistrationTypes, EventStatus, EventTypes, ZoomWebinarAction } from "../../pages/Events/enum";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import { getFormattedCurrentTimezone, uniqueTimezoneArr } from "../../components/Events/timezoneGenerateFunction";
import { useSelector } from "react-redux";
import { Integrations, IntegrationsState } from "../../pages/Settings/interface/integration_interface";
import { IntegrationTypes } from "../../pages/Settings/enum/integrations.enum";
import { MapEvent } from "../../pages/Events/interfaces/event-registration-tools_interface";
import { createEvent, getMapEventData , getMapEvents, initiateProspectFetch, searchWebsite } from "../../scripts/apis/events";
import { Event, EventCategory } from "../../pages/Events/interfaces";
import toast from "react-hot-toast";
import _ from "lodash";
import { combineDateTime, generateRandomString, transformTitleToLink } from '../../scripts/helpers';
import { getAllEventCategories } from '../../scripts/apis/eventCategory';
import { useNavigate } from 'react-router-dom';
import { IBillingInfo } from '../../redux/billing/billing-redux_interface';
import { EventHQEventLimit, ProductType } from '../../enums/billing.enum';


interface LocationDetails {
    name: string;
    url: string;
    city: string;
    latitude: string;
    longitude: string;
    pincode: string;
}
interface CreateEventFormValues {
    currentView: 'eventType' | 'eventInfo';
    showEventFormFields: boolean;
    title: string;
    type: EventTypes;
    locationType: EventLocationType;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    timezone: TimezoneOption;
    country?: string;
    state?: string;
    locationDetails?: LocationDetails;
    registrationType?: EventRegistrationTypes;
    webinarType?: 'create-webinar' | 'select-webinar' | 'webinar-link';
    eventIntegrationType?: EventIntegrationType;
    mapEvent?: MapEvent;
    webinarLink?: string;
    eventFormFieldsDisabled?: boolean;
    isEventDataFetching?: boolean;
    eventLink?: string;
    eventCategoryId?: string;
    keyword?: string;
    isFormSubmitting?: boolean;
}

interface TimezoneOption {
    name: string;
    value: string;
}

interface EventTypeOption {
    name: string;
    description: string;
    value: EventTypes;
    icon: IconName;
}

interface EventLocationTypeOption {
    name: string;
    value: EventLocationType;
    description?: string;
    disabled: boolean;
}

interface EventPlatFormOption { 
    id: IntegrationTypes;
    name: string;
}

interface CreateEventContextType {
    formik: FormikProps<CreateEventFormValues>;
    handleValuesChange: (key: keyof CreateEventFormValues, value: string | number | any, markAsTouched?: boolean, customHandler?: () => void) => void;
    handleResetFields: (keys: (keyof CreateEventFormValues)[]) => void;
    eventTypeOptions?: EventTypeOption[];
    eventLocationTypeOptions?: EventLocationTypeOption[];
    eventPlatFormOptions?: EventPlatFormOption[];
    integrations?: Integrations[];
    fetchEventFromPlatform?: (platform: EventIntegrationType) => void;
    eventsFromPlatform?: MapEvent[];
    fetchEventData: (type?: EventIntegrationType, id?: string, webinarLink?: string) => Promise<Event | undefined>;
    fetchEventDataFromWebsite: (websiteLink?: string) => Promise<void>;
    googleAutocompleteRef: React.MutableRefObject<google.maps.places.Autocomplete | null>;
    eventCategories?: EventCategory[];
    handleCreatePopupClose: () => void;
    prevLink?: string | null;
    setPrevLink: React.Dispatch<React.SetStateAction<string | null>>;
    // handleEventCategory: (eventCategory: EventCategory, append?: boolean, remove?: boolean) => void;
}

export const CreateEventContext = createContext<CreateEventContextType>({
    formik: {} as FormikProps<CreateEventFormValues>,
    handleValuesChange: () => {},
    handleResetFields: () => {},
    eventTypeOptions: [],
    eventLocationTypeOptions: [],
    eventPlatFormOptions: [],
    fetchEventFromPlatform: () => {},
    eventsFromPlatform: [],
    fetchEventData: async () => undefined,
    fetchEventDataFromWebsite: async () => {},
    googleAutocompleteRef: { current: null },
    eventCategories: [],
    handleCreatePopupClose: () => {},
    integrations: [],
    prevLink: null,
    setPrevLink: () => {},
    // handleEventCategory: () => {},
});

const CreateEventProvider: React.FC<{ children: React.ReactNode; handleCreatePopupClose: () => void }> = ({ children, handleCreatePopupClose }):React.JSX.Element => 
{

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const [eventsFromPlatform, setEventsFromPlatform] = useState<MapEvent[]>([]);
    const [eventCategories, setEventCategories] = useState<EventCategory[]>([]);
    const [prevLink, setPrevLink] = useState<string | null>(null);

    const googleAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    
    const currentTimezone =  getFormattedCurrentTimezone();

    const csrfTokenData = useSelector((state: any) => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const integrations = useSelector((state: IntegrationsState): Integrations[] =>
    {
        return state.integrations.value;
    });

    const navigate = useNavigate();

    const isPaidOrg = orgBillingInfo?.productType === ProductType.EVENTHQ || orgBillingInfo?.eventhq === EventHQEventLimit.THREE;

    const isVirtualEventAvailable = integrations?.some((integration) => integration?.type === IntegrationTypes.ZOOM);

    const validationSchema = Yup.object().shape({ 
        title: Yup.string().required('Title is required'),
        startTime: Yup.string().required('Start Time is required'),
        endTime: Yup.string().required('End Time is required'),
        timezone: Yup.object().shape({
            name: Yup.string().required('Timezone is required'),
            value: Yup.string().required('Timezone is required')
        }),
        eventIntegrationType: Yup.string().test('eventIntegrationType', 'Platform is required', function(value) {
            const { webinarType, locationType } = this.parent;
            if (locationType === EventLocationType.VIRTUAL && webinarType !== 'webinar-link') {
                return value && value.trim() !== '' ? true : this.createError({ message: 'Platform is required' });
            }
            return true;
        }),
        mapEvent: Yup.object().shape({}).test(('mapEvent'), 'Select a webinar', (value) => {
            if(formik.values.webinarType === 'select-webinar') {
                return !_.isEmpty(value);
            }
            return true;
        }),
        eventLink: Yup.string().url('Enter valid URL').test(('eventLink'), 'Event Link is required', (value) => {
            if(formik.values.type !== EventTypes.HOST) {
                return value !== '';
            }
            return true;
        }),
        webinarLink: Yup.string().url('Enter valid URL'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema,
        initialValues: {
            currentView: 'eventType',
            showEventFormFields: false,
            title: '',
            type: '' as any,
            locationType: EventLocationType.ONSITE,
            eventCategoryId: eventCategories[0]?.id,
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().add(1, 'days').format('DD/MM/YYYY'),
            startTime: '08:00',
            endTime: '17:00',
            timezone: {
            name: currentTimezone?.name,
            value: currentTimezone?.value
            },
            country: '',
            state: '',
            locationDetails: {
            name: '',
            url: '',
            city: '',
            latitude: '',
            longitude: '',
            pincode: ''
            },
            registrationType: EventRegistrationTypes.OPEN,
            webinarType: isVirtualEventAvailable ? 'create-webinar' : 'webinar-link',
            webinarLink: '',
            eventIntegrationType: '' as any,
            mapEvent: {} as MapEvent,
            eventFormFieldsDisabled: false,
            isEventDataFetching: false,
            eventLink: '',
            keyword: '',
            isFormSubmitting: false,
        } as CreateEventFormValues,
        onSubmit: () => {
            handleFormSubmit();
        }
    });
    
    const eventTypeOptions: EventTypeOption[] = 
    [
        {
            name: 'Attending',
            description: 'Join the event.',
            value: EventTypes.ATTENDEE,
            icon: 'user'
        },
        {
            name: 'Speaking',
            description: 'Give a talk.',
            value: EventTypes.SPEAKER,
            icon: 'microphone'
        },
        {
            name: 'Sponsored',
            description: 'Support the event.',
            value: EventTypes.SPONSORED,
            icon: 'dollar-circle'
        },
        {
            name: 'Track',
            description: 'Find your prospects.',
            value: EventTypes.TRACK,
            icon: 'sparkles'
        }
    ]
    if(isPaidOrg) {
        eventTypeOptions.unshift({
            name: 'Host',
            description: 'Create your event.',
            value: EventTypes.HOST,
            icon: 'user-plus'
        });
    }

    const eventLocationTypeOptions =
    [
        {
            name: 'In Person',
            value: EventLocationType.ONSITE,
            disabled: false
        },
        {
            name: 'Virtual',
            value: EventLocationType.VIRTUAL,
            disabled: false
            // disabled: !isVirtualEventAvailable,
        },
        {
            name: 'Hybrid',
            value: EventLocationType.HYBRID,
            description: 'Coming Soon',
            disabled: true
        }
    ]

    const eventPlatFormOptions = [];
    if(integrations.length > 0) {
        if(integrations?.some((integration) => integration?.type === IntegrationTypes.ZOOM)) {
            eventPlatFormOptions.push({
                id: IntegrationTypes.ZOOM,
                name: 'Zoom',
            });
        }
    }

    const handleValuesChange = (key: keyof CreateEventFormValues, value: string | number | any, markAsTouched: boolean = true, customHandler?: () => void) => { 
        if (customHandler) {
            customHandler();
        } else {
            formik.setFieldValue(key, value);
            if (markAsTouched) {
                formik.setFieldTouched(key, true, true);
            }
        }
    };

    const handleResetFields = (keys: (keyof CreateEventFormValues)[]) => {
        keys.forEach(key => {
            formik.setFieldValue(key, formik.initialValues[key]);
        });
    };

    // const handleEventCategory = async (eventCategory: EventCategory, append?: boolean, remove?: boolean) => {
    // };

    const handleNavigateAfterSubmission = (id: string | number) => {
        if(isPaidOrg)
        {
            navigate(`/events/${id}/overview`);
        }
        else
        {
            navigate(`/events/${id}/people/prospects`);
        }
    };

    const preparePayload = (): Partial<Event> =>
    {
        const startDateTimestamp = combineDateTime(formik.values.startDate, formik.values.startTime, formik.values.timezone.value);
        const endDateTimestamp = combineDateTime(formik.values.endDate, formik.values.endTime, formik.values.timezone.value);

        const currentTimestampinUTC = moment().utc().unix();
        let eventLink = '';
        if(formik.values.type === EventTypes.HOST) {
            eventLink = generateRandomString();
        }
        else {
            eventLink = `${transformTitleToLink(formik.values.title)}-${currentTimestampinUTC}`;
        }

        const payload: Partial<Event> = {
            title: formik.values.title,
            type: formik.values.type,
            locationType: formik.values.locationType,
            eventStartDateTime: Number(startDateTimestamp),
            eventEndDateTime: Number(endDateTimestamp),
            closingDateTime: Number(endDateTimestamp),
            timezone: formik?.values.timezone?.value,
            country: formik.values.country,
            state: formik.values.state,
            locationDetails: formik.values.locationDetails,
            eventCategoryId: formik.values.eventCategoryId,
            status: EventStatus.DRAFT,
            link: eventLink,
        };
        if(formik.values.type !== EventTypes.ATTENDEE) {
            payload.registrationType = formik.values.registrationType;
        }
        if(formik.values.locationType === EventLocationType.VIRTUAL && formik.values.webinarType === 'create-webinar') {
            // payload.integrationType = formik.values.eventIntegrationType;
            payload.zoomWebinarAction = ZoomWebinarAction.CREATE;
        }
        if(formik.values.locationType === EventLocationType.VIRTUAL && formik.values.webinarType === 'select-webinar' && formik.values?.mapEvent?.id) {
            payload.integrationType = formik.values.eventIntegrationType;
            payload.referenceEventId = formik.values?.mapEvent?.id;
        }
        if(formik.values.locationType === EventLocationType.VIRTUAL && formik.values.webinarType === 'webinar-link' && formik.values.webinarLink) { 
            payload.virtualLink = formik.values.webinarLink;
        }
        if(formik.values.type !== EventTypes.HOST && formik.values?.keyword !== '') { 
            payload.keyword = formik.values?.keyword;
        }
        return payload;
    };

    const handleFormSubmit = async (): Promise<void> =>
    {
        handleValuesChange('isFormSubmitting', true);

        let payload = preparePayload();

        if (Number(payload?.eventEndDateTime) < Number(payload?.eventStartDateTime)) 
        {
            toast.error('End Date Time should be greater than Start Date Time');
            handleValuesChange('isFormSubmitting', false);
            return;
        }

        if (moment(payload?.eventEndDateTime).diff(moment(payload?.eventStartDateTime), 'days') > 30) {
            toast.error('Event duration cannot exceed 30 days');
            handleValuesChange('isFormSubmitting', false);
            return;
        }

        try {
            const response = await createEvent(payload, csrfTokenData);
            if (response) {
                if (Number(response.type) !== EventTypes.HOST) {
                    try {
                        await initiateProspectFetch(response.id);
                        toast.success('Event created successfully');
                        handleCreatePopupClose();
                        handleNavigateAfterSubmission(response.id);
                    } catch (error) {
                        console.error(error);
                        toast.error((error as Error)?.message || 'Something went wrong');
                    }
                } else {
                    toast.success('Event created successfully');
                    handleCreatePopupClose();
                    handleNavigateAfterSubmission(response.id);
                }
            }
        } catch (error) {
            console.error(error);
            toast.error((error as Error)?.message || 'Failed to create event');
        } finally {
            handleValuesChange('isFormSubmitting', false);
        }

    };

    const fetchEventFromPlatform = async (platform: EventIntegrationType): Promise<void> => 
    {
        try 
        {
            const events = await getMapEvents(platform);
            
            if(events) {
                setEventsFromPlatform(events?.mapEvents);
            }
        } 
        catch (error) 
        {
            
        }
    };

    const fetchEventData = async (type?: EventIntegrationType, id?: string, webinarLink?: string): Promise<Event | undefined> =>
    {
        handleValuesChange('isEventDataFetching', true);
        try 
        {
            const eventData = await getMapEventData(type, id, webinarLink);
            
            if(eventData)
            {

                const timezone = _.find(uniqueTimezoneArr as any, function (item: { name: string; value: string }): boolean
                {
                    return item.value === eventData?.timezone;
                });

                handleValuesChange('title', eventData?.title, false);
                handleValuesChange('timezone', timezone, false);
                handleValuesChange('startDate', moment.unix(Number(eventData?.eventStartDateTime)).format('DD/MM/YYYY'));
                handleValuesChange('endDate', moment.unix(Number(eventData?.eventEndDateTime)).format('DD/MM/YYYY'));
                handleValuesChange('startTime', moment.unix(Number(eventData?.eventStartDateTime)).format('hh:mm'), false);
                handleValuesChange('endTime', moment.unix(Number(eventData?.eventEndDateTime)).format('hh:mm'), false);
                handleValuesChange('registrationType', eventData?.registrationType);
            }
            
            return eventData || {};
        } 
        catch (error) 
        {
            toast.error((error as Error)?.message || 'Failed to fetch event data');
            console.log(error);
        }
        finally
        {
            handleValuesChange('isEventDataFetching', false);
            handleValuesChange('eventFormFieldsDisabled', false);
        }
    };

    const fetchEventDataFromWebsite = async (websiteLink?: string): Promise<void> =>
    {
        handleValuesChange('isEventDataFetching', true);
        handleValuesChange('showEventFormFields', true);
        handleValuesChange('eventFormFieldsDisabled', true);
        try 
        {
            const data = {
                url: websiteLink
            }
            const eventData = await searchWebsite(data, csrfTokenData);
            
            if(eventData)
            {
                handleValuesChange('showEventFormFields', true);

                if(!(_.isEmpty(eventData)))
                {
                    const timezone = _.find(uniqueTimezoneArr as any, function (item: { name: string; value: string }): boolean
                    {
                        return item?.value === eventData?.timezone;
                    });    
                    handleValuesChange('title', eventData?.title || '', false);
                    handleValuesChange('startDate', moment.unix(eventData?.eventStartDateTime).format('DD/MM/YYYY'), false);
                    handleValuesChange('endDate', moment.unix(eventData?.eventEndDateTime).format('DD/MM/YYYY'), false);
                    handleValuesChange('startTime', moment.unix(eventData?.eventStartDateTime).format('HH:mm'), false);
                    handleValuesChange('endTime', moment.unix(eventData?.eventEndDateTime).format('HH:mm'), false);
                    handleValuesChange('timezone', timezone, false);
                    handleValuesChange('registrationType', eventData?.registrationType || EventRegistrationTypes.OPEN, false);
                    handleValuesChange('country', eventData?.country, false);
                    handleValuesChange('state', eventData?.state, false);
                    handleValuesChange('locationDetails', eventData?.locationDetails, false);
                    handleValuesChange('keyword', eventData?.keyword, false);

                    setTimeout(() => {
                        formik.handleSubmit();
                    }, 0)
                }
            }
        } 
        catch (error) 
        {
            toast.error((error as Error)?.message || 'Failed to fetch event data');
            console.log(error);
        }
        finally
        {
            handleValuesChange('isEventDataFetching', false);
            handleValuesChange('isFormSubmitting', false);
            handleValuesChange('eventFormFieldsDisabled', false);
        }
    };

    const fetchAllEventCategories = async (): Promise<void> =>
    {
        try 
        {
            const categories = await getAllEventCategories();
            if(categories)
            {
                setEventCategories(categories);
            }
        } 
        catch (error) 
        {
            console.log(error); 
        }
    };

    useEffect(() =>
    {
        fetchAllEventCategories();
    }, []);
    
    return (
        <CreateEventContext.Provider value={{
            formik,
            handleValuesChange,
            handleResetFields,
            eventTypeOptions,
            eventLocationTypeOptions,
            eventPlatFormOptions,
            eventsFromPlatform,
            fetchEventFromPlatform,
            fetchEventData,
            fetchEventDataFromWebsite,
            googleAutocompleteRef,
            eventCategories,
            handleCreatePopupClose,
            integrations,
            prevLink,
            setPrevLink
            // handleEventCategory
        }}>
            {children}
        </CreateEventContext.Provider>
    );
}

export default CreateEventProvider;