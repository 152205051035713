import './styles.scss';

const ExplorePage: React.FC = (): React.JSX.Element => {
    return (
        <div className="explore-page">
            Coming Soon..
        </div>
    );
};

export default ExplorePage;