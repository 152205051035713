import { useSelector } from "react-redux";
import { Integrations, IntegrationsState } from "../../../pages/Settings/interface/integration_interface";
import { IntegrationTypes } from "../../../pages/Settings/enum/integrations.enum";
import { CustomSwitch } from "../../../common/StyledComponents/Switch.styled";
import { useEffect, useState } from "react";
import { FormControlLabel } from "@mui/material";
import { EventStatus, SyncCrm } from "../../../pages/Events/enum";
import { updateEvent } from "../../../scripts/apis/events";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import CustomTooltip from "../../../common/Tooltip";
import { Event } from "../../../pages/Events/interfaces";

import './styles.scss';

interface IEventIntegrationSyncProps { 
    eventDetails: Event;
    setRefreshEventData: React.Dispatch<React.SetStateAction<boolean>>;
}

const IntegrationSync: React.FC<IEventIntegrationSyncProps> = ({ eventDetails, setRefreshEventData }): React.JSX.Element =>
{
    const { eventId } = useParams();

    const integrations = useSelector((state: IntegrationsState): Integrations[] =>
    {
        return state.integrations.value;
    });

    const isPublished = eventDetails?.status === EventStatus.PUBLISHED;

    const isHubspotIntegrated = integrations?.some((integration) => integration.type === IntegrationTypes.HUBSPOT);

    const [isSyncEnabled, setIsSyncEnabled] = useState<boolean>(false);

    const handleSyncCrm = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> =>
    {
        setIsSyncEnabled(!isSyncEnabled);
        try 
        {
            const data= {
                syncCrm: event.target.checked ? SyncCrm.ENABLE : SyncCrm.DISABLE
            }
            const updateSyncCrm = await updateEvent(eventId as string, data);
            if(updateSyncCrm)
            {
                toast.success('Sync status updated successfully');
                setRefreshEventData(true);
            }
        } 
        catch (error) 
        {
            toast.error('Failed to update Sync status');
        }
    }

    useEffect(() =>
    {
        if(eventDetails && eventDetails?.syncCrm)
        {
            setIsSyncEnabled(eventDetails?.syncCrm === SyncCrm.ENABLE);
        }
    }, [eventDetails?.syncCrm]);

    return (
        <>  
            {
                isHubspotIntegrated &&
                <div className="integration-sync-component">
                    <h3 className="title">{'Sync Registrants to Hubspot'}</h3>
                    <div className="switch-container">
                        <CustomTooltip title={isPublished ? "" : "Event is not published"}>
                            <div>
                                <FormControlLabel
                                    checked={isSyncEnabled}
                                    control={
                                        <CustomSwitch
                                            onChange={(event) => handleSyncCrm(event)}
                                            disabled={!isPublished}
                                        />
                                    }
                                    label={''}
                                />
                            </div>
                        </CustomTooltip>
                    </div>
                </div>
            }
        </>
    )
};

export default IntegrationSync;