import React, { useState } from 'react';
import { EventLeadCapture } from '../../../Events/interfaces/event-lead-capture_interface';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { renderInputComponent } from '../../../Settings/CustomField/CustomTypeComponent';
import _ from 'lodash';
import { EnrichmentComponent } from '../../../Audience/ViewAudienceInfo';

interface LeadsFormProps 
{
    handlePopupClose: () => void;
    leadData: EventLeadCapture | null;
}

const LeadsForm: React.FC<LeadsFormProps> = ({ handlePopupClose, leadData }) => 
{
    const [accordionExpansion, setAccordionExpansion] = useState<string | boolean>('formAccordian');
    const [customFormState, setCustomFormState] = useState<{ [key: string]: any }>({});

    const handleAccordionChange = (propertyId: string) => (event: React.SyntheticEvent, newExpanded: boolean): void => 
    {
        setAccordionExpansion(newExpanded ? propertyId : false);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => 
    {
        const { name, value } = event.target;
        setCustomFormState((prevState): any => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div id="leadForm">
            <div className="registrant-detailed-info-container">
                <div className="registrant-info-accordian-container">
                    <div className="form-fields-accordian-container">
                        <Accordion expanded={accordionExpansion === 'formAccordian'} id={'formAccordian'} className="form-accordian" onChange={handleAccordionChange('formAccordian')}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="formAccordian-header"
                                style={{
                                    borderRadius: accordionExpansion === 'formAccordian' ? '8px 8px 0 0' : '8px',
                                }}
                                expandIcon={<FontAwesomeIcon className="dropdown-icon" icon={['fal', accordionExpansion === 'formAccordian' ? 'chevron-up' : 'chevron-right']} />}
                            >
                                <div className="heading-container">
                                    <h3 className="accordian-title">{'Lead Form Details'}</h3>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className="form-accordian-details">
                                <div className="custom-field-container">
                                    {leadData?.leadData && Object.keys(leadData.leadData).map((key) => {
                                        const value = leadData.leadData ? leadData.leadData[key] : '';
                                        return renderInputComponent({ propertyKey: key, label: key }, 'text', handleInputChange, value, false);
                                    })}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="clearbit-accordian-container">
                            {leadData?.clearbitData && <div className="registrant-enrichment-container"><EnrichmentComponent fromRegistrant={false} accordionExpansion={accordionExpansion} handleAccoridon={handleAccordionChange} clearbitData={leadData?.clearbitData} /></div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeadsForm;