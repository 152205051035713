import React, { useEffect, useState } from 'react';
import { FormControlLabel, Stack } from '@mui/material';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { addTicket } from '../../../redux/events/eventRegistrations/Tickets';
import { createEventTicket, deleteTicket, getEventTicketActivityLogs, updateEventTicket } from '../../../scripts/apis/eventTickets';
import APP_CONSTANTS from '../../../scripts/constants';
import eventBus from '../../../scripts/event-bus';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CONTENT } from '../../../scripts/i18n';
import { AdditionalInfoComponent, FormControlComponent, FormLabelComponent, MultipleSelectComponent, SelectComponent, ToggleButtonGroupComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { IEventReduxValues, IEventsDispatch } from '../interfaces/create-events_interface';
import { ITickets, IticketReduxData } from '../interfaces/create-ticket_interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import _ from 'lodash';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { Currency, EnableApprovalStatus, EnableInvoiceStatus, EnableTicketWaitlistStatus, EventRegistrationTypes, EventTicketPaymentIntegration, PrivateTicket, TicketType } from '../../../pages/Events/enum';
import InvoiceSettings from './InvoiceSettings';
import toast from 'react-hot-toast';
import DeletePopup from '../../../common/DeletePopup';
import { EventTicket } from '../../../pages/Events/interfaces';
import DateTimePicker from '../../../common/FormComponents/DateTimePicker';
import { combineDateTime, currencyOptionLabel } from '../../../scripts/helpers';
import { IIntegration, Integrations, IntegrationsState } from '../../../pages/Settings/interface/integration_interface';
import { IntegrationTypes } from '../../../pages/Settings/enum/integrations.enum';
import getSymbolCurrency from "currency-symbol-map";
import { getEmojiByCurrencyCode } from "country-currency-emoji-flags";
import CustomTooltip from '../../../common/Tooltip';
import { ActivityLogResponse } from '../../../interfaces/activity-log_interface';
import ActivityLogComponent from '../../../common/ActivityLog/ActivityLogComponent';
import { eventTicketsActivityLog } from '../../../scripts/activityLogGenerator/activitylog-generator';

import './styles.scss';
import PopupDialog from '../../../common/PopupDialog';

interface ICreateTicketsProps {
    eventId: string;
    isTableView: boolean;
    ticketData: EventTicket | undefined;
    presentTicketsName: string[];
    setRefresh: (refresh: boolean) => void;
    handlePopupClose: () => void;
    routeFromId: string;
}

const CreateTickets: React.FC<ICreateTicketsProps> = (props: ICreateTicketsProps):React.JSX.Element => 
{
    const { eventId, isTableView } = props;


    const ticketsInfo: EventTicket | undefined = props?.ticketData;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const ticketsData = useSelector((state: IticketReduxData): ITickets => 
    {
        return state.tickets.value;
    });

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const integrations = useSelector((state: IntegrationsState): Integrations[] =>
    {
        return state.integrations.value;
    });
    
    const csrfTokenData = useSelector((state): string => 
    { 
        return state['csrfTokenValue'].value.csrfToken;
    });

    const [spinner, setSpinner] = useState<boolean>(false);
    const [maxEndDate, setMaxEndDate] = useState<number | null>(null);
    const [stageIdData, setStageIdData] = useState<{ id: string; name: string }[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    // const [integrations, setIntegrations] = useState<IIntegration[]>([]);
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const [showSaleStartComponent, setShowSaleStartComponent] = useState<boolean>(false);
    const [showSaleEndComponent, setShowSaleEndComponent] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState(moment());
    const [headerText, setHeaderText] = useState<string>('Create Ticket');
    const [headerDescription, setHeaderDescription] = useState<string>('Create a new ticket for the event');
    const [showInvoiceSettingsPopup, setShowInvoiceSettingsPopup] = useState<boolean>(false);

    const ticketType = [
        {  
            name: 'Public', value: 2,  icon: <FontAwesomeIcon icon={['fal', 'globe']} />
        },
        {
            name: 'Private', value: 1, icon: <FontAwesomeIcon icon={['fal', 'lock-keyhole']} />
        }
    ];

    const ticketPricing = [
        {
            name: 'Free', value: 1, icon: <FontAwesomeIcon icon={['fal', 'circle-0']} />
        },
        {  
            name: 'Paid', value: 2, icon: <FontAwesomeIcon icon={['fal', 'circle-dollar']} />
        }
    ]

    const handlePopupClose = (): void =>
    {
        setShowInvoiceSettingsPopup(false);
    }

    const generateIntegrationOptions = () =>
    {
        const integrationOptions = [];

        if (integrations && integrations.length > 0 && integrations.some((integration: IIntegration) => integration?.type === IntegrationTypes.RAZORPAY))
        {
            integrationOptions.push({
                id: EventTicketPaymentIntegration.RAZORPAY, name: 'Razorpay'
            });
        }
        if (integrations && integrations.length > 0 && integrations.some((integration: IIntegration) => integration?.type === IntegrationTypes.STRIPE))
        {
            integrationOptions.push({
                id: EventTicketPaymentIntegration.STRIPE, name: 'Stripe'
            });
        }

        return integrationOptions;
    };

    const integrationOptionLabel = {
        [EventTicketPaymentIntegration.RAZORPAY]: 'Razorpay',
        [EventTicketPaymentIntegration.STRIPE]: 'Stripe'
    };

    const generateCurrencyOptions = () =>
    {
        const currencyOptions = 
        [
            {
                id: Currency.CANADIAN_DOLLAR, name: getSymbolCurrency(Currency.CANADIAN_DOLLAR) as string, startIcon: getEmojiByCurrencyCode(Currency.CANADIAN_DOLLAR)
            },
            {
                id: Currency.US_DOLLAR, name: getSymbolCurrency(Currency.US_DOLLAR) as string, startIcon: getEmojiByCurrencyCode(Currency.US_DOLLAR)
            },
            {
                id: Currency.INDIAN_RUPEE, name: getSymbolCurrency(Currency.INDIAN_RUPEE) as string, startIcon: getEmojiByCurrencyCode(Currency.INDIAN_RUPEE)
            },
            {
                id: Currency.EURO, name: getSymbolCurrency(Currency.EURO) as string, startIcon: getEmojiByCurrencyCode(Currency.EURO)
            },
            {
                id: Currency.BRITISH_POUND, name: getSymbolCurrency(Currency.BRITISH_POUND) as string, startIcon: getEmojiByCurrencyCode(Currency.BRITISH_POUND)
            }
        ];

        if (formik.values.integrationType === EventTicketPaymentIntegration.STRIPE)
        {
            return currencyOptions;
        }
        else if (formik.values.integrationType === EventTicketPaymentIntegration.RAZORPAY)
        {
            return currencyOptions.filter(option => option.id === Currency.INDIAN_RUPEE);
        }
    };

    const isPaymentIntegrationDone = integrations?.some((integration: IIntegration) => integration?.type === IntegrationTypes.RAZORPAY || integration?.type === IntegrationTypes.STRIPE);

    const handleDrawerClose = ():void => 
    {
        props?.handlePopupClose();
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.CLOSE_EVENT, {
            open: false
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        if (props?.routeFromId) 
        {
            navigate('/events/' + eventId + '/registrations/tickets');
        }
    };

    const handleStageSelect = (event: React.ChangeEvent<HTMLInputElement>): void => 
    {
        const value = event.target.value;
        formik.setFieldValue('stageIds', typeof (value) === 'string' ? value.split(',') : value);
    };

    const handleAddTax = (): void => 
    {
        formik.setFieldValue('tax', [...formik.values.tax, { name: '', percentage: '' }]);
    };

    const handleTaxInput = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => 
    {
        const { name, value } = event.target;
        const fieldName = `tax[${index}].${name}`;
        formik.setFieldValue(fieldName, value);
    }; 

    const handleTaxRemove = (event: React.MouseEvent<SVGSVGElement>, index: number): void =>
    {
        const taxData = formik.values.tax;
        taxData.splice(index, 1);
        formik.setFieldValue('tax', taxData);
    };

    const handleNestedSidebarOpen = (): void =>
    {
        setHeaderText('Activity');
        setHeaderDescription(ticketsInfo?.name || '');
        formik.setFieldValue('showActivityLog', true);
    };

    const IntegrationInfoComponent = ():React.JSX.Element =>
    {
        return (
            <AdditionalInfoComponent
                    title="Integrate your Payment Gateway"
                    description={"Link your payment gateway to manage paid tickets."}
                    onClick={() => 
                        {
                            handleDrawerClose();
                            navigate('/settings/integrations/payments')
                        }}
            />
        )
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required').test('isNameValid','Ticket name already exists.',(value):boolean => 
            {
                if(props.presentTicketsName.length > 0)
                {
                    if(ticketsInfo?.name === value)
                    {
                        return true;
                    }
                    else if(props.presentTicketsName.includes(value.replace(/\s/g, '').toLowerCase()))
                    {
                        return false;
                    }
                    else
                    {
                        return true;
                    }
                }
                else
                {
                    return true;
                }
            }),
        description: Yup.string()
            .notRequired(),
        totalTickets: Yup.number(),
        stageIds: Yup.array(),
        enableApproval: Yup.number(),
        enableWaitlistStatus: Yup.number(),
        privateTicket: Yup.number().required('Ticket Type is required'),
        type: Yup.number()
            .test('isTicketFree', 'Pricing Type is required', (value): boolean => 
            {
                if (formik.values.privateTicket === PrivateTicket.PUBLIC) 
                {
                    if (!value) 
                    {
                        return false;
                    }
                    else 
                    {
                        return true;
                    }
                }
                else 
                {
                    return true;
                }
            }
            ),
        ticketPrice: Yup.number()
            .test('isTicketPriceMin', 'Ticket Price should be greater than 0', (value): boolean => 
            {
                if (formik.values.privateTicket === PrivateTicket.PUBLIC && formik.values.type === TicketType.PAID) 
                {
                    if (value && value < 1) 
                    {
                        return false;
                    }
                }
                return true;
            })
            .test('isTicketPriceRequired', 'Enter the Ticket Price', (value): boolean => 
            {
                if (formik.values.privateTicket === PrivateTicket.PUBLIC && formik.values.type === TicketType.PAID)
                {
                    if (!value) 
                    {
                        return false;
                    }
                }
                return true;
            }),
        tax: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string()
                    .test('isTaxNameRequired', 'Tax Name is required', (value): boolean => { 
                        if (formik.values.enableInvoice === EnableInvoiceStatus.ENABLE) 
                        {
                            if (!value) 
                            {
                                return false;
                            }
                        }
                        return true;
                    }),
                percentage: Yup.number()
                    .test('isTaxPercentageRequired', 'Tax Percentage is required', (value): boolean => {
                        if (formik.values.enableInvoice === EnableInvoiceStatus.ENABLE) 
                        {
                            if (!value) 
                            {
                                return false;
                            }
                        }
                        return true;
                    })
                    .test('isTaxPercentageMin', 'Tax percentage should be greater than 0', (value): boolean => {
                        if (formik.values.enableInvoice === EnableInvoiceStatus.ENABLE) 
                        {
                            if (value && value < 0.1) 
                            {
                                return false;
                            }
                        }
                        return true;
                    })
                    .test('isTaxPercentageMax', 'Tax percentage should be less than 100', (value): boolean => {
                        if (formik.values.enableInvoice === EnableInvoiceStatus.ENABLE) 
                        {
                            if (value && value > 100) 
                            {
                                return false;
                            }
                        }
                        return true;
                    })
              })
            )
            .test('isTaxNeeded', 'Tax is required', (value) =>
            {
                if (formik.values.includeTax)
                {
                    if (value?.length === 0) 
                    {
                        return false;
                    }
                }
                return true;
            }),
            includeTax: Yup.boolean()
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: ticketsData.name ? ticketsData.name : '',
            description: ticketsData.description ? ticketsData.description : '',
            totalTickets: ticketsData.totalTicketsAvailable ? ticketsData.totalTicketsAvailable : '',
            // saleStartDateTime: ticketsData.saleStartDateTime ? ticketsData.saleStartDateTime : '',
            // saleEndDateTime: ticketsData.saleEndDateTime ? ticketsData.saleEndDateTime : '',
            saleStartDate: ticketsInfo && ticketsInfo?.saleStartDateTime ? moment.unix(Number(ticketsInfo?.saleStartDateTime)) : currentTime,
            saleStartTime: ticketsInfo && ticketsInfo?.saleStartDateTime ? moment.unix(Number(ticketsInfo?.saleStartDateTime)).format('HH:mm') :currentTime.format('HH:mm'),
            saleCloseDate: ticketsInfo && ticketsInfo?.saleCloseDateTime ? moment.unix(Number(ticketsInfo?.saleCloseDateTime)) : moment.unix(Number(eventReduxData?.closingDateTime)),
            saleCloseTime: ticketsInfo && ticketsInfo?.saleCloseDateTime ? moment.unix(Number(ticketsInfo?.saleCloseDateTime)).format('HH:mm') : moment.unix(Number(eventReduxData?.closingDateTime)).format('HH:mm'),
            stageIds: ['1'],
            // enableApproval: ticketsData?.enableApproval ? ticketsData?.enableApproval : 2,
            enableApproval: eventReduxData?.registrationType === EventRegistrationTypes.INVITE ? 1 : ticketsData?.enableApproval,
            enableWaitlistStatus: Number(ticketsData?.enableWaitlistStatus) ? Number(ticketsData?.enableWaitlistStatus) : Number(eventReduxData?.enableWaitlistStatus) || EnableTicketWaitlistStatus.DISABLE,
            privateTicket: ticketsData?.privateTicket ? ticketsData?.privateTicket : PrivateTicket.PRIVATE,
            type: ticketsData?.type ? ticketsData?.type : 1,
            ticketPrice: ticketsData?.ticketPrice ? ticketsData?.ticketPrice : '',
            enableInvoice: ticketsData?.enableInvoice ? ticketsData?.enableInvoice : EnableInvoiceStatus.DISABLE,
            includeTax: ticketsData?.enableInvoice === EnableInvoiceStatus.ENABLE && ticketsInfo?.tax && ticketsInfo?.tax.length > 0 ? true : false,
            tax: ticketsInfo?.tax ? ticketsInfo?.tax : [],
            integrationType: ticketsInfo?.paymentIntegration ? ticketsInfo?.paymentIntegration : generateIntegrationOptions()?.[0]?.id,
            currency: ticketsInfo?.currency ? ticketsInfo?.currency : ticketsInfo?.paymentIntegration === EventTicketPaymentIntegration.STRIPE ? Currency.US_DOLLAR : Currency.INDIAN_RUPEE,
            showInvoicingAndTax: false,
            showActivityLog: false,
            showCapacityTimeStage: false,
        },
        onSubmit: async (): Promise<void> => 
        {

            const saleStartDateTime = combineDateTime(formik.values.saleStartDate, formik.values.saleStartTime);
            const saleCloseDateTime = combineDateTime(formik.values.saleCloseDate, formik.values.saleCloseTime);

            if (Number(saleCloseDateTime) > Number(eventReduxData?.eventEndDateTime)) 
            {
                toast.error('Ticket Sale End Date & Time should be less than Event End Date & Time');
                return;
            }

            if (Number(saleStartDateTime) > Number(saleCloseDateTime))
            {
                toast.error('Ticket Sale Start Date & Time should be less than Ticket Sale End Date & Time');
                setSpinner(false);
                return;
            }
            setSpinner(true);
            const ticketData = {
                name: formik.values.name,
                description: formik.values.description,
                type: formik.values.type,
                totalTicketsAvailable: formik.values.totalTickets,
                saleStartDateTime,
                saleCloseDateTime,
                privateTicket: formik.values.privateTicket,
                stageIds: [],
                tax: formik.values.tax?.length > 0 ? formik.values.tax : null,
                enableWaitlistStatus: Number(formik.values.enableWaitlistStatus),
                enableApproval: formik.values.enableApproval || EnableApprovalStatus.DISABLE,
                enableInvoice: formik.values?.enableInvoice,
                ticketPrice: formik.values.type === TicketType.PAID ? Number(formik.values?.ticketPrice) : 0,
                paymentIntegration: formik.values.integrationType,
                currency: formik.values.currency
            };

            if (ticketsInfo) 
            {
                if (ticketsInfo.title !== formik.values.name || ticketsInfo.description !== formik.values.description || ticketsInfo.totalTicketsAvailable !== formik.values.totalTickets || ticketsInfo.ticketAvailability !== ticketsData.ticketAvailability || Number(ticketsInfo.saleStartDateTime) !== Number(saleStartDateTime) || Number(ticketsInfo.saleCloseDateTime) !== Number(saleCloseDateTime)) 
                {
                    try 
                    {
                        const ticketCreated = await updateEventTicket(eventId, ticketsInfo.id, ticketData);
                        if (ticketCreated) 
                        {
                            toast.success('Ticket Details Updated');
                            props.setRefresh(true);
                            setSpinner(false);
                            handleDrawerClose();
                        }
                    }
                    catch (error) 
                    {
                        setSpinner(false);
                        console.log(error);
                        if ((error as Error)?.message == 'INVOICE PREFIX OR INVOICE NUMBER NOT SET')
                        {
                            setShowInvoiceSettingsPopup(true);
                            return toast.error('Please update Invoice settings to create the ticket.');
                        }
                        toast.error((error as Error)?.message || 'Error in updating ticket');
                    }
                }
            }
            else 
            {
                try 
                {
                    const ticketCreated = await createEventTicket(eventId, ticketData, csrfTokenData);
                    if (ticketCreated) 
                    {
                        toast.success('Ticket Created');
                        props.setRefresh(true);
                        setSpinner(false);
                        handleDrawerClose();
                    }
                }
                catch (error) 
                {
                    setSpinner(false);

                    if ((error as Error)?.message == 'INVOICE PREFIX OR INVOICE NUMBER NOT SET')
                    {
                        setShowInvoiceSettingsPopup(true);

                        return toast.error('Please update Invoice settings to create the ticket.');
                    }
                    toast.error(_.startCase((error as Error).message.toLowerCase()));
                    console.log(error);
                }
            }
        }
    });

    const fetchActivityLogs = async ():Promise<void> =>
    {
        try 
        {
            const activityLogs = await getEventTicketActivityLogs(eventId, ticketsInfo?.id);
            if (activityLogs) 
            {
                setActivityLogs(activityLogs);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchData = async ():Promise<void> => 
    {

        setStageIdData([
            {
                id: '1', name: 'All'
            }
        ]);
        
        if (ticketsInfo) 
        {
            setHeaderText('Edit Ticket');
            setHeaderDescription('Update ticket details, pricing, and availability.');

            if(ticketsInfo?.description)
            {
                setShowDescription(true);
            }
            if(ticketsInfo?.saleStartDateTime)
            {
                setShowSaleStartComponent(true);
            }
            if(ticketsInfo?.saleCloseDateTime)
            {
                setShowSaleEndComponent(true);
            }
            dispatch(addTicket({
                name: ticketsInfo && ticketsInfo.name ? ticketsInfo.name : '',
                description: ticketsInfo && ticketsInfo.description ? ticketsInfo.description : '',
                totalTicketsAvailable: ticketsInfo && ticketsInfo.totalTicketsAvailable ? ticketsInfo.totalTicketsAvailable : 0,
                saleStartDate: moment.unix(ticketsInfo.saleStartDateTime).format('YYYY-MM-DD'),
                saleStartTime: moment.unix(ticketsInfo.saleStartDateTime).format('HH:mm'),
                saleEndDate: moment.unix(ticketsInfo.saleCloseDateTime).format('YYYY-MM-DD'),
                saleEndTime: moment.unix(ticketsInfo.saleCloseDateTime).format('HH:mm'),
                saleStartDateTime: ticketsInfo?.saleStartDateTime,
                saleEndDateTime: ticketsInfo?.saleCloseDateTime,
                maximumPeopleWaitlist: ticketsInfo && ticketsInfo.maximumPeopleWaitlist ? ticketsInfo.maximumPeopleWaitlist : 0,
                privateTicket: ticketsInfo && ticketsInfo.privateTicket ? ticketsInfo.privateTicket : 1,
                ticketGroupId: ticketsInfo && ticketsInfo.ticketGroupId ? ticketsInfo.ticketGroupId : 0,
                enableApproval: ticketsInfo?.enableApproval,
                enableInvoice: ticketsInfo?.enableInvoice,
                enableWaitlistStatus: ticketsInfo.enableWaitlistStatus === 1 ? 1 : Number(ticketsInfo?.enableWaitlistStatus),
                type: ticketsInfo?.type,
                ticketPrice: ticketsInfo?.ticketPrice,
            }));
        }

        if (!eventReduxData.closingDateTime) 
        {
            setMaxEndDate(Number(eventReduxData.eventEndDateTime));
        }
        else 
        {
            setMaxEndDate(Number(eventReduxData.closingDateTime));
        }
    };

    // const fetchIntegrations = async ():Promise<void> =>
    // {
    //     try 
    //     {
    //         const integrationsData = await getIntegrationsData();
    //         if (integrationsData) 
    //         {
    //             setIntegrations(integrationsData);
    //         }
    //     } 
    //     catch (error) 
    //     {
    //         console.log(error, 'Error in fetching integrations');
    //     }
    // };

    const removeTicketFromTable = async (ticket: EventTicket): Promise<void> => 
    {
        try 
        {
            const ticketDeleted = await deleteTicket(eventId, ticket?.id as string);
            if (ticketDeleted) 
            {
                setShowDeletePopup(false);
                handleDrawerClose();
                toast.success('Ticket Deleted');
                props?.setRefresh(true);
            }
        }
        catch (error) 
        {
            toast.error((error as Error)?.message);
            console.log(error);
        }         
    };

    const handleBackToPopup = (): void =>
    {
        formik.setFieldValue('showActivityLog', false);
        formik.setFieldValue('showCapacityTimeStage', false);
        formik.setFieldValue('showInvoicingAndTax', false);

        setHeaderText(ticketsInfo ? 'Edit Ticket' : 'Create Ticket');
        setHeaderDescription(ticketsInfo ? 'Update ticket details, pricing, and availability.' : 'Sell tickets for your event effortlessly.');
    }

    const toggleDescription = () =>
    {
        setShowDescription(prev => !prev);
    }

    const toggleCapacityTimeStage = (): void =>
    {
        setHeaderText('Advanced');
        setHeaderDescription('Restrict by Capacity, Date or Stage');
        formik.setFieldValue('showCapacityTimeStage', !formik.values.showCapacityTimeStage);
        // setShowCapacityTimeStage(prev => !prev);
    }

    const toggleInvoicingAndTax = (): void =>
    {
        // setShowInvoicingAndTax(prev => !prev);
        formik.setFieldValue('showInvoicingAndTax', !formik.values.showInvoicingAndTax);

    }

    const toggleSaleStartComponent = (isChecked: boolean) =>
    {
        setShowSaleStartComponent(isChecked);
    }

    const toggleSaleEndComponent = (isChecked: boolean) =>
    {
        setShowSaleEndComponent(isChecked);
    }

    useEffect((): void => 
    {
        fetchData();
        // fetchIntegrations();
    }, [eventReduxData]);

    useEffect(() =>
    {
        if(ticketsInfo)
        {
            fetchActivityLogs();
        }
    }, []);

    let ticketPrice = Number(formik.values?.ticketPrice);
    if(formik.values?.tax && formik.values.tax?.length > 0)
    {
        const totalPercentage = _.sumBy(formik.values.tax, (tax: { name: string; percentage: number }) => Number(tax?.percentage));
        ticketPrice += (ticketPrice * totalPercentage / 100);
    }

    const renderTicketName = () => (
        <div className="popup-container-spacing">
            <FormLabelComponent label={CONTENT.EVENTS_MODULE.TICKET.FORMS.TICKET.LABEL.TITLE} required />
            <FormControlComponent
                type="text"
                placeholder='General Pass'
                value={formik.values.name}
                onChange={(event): void => {
                    formik.setFieldValue('name', event.target.value);
                }}
                isInvalid={formik.errors.name && formik.touched.name}
            />
            <Form.Control.Feedback type="invalid">{'Enter valid name'}</Form.Control.Feedback>
            {formik.errors.name && formik.touched.name ? <p className="error-msg">{formik.errors.name}</p> : null}
        </div>
    );

    const renderDescription = () => (
        <>
            {!showDescription && (
                <div className="popup-container-spacing">
                    <CustomButton
                        name="+ Add Description"
                        btnType="tertiary"
                        onClick={toggleDescription}
                    />
                </div>
            )}

            {showDescription && (
                <div className="popup-container-spacing">
                    <FormLabelComponent label={'Description'} />
                    <FormControlComponent
                        type={'text'}
                        placeholder='Enter Description'
                        value={formik.values.description}
                        onChange={(event): void => {
                            formik.setFieldValue('description', event.target.value);
                        }}
                        isInvalid={formik.errors.description && formik.touched.description}
                    />
                </div>
            )}
        </>
    );

    const renderRequireApproval = () => (
        <div className="popup-container-spacing">
            <div className="approval-container">
                <div className="approval-icon-label-container">
                    <FontAwesomeIcon icon={['fal', 'user-check']} className="approval-icon" />
                    <FormLabelComponent label={'Require Approval'} noBottomMargin />
                </div>
                <CustomTooltip key={1} title={eventReduxData.registrationType === 2 && <p variant="subtitle1" fontSize={'12px'}>Approval is Enabled for Event</p>}>
                    <FormControlLabel
                        checked={formik.values.enableApproval === 1 || eventReduxData.registrationType === 2}
                        control={<CustomSwitch
                            defaultChecked={eventReduxData.registrationType === 2}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                formik.setFieldValue('enableApproval', event.target.checked ? 1 : 2);
                            }}
                            disabled={eventReduxData.registrationType === 2}
                        />}
                        label={''}
                    />
                </CustomTooltip>
            </div>
        </div>
    );

    const renderVisibility = () => (
        <div className="popup-container-spacing">
            <FormLabelComponent label={'Visibility'} />
            <ToggleButtonGroupComponent value={formik.values.privateTicket} options={ticketType} onChange={(event, value): void => {
                formik.setFieldValue('privateTicket', Number(value));
            }}
            />
            {formik.errors.privateTicket && formik.touched.privateTicket ? <p className="error-msg">{formik.errors.privateTicket}</p> : null}
        </div>
    );

    const renderTicketPricing = () => (
        <div className="popup-container-spacing">
            <FormLabelComponent label={'Pricing'} required />
            <ToggleButtonGroupComponent value={formik.values.type} options={ticketPricing} onChange={(event, value): void => {
                formik.setFieldValue('type', Number(value));
            }}
            />
            {formik.errors.type && formik.touched.type ? <p className="error-msg">{formik.errors.type}</p> : null}

            {!formik.values.showCapacityTimeStage && !formik.values.showInvoicingAndTax && formik.values.type === TicketType.PAID && isPaymentIntegrationDone &&
                <div className="popup-container-spacing pt-8">
                    {/* <FormLabelComponent label="Ticket Cost" required /> */}
                    <div className="ticket-price-container w-100">
                        <SelectComponent
                            options={generateCurrencyOptions()}
                            optionLabels={currencyOptionLabel}
                            value={formik.values.currency}
                            selectedAsString
                            onChange={(event): void => {
                                formik.setFieldValue('currency', String(event.target.value));
                            }}
                            customValueRender={(value) => {
                                return (
                                    <div className="currency-select">
                                        <p>{getEmojiByCurrencyCode(value as string)}</p>
                                        <p>{getSymbolCurrency(value as string)}</p>
                                    </div>
                                )
                            }}
                            defaultPlaceholder="Select Currency"
                        />
                        <div className="w-100">
                            <FormControlComponent
                                type={'number'}
                                placeholder='500'
                                rows={4}
                                value={formik.values.ticketPrice}
                                onKeyDown={(evt): void => {
                                    return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
                                }}
                                onChange={(event): void => {
                                    formik.setFieldValue('ticketPrice', event.target.value);
                                }}
                                isInvalid={!!formik.errors.ticketPrice && formik.touched.ticketPrice}
                            />
                            {formik.errors.ticketPrice && formik.touched.ticketPrice ? <p className="error-msg">{formik.errors.ticketPrice}</p> : null}
                        </div>
                    </div>
                </div>
            }
        </div>
    );

    const renderIntegrationInfo = () => (
        (formik.values.type === TicketType.PAID && !isPaymentIntegrationDone) && <IntegrationInfoComponent />
    );

    const renderPaymentGateway = () => (
        (!formik.values.showCapacityTimeStage && !formik.values.showInvoicingAndTax && formik.values.type === TicketType.PAID && isPaymentIntegrationDone) &&
        <div className="popup-container-spacing">
            <FormLabelComponent label="Payment Gateway" required />
            <SelectComponent
                options={generateIntegrationOptions()}
                value={formik.values.integrationType || ''}
                onChange={(event): void => {
                    formik.setFieldValue('integrationType', event.target.value);
                }}
                optionLabels={integrationOptionLabel}
                defaultPlaceholder="Select Payment Gateway"
            />
        </div>
    );

    const renderInvoicingAndTax = () => (
        <>
            {
                formik.values.showInvoicingAndTax &&             
                <>
                    <div className="popup-container-spacing">
                        <div className="approval-container">
                            <div className="approval-icon-label-container">
                                <FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} className="approval-icon" />
                                <FormLabelComponent label={'Enable Invoice'} noBottomMargin />
                            </div>
                            <FormControlLabel
                                checked={formik.values.enableInvoice === 1}
                                control={<CustomSwitch
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                        formik.setFieldValue('enableInvoice', event.target.checked ? 1 : 2);
                                        if (!event.target.checked) {
                                            formik.setFieldValue('includeTax', false);
                                            formik.setFieldValue('tax', []);
                                        }
                                    }}
                                />}
                                label={''}
                            />
                        </div>
                    </div>

                    {
                        <div className="popup-container-spacing">
                            <div className="approval-container">
                                <div className="approval-icon-label-container">
                                    <FontAwesomeIcon icon={['fal', 'money-check-dollar']} className="approval-icon" />
                                    <FormLabelComponent label={'Include Tax'} noBottomMargin />
                                </div>
                                <FormControlLabel
                                    checked={formik.values.includeTax}
                                    control={<CustomSwitch
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                            formik.setFieldValue('includeTax', event.target.checked);
                                            if (!event.target.checked) {
                                                formik.setFieldValue('tax', []);
                                            }
                                            else {
                                                handleAddTax();
                                            }
                                        }}
                                    />}
                                    label={''}
                                    disabled={formik.values.enableInvoice === EnableInvoiceStatus.DISABLE}
                                />
                            </div>
                        </div>
                    }

                    {formik.values.includeTax && <div className="popup-container-spacing">
                        <div className="tax-remove-icon-cont">
                            <FormLabelComponent label={`Tax`} />
                        </div>
                        {

                            formik.values?.tax && formik.values?.tax.map((tax, index) => {
                                return (
                                    <div className="tax-container" key={index}>
                                        <div className="tax-input-cont">
                                            <div className='w-100'>
                                                <FormControlComponent name="name" placeholder="Enter Tax Name" onChange={(event): void => {
                                                    handleTaxInput(event, index,)
                                                }} isInvalid={formik.errors.tax && formik.errors.tax[index]?.name && formik.touched.tax && formik.touched.tax[index]?.name} value={tax.name} type='text' />
                                                {formik.errors.tax && formik.errors.tax[index]?.name && formik.touched.tax && formik.touched.tax[index]?.name ? <p className="error-msg">{formik.errors.tax[index]?.name}</p> : null}
                                            </div>
                                            <div className='w-100'>
                                                <FormControlComponent name="percentage" placeholder="Enter Tax Percentage" onChange={(event): void => {
                                                    handleTaxInput(event, index,)
                                                }} isInvalid={formik.errors.tax && formik.errors.tax[index]?.percentage && formik.touched.tax && formik.touched.tax[index]?.percentage} value={tax.percentage} type='text' />
                                                {formik.errors.tax && formik.errors.tax[index]?.percentage && formik.touched.tax && formik.touched.tax[index]?.percentage ? <p className="error-msg">{formik.errors.tax[index]?.percentage}</p> : null}
                                            </div>
                                            <CustomButton onClick={(event): void => { handleTaxRemove(event, index)}} btnType='tertiary_outline' name='' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} />
                                        </div>
                                       
                                    </div>
                                )
                            })
                            
                        }
                       
                       <div className="popup-container-spacing">
                            <CustomButton
                                name="+ Add Tax"
                                btnType="tertiary"
                                onClick={() => handleAddTax()}
                            />
                        </div>
                    </div>
                    }


                    {formik.values.includeTax && formik.values.tax && formik.values.tax?.length > 0 && <div className="tax-calc-card">
                        <div className='tax-calc-price-container'>
                                        <div className="tax-calc-container">
                                            <div className="tax-details">
                                                <p>{`Ticket Price`}</p>
                                            </div>
                                            <p className="value">{`${getSymbolCurrency(formik.values.currency || Currency.INDIAN_RUPEE)}${ticketPrice}`}</p>
                                        </div>
                            {    
                                formik.values.tax?.map((tax, index) => {
                                    const taxValue = _.round((Number(Number(formik.values?.ticketPrice) * tax.percentage) / 100), 2);
                                    return ( (tax?.name || tax?.percentage) &&
                                         <div className="tax-calc-container" key={index}>
                                            <div className="tax-details">
                                                <p>{`${tax?.name} (${tax?.percentage}%)`}</p>
                                            </div>
                                            <p className="value">{`${getSymbolCurrency(formik.values.currency || Currency.INDIAN_RUPEE)}${taxValue}`}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="total-price-container">
                            <p>Total Cost</p>
                            <h3>{getSymbolCurrency(formik.values.currency || Currency.INDIAN_RUPEE)}{Number(ticketPrice)?.toFixed(2)}</h3>
                        </div>
                    </div>
                    }
                </>
            }

            {formik.values.type === TicketType.PAID && isPaymentIntegrationDone && !formik.values.showInvoicingAndTax && !formik.values.showCapacityTimeStage && (
                <AdditionalInfoComponent
                    title="Invoicing and Tax"
                    description={
                        ticketsInfo?.enableInvoice === EnableInvoiceStatus.ENABLE && ticketsInfo?.tax && ticketsInfo?.tax.length > 0
                            ? `Invoicing: On • ${ticketsInfo?.tax?.map(tax => `${tax?.name}: ${tax?.percentage}%`).join(' . ')} • Total Price: ${getSymbolCurrency(formik.values.currency || Currency.INDIAN_RUPEE)}${ticketPrice.toFixed(2)}`
                            : "Manage invoicing and tax settings for your ticket."
                    }
                    onClick={toggleInvoicingAndTax}
                />
            )}
        </>
    );

    const renderAdvancedInfo = () => (
        <>
            {formik.values.showCapacityTimeStage &&
                <>
                    {/* Total tickets available */}
                    <div className="popup-container-spacing">
                        <FormLabelComponent label={CONTENT.EVENTS_MODULE.TICKET.FORMS.TICKET.LABEL.TOTAL_TICKETS} />
                        <FormControlComponent
                            type="number"
                            value={formik.values.totalTickets}
                            placeholder='500'
                            required
                            onChange={(event): void => {
                                formik.setFieldValue('totalTickets', event.target.value);
                            }}
                            onKeyDown={(evt): void => {
                                return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
                            }}
                            isInvalid={formik.errors.totalTickets && formik.touched.totalTickets}
                        />
                        {formik.errors.totalTickets && formik.touched.totalTickets ? <p className="error-msg">{formik.errors.totalTickets}</p> : null}
                    </div>

                    {/* Enable waitlist */}
                    <div className="popup-container-spacing">
                        <div className="approval-container">
                            <div className="approval-icon-label-container">
                                <FontAwesomeIcon icon={['fal', 'user-clock']} className="approval-icon" />
                                <FormLabelComponent label={'Enable Waitlist'} noBottomMargin />
                            </div>
                            <CustomTooltip key={1} title={Number(eventReduxData.enableWaitlistStatus) === 1 && <p variant="subtitle1" fontSize={'12px'}>Waitlist is Enabled for Registrations</p>}>
                                <FormControlLabel
                                    checked={formik.values.enableWaitlistStatus === 1 || Number(eventReduxData.enableWaitlistStatus) === 1}
                                    control={<CustomSwitch
                                        defaultChecked={Number(eventReduxData.enableWaitlistStatus) === 1}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                            formik.setFieldValue('enableWaitlistStatus', event.target.checked ? 1 : 2);
                                        }}
                                    />}
                                    label={''}
                                    disabled={Number(eventReduxData.enableWaitlistStatus) === 1}
                                />
                            </CustomTooltip>

                        </div>
                    </div>

                    {/* Ticket timing */}
                    <div className="popup-container-spacing">
                        {/* <FormLabelComponent label="Sale Date & Time" required /> */}
                        <div className="time-container">
                            <div className='time-approval-container'>
                                <div className="approval-icon-label-container">
                                    <FontAwesomeIcon icon={['fal', 'clock']} className="approval-icon" />
                                    <FormLabelComponent label={'Sale Start'} noBottomMargin />
                                </div>
                                <FormControlLabel
                                    control={<CustomSwitch
                                        checked={showSaleStartComponent}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                            toggleSaleStartComponent(event.target.checked);
                                        }}
                                    />}
                                    label={''}
                                />
                            </div>
                            {showSaleStartComponent && <div>
                                <DateTimePicker
                                    dateValue={moment(formik.values.saleStartDate, 'DD/MM/YYYY').unix()}
                                    timeValue={formik.values.saleStartTime}
                                    onDateChange={(date): void => {
                                        formik.setFieldValue('saleStartDate', date);
                                    }}
                                    onTimeChange={(time): void => {
                                        formik.setFieldValue('saleStartTime', time);
                                    }}
                                    dateFormat='DD/MM/YYYY'
                                    maxDate={Number(eventReduxData?.closingDateTime)}
                                />
                            </div>}
                        </div>
                        {/* <div className="ticket-sale-timing">
                    <DateTimePicker 
                        dateValue={moment(formik.values.saleStartDate, 'DD/MM/YYYY').unix()}
                        timeValue={formik.values.saleStartTime}
                        onDateChange={(date): void => 
                        {
                            formik.setFieldValue('saleStartDate', date);
                        }}
                        onTimeChange={(time): void => 
                        {
                            formik.setFieldValue('saleStartTime', time);
                        }}
                        dateFormat='ddd, MMM D'
                        maxDate={Number(eventReduxData?.closingDateTime)}
                    />
                    <p>-</p>
                    <DateTimePicker 
                        dateValue={moment(formik.values.saleCloseDate, 'DD/MM/YYYY').unix()}
                        timeValue={formik.values.saleCloseTime}
                        onDateChange={(date): void => 
                        {
                            formik.setFieldValue('saleCloseDate', date);
                        }}
                        onTimeChange={(time): void => 
                        {
                            formik.setFieldValue('saleCloseTime', time);
                        }}
                        dateFormat='ddd, MMM D'
                        minDate={moment(formik.values.saleStartDate).unix()}
                        maxDate={Number(eventReduxData?.closingDateTime)}
                    />
                    </div> */}
                    </div>
                    <div className="popup-container-spacing">
                        <div className="time-container">
                            <div className='time-approval-container'>
                                <div className="approval-icon-label-container">
                                    <FontAwesomeIcon icon={['fal', 'clock']} className="approval-icon" />
                                    <FormLabelComponent label={'Sale End'} noBottomMargin />
                                </div>
                                <FormControlLabel
                                    control={<CustomSwitch
                                        checked={showSaleEndComponent}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                            toggleSaleEndComponent(event.target.checked);
                                        }}
                                    />}
                                    label={''}
                                />
                            </div>
                            {showSaleEndComponent && <div>
                                <DateTimePicker
                                    dateValue={moment(formik.values.saleCloseDate, 'DD/MM/YYYY').unix()}
                                    timeValue={formik.values.saleCloseTime}
                                    onDateChange={(date): void => {
                                        formik.setFieldValue('saleCloseDate', date);
                                    }}
                                    onTimeChange={(time): void => {
                                        formik.setFieldValue('saleCloseTime', time);
                                    }}
                                    dateFormat='DD/MM/YYYY'
                                    minDate={moment(formik.values.saleStartDate).unix()}
                                    maxDate={Number(eventReduxData?.closingDateTime)}
                                />
                            </div>}
                        </div>
                    </div>

                    {/* Stages */}
                    <div className="popup-container-spacing">
                        <FormLabelComponent label='Select Stage' />
                        <MultipleSelectComponent value={formik.values.stageIds} all={true} onChange={handleStageSelect} multiple={false} options={stageIdData} optionLabels={CONTENT.EVENTS_MODULE.TICKET.FORMS.TICKET.TICKET_OPTION} />
                    </div>
                </>
            }

            {!formik.values.showCapacityTimeStage && !formik.values.showInvoicingAndTax && (
                <AdditionalInfoComponent
                    title="Advanced"
                    description={ticketsInfo ? `Capacity: ${ticketsInfo.ticketAvailability} • ${moment.unix(ticketsInfo.saleStartDateTime).format('DD/MM/YY')} - ${moment.unix(ticketsInfo.saleCloseDateTime).format('DD/MM/YY')} • Waitlist: ${ticketsInfo.enableWaitlistStatus === EnableTicketWaitlistStatus.ENABLE ? 'On' : 'Off'} ` : "Restrict by Capacity, Date or Stage"}
                    onClick={toggleCapacityTimeStage}
                />
            )}
        </>
    );

    const renderActivityLog = () => (
        <ActivityLogComponent activityLog={eventTicketsActivityLog(activityLogs, ticketsInfo as EventTicket)} />
    );

    const renderHeader = () => {
        return (
            <div className='popup-header'>
                <div className='popup-header-content'>
                    {formik.values.showCapacityTimeStage || formik.values.showInvoicingAndTax || formik.values.showActivityLog ? (
                        <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} onClick={handleBackToPopup} />
                    ) : (
                        <div className='popup-header-icon'><FontAwesomeIcon icon={['fal', 'ticket-simple']} /></div>
                    )}

                    <div className='popup-header-text'>
                        <h3>
                            {headerText}
                        </h3>
                        <p>
                            {headerDescription}
                        </p>
                    </div>
                </div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => props.handlePopupClose()} />
            </div>
        );
    }

    const renderMainContent = () => {
        if (formik.values.showActivityLog) {
            return renderActivityLog();
        } else if (formik.values.showCapacityTimeStage) {
            return renderAdvancedInfo();
        } else if (formik.values.showInvoicingAndTax) {
            return renderInvoicingAndTax();
        } else {
            return (
                <>
                    {renderTicketName()}
                    {renderDescription()}
                    {renderRequireApproval()}
                    {renderVisibility()}
                    {renderTicketPricing()}
                    {renderIntegrationInfo()}
                    {renderPaymentGateway()}
                    {renderInvoicingAndTax()}
                    {renderAdvancedInfo()}
                </>
            );
        }
    };

    const renderFooter = () => {
        if (formik.values.showActivityLog || formik.values.showCapacityTimeStage || formik.values.showInvoicingAndTax) {
            return <></>;
        }

        return (
            <div className="popup-footer">
                {(!ticketsInfo) ? <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                    <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                    <CustomButton btnType='primary' loading={spinner} disabled={spinner || (formik.values.type === TicketType.PAID && !isPaymentIntegrationDone)} name={'Create Ticket'} type='submit' />
                </Stack>
                    : (ticketsInfo) &&
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        <div className='popup-footer-left'>
                            {!isTableView && <FontAwesomeIcon className="popup-delete-icon" icon={['fal', 'trash']} onClick={() => setShowDeletePopup(true)} />}
                            {
                                <div className="ticket-activity-log-button">
                                    <CustomButton
                                        name=""
                                        btnType="secondary"
                                        startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                                        style={{ width: '32px' }}
                                        onClick={handleNestedSidebarOpen}
                                    />
                                </div>
                            }
                        </div>

                        <Stack direction={'row'} spacing={1} display={'flex'}>
                            <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                            <CustomButton btnType='primary' loading={spinner} disabled={spinner || (formik.values.type === TicketType.PAID && !isPaymentIntegrationDone)} name={'Update Ticket'} type='submit' />
                        </Stack>
                    </Stack>
                }
            </div>
        )
    }

    return (
        <div id="createTicket">

            {renderHeader()}

            <Form noValidate
                onSubmit={(values): void => {
                    return formik.handleSubmit(values);
                }}
                autoComplete="off">

                <div className='popup-container'>
                    {renderMainContent()}
                </div>

                {renderFooter()}
            </Form>

            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={() => removeTicketFromTable(ticketsInfo as EventTicket)}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                    }}
                    modalContent={`Are you sure you want to delete ${ticketsInfo?.name}?`}
                    modalTitle='Delete Ticket'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='trash'
                />
            }

            {
                showInvoiceSettingsPopup &&
                <PopupDialog
                        modalId="inoviceSettingsPopup"
                        show={showInvoiceSettingsPopup}
                        cancelClick={handlePopupClose}
                        modalContent={<InvoiceSettings eventId={eventId} isInvoiceDetailsPresent={false} handlePopupClose={handlePopupClose}/>}
                    />
            }
        </div >
    );
};

export default CreateTickets;