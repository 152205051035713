
import './styles.scss'
import { EventRegistrantFeedbackCount, EventSession, EventStage } from "../../../../pages/Events/interfaces"
import _ from "lodash";
import { useEffect, useState } from "react";
import { RegistrantFeedbacks, SessionFeedbackComponent } from "./AgendaFeedbackComponents";
import { EventRegistrant } from '../../../../pages/Events/interfaces/event-registrant_interface';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadAllSessionFeedbacks, getSessionFeedbackCount, getSessionFeedbacks } from '../../../../scripts/apis/eventSession';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';

interface AgendaInScreenPopupProps {
    sessionData: EventSession;
    stageData: EventStage | null;
    handleClose: () => void;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    handleInScreenPopupClose: () => void;
}

const AgendaInScreenPopup: React.FC<AgendaInScreenPopupProps> = ({ sessionData, handleClose, setRefresh, stageData, handleInScreenPopupClose }): React.JSX.Element => 
{

    const [component, setComponent] = useState<'sessionFeedbacks' | 'registrantsInfo'>('sessionFeedbacks');
    const [selectedRegistrant, setSelectedRegistrant] = useState<EventRegistrant | null>(null);
    const [feedbacksCount, setFeedbacksCount] = useState<EventRegistrantFeedbackCount>();
    const [feedbacks, setFeedbacks] = useState<EventRegistrant[]>([]);
    const [feedbackDownloadSpinner, setFeedbackDownloadSpinner] = useState<boolean>(false);

    const handleCloseButtonClick = (): void =>
    {
        if(component === 'registrantsInfo')
        {
            setComponent('sessionFeedbacks');
        }
        else
        {
            handleClose();
        }
    }

    const handleFeedbacksDownload = async (): Promise<void> =>
    {
        setFeedbackDownloadSpinner(true);
        try 
        {
            const response = await downloadAllSessionFeedbacks(sessionData?.eventId, sessionData.id);
            // Use file-saver to save the blob as a file
            saveAs(response.data, `${sessionData?.title} feedbacks.csv`);
        } 
        catch (error) 
        {
            console.log(error, 'error')
            toast.error('Error downloading feedbacks');
        }
        finally
        {
            setFeedbackDownloadSpinner(false);
        }
    }

    // Doing the feedbacks data fetching here because count is required for the tabs so fetching feedbacks also here to prevent multiple useEffects
    const fetchFeedbacksData = async (): Promise<void> =>
    {
        try 
        {
            const feedbackCount = await getSessionFeedbackCount(sessionData?.eventId, sessionData.id);
            setFeedbacksCount(feedbackCount);
            const registrantFeedbacks = await getSessionFeedbacks(sessionData?.eventId, sessionData?.id, Number(feedbacksCount?.all), 0);
            if(registrantFeedbacks)
            {
                setFeedbacks(registrantFeedbacks);
            }
        } 
        catch (error)
        {
            console.log('Error fetching feedbacks count', error);
        }
    }

    useEffect(() => 
    {
        if(component === 'registrantsInfo')
        {
            setComponent('sessionFeedbacks');
            setSelectedRegistrant(null);
        }
        fetchFeedbacksData();
    }, [sessionData]);

    return (
        <div id="agendaInScreenPopup">
            <div className="agenda-inscreenpopup-header-container">
                <div></div>
                <div className="header-actions">
                    <CustomButton 
                        name=""
                        btnType="secondary"
                        loading={feedbackDownloadSpinner}
                        disabled={feedbackDownloadSpinner}
                        startIcon={<FontAwesomeIcon icon={['fal', 'download']} />}
                        onClick={handleFeedbacksDownload}
                    />
                    <CustomButton 
                        name=""
                        btnType="secondary"
                        startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />}
                        onClick={handleCloseButtonClick}
                    />
                </div>
            </div>
            {
                component === 'sessionFeedbacks' ? 
                <SessionFeedbackComponent handleInScreenPopupClose={handleInScreenPopupClose} feedbackResponseCount={feedbacksCount as EventRegistrantFeedbackCount} feedbacksData={feedbacks} setRefresh={setRefresh} sessionData={sessionData} stageData={stageData} onSessionFeedbackRowClick={(row: EventRegistrant) => setSelectedRegistrant(row)} onExpandClick={(component: 'sessionFeedbacks' | 'registrantsInfo') => setComponent(component)} />
                :
                <RegistrantFeedbacks registrantData={selectedRegistrant} />
            }
        </div>
    )
}

export default AgendaInScreenPopup
