import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Form } from 'react-bootstrap';
import { createEventCategory, deleteEventCategory, getAllEventCategories, updateEventCategory } from '../../../scripts/apis/eventCategory';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import _ from 'lodash';
import { CONTENT } from '../../../scripts/i18n';
import { useSelector } from 'react-redux';
import { EventCategory } from '../../../pages/Events/interfaces';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { FormControlComponent, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IEventCategoryProps 
{ 
    setEventCategoryDetails: (eventCategoryDetails: EventCategory[]) => void;
    eventCategoryName: string;
    isEdit?: boolean;
    eventCategoryId?: string | number;
    handleEditPopupClose: () => void;
    formik: any;
    updateEventDetails?: (eventCategory: EventCategory) => void;
}

const CreateEventCategory: React.FC<IEventCategoryProps> = ({ setEventCategoryDetails, eventCategoryName, isEdit, eventCategoryId, handleEditPopupClose, formik, updateEventDetails }): React.JSX.Element => 
{
    const navigate = useNavigate();
    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const [validated, setValidated] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [groupName, setGroupName] = useState(eventCategoryName);

    const formSubmit = async (event): Promise<void> => 
    {
        setSpinner(true);
        event.preventDefault();
        setValidated(true);
        const eventCategoryData = {
            name: groupName,
        };

        if(isEdit) {
            if(eventCategoryName !== groupName) 
            {
                try 
                {
                    const categoryUpdated = await updateEventCategory(Number(eventCategoryId), eventCategoryData);
                    if(categoryUpdated) 
                    {
                        const eventCategoryData: EventCategory[] = await getAllEventCategories();
                        if(eventCategoryData) 
                        {
                            setEventCategoryDetails([...eventCategoryData]);
                            const group = _.find(eventCategoryData, ['id', String(categoryUpdated.id)]);
                            formik.setFieldValue('eventCategoryObject', group);
                            eventBus.dispatch('event-overview-refreshed', { refresh: true });
                            eventBus.dispatch('event-details-refreshed', {
                                refresh: true
                            });
                            eventBus.dispatch('Refresh-events-top-bar', { refresh: true });
                            handleDialogClose();
                        }
                    }
                } 
                catch (error)
                {
                    console.error(error);
                }
            }
        }
        else
        {
            try 
            {
                const categoryCreated: EventCategory = await createEventCategory(eventCategoryData, csrfTokenData);
                if(categoryCreated) 
                {
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.FORM_SUBMIT_CONFIRMATION, {
                        submit: true,
                        page: 'tickets',
                    });
                    eventBus.dispatch('dialog-submit', true);
                    const eventCategoryData: EventCategory[] = await getAllEventCategories();
                    if(eventCategoryData) 
                    {
                        setEventCategoryDetails([...eventCategoryData]);
                        const group = _.find(eventCategoryData, ['id', String(categoryCreated.id)]);
                        formik.setFieldValue('eventCategoryObject', group);
                        if (updateEventDetails && group) 
                        {
                            await updateEventDetails(group);
                        }
                        handleDialogClose();
                    }
                }
            } 
            catch (error) 
            {
                console.error(error);
                handleDialogClose();
            }
        }
    };

    const handleDialogClose = (): void => 
    {
        if(handleEditPopupClose) 
        {
            handleEditPopupClose();
        }
    };

    const handleDelete = async (): Promise<void> =>
    {
        setSpinner(true);
        try 
        {
            const categoryDeleted = await deleteEventCategory(eventCategoryId);
            if(categoryDeleted)
            {
                const eventCategoryData: EventCategory[] = await getAllEventCategories();
                if(eventCategoryData) 
                {
                    setEventCategoryDetails([...eventCategoryData]);

                    formik.setFieldValue('eventCategoryObject', {});
                    eventBus.dispatch('event-overview-refreshed', { refresh: true });
                    eventBus.dispatch('event-details-refreshed', {
                        refresh: true
                    });
                    eventBus.dispatch('Refresh-events-top-bar', { refresh: true });
                    handleDialogClose();
                }
            }
        } 
        catch (error) 
        {
            console.error(error, 'Error in deleting event category');
        }
        finally
        {
            setSpinner(false);
        }
    };

    useEffect((): void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, (): void => 
        {
            navigate(0);
        });
    }, []);

    return (
        <Box id="createEventCategory">
            <Form noValidate validated={validated} onSubmit={formSubmit} autoComplete='off'>
                <div>
                    <div className="popup-container">
                        <div className='label-wrapper'>
                            <FormLabelComponent label={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.CREATE_EVENT_CATEGORY.LABEL} required />
                            <FormControlComponent 
                                type='text' 
                                name='ticket-group'
                                placeholder={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.CREATE_EVENT_CATEGORY.PLACEHOLDER} 
                                value={groupName}
                                onChange={(event): void => 
                                {
                                    setGroupName(event.target.value);
                                }}
                                required
                            />
                        </div>
                    </div>

                    <Box className="dialog-submit-button-container popup-style" justifyContent={'space-between'}>
                        {isEdit ? <CustomButton startIcon={<FontAwesomeIcon className="popup-delete-icon" icon={['fal', 'trash']} />} disabled={spinner} btnType='tertiary' name='' onClick={handleDelete} /> : <div />}
                        <Box className="dialog-submit-inner-container">
                            <CustomButton btnType='secondary' name='Cancel' onClick={handleDialogClose} />
                            <CustomButton btnType='primary' name={isEdit ? 'Save' : 'Create'} disabled={spinner || (isEdit && eventCategoryName === groupName) || groupName === ''} onClick={formSubmit} type='submit' />
                        </Box>
                    </Box>
                </div>
            </Form>
        </Box>
    );
};

export default CreateEventCategory;