import { EventCategory } from '../../pages/Events/interfaces';
import APP_CONSTANTS from '../constants';
import { triggerApi } from '../helpers';

export const eventCategoryCount = async (): Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/category/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllEventCategories = async (): Promise<EventCategory[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/category';
    return triggerApi(API_URL, 'GET', '');
};

export const getEventCategoryById = async (EVENT_CATEGORY_ID: number): Promise<EventCategory[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/category/' + EVENT_CATEGORY_ID;
    return triggerApi(API_URL, 'GET', '');
};

export const createEventCategory = async (EVENT_CATEGORY_DATA: EventCategory, csrfToken: string): Promise<EventCategory> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/category';
    return triggerApi(API_URL, 'POST', EVENT_CATEGORY_DATA, csrfToken);
};

export const updateEventCategory = async (EVENT_CATEGORY_ID: number, EVENT_CATEGORY_DATA: EventCategory): Promise<EventCategory | false> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/category/' + EVENT_CATEGORY_ID;
    return triggerApi(API_URL, 'PUT', EVENT_CATEGORY_DATA);
};

export const deleteEventCategory = async (EVENT_CATEGORY_ID: string): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/category/' + EVENT_CATEGORY_ID;
    return triggerApi(API_URL, 'DELETE', '');
};
