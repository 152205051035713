import React, { useState, useEffect, ReactElement } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Stack, Typography } from '@mui/material';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';

import './styles.scss';

import { CONTENT } from '../../scripts/i18n';
import { addSpeakers } from '../../redux/speakers/Speakers';
import { getAllSpeakersActivityLogs } from '../../scripts/apis/speakers';
import { createEventSpeaker, removeEventSpeaker, updateEventSpeaker, uploadEventSpeakerImage } from '../../scripts/apis/eventSpeakers';
import { decompressImage } from '../../common/ImageProcessing';
import { isImageBuffer } from '../../scripts/helpers';
import { renderCheckBoxComponent, renderDateComponent, renderDropDownComponent, renderInputComponent, renderRadioGroupComponent } from '../../pages/Settings/CustomField/CustomTypeComponent';
import { FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../common/FormComponents/Buttons';
import DeletePopup from '../../common/DeletePopup';
import CustomSpinner from '../../common/CustomSpinner';
import ActivityLogComponent from '../../common/ActivityLog/ActivityLogComponent';

import { ISpeakerValue } from './ICreateSpeaker';
import { CustomFieldLocations, customFieldCountByLocation, customFieldsByLocation } from '../../pages/Settings/CustomField/CustomField.helper';
import { ActivityLogResponse } from '../../interfaces/activity-log_interface';
import { speakersActivityLog } from '../../scripts/activityLogGenerator/activitylog-generator';
import { Speaker } from '../../pages/Speakers/interfaces';
import { CustomField } from '../../pages/Registration/interfaces/custom-field_interface';
import { CustomFieldType } from '../../pages/Registration/enum/custom-field.enum';

interface CreateSpeakerProps {
    speakerData?: Speaker; 
    disabled?: boolean;
    eventId?: string | number;
    speakerId?: string | number;
    handleSpeakerPopupClose?: () => void;
    setRefresh: (refresh: boolean) => void;
    heading?: string;
    handlePopupClose: () => void;
    addEventSpecificSpeaker?: boolean;
    isTableView?: boolean;
}

interface speakers {
    speakers: {
        value: {
            name: string,
            email: string,
            additionalInfo: {
                headline: string,
                bio: string,
                image: any,
                social?: {
                    linkedIn?: string,
                    twitter?: string
                }
            },
            additionalFieldData: object,
        }
    }

}

const CreateSpeaker = (props: CreateSpeakerProps): React.JSX.Element =>
{
    const existingSpeakerInfo = props?.speakerData as Speaker | undefined;
    const disabled = props?.disabled;
    const eventId = props?.eventId;
    const heading = props?.heading;
    const [spinner, setSpinner] = useState(false);
    const [speakerImage, setSpeakerImage] = useState<{
        file: File | undefined,
        blob: string
    }>({
        file: undefined,
        blob: ''
    });
    const [customFormState, setCustomFormState] = useState<Record<string, any>>({});
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const [customFieldsSpinner, setCustomFieldsSpinner] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    const [showActivityLog, setShowActivityLog] = useState(false);

    const dispatch = useDispatch();

    const fetchSpeakerCustomFieldData = async ():Promise<void> => 
    {
        try 
        {
            const cfcount = await customFieldCountByLocation(CustomFieldLocations.SPEAKER);
            if(cfcount)
            {
                setCustomFieldsSpinner(true);
                try 
                {
                    const customFields = await customFieldsByLocation(cfcount,0,CustomFieldLocations.SPEAKER);
                    if(customFields)
                    {
                        setCustomFields(customFields as any);
                        setCustomFieldsSpinner(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setCustomFieldsSpinner(false);
                }
            }
            
        }
        catch (error) 
        {
            console.log(error);
            setCustomFieldsSpinner(false);
        }
        
    };

    const speakersData = useSelector((state: speakers ):ISpeakerValue => 
    {
        return state.speakers.value;
    });

    const csrfTokenData = useSelector((state: any) => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        multiple: false,
        onDrop: (acceptedFiles): void => 
        {
            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = (): void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    setSpeakerImage({
                        file,
                        blob: URL.createObjectURL(file)
                    })
                }
                else 
                {
                    setSpeakerImage({
                        file: undefined,
                        blob: ''
                    });
                    toast.error(fileSizeInMB > 10 ? 'Image size should be less than 10 MB' : 'Please provide a valid image');
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        },
    });

    const getImageUrl = async (imageUrl: string): Promise<void> => 
    {
        const updatedImage = await decompressImage(imageUrl, 1500, 1500);
        if (updatedImage) 
        {
            setSpeakerImage({
                ...speakerImage,
                blob: updatedImage
            });
        }
    };

    const Preview = (): ReactElement => 
    {
        return (
            <Box className="preview-container">
                <img
                    src={(speakerImage?.blob || '')}
                />
            </Box>
        );
    };

    const handleNestedSidebarOpen = (): void =>
    {
        setShowActivityLog(true);
    };

    const handleDrawerClose: () => void = ():void => 
    {

        props?.handlePopupClose();
        setShowActivityLog(false);
    };

    const preparePayload = (): Partial<Speaker> => {
        return {
            name: formik.values.name,
            email: formik.values.email,
            additionalInfo: {
                headline: formik.values?.headline,
                bio: formik.values?.bio,
                social: {
                    linkedIn: formik.values.linkedIn,
                    twitter: formik.values.twitter
                } as any,
            }
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[a-zA-Z ]*$/, 'Enter a valid Speaker Name')
            .required('Name is required'),
        email: Yup.string()
            .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, 'Enter valid Email')
            .required('Email is required'),
        headline: Yup.string()
            .required('Headline is required'),
        bio: Yup.string().test('isValidBio', 'Maximum words Exceeded', (value) => 
        {
            if (value && value.trim()?.length > 0) 
            {
                const words = value.trim().split(/\s+/);
                const wordsLength = words?.length;
                // setSpeakerBioLength(wordsLength);
                if (wordsLength > 250) 
                {
                    return false;
                }
                else 
                {
                    return true;
                }
            }
            else 
            {
                // setSpeakerBioLength(0);
                return true;
            }
        }),
        linkedIn: Yup.string()
            .matches(/(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/, 'Please enter a valid LinkedIn URL'),
        twitter: Yup.string()
            .matches(/(https:\/\/x.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/, 'Please enter a valid Twitter URL')
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: speakersData.name ? speakersData.name : '',
            email: speakersData.email ? speakersData.email : '',
            headline: speakersData.additionalInfo.headline ? speakersData.additionalInfo.headline : '',
            bio: speakersData.additionalInfo.bio ? speakersData.additionalInfo.bio : '',
            linkedIn: speakersData.additionalInfo?.social?.linkedIn ? speakersData.additionalInfo?.social?.linkedIn : '',
            twitter: speakersData.additionalInfo?.social?.twitter ? speakersData.additionalInfo?.social?.twitter : '',
        },

        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);
            
            let payload = preparePayload();

            if(eventId)
            {
                if (!existingSpeakerInfo) {
                    const newSpeakerInfo = {
                        ...payload,
                        additionalFieldData: customFormState ? { ...customFormState } : undefined,
                    };
    
                    try {
                        // Await the speaker creation API
                        const response = await createEventSpeaker(newSpeakerInfo, eventId, csrfTokenData);
                    
                        // If there is an image, upload it
                        if (speakerImage?.file) {
                            const image = new FormData();
                            image.append('file', speakerImage?.file);
                    
                            try {
                                const imageUploaded = await uploadEventSpeakerImage(response?.speakerId, image, csrfTokenData);
                                if (!imageUploaded) {
                                    toast.error('Image upload failed.');
                                }
                            } catch (error) {
                                console.error('Error during image upload:', error);
                                toast.error('Image upload failed.');
                            }
                        }
                    
                        props?.setRefresh(true);
                        toast.success('Speaker created successfully');
                        handleDrawerClose();
                    } catch (error) {
                        console.error('Error creating speaker:', error);
                        toast.error((error as Error)?.message || 'Error creating speaker');
                    } finally {
                        setSpinner(false);
                    }
                } else if(existingSpeakerInfo) {
                    const newSpeakerInfo = {
                        ...payload,
                        additionalFieldData: customFormState ? { ...customFormState } : undefined,
                    };
    
                    try {
                        // Await the speaker creation API
                        await updateEventSpeaker(newSpeakerInfo, existingSpeakerInfo?.id);
    
                        // If there is an image, upload it
                        if (speakerImage?.file) {
                            const image = new FormData();
                            image.append('file', speakerImage?.file);
    
                            try {
                                const imageUploaded = await uploadEventSpeakerImage(existingSpeakerInfo?.id, image, csrfTokenData);
                                if (!imageUploaded) {
                                    toast.error('Image upload failed.');
                                }
                            } catch (error) {
                                toast.error('Image upload failed.');
                            }
                        }
    
                        props?.setRefresh(true);
                        toast.success('Speaker created successfully');
                        handleDrawerClose();
                    } catch (error) {
                        console.log(error);
                        toast.error((error as Error)?.message || 'Error creating speaker');
                    } finally {
                        setSpinner(false);
                    }
                }
            }
        }
    });

    const deleteEventSpeaker = async ():Promise<void> => 
    {
        try 
        {
            const speakerRemoved = await removeEventSpeaker(eventId as string, existingSpeakerInfo?.id as string);
            if (speakerRemoved) 
            {
                setShowDeletePopup(true);
                handleDrawerClose();
                props?.setRefresh(true);
                toast.success('Speaker removed from the event.')
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message || 'Error removing speaker');
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, id: string | number): void => 
    {
        const { name, value } = event.target;
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleDropDownInputChange = (name: string, value: string | number, id: string | number): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleBoxChange = (name: string, value: string | number, id: string | number): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleCheckboxChange = (item: any, data: CustomField): void => 
    {

        if(customFormState[data.label + ' ' + data.id])
        {
            const selectedIndex = customFormState[data.label + ' ' + data.id].indexOf(item.name);
            const updatedSelectedOptions = [...customFormState[data.label + ' ' + data.id]];

            if (selectedIndex === -1) 
            {
                updatedSelectedOptions.push(item.name);
            }
            else 
            {
                updatedSelectedOptions.splice(selectedIndex, 1);
            }

            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: updatedSelectedOptions,
                }; 
            });
        }
        else
        {
            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: [item.name],
                }; 
            });
        }
        
    };

    const fetchActivityLogs = async (): Promise<void> =>
    {
        try 
        {
            const activityLogs = await getAllSpeakersActivityLogs(props?.speakerData?.id);
            if(activityLogs)
            {
                setActivityLogs(activityLogs);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const handleBackToPopup = (): void =>
    {
        setShowActivityLog(false);
    }

    useEffect(():void => 
    {
        if (existingSpeakerInfo) 
        {
            setSpeakerImage({
                file: undefined,
                blob: existingSpeakerInfo.additionalInfo && existingSpeakerInfo.additionalInfo.image && existingSpeakerInfo.additionalInfo.image !== null ? existingSpeakerInfo.additionalInfo.image : ''
            })
            dispatch(addSpeakers({
                name: existingSpeakerInfo.name,
                email: existingSpeakerInfo.email,
                additionalInfo: {
                    headline: existingSpeakerInfo?.additionalInfo.headline,
                    bio: existingSpeakerInfo?.additionalInfo.bio ? existingSpeakerInfo?.additionalInfo.bio : '',
                    image: existingSpeakerInfo?.additionalInfo && existingSpeakerInfo?.additionalInfo.image && existingSpeakerInfo?.additionalInfo.image !== null ? existingSpeakerInfo?.additionalInfo.image : '',
                    social: existingSpeakerInfo?.additionalInfo?.social ? existingSpeakerInfo?.additionalInfo?.social : ''
                }
            }));

            fetchActivityLogs();
        }
        if (existingSpeakerInfo?.additionalInfo.image) 
        {
            getImageUrl(existingSpeakerInfo.additionalInfo.image);
        }
        if (existingSpeakerInfo?.additionalFieldData) 
        {
            setCustomFormState({
                ...existingSpeakerInfo?.additionalFieldData 
            });
        }

        fetchSpeakerCustomFieldData();

    }, [existingSpeakerInfo]);

    const renderFormFields = (): ReactElement => {
        return (
            <>
                {/* Image */}
                <Box className="popup-container-spacing">
                    <div className="image-activity-log-container">
                        <div>
                            <FormLabelComponent label='Select Photo' />
                            {
                                speakerImage?.blob !== '' ?
                                    <Box className="preview-upload-box"><Preview />
                                        {
                                            !props.addEventSpecificSpeaker ?
                                                <React.Fragment>
                                                    <Box className="icon-box">
                                                        <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={(): void => {
                                                            setSpeakerImage({
                                                                blob: '',
                                                                file: undefined
                                                            });
                                                        }} />
                                                    </Box>
                                                </React.Fragment> : null
                                        }
                                    </Box>
                                    :
                                    <React.Fragment>
                                        {
                                            !props.addEventSpecificSpeaker ?
                                                <React.Fragment>
                                                    <Box {...getRootProps()} className="empty-upload-box">
                                                        <input {...getInputProps()} hidden accept="image/*" type="file" />
                                                        <FontAwesomeIcon className="upload-icon" icon={['fal', 'cloud-arrow-up']} />
                                                        <Typography>{'Browse File'}</Typography>
                                                    </Box>
                                                </React.Fragment>
                                                :
                                                null
                                        }
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </Box>

                {/* Name */}
                <Box className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.NAME} required />
                    <FormControlComponent
                        type="text"
                        placeholder={CONTENT.SPEAKERS_PAGE.FORMS.PLACEHOLDER.NAME}
                        disabled={disabled || false}
                        value={formik.values.name}
                        onChange={(event): void => {
                            formik.setFieldValue('name', event.target.value);
                        }}
                        required
                        isInvalid={!!formik.errors.name && formik.touched.name}
                    />
                    {formik.errors.name && formik.touched.name ? <Typography className="error-msg">{formik.errors.name}</Typography> : null}
                </Box>

                {/* Email */}
                <Box className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.EMAIL} required />
                    <FormControlComponent
                        value={formik.values.email}
                        placeholder={CONTENT.SPEAKERS_PAGE.FORMS.PLACEHOLDER.EMAIL}
                        disabled={disabled || false}
                        required
                        type="email"
                        onChange={(event): void => {
                            formik.setFieldValue('email', event.target.value?.toLowerCase());
                        }}
                        isInvalid={!!formik.errors.email && formik.touched.email}
                    />
                    {formik.errors.email && formik.touched.email ? <Typography className="error-msg">{formik.errors.email}</Typography> : null}
                </Box>

                {/* Headline */}
                <Box className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.HEADLINE} required />
                    <FormControlComponent
                        value={formik.values.headline}
                        placeholder={CONTENT.SPEAKERS_PAGE.FORMS.PLACEHOLDER.HEADLINE}
                        disabled={disabled || false}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            formik.setFieldValue('headline', event.target.value);
                        }}
                        required
                        isInvalid={!!formik.errors.name && formik.touched.name}
                        type="text"
                    />
                    {formik.errors.headline && formik.touched.headline ? <Typography className="error-msg">{formik.errors.headline}</Typography> : null}
                </Box>

                {/* Bio */}
                <Box className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.BIO} />
                    <FormControlComponent
                        as="textarea"
                        type="text"
                        value={formik.values.bio}
                        rows={3}
                        placeholder='Bio'
                        onChange={(event): void => {
                            formik.setFieldValue('bio', event.target.value);
                        }}
                        disabled={disabled || false}
                    />
                    {formik.errors.bio && formik.touched.bio ? <Typography className="error-msg">{formik.errors.bio}</Typography> : null}
                </Box>

                {/* Social Links */}
                <Box className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.SOCIAL_LINK.LINKEDIN} />
                    <FormControlComponent
                        type="text"
                        value={formik.values.linkedIn}
                        placeholder='URL'
                        onChange={(event): void => {
                            formik.setFieldValue('linkedIn', event.target.value);
                        }}
                        disabled={disabled || false}
                        isInvalid={!!formik.errors.linkedIn && formik.touched.linkedIn}
                    />
                    {formik.errors.linkedIn && formik.touched.linkedIn ? <Typography className="error-msg">{formik.errors.linkedIn}</Typography> : null}
                </Box>

                <Box className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.SOCIAL_LINK.TWITTER} />
                    <FormControlComponent
                        value={formik.values.twitter}
                        placeholder='URL'
                        type="text"
                        disabled={disabled || false}
                        onChange={(event): void => {
                            formik.setFieldValue('twitter', event.target.value);
                        }}
                        isInvalid={!!formik.errors.twitter && formik.touched.twitter}
                    />
                    {formik.errors.twitter && formik.touched.twitter ? <Typography className="error-msg">{formik.errors.twitter}</Typography> : null}
                </Box>

                {/* Custom Fields */}
                {customFields.length > 0 &&
                    customFieldsSpinner ? <CustomSpinner height={'100%'} /> :
                    <Box className="custom-field-container">
                        {customFields.map((item: CustomField) => {
                            if (item.type === CustomFieldType.NUMBER) {
                                return renderInputComponent(item, 'number', handleInputChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.SINGLE_LINE) {
                                return renderInputComponent(item, 'text', handleInputChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.MULTI_LINE) {
                                return renderInputComponent(item, 'textarea', handleInputChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.CHECKBOXES) {
                                return renderCheckBoxComponent(item, handleCheckboxChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.DROPDOWN) {
                                return renderDropDownComponent(item, handleDropDownInputChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.RADIO) {
                                return renderRadioGroupComponent(item, handleBoxChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.DATE) {
                                return renderDateComponent(item, handleInputChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                        })
                        }
                    </Box>
                }
            </>
        )
    }

    const renderHeader = () => {

        if(disabled) {
            return <></>
        }

        return (
            <div className='popup-header'>
                <div className='popup-header-content'>
                    {showActivityLog ? (
                        <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} onClick={handleBackToPopup} />
                    ) : (
                        <div className='popup-header-icon'><FontAwesomeIcon icon={['fal', 'microphone']} /></div>
                    )}

                    <div className='popup-header-text'>
                        <h3>
                            {showActivityLog ? 'Activty' : heading}
                        </h3>
                        <p>
                            {showActivityLog ? existingSpeakerInfo?.name : ''}
                        </p>
                    </div>
                </div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => props.handlePopupClose()} />
            </div>
        );
    }

    const renderMainContent = () => {
        if(showActivityLog){
            return <ActivityLogComponent activityLog={speakersActivityLog(activityLogs, existingSpeakerInfo as Speaker)} />
        }else {
            return renderFormFields();
        }
    }

    const renderFooter = () => {
        if (disabled || showActivityLog) {
            return <></>
        }

        return (
            <div className="popup-footer">
                {!props?.speakerData ?

                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        <div className='popup-footer-left'>
                            {
                                props?.speakerData &&
                                <CustomButton
                                    name=""
                                    btnType="secondary"
                                    startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                                    style={{ width: '32px' }}
                                    onClick={handleNestedSidebarOpen}
                                />
                            }
                        </div>
                        <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-start'}>
                            <CustomButton onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} btnType='secondary' type='button' />
                            <CustomButton type="submit" loading={spinner} name={CONTENT.SPEAKERS_PAGE.FORMS.BUTTON.SUBMIT_BTN} btnType='primary' />
                        </Stack>
                    </Stack>
                    : 
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                         <div className='popup-footer-left'>
                            {eventId && <FontAwesomeIcon className="popup-delete-icon" icon={['fal', 'trash']} onClick={() => setShowDeletePopup(true)} />}
                            {
                                props?.speakerData && !props?.isTableView &&
                                <CustomButton
                                    name=""
                                    btnType="secondary"
                                    startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                                    style={{ width: '32px' }}
                                    onClick={handleNestedSidebarOpen}
                                />
                            }
                        </div>
                        <Stack direction={'row'} spacing={1} display={'flex'}>
                            <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                            <CustomButton btnType='primary' loading={spinner} name={'Save'} type='submit' />
                        </Stack>
                    </Stack>
                }
            </div>
        )

    }

    return (
        <Box id="createSpeaker">
            {heading && renderHeader()}

            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">

                <div className={props?.isTableView ? 'speaker-form-drawer' : 'popup-container'}>
                   {renderMainContent()}
                </div>
            
                {renderFooter()}

            </Form>

            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={deleteEventSpeaker}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                    }}
                    modalContent={`Are you sure you want to delete ${existingSpeakerInfo?.name}?`}
                    modalTitle='Delete Speaker'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='trash'
                />
            }
        </Box>
    );
};

export default CreateSpeaker;