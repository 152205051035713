import React, { useContext, useState } from "react";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import './styles.scss';
import { EventStageContext } from "../../../../contexts/EventStage/EventStageContext";
import _ from "lodash";
import AddStage from "../../../../components/Events/Agenda/AddStage";
import StageAccordian from "./StageAccordian";
import StageDateTabs from "./StageDateTabs";
import { UserContext } from "../../../../contexts/UserContext";
import { UserViewType } from "../../../Settings/enum/users.enum";
import { MinimalViewComponent } from "../../../../common/MinimalView/MinimalViewComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EventsHeaderBar from "../../../../common/EventsHeaderBar";
import PopupDialog from "../../../../common/PopupDialog";

const StagePage = () => 
{ 
    const { daysArr, selectedDate, setSelectedDate, stageData, eventId, setRefresh, handleInScreenPopupClose } = useContext(EventStageContext);

    const {
        userDetails
    } = useContext(UserContext);
    const [showStagePopup, setShowStagePopup] = useState(false);

    const handlePopupClose = (): void =>
    {
        setShowStagePopup(false);
    }

    const openStageSideDrawer = (): void =>
    {   
        setShowStagePopup(true);
    };

    const renderNormalViewForAgenda = (): React.JSX.Element => { 
        return (
            <div id="stagePage">
                <EventsHeaderBar 
                    title={'Agenda'}
                    // buttons={[
                    //     <CustomButton key={1} btnType='secondary' name='Add Stage' type='button' onClick={openStageSideDrawer}/>
                    // ]}
                />

                <div className="tab-wrapper">
                    <div className="stage-date-tabs-container">
                        <StageDateTabs dates={daysArr} selectedDate={selectedDate} handleDateChange={(date) => {
                            handleInScreenPopupClose();
                            setSelectedDate(date);
                        }} />
                    </div>
                    <CustomButton key={2} name='' startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />} btnType='primary' onClick={() => openStageSideDrawer()} />
                </div>

                <StageAccordian setRefresh={setRefresh} eventId={eventId} stages={stageData} selectedDate={selectedDate} />

            </div>
        );
    };

    const renderStagesAsAccordian = (): React.JSX.Element => { 
        return (
            <div id="stageAccordianMinimal">
                <div className="tabs-actions-container">
                    <StageDateTabs dates={daysArr} selectedDate={selectedDate} handleDateChange={(date) => {
                            handleInScreenPopupClose();
                            setSelectedDate(date);
                        }} />
                    <CustomButton key={2} name='' startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />} btnType='primary' onClick={() => openStageSideDrawer()} />
                </div>
                <StageAccordian minimalView={true} setRefresh={setRefresh} eventId={eventId} stages={stageData} selectedDate={selectedDate} />
            </div>
        )
    };

    const renderMinimalViewForAgenda = (): React.JSX.Element => { 
        return (
            <div id="stagePageMinimalView">
                <MinimalViewComponent 
                    title={<EventsHeaderBar title={'Agenda'} showBorder={true} />}
                    titleBarStyle={{ padding: '0' }}
                    description="Manage your Stage and Sessions here."
                    component={renderStagesAsAccordian()}
                    componentType='list'
                />
            </div>
        );
    };
    
    return(
        <>
            {
                userDetails?.viewType === UserViewType.NORMAL ? renderNormalViewForAgenda() : renderMinimalViewForAgenda()
            }
            {
                showStagePopup &&
                <PopupDialog
                    modalId={'stagePopup'}
                    show={showStagePopup}
                    cancelClick={handlePopupClose}
                    modalContent={<AddStage eventId={eventId} setRefresh={setRefresh} selectedDate={selectedDate} heading={'Add Stage'} handlePopupClose={handlePopupClose} description={'Quickly set up conference stages.'} />}
                />
            }
        </>
    )
};  

export default StagePage;