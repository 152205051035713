import React, { useContext, useState } from 'react';
import { IconButton, Popover } from '@mui/material';
import { deleteEventBudget, updateEventBudget } from '../../../scripts/apis/eventBudget';
import { useDispatch } from 'react-redux';
import { refreshBudgetDetails } from '../../../redux/events/eventBudget/EventBudget';
import APP_CONSTANTS from '../../../scripts/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import eventBus from '../../../scripts/event-bus';
import { CONTENT } from '../../../scripts/i18n';
import MuiChip from '../../../common/FormComponents/MuiChip';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { EventBudget } from '../interfaces';
import { Row } from 'react-table';
import toast from 'react-hot-toast';
import TableEmptyComponent from '../../../common/TableEmptyComponent';
import TanstackToolbarWithServerFilter from '../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { EventBudgetAnalysis, EventBudgetTags } from '../enum';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { UserViewType } from '../../Settings/enum/users.enum';
import { MinimalViewComponent } from '../../../common/MinimalView/MinimalViewComponent';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import ColumnVisibilityDropdown from '../../../common/TanstackTable/ColumnVisibilityDropdown';
import EventsHeaderBar from '../../../common/EventsHeaderBar';

interface IEventBudgetDataGrid {
    rows: Row[];
    budgetDetails: object;
    openDrawer: (props: { createComp: boolean; updateComp?: boolean; budgetDetails?: object; parentBudgetData?: boolean; subBudgetData?: boolean; parentBudgetValue?: object; }) => void;
    eventId: string | number;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
    rowCount: number;
}

interface IDeleteObject {
    acceptObj: { 
        subBudgetId: string | number; 
        budgetDetails: EventBudget; 
        budgetId: number; 
        eventId: string | number 
    };
}

const tags = {
    1: 'Marketing',
    2: 'Sales',
    3: 'Design',
    4: 'Booth',
    5: 'Vendor',
};

const TagsColor = (value: number): 'green' | 'blue' | 'pink' | 'red' | 'yellow'|'null' => 
{
    switch (value) 
    {
    case 1:
        return 'green';
    case 2:
        return 'blue';
    case 3:
        return 'pink';
    case 4:
        return 'red';
    case 5:
        return 'yellow';
    default:
        return 'null';
    }
};

const EventBudgetDataGrid = ({ rows, openDrawer, eventId, setCurrentUrl, rowCount }: IEventBudgetDataGrid): React.JSX.Element => 
{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const {
        userDetails
    } = useContext(UserContext);

    const deleteEventBudgetData = async (eventId: number, budgetId: number): Promise<void> => 
    {
        try 
        {
            const data = await deleteEventBudget(
                eventId, budgetId
            );
            if (data) 
            {
                dispatch(refreshBudgetDetails({
                    refreshBudget: true
                }));
                toast.success('Budget Deleted');
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const deleteSubBudgetData = async (budgetDetails: EventBudget, budgetId: number, subBudgetId: number): Promise<void> => 
    {

        if(budgetDetails?.subBudget && budgetDetails?.['subBudget']?.length > 0)
        {
            const newSubBudget =  budgetDetails['subBudget'].filter((item, index):any => 
            {
                if(index !== subBudgetId)
                {
                    return item;
                }
            } );

            const newPayload = {
                ...budgetDetails,
                ['subBudget']: newSubBudget?.length > 0 ? newSubBudget : null
            };

            try 
            {
                const data = await updateEventBudget(
                    Number(budgetDetails['eventId']),
                    Number(budgetId),
                    newPayload
                );
                if (data) 
                {
                    dispatch(refreshBudgetDetails({
                        refreshBudget: true
                    }));
                    toast.success('Sub Budget Deleted');
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const deleteEventBudgetfromTable = (): void => 
    {
        eventBus.on('delete-event-budget', async (object: IDeleteObject): Promise<void> => 
        {
            const acceptObj = object.acceptObj;
            let deleteSuccess = true;

            if (acceptObj.subBudgetId && acceptObj.subBudgetId !== 'undefined') 
            {
                if (deleteSuccess) 
                {
                    await deleteSubBudgetData(acceptObj?.budgetDetails, acceptObj.budgetId, Number(acceptObj.subBudgetId));
                    deleteSuccess = false;
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.CLOSE_POPUP_EVENT, true);
                }
            }
            else 
            {
                if (deleteSuccess) 
                {
                    await deleteEventBudgetData(Number(acceptObj.eventId), acceptObj.budgetId);
                    deleteSuccess = false;
                }
            }

        });

    };

    const handleEditClick = (row: CellContext<EventBudget, any>): void => 
    {
        openDrawer({
            updateComp: true,
            budgetDetails: row.row?.original,
            parentBudgetData: row.row?.depth == 0,
            subBudgetData: row.row?.depth > 0,
            parentBudgetValue: {
                parentActualBudget: row.row?.getParentRow()?.original?.budget,
                parentBudgetDetails: row.row?.getParentRow()?.original,
                subBudgets: row.row?.getParentRow()?.subRows,
                currentSubBudgetId: row.row?.depth > 0 ? row.row?.getParentRow()?.subRows?.findIndex(subRow => subRow.id == row.row.id) : undefined,
            },
            createComp: false,
        })
        
    };

    const actions = (row: CellContext<EventBudget, any>): React.JSX.Element => 
    {
        const handleDeleteClick = (): void => 
        {
            const budgetName = row.row?.original?.name;
            eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
                open: true,
                title: CONTENT.EVENTS_PAGE.DELETE_POPUP.TITLE,
                content: 'Are you sure you want to delete the budget ' + budgetName + '?',
                acceptBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.ACCEPT_BTN,
                acceptEvent: 'delete-event-budget',
                rejectBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.CANCEL_BTN,
                acceptObj: {
                    budgetId: row.row?.depth > 0 ? row.row.getParentRow()?.original?.id : row.row?.original?.id,
                    eventId,
                    budgetDetails: row.row?.getParentRow()?.original,
                    subBudgetId: String(row.row?.depth > 0 ? row.row?.getParentRow()?.subRows?.findIndex(subRow => subRow.id == row.row.id) : undefined)
                },
            });

            deleteEventBudgetfromTable();

        };
        
        return <ActionsDropdown onDeleteClick={handleDeleteClick} onEditClick={() => handleEditClick(row)} />;

    };

    // useEffect((): void => 
    // {
    //     eventBus.on('selected-row-id', (rowId): void => 
    //     {
    //         if (rowId) 
    //         {
    //             setSelectionModel([rowId]);
    //         }
    //         else 
    //         {
    //             setSelectionModel([]);
    //         }
    //     });
    //     if (rows?.length > 0) 
    //     {
    //         const updatedRowsId = rows.map((item): Row => 
    //         {
      
    //             const regex = /^-\d+$/;
    //             if (item.itemName) 
    //             {
    //                 const itemNameArray = item.itemName.split(' ');
    //                 const removeUnwantedChar = itemNameArray.map((word): string => 
    //                 {
    //                     if (!regex.test(word)) 
    //                     {
    //                         return word;
    //                     }
    //                 });
    //                 if (removeUnwantedChar.length == 1) 
    //                 {
    //                     item.itemName = removeUnwantedChar[0];
    //                 }
    //                 else 
    //                 {
    //                     let validStr = '';
    //                     for (let i = 0; i < removeUnwantedChar.length; i++) 
    //                     {
    //                         if (i !== (removeUnwantedChar.length - 1)) 
    //                         {
    //                             if (removeUnwantedChar[i] !== undefined) 
    //                             {
    //                                 validStr = validStr + removeUnwantedChar[i] + ' ';
    //                             }

    //                         }
    //                         else 
    //                         {
    //                             if (removeUnwantedChar[i] !== undefined) 
    //                             {
    //                                 validStr = validStr + removeUnwantedChar[i];
    //                             }
    //                         }
    //                     }
    //                     item.itemName = validStr;
    //                 }
    //             }
    //             return item;
    //         });

    //         setUpdatedRows(updatedRowsId);
    //     }
    // }, [rows]);

    const columnHelper = createColumnHelper<EventBudget>();

    const budgetColumns = [
        columnHelper.accessor("name", {
            header: "Category",
            id: "categoryName",
            cell: (info) => {
                return (
                    <>
                        {info.row.depth == 0 && 
                            <div style={{ display: 'flex', alignItems: 'center', gap: '14px' }}>
                                {info?.row?.subRows?.length > 0 && info.row.getCanExpand() && (
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        info.row.getToggleExpandedHandler()();
                                    }}>
                                        {info.row.getIsExpanded() ? <FontAwesomeIcon style={{ height: '14px', width: '14px' }} icon={['fal', 'chevron-down']} /> : <FontAwesomeIcon style={{ height: '14px', width: '14px' }} icon={['fal', 'chevron-right']} />}
                                    </div>
                                )}
                                <p style={{
                                    paddingLeft: info?.row?.subRows?.length > 0 ? '' : '28px'
                                }} className="bolded-cellContent">{`${info.row.original.name}`}{info?.row?.subRows?.length > 0 ? <MuiChip rounded chipColor="grey" label={info?.row?.subRows?.length} /> : ''}</p> 
                                {/* '(' + info?.row?.subRows?.length + ')' */}
                            </div>
                        }
                    </>
                );
            },
            size: 300,
            meta: {
                isExpander: true
            }
        }),
        columnHelper.accessor("tags", {
            header: "Tags",
            id: "tags",
            cell: (info) => {
                return (
                    tags[info.getValue()] && <MuiChip circleIcon label={tags[info.getValue()]} chipColor={TagsColor(info.getValue())}/>
                );
            }
        }),
        columnHelper.accessor("subBudget.name", {
            header: "Item Name",
            id: "itemName",
            cell: (info) => {
                return (info.row.depth > 0) ? (
                    <div className="cellContent">{info.row.original.name}</div>
                ) : null;
            }
        }),
        columnHelper.accessor("budget", {
            header: "Budget",
            id: "budget",
            cell: (info) => {
                return (
                    <div className="cellContent">{`$${info.getValue()}`}</div>
                );
            }
        }),
        columnHelper.accessor("actualSpend", {
            header: "Actual Spending",
            id: "actualSpending",
            cell: (info) => {
                return (
                    <div className="cellContent">{`$${info.getValue()}`}</div>
                );
            }
        }),
        columnHelper.accessor("analysis" as any, {
            header: "Analysis",
            id: "analysis",
            cell: (info) => {

                let label = '';
                let chipcolor = '';
                if (Number(info.row.original.budget) > Number(info.row.original.actualSpend)) 
                {
                    label = 'Underspend';
                    chipcolor = 'green';
                }
                else if (Number(info.row.original.budget) < Number(info.row.original.actualSpend)) 
                {
                    label = 'Overspend';
                    chipcolor = 'red';
                }
                else 
                {
                    label = 'In Budget';
                    chipcolor = 'yellow';
                }

                return (
                    <MuiChip circleIcon label={label} chipColor={chipcolor}/>
                );
            }
        }),
        columnHelper.accessor("actions" as any, {
            header: "",
            id: "actions",
            cell: (info) => {
                return actions(info)
            },
            size: 40, 
            maxSize: 40,
            enableResizing: false,
            enableSorting: false,
        })
    ];
    const [allColumns, setAllColumns] = useState(budgetColumns);

    const filterColumns = [
        {
            header: 'Category',
            id: 'categoryName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Tags',
            id: 'tags',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    {
                        name: 'Marketing', value: EventBudgetTags.MARKETING,
                    },
                    {
                        name: 'Sales', value: EventBudgetTags.SALES,
                    },
                    {
                        name: 'Design', value: EventBudgetTags.DESIGN,
                    },
                    {
                        name: 'Booth', value: EventBudgetTags.BOOTHS,
                    },
                    {
                        name: 'Vendor', value: EventBudgetTags.VENDORS,
                    },
                ]
            }
        },
        {
            header: 'Analysis',
            id: 'analysis',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    { 
                        name: 'Underspend', value: EventBudgetAnalysis.UNDER_SPEND 
                    },
                    { 
                        name: 'Overspend', value: EventBudgetAnalysis.OVER_SPEND
                    },
                    { 
                        name: 'In Budget', value: EventBudgetAnalysis.IN_BUDGET
                    },
                ]
            }
        },
        {
            header: 'Budget',
            id: 'budget',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Actual Spend',
            id: 'actualSpend',
            meta: {
                type: 'string'
            }
        },
    ];

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const renderNormalViewForEventBudget = (): React.JSX.Element => { 
        return (
            <div className="event-budget-table-container">
                {/* <DataGridPro
                    treeData
                    rows={rows?.length > 0 ? updatedRows : dummyrow}
                    columns={columns}
                    getTreeDataPath={(row): string[] => 
                    {
                        const rowPath = getTreeDataPath(row); return rowPath;
                    }}
                    groupingColDef={groupingColDef}
                    className="event-budget-table"
                    onCellClick={(params): void => 
                    {
                        if (!APP_CONSTANTS.DATA_GRID_NON_CLICKABLE_COLUMNS.includes(params.field) && !params.isEditable) 
                        {
                            if (params.row.onClick) 
                            {
                                params.row.onClick();
                            }
                        }
                    }}
                    paginationMode="server"
                    rowSelectionModel={selectionModel}
                    // processRowUpdate={processRowUpdate}
                    // onProcessRowUpdateError={handleProcessRowUpdateError}
                    onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel): void => 
                    {
                        return setSelectionModel(rowSelectionModel);
                    }
                    }
                    sx={{
                        height: '100%'
                    }}
                    pagination={true}
                    rowCount={rowCount}
                    paginationModel={{
                        pageSize: pageSize, page: page
                    }}
                    onPaginationModelChange={(paginationModel): void => 
                    {
                        updateCurrentPage(paginationModel.page + 1);
                    }}
                /> */}
                {
                    Number(rowCount) > 0 ? (
                        <div style={{ height: 'calc(100vh - 180px)' }}>
                            <TanstackToolbarWithServerFilter 
                                setCurrentUrl={setCurrentUrl}
                                setColumns={setAllColumns}
                                columns={budgetColumns}
                                tabs={[{
                                    tabName: 'Budget',
                                    count: rowCount,
                                    navigation: () => {},
                                    selectedTab: true,
                                }]}
                                filterColumns={filterColumns}
                                handleApplyFilters={(filters) => handleApplyFilters(filters)}
                            />
                            <TanstackTable 
                                data={rows}
                                initialColumns={allColumns}
                                showSpinner={false}
                                rowCount={rows.length}
                                subRowKey="subBudget"
                                hidePagination
                                onRowClick={(row) => handleEditClick(row)}
                                rightPinnedColumns={['actions']}
                                showBottomPadding={false}
                            />
                        </div>
                    ) : (
                        <TableEmptyComponent 
                            infoText={'No Budget Data'}
                        />
                    )
                }
                
            </div>
        );
    };

    const renderMinimalViewForEventBudget = (): React.JSX.Element => { 
        return (
            <div className="event-budget-table-container">
                <MinimalViewComponent
                    title={<EventsHeaderBar title="Budget" showBorder />}
                    titleBarStyle={{ padding: '0' }}
                    description="Manage your Budgets here."
                    tabData={[
                        {
                            tabName: 'All',
                            count: Number(rowCount),
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        <TanstackToolbarWithServerFilter
                            key={1}
                            columns={budgetColumns}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        <ColumnVisibilityDropdown 
                            key={2}
                            columns={budgetColumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomButton
                            key={1}
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => openDrawer({ createComp: true })}
                        />
                    ]}
                    component={
                        <div className="minimalView-table-container">
                            {
                                Number(rowCount) == 0 ?
                                    <div style={{ height: 'calc(100vh - 280px)' }}>
                                        <TableEmptyComponent
                                            emptyImg={''}
                                            openDrawer={openDrawer}
                                            infoText={'No Budgets'}
                                            subInfoText={'Create your first Budget'}
                                        />
                                    </div>
                                    :
                                    <TanstackTable
                                        data={rows}
                                        initialColumns={allColumns}
                                        showSpinner={false}
                                        rowCount={rows.length}
                                        subRowKey="subBudget"
                                        hidePagination
                                        onRowClick={(row) => handleEditClick(row)}
                                        rightPinnedColumns={['actions']}
                                        // height='calc(100vh - 280px)'
                                        showBottomPadding={false}
                                    />
                            }
                        </div>
                    }
                    componentType='table'
                />
            </div>
        );
    };

    return (
        <>
            {
                userDetails?.viewType === UserViewType.NORMAL ? renderNormalViewForEventBudget() : renderMinimalViewForEventBudget()
            }
        </>
    );
};

export const ActionsDropdown: React.FC<{onDeleteClick: () => void, onEditClick: () => void,  extraActions?: any}> = ({ onDeleteClick, onEditClick, extraActions }): React.JSX.Element => 
{
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenDropdown = (event): void => 
    {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleCloseDropdown = (event): void => 
    {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const handleDelete = (event): void => 
    {
        onDeleteClick();
        handleCloseDropdown(event);
        event.stopPropagation();

    };
    const handleEdit = (event): void => 
    {
        onEditClick();
        handleCloseDropdown(event);
        event.stopPropagation();
    };

    return (
        <div>
            <IconButton className="actions-column-button" onClick={handleOpenDropdown}>
                <FontAwesomeIcon icon={['fal', 'ellipsis-vertical']} />
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseDropdown}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div id="dropDownBlock">
                    <div onClick={(event) => {handleEdit(event)}} className="dropDownMenu">
                        Edit
                    </div>
                    <div onClick={(event) => {handleDelete(event)}} className="dropDownMenuDelete">
                        Delete
                    </div>
                    {
                        extraActions && extraActions.map((action, index): React.JSX.Element => 
                        {
                            return (
                                <div key={index} onClick={(event) => {action.onClick(); event.stopPropagation(); handleCloseDropdown(event)}} className="dropDownMenu">
                                    {action.name}
                                </div>
                            );
                        })
                    }
                </div>
            </Popover>
        </div>
    );
};

export default EventBudgetDataGrid;

export const uniqueId = (): string => 
{
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
};


