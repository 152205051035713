import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import { deleteEvent, eventStatusCount, eventsCount, getEvents, getEventsGraph } from '../scripts/apis/events';
import { useDispatch } from 'react-redux';
import { addEventData, refreshEventsData } from '../redux/events/EventsData';
import { useTablePagination } from './TablePaginationContext';
import _ from 'lodash';
import { Event, EventGraph, EventGraphObject, EventsGraph } from '../pages/Events/interfaces';
import toast from 'react-hot-toast';
import { Integrations, IntegrationsState } from '../pages/Settings/interface/integration_interface';
import { UserContext } from './UserContext';
import { UserViewType } from '../pages/Settings/enum/users.enum';
import { getEncodedParams } from '../scripts/helpers';
import { useSelector } from 'react-redux';
import { IBillingInfo } from '../redux/billing/billing-redux_interface';
import { EventHQEventLimit, ProductType } from '../enums/billing.enum';

interface IEventsChartReady {
    eventsTypeGraphReady?: boolean;
    eventsStatusGraphReady?: boolean;
    eventsLocationGraphReady?: boolean;
}

interface IEventsPageContext {
    isEmpty: boolean;
    setIsEmpty: (isEmpty: boolean) => void;
    setEventDataCount: (count: number) => void;
    setRows: (rows: any) => void;
    setEvents: (events: any) => void;
    rows: any;
    eventDataCount: number;
    eventTotalDataCount: number,
    events: any;
    deleteEventFromEventsTable: (eventId: string | number) => void;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    pageSize: number;
    updateCurrentPage: (page: number) => void;
    updatePageSize: (size: number) => void;
    showSpinner: boolean;
    isChartReady: IEventsChartReady;
    eventsTypeData: any;
    eventStatusData: any;
    eventLocationData: any;
    eventsTrendData: any;
    statusCounts: EventGraphObject | undefined;
    // editEventsFromTable: (eventData: any) => void;
    currentPage: number;
    integrations: any;
    showDeletePopup: boolean;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    currentUrl: string;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
    shouldShowCharts: () => boolean;
    shouldShowCreateEventButton: () => boolean;
}

export const eventLocationOptions = [
    {
        name: 'In Person', value: 1, id: 1,
    },
    {
        name: 'Virtual', value: 2, id: 2,
    },
    {
        name: 'Hybrid', value: 3, id: 3,
    },
];

export const eventTypeOptions = [
    {
        name: 'Host', value: 1, id: 1,
    },
    {
        name: 'Sponsored', value: 3, id: 3,
    },
    {
        name: 'Speaker', value: 4, id: 4,
    },
    {
        name: 'Attendee', value: 5, id: 5,
    }
];

export const EventsPageContext = createContext<IEventsPageContext>({
    isEmpty: false,
    setIsEmpty: () => {},
    setEventDataCount: () => {},
    setRows: () => {},
    setEvents: () => {},
    rows: [],
    eventDataCount: 0,
    eventTotalDataCount: 0,
    events: [],
    deleteEventFromEventsTable: () => {},
    setRefresh: () => {},
    pageSize: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    isChartReady: {
        eventsTypeGraphReady: false,
        eventsStatusGraphReady: false,
        eventsLocationGraphReady: false
    },
    eventsTypeData: [],
    eventStatusData: [],
    eventLocationData: [],
    eventsTrendData: {},
    statusCounts: undefined,
    currentPage: 0,
    integrations: [],
    showDeletePopup: false,
    setShowDeletePopup: () => {},
    currentUrl: '',
    setCurrentUrl: () => {},
    shouldShowCharts: () => false,
    shouldShowCreateEventButton: () => false
});

const EventsPageProvider: React.FC<{children: React.ReactNode}> = ({ children }): React.JSX.Element =>
{

    const {
        userDetails
    } = useContext(UserContext);

    const integrations = useSelector((state: IntegrationsState): Integrations[] =>
    {
        return state.integrations.value;
    });

    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [events, setEvents] = useState<Event[]>([]);
    const [eventDataCount, setEventDataCount] = useState<number>(0);
    const [eventTotalDataCount, setEventTotalDataCount] = useState<number>(0);
    const [rows, setRows] = useState<Event[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [isChartReady, setChartReady] = useState<{
        eventsTypeGraphReady?: boolean,
        eventsStatusGraphReady?: boolean,
        eventsLocationGraphReady?: boolean
    }>({
        eventsTypeGraphReady: false,
        eventsStatusGraphReady: false,
        eventsLocationGraphReady: false
    });
    const [eventsTypeData, setEventsTypeData] = useState<EventsGraph[]>([]);
    const [eventStatusData, setEventsStatusData] = useState<EventsGraph[]>([]);
    const [eventLocationData, setEventsLocationData] = useState<EventsGraph[]>([]);
    const [eventsTrendData, setEventTrendData] = useState({
        count: 0,
        avgRoi: 0,
        pipeline: 0,
        revenue: 0
    });
    const [statusCounts, setEventStatusCount] = useState<EventGraphObject>();
    // const [integrations, setIntegrations] = useState<Integrations[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [isEventDataCountUpdated, setIsEventDataCountUpdated] = useState<boolean>(false);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const shouldShowCreateEventButton = (): boolean => {
        const { productType, eventhq } = orgBillingInfo || {};
        const isEventHQ = productType === ProductType.EVENTHQ;
        const isUnderLimit = eventhq === EventHQEventLimit.THREE && Number(eventTotalDataCount) < 3;
        const isIntelligenceWithoutLimit = productType === ProductType.INTELLIGENCE && (!eventhq || eventhq !== EventHQEventLimit.THREE);

        return isEventHQ || isUnderLimit || isIntelligenceWithoutLimit;
    };

    const shouldShowCharts = (): boolean => {
        const { productType, eventhq } = orgBillingInfo || {};
        const isEventHQ = productType === ProductType.EVENTHQ;
        const isIntelligenceWithoutLimit = productType === ProductType.INTELLIGENCE && (eventhq && eventhq !== EventHQEventLimit.THREE);
        return isEventHQ || isIntelligenceWithoutLimit || false;
    };

    const fetchCounts = async (): Promise<void> => {
        try {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await eventsCount(encodedParams);
            const statusCount = await eventStatusCount();
            if (statusCount) {
                setEventStatusCount(statusCount);
            }
            if (count) {
                setEventDataCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
                setIsEventDataCountUpdated(true);
            }
            if (!eventTotalDataCount) {
                setEventTotalDataCount(count);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchData = async (): Promise<void> => {
        setShowSpinner(true);
        try {
            const encodedParams = getEncodedParams(currentUrl);
            if (userDetails?.viewType === UserViewType.MINIMAL && eventDataCount) {
                const eventsData = await getEvents(eventDataCount, 0, ...encodedParams);
                if (eventsData) {
                    setEvents([...eventsData]);
                    dispatch(refreshEventsData([]));
                    dispatch(addEventData(eventsData));
                    setRows([...eventsData]);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            } else {
                const eventsData = await getEvents(pageSize, currentPage - 1, ...encodedParams);
                if (eventsData) {
                    setEvents([...eventsData]);
                    dispatch(refreshEventsData([]));
                    dispatch(addEventData(eventsData));
                    setRows([...eventsData]);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowSpinner(false);
        }
    };

    // const fetchIntegrations = async (): Promise<void> => {
    //     try {
    //         const integrationsData = await getIntegrationsData();
    //         if (integrationsData) {
    //             setIntegrations(integrationsData);
    //         }
    //     } catch (error) {
    //         console.log('Error fetching integrations', error);
    //     }
    // };

    const fetchGraphData = async (): Promise<void> => {
        try {
            const graphData: EventGraph = await getEventsGraph();
            if (graphData) {
                if (graphData?.type) {
                    const eventTypesum: number = _.sumBy(_.values(graphData.type));
                    const eventType: EventsGraph[] = Object.entries(graphData.type)
                        .filter(([, percentage]): boolean => {
                            return percentage !== 0;
                        })
                        .map(([name, percentage]): { name: string; percentage: number; value?: string; } => {
                            return {
                                name: _.capitalize(name),
                                percentage: Math.round((Number(percentage) / eventTypesum) * 100),
                                value: String(percentage)
                            };
                        });

                    setEventsTypeData(eventType);
                }
                if (graphData?.status) {
                    const eventStatusSum: number = _.sumBy(_.values(graphData.status));
                    const eventStatus: EventsGraph[] = Object.entries(graphData.status)
                        .filter(([, percentage]): boolean => {
                            return Number(percentage) !== 0;
                        })
                        .map(([name, percentage]): { name: string; percentage: number; value: string; } => {
                            return {
                                name: _.capitalize(name),
                                percentage: Math.round((Number(percentage) / eventStatusSum) * 100),
                                value: String(percentage)
                            };
                        });

                    setEventsStatusData(eventStatus);
                }
                if (graphData?.location) {
                    const eventLocationSum: number = _.sumBy(_.values(graphData.location));
                    const eventLocation: EventsGraph[] = Object.entries(graphData.location)
                        .filter(([, percentage]): boolean => {
                            return percentage !== 0;
                        })
                        .map(([name, percentage]): { name: string; percentage: number; value: string; } => {
                            return {
                                name: name === 'ONSITE' ? 'In Person' : _.capitalize(name),
                                percentage: Math.round((Number(percentage) / eventLocationSum) * 100),
                                value: String(percentage)
                            };
                        });

                    setEventsLocationData(eventLocation);
                }
                if (graphData?.trend) {
                    setEventTrendData(graphData.trend);
                }
                setChartReady((prevState): IEventsChartReady => {
                    return {
                        ...prevState, eventsTypeGraphReady: true, eventsStatusGraphReady: true, eventsLocationGraphReady: true
                    };
                });

            } else {
                setChartReady((prevState): IEventsChartReady => {
                    return {
                        ...prevState, eventsTypeGraphReady: true, eventsStatusGraphReady: true, eventsLocationGraphReady: true
                    };
                });
            }
        } catch (error) {
            console.log(error);
            setChartReady((prevState): IEventsChartReady => {
                return {
                    ...prevState, eventsTypeGraphReady: true, eventsStatusGraphReady: true, eventsLocationGraphReady: true
                };
            });
        }
    };

    useEffect(() => {
        fetchCounts();

        if(shouldShowCharts()) {
            fetchGraphData();
        }
    }, []);

    useEffect((): void => {
        fetchCounts();
    }, [currentPage, pageSize, currentUrl]);

    useEffect((): void => {
        if (isEventDataCountUpdated) {
            fetchData();
            setIsEventDataCountUpdated(false);
        }
    }, [eventDataCount]);

    useEffect((): void => {
        if (refresh) {
            fetchCounts();
            fetchData();
            if(shouldShowCharts()) {
                fetchGraphData();
            }
        }
    }, [refresh]);

    const deleteEventFromEventsTable = async (eventId: string | number): Promise<void> => {
        try {
            const eventDeleted = await deleteEvent(eventId);
            if (eventDeleted) {
                setShowDeletePopup(false);
                setRefresh(true);
                toast.success('Event deleted successfully');
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <EventsPageContext.Provider 
            value={{
                isEmpty, 
                setIsEmpty, 
                setEventDataCount, 
                setRows, 
                setEvents, 
                rows, 
                eventDataCount, 
                eventTotalDataCount,
                events, 
                deleteEventFromEventsTable, 
                setRefresh, 
                pageSize, 
                updateCurrentPage, 
                updatePageSize, 
                showSpinner, 
                isChartReady, 
                eventsTypeData, 
                eventStatusData, 
                eventLocationData, 
                eventsTrendData, 
                statusCounts, 
                // editEventsFromTable, 
                currentPage, 
                integrations,
                showDeletePopup,
                setShowDeletePopup,
                currentUrl,
                setCurrentUrl,
                shouldShowCharts,
                shouldShowCreateEventButton
            }}
        >
            {children}
        </EventsPageContext.Provider>
    );
};

export default EventsPageProvider;