import { createContext, useEffect, useState } from 'react';
import { BillingDetails } from '../../interfaces/settings/billing-details_interface';
import { EventHQEventLimit, IntelligenceLimit, ProductType } from '../../enums/billing.enum';
import { Navigate, Route } from 'react-router-dom';
import UserProvider from '../UserContext';
import ProtectedRoute from '../../layouts/ProtectedRoute';
import DashboardPage from '../../pages/Dashboard/DashboardPage';
import EventsPageProvider from '../EventsPageContext';
import EventsPage from '../../pages/Events/EventsPage';
import EventsInfo from '../../pages/Events/EventsInfo';
import RegistrationPageProvider from '../RegistrationsContext';
import RegistrationsPage from '../../pages/Registration/RegistrationsPage/RegistrationsPage';
import FormComponentsProvider from '../FormComponentsContext';
import Registrations from '../../pages/Registration/Registrations';
import BudgetsPage from '../../pages/Budget/BudgetsPage';
import AnalyticsPage from '../../pages/Analytics/AnalyticsPage';
import { CONTENT } from '../../scripts/i18n';
import SettingsPage from '../../pages/Settings/SettingsPage';
import AudiencePage from '../../pages/Audience/AudiencePage';
import CompetitorPage from '../../intelligence/pages/Competitor/CompetitorPage';
import ExplorePage from '../../intelligence/pages/Explore/ExplorePage';
import { LocalStorage } from '../../scripts/LocalStorage';
import { orgBillingDetails } from '../../scripts/apis/billing';
import { useDispatch } from 'react-redux';
import { billingDetails } from '../../redux/billing/billingInfo';

interface IBillingContext {
    orgBillingInfo: BillingDetails;
    ProductRoutes: React.ReactNode;
    loading: boolean;
}

export const BillingContext = createContext<IBillingContext>({ 
    orgBillingInfo: {
        users: false,
        usersLimit: 0,
        billingModules: {
            sponsor: false,
            vendor: false,
            photoSharing: {
                photoSharingLimit: 0,
                userUploadPhotoSharing: false,
                photoSharingWatermark: false,
            },
            checkInApp: false,
            connectApp: false,
            integrations: false,
        },
        intelligence: IntelligenceLimit.TEN,
        eventhq: undefined,
        productType: ProductType.INTELLIGENCE
    },
    ProductRoutes: <></>,
    loading: true
})

const BillingProvider: React.FC<{ children: React.ReactNode }> = ({ children }): React.JSX.Element => {
    const [orgBillingInfo, setBillingInfo] = useState<BillingDetails>({
        users: false,
        usersLimit: 0,
        billingModules: {
            sponsor: false,
            vendor: false,
            photoSharing: {
                photoSharingLimit: 0,
                userUploadPhotoSharing: false,
                photoSharingWatermark: false,
            },
            checkInApp: false,
            connectApp: false,
            integrations: false,
        },
        intelligence: IntelligenceLimit.TEN,
        eventhq: undefined
    });
    const [loading, isLoading] = useState<boolean>(true);

    const dispatch = useDispatch();

    const routesForEhqManagement = (): React.ReactNode => { 
        return (
            <>
                {/* Dashboard but navigate to events */}
                <Route element={<UserProvider><ProtectedRoute module="events" /></UserProvider>}>
                    <Route path={'/dashboard'} element={<Navigate to={'/events?eventPhase=2'} replace />} />
                </Route>

                {/* events */}
                <Route element={<UserProvider><ProtectedRoute module="events" /></UserProvider>}>
                    <Route path={'/events/:status?/:eventId?'} element={<EventsPageProvider><EventsPage /> </EventsPageProvider>} />
                </Route>

                {/* event budget */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="budget" /></UserProvider>}>
                    <Route path={'/events/:eventId/budget'} element={<EventsInfo data={'budget'} />} />
                </Route>

                {/* event overview */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="overview" /></UserProvider>}>
                    <Route path={'/events/:eventId/overview'} element={<EventsInfo data={'overview'} />} />
                </Route>

                {/* event registration details - ideally contains registration form, tickets, coupons, utm's */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="registrations" /></UserProvider>}>
                    <Route path={'/events/:eventId/registrations'} element={<EventsInfo data={'registrations'} secondary-navbar={false} />} />
                </Route>

                {/* event landing page */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="landing-page" /></UserProvider>}>
                    <Route path={'/events/:eventId/landing-page'} element={<EventsInfo data={'landingPage'} />} />
                </Route>

                {/* coupons */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="registrations" /></UserProvider>}>
                    <Route path={'/events/:eventId/coupons'} element={<EventsInfo data={'coupons'} />} />
                </Route>

                {/* utm's */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="share-links" /></UserProvider>}>
                    <Route path={'/events/:eventId/share-links'} element={<EventsInfo data={'utm'} />} />
                </Route>

                {/* event tickets */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="tickets" /></UserProvider>}>
                    <Route path={'/events/:eventId/tickets'} element={<EventsInfo data={'tickets'} />} />
                </Route>

                {/* event registrants */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="registrants" /></UserProvider>}>
                    <Route path={'/events/:eventId/people/registrants/:eventRegistrantId?'} element={<EventsInfo tab={'registrants'} data={'registrants'} secondary-navbar={false} />} />
                </Route>

                {/* event prospects */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="prospects" /></UserProvider>}>
                    <Route path={'/events/:eventId/people/prospects'} element={<EventsInfo data={'prospects'} />} />
                </Route>

                {/* event speakers */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="speakers" /></UserProvider>}>
                    <Route path={'/events/:eventId/people/speakers/:eventSpeakerId?'} element={<EventsInfo tab={'speakers'} data={'speakers'} secondary-navbar={false} />} />
                </Route>

                {/* event vendors */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="vendors" /></UserProvider>}>
                    <Route path={'/events/:eventId/people/vendors/:eventVendorId?'} element={<EventsInfo tab={'vendors'} data={'vendors'} secondary-navbar={false} />} />
                </Route>

                {/* event sponsors */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="sponsors" /></UserProvider>}>
                    <Route path={'/events/:eventId/people/sponsors/:eventSponsorId?'} element={<EventsInfo data={'sponsors'} tab={'sponsors'} subTab={'sponsors'} secondary-navbar={false} />} />
                </Route>

                {/* event marketing */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="marketing" /></UserProvider>}>
                    <Route path={'/events/:eventId/marketing'} element={<EventsInfo data={'marketing'} />} />
                </Route>

                {/* event agenda - ideally has event stage / sessions */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="agenda" /></UserProvider>}>
                    <Route path={'/events/:eventId/agenda'} element={<EventsInfo data={'agenda'} />} />
                </Route>

                {/* Event analytics Revenue */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="analytics" /></UserProvider>}>
                    <Route path={'/events/:eventId/analytics/revenue'} element={<EventsInfo data={'analytics'} />} />
                </Route>

                {/* Event analytics NPS */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="analytics"/></UserProvider>}>
                    <Route path={'/events/:eventId/analytics/nps'} element={<EventsInfo data={'analytics'} />} />
                </Route>

                {/* event access control */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="checkin" /></UserProvider>}>
                    <Route path={'/events/:eventId/check-in'} element={<EventsInfo data={'checkIn'} />} />
                </Route>

                {/* event check in devices */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="devices" /></UserProvider>}>
                    <Route path={'/events/:eventId/check-in/devices'} element={<EventsInfo data={'allDevices'} />} />
                </Route>

                {/* event check in notifications */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="notifications"/></UserProvider>}>
                    <Route path={'/events/:eventId/check-in/notifications'} element={<EventsInfo data={'checkInNotifications'} />} />
                </Route>

                {/* event lead capture */}
                {/* <Route element={<UserProvider><ProtectedRoute subModule="" />}>
                    <Route path={'/events/:eventId/connect'} element={<EventsInfo data={'leadCapture'} />} />
                </Route> */}

                {/* event target list */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="targetList" /></UserProvider>}>
                    <Route path={'/events/:eventId/target-list'} element={<EventsInfo data={'targetList'} />} />
                </Route>

                {/* event target list Audience */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="targetList" /></UserProvider>}>
                    <Route path={'/events/:eventId/target-list/:listId'} element={<EventsInfo data={'targetListAudience'} />} />
                </Route>

                {/* create event target list */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" sidebar={false} subModule="targetList" /></UserProvider>}>
                    <Route path={'/events/:eventId/target-list/details/'} element={<EventsPageProvider><EventsInfo navabar-topbar={true} data={'createTargetList'} /></EventsPageProvider>} />
                </Route>

                {/* Update event target list */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" sidebar={false} subModule="targetList" /></UserProvider>}>
                    <Route path={'/events/:eventId/target-list/details/:listId'} element={<EventsPageProvider><EventsInfo navabar-topbar={true} data={'updateTargetList'} /></EventsPageProvider>} />
                </Route>

                {/* event photo sharing */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="photo-sharing" /></UserProvider>}>
                    <Route path={'/events/:eventId/photo-sharing/:folder?/:hidden?/:default-photos?/:user-uploads?/:approved?'} element={<EventsInfo data={'photoSharing'} />} />
                </Route>

                {/* Event settings */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="settings" /></UserProvider>}>
                    <Route path={'/events/:eventId/settings'} element={<EventsInfo data={'settings'} />} />
                </Route>

                {/* Event Social Share */}
                {/* <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="socialShare" /></UserProvider>}>
                    <Route path={'/events/:eventId/social-share'} element={<EventsInfo data={'socialShare'} />} />
                </Route>

                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="socialShare" sidebar={false} /></UserProvider>}>
                    <Route path={'/events/:eventId/social-share/:placidTemplateId'} element={<EventSocialShareManager />} />
                </Route> */}

                {/* global - audience */}
                <Route element={<UserProvider><ProtectedRoute module="audience" /></UserProvider>}>
                    <Route path={'/audience/:audienceId?'} element={<AudiencePage />} />
                </Route>

                {/* global intelligence CRM Pipeline */}
                {/* <Route element={<UserProvider><ProtectedRoute product="intelligence" module="pipeline" /></UserProvider>}>
                    <Route path={'/lead-tracker/prospects'} element={<LeadTrackerProvider><LeadTrackerPage /></LeadTrackerProvider>} />
                </Route>

                <Route element={<UserProvider><ProtectedRoute product="intelligence" module="pipeline" /></UserProvider>}>
                    <Route path={'/lead-tracker/lists/:leadTrackerListId?'} element={<LeadTrackerProvider><LeadTrackerPage /></LeadTrackerProvider>} />
                </Route> */}

                {/* Competitors */}
                <Route element={<UserProvider><ProtectedRoute product="intelligence" module="competitor" /></UserProvider>}>
                    <Route path={'/competitor'} element={<CompetitorPage />} />
                </Route>

                {/* Explore */}
                <Route element={<UserProvider><ProtectedRoute product="intelligence" module="explore" /></UserProvider>}>
                    <Route path={'/explore'} element={<ExplorePage />} />
                </Route>

                {/* global - speakers */}
                {/* <Route element={<UserProvider><ProtectedRoute module="speakers" /></UserProvider>}>
                    <Route path={'/speakers/:speakerId?'} element={<SpeakersProvider><SpeakersPage /></SpeakersProvider>} />
                </Route> */}

                {/* global - sponsors */}
                {/* <Route element={<UserProvider><ProtectedRoute module="sponsors" /></UserProvider>}>
                    <Route path={'/sponsors/:sponsorId?'} element={orgBillingInfo && orgBillingInfo?.billingModules && orgBillingInfo?.billingModules?.sponsor ? <SponsorsPageProvider><SponsorsPage /></SponsorsPageProvider> : <Navigate to={'/not-found'} replace />} />
                </Route> */}

                {/* global - vendors */}
                {/* <Route element={<UserProvider><ProtectedRoute module="vendors" /></UserProvider>}>
                    <Route path={'/vendors/:vendorId?'} element={orgBillingInfo && orgBillingInfo?.billingModules && orgBillingInfo?.billingModules?.vendor ? <VendorsProvider><VendorsPage /></VendorsProvider> : <Navigate to={'/not-found'} replace />} />
                </Route> */}

                {/* global - registrations */}
                <Route element={<UserProvider><ProtectedRoute module="registrations" /></UserProvider>}>
                    <Route path={'/registrations/:formId?'} element={<RegistrationPageProvider><RegistrationsPage /></RegistrationPageProvider>} />
                </Route>

                {/* registrations -> form fields page */}
                <Route element={<UserProvider><ProtectedRoute module="registrations" top-app-bar={false} sidebar={false} /></UserProvider>}>
                    <Route path={'/registrations/:formId/form'} element={<FormComponentsProvider><Registrations tab={'form'} /></FormComponentsProvider>} />
                </Route>

                {/* registrations -> form fields preview page */}
                <Route element={<UserProvider><ProtectedRoute module="registrations" top-app-bar={false} sidebar={false} /></UserProvider>}>
                    <Route path={'/registrations/:formId/preview'} element={<FormComponentsProvider><Registrations tab={'preview'} /></FormComponentsProvider>} />
                </Route>

                {/* global - budgets */}
                <Route element={<UserProvider><ProtectedRoute module="budget" /></UserProvider>}>
                    <Route path={'/budget'} element={<BudgetsPage />} />
                </Route>

                {/* global - audience analytics page */}
                <Route element={<UserProvider><ProtectedRoute module="analytics" /></UserProvider>}>
                    <Route path={'/analytics'} element={<Navigate to={'/analytics/audience'} replace />} />
                </Route>

                {/* global - audience analytics page */}
                <Route element={<UserProvider><ProtectedRoute module="analytics" top-app-bar={false} /></UserProvider>}>
                    <Route path={'/analytics/audience'} element={<AnalyticsPage page='Audience' />} />
                </Route>

                {/* global - budget analytics page */}
                <Route element={<UserProvider><ProtectedRoute module="analytics" top-app-bar={false} /></UserProvider>}>
                    <Route path={'/analytics/budget'} element={<AnalyticsPage page='Budget' />} />
                </Route>

                {/* global - events analytics page */}
                <Route element={<UserProvider><ProtectedRoute module="analytics" top-app-bar={false} /></UserProvider>}>
                    <Route path={'/analytics/events'} element={<AnalyticsPage page='Events' />} />
                </Route>

                {/* global - roi analytics page */}
                <Route element={<UserProvider><ProtectedRoute module="analytics" top-app-bar={false} /></UserProvider>}>
                    <Route path={'/analytics/roi'} element={<AnalyticsPage page='roi' />} />
                </Route>

                {/* //////////// */}
                {/* Settings Routes */}

                {/* settings page -> redirects to profile settings */}
                <Route element={<UserProvider><ProtectedRoute module="settings" /></UserProvider>}>
                    <Route path={'/settings'} element={<Navigate to={'/settings/general'} replace />} />
                </Route>

                {/* profile settings */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="general" /></UserProvider>}>
                    <Route path={'/settings/general'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.GENERAL.TAB} />} />
                </Route>

                {/* organisation settings */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="organization" /></UserProvider>}>
                    <Route path={'/settings/organization'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.ORGANIZATION.TAB} />} />
                </Route>

                {/* integration related settings */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} />} />
                </Route>

                {/* all integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/all'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.ALL} />} />
                </Route>

                {/* crm integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/crm'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.CRM} />} />
                </Route>

                {/* marketing tools integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/marketing-tools'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.MARKETING_TOOLS} />} />
                </Route>

                {/* registration tools integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/registration-tools'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.EVENT_REGISTRATION_TOOLS} />} />
                </Route>

                {/* csm tools integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/csm-tools'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.CSM_TOOLS} />} />
                </Route>

                {/* enrichment integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/enrichment'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.ENRICHMENT} />} />
                </Route>

                {/* payments integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/payments'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.PAYMENTS} />} />
                </Route>

                {/* other integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/others'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.OTHERS} />} />
                </Route>

                {/* icp page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="icp" /></UserProvider>}>
                    <Route path={'/settings/icp'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.ICP.TAB} />} />
                </Route>

                {/* buyer persona page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="buyerpersona" /></UserProvider>}>
                    <Route path={'/settings/buyer-persona'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.BUYER_PERSONA.TAB} />} />
                </Route>

                {/* users page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="users" /></UserProvider>}>
                    <Route path={'/settings/users'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.USERS.TAB} />} />
                </Route>

                {/* custom fields */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="properties" /></UserProvider>}>
                    <Route path={'/settings/properties'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.CUSTOM_FIELD.TAB} />} />
                </Route>

                {/* <Route element={<ProtectedRoute/>}>
                    <Route path={'/settings/billing'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.BILLING.TAB} />} />
                </Route> */}
            </>
        )
    };

    const routesForEhqIntelligence = (): React.ReactNode => { 
        return (
            <>
                {/* Dashboard but navigate to events */}
                <Route element={<UserProvider><ProtectedRoute module="events" /></UserProvider>}>
                    <Route path={'/dashboard'} element={<Navigate to={'/events?eventPhase=2'} replace />} />
                </Route>

                {/* events */}
                <Route element={<UserProvider><ProtectedRoute module="events" /></UserProvider>}>
                    <Route path={'/events/:status?/:eventId?'} element={<EventsPageProvider><EventsPage /> </EventsPageProvider>} />
                </Route>

                {/* event registrants */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="registrants" /></UserProvider>}>
                    <Route path={'/events/:eventId/people/registrants/:eventRegistrantId?'} element={<EventsInfo tab={'registrants'} data={'registrants'} secondary-navbar={false} />} />
                </Route>

                {/* event prospects */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="prospects" /></UserProvider>}>
                    <Route path={'/events/:eventId/people/prospects'} element={<EventsInfo data={'prospects'} />} />
                </Route>

                {/* event speakers */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="speakers" /></UserProvider>}>
                    <Route path={'/events/:eventId/people/speakers/:eventSpeakerId?'} element={<EventsInfo tab={'speakers'} data={'speakers'} secondary-navbar={false} />} />
                </Route>

                {/* event sponsors */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="sponsors" /></UserProvider>}>
                    <Route path={'/events/:eventId/people/sponsors/:eventSponsorId?'} element={<EventsInfo data={'sponsors'} tab={'sponsors'} subTab={'sponsors'} secondary-navbar={false} />} />
                </Route>

                {/* Event settings */}
                <Route element={<UserProvider><ProtectedRoute module="events-module" subModule="settings" /></UserProvider>}>
                    <Route path={'/events/:eventId/settings'} element={<EventsInfo data={'settings'} />} />
                </Route>

                {/* global - audience */}
                <Route element={<UserProvider><ProtectedRoute module="audience" /></UserProvider>}>
                    <Route path={'/audience/:audienceId?'} element={<AudiencePage />} />
                </Route>

                {/* global intelligence CRM Pipeline */}
                {/* <Route element={<UserProvider><ProtectedRoute product="intelligence" module="pipeline" /></UserProvider>}>
                    <Route path={'/lead-tracker/prospects'} element={<LeadTrackerProvider><LeadTrackerPage /></LeadTrackerProvider>} />
                </Route>

                <Route element={<UserProvider><ProtectedRoute product="intelligence" module="pipeline" /></UserProvider>}>
                    <Route path={'/lead-tracker/lists/:leadTrackerListId?'} element={<LeadTrackerProvider><LeadTrackerPage /></LeadTrackerProvider>} />
                </Route> */}

                {/* Competitors */}
                <Route element={<UserProvider><ProtectedRoute product="intelligence" module="competitor" /></UserProvider>}>
                    <Route path={'/competitor'} element={<CompetitorPage />} />
                </Route>

                {/* Explore */}
                <Route element={<UserProvider><ProtectedRoute product="intelligence" module="explore" /></UserProvider>}>
                    <Route path={'/explore'} element={<ExplorePage />} />
                </Route>

                {/* Settings Routes */}

                {/* settings page -> redirects to profile settings */}
                <Route element={<UserProvider><ProtectedRoute module="settings" /></UserProvider>}>
                    <Route path={'/settings'} element={<Navigate to={'/settings/general'} replace />} />
                </Route>

                {/* profile settings */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="general" /></UserProvider>}>
                    <Route path={'/settings/general'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.GENERAL.TAB} />} />
                </Route>

                {/* organisation settings */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="organization" /></UserProvider>}>
                    <Route path={'/settings/organization'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.ORGANIZATION.TAB} />} />
                </Route>

                {/* integration related settings */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} />} />
                </Route>

                {/* all integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/all'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.ALL} />} />
                </Route>

                {/* crm integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/crm'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.CRM} />} />
                </Route>

                {/* marketing tools integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/marketing-tools'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.MARKETING_TOOLS} />} />
                </Route>

                {/* registration tools integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/registration-tools'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.EVENT_REGISTRATION_TOOLS} />} />
                </Route>

                {/* csm tools integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/csm-tools'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.CSM_TOOLS} />} />
                </Route>

                {/* enrichment integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/enrichment'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.ENRICHMENT} />} />
                </Route>

                {/* payments integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/payments'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.PAYMENTS} />} />
                </Route>

                {/* other integrations page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="integrations" /></UserProvider>}>
                    <Route path={'/settings/integrations/others'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.OTHERS} />} />
                </Route>

                {/* icp page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="icp" /></UserProvider>}>
                    <Route path={'/settings/icp'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.ICP.TAB} />} />
                </Route>

                {/* buyer persona page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="buyerpersona" /></UserProvider>}>
                    <Route path={'/settings/buyer-persona'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.BUYER_PERSONA.TAB} />} />
                </Route>

                {/* users page */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="users" /></UserProvider>}>
                    <Route path={'/settings/users'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.USERS.TAB} />} />
                </Route>

                {/* custom fields */}
                <Route element={<UserProvider><ProtectedRoute module="settings" subModule="properties" /></UserProvider>}>
                    <Route path={'/settings/properties'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.CUSTOM_FIELD.TAB} />} />
                </Route>
            </>
        );
    };

    const generateProtectedRoutes = (): React.ReactNode => {
        // switch (orgBillingInfo.productType) {
        //     case ProductType.EVENTHQ:
        //         return routesForEhqManagement();
        //     case ProductType.INTELLIGENCE:
        //         return routesForEhqIntelligence();
        //     default:
        //         return <></>
        // }
        if(orgBillingInfo?.productType === ProductType.EVENTHQ || orgBillingInfo?.eventhq === EventHQEventLimit.THREE) {
            return routesForEhqManagement();
        }
        else {
            return routesForEhqIntelligence();
        }
    };

    const fetchOrgBillingDetails = async (): Promise<void> => {
        try {
            const user = LocalStorage.get('@UserMe');
            if (user) {
                const billingInfo = await orgBillingDetails();
                if (billingInfo) {
                    setBillingInfo(billingInfo as BillingDetails);
                    dispatch(billingDetails({
                        billingDetails: billingInfo
                    }));
                }
                else {
                    dispatch(billingDetails({
                        billingDetails: {
                            users: true,
                            billingModules: {
                                sponsor: true,
                                vendor: true,
                                photoSharing: {
                                    userUploadPhotoSharing: true,
                                    photoSharingWatermark: true
                                },
                                checkInApp: true,
                                connectApp: true,
                                integrations: true,
                            }
                        }
                    }));
                }
            }
        }
        catch (error) {
            console.log('Error fetching billing details', error);
            dispatch(billingDetails({
                billingDetails: {
                    users: true,
                    billingModules: {
                        sponsor: true,
                        vendor: true,
                        photoSharing: {
                            userUploadPhotoSharing: true,
                            photoSharingWatermark: true
                        },
                        checkInApp: true,
                        connectApp: true,
                        integrations: true,
                    }
                }
            }));
        }
        finally {
            isLoading(false);
        }
    };

    useEffect(() => {
        fetchOrgBillingDetails();
    }, []);

    return (
        <BillingContext.Provider 
            value={{ 
                orgBillingInfo,
                ProductRoutes: generateProtectedRoutes(),
                loading
            }}
        >
            {children}
        </BillingContext.Provider>
    )
}

export default BillingProvider
