import { useLocation } from "react-router-dom";
import { EventGraphObject, EventTicket } from "../pages/Events/interfaces";
import { IEventReduxValues } from "../components/Events/interfaces/create-events_interface";
import { EnableApprovalStatus, EnableWaitlistStatus, EventRegistrationTypes, TicketType } from "../pages/Events/enum";
import _ from "lodash";

interface IRegistrantToolbarTabs { 
    registrantCount: number;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
    eventId: string | number;
    registrantStatusCount?: EventGraphObject; 
    tickets: EventTicket[];
    navigate: any;
    eventReduxData?: IEventReduxValues;
}

interface ITab {
    tabName: string;
    count: number;
    navigation: () => void;
    selectedTab: boolean;
}

export const useRegistrantToolbarTabs = ({
    eventId,
    navigate,
    registrantCount,
    registrantStatusCount,
    setCurrentUrl,
    tickets,
    eventReduxData
}: IRegistrantToolbarTabs): ITab[] => { 
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const tabs: ITab[] = [
        {
            tabName: 'All',
            count: registrantCount,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/registrants`);
                navigate(`/events/${eventId}/people/registrants`);
            },
            selectedTab: window.location.search.length == 0,
        },
        {
            tabName: 'In Review',
            count: Number(registrantStatusCount?.INVITE) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/registrants?status=1`);
                navigate(`/events/${eventId}/people/registrants?status=1`);
            },
            selectedTab: searchParams?.get('status') == '1',
        },
        {
            tabName: 'Waitlist',
            count: Number(registrantStatusCount?.WAITLIST) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/registrants?status=4`);
                navigate(`/events/${eventId}/people/registrants?status=4`);
            },
            selectedTab: searchParams?.get('status') == '4',
        },
        {
            tabName: 'Approved',
            count: Number(registrantStatusCount?.APPROVED) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/registrants?status=2`);
                navigate(`/events/${eventId}/people/registrants?status=2`);
            },
            selectedTab: searchParams?.get('status') == '2',
        },
        {
            tabName: 'Rejected',
            count: Number(registrantStatusCount?.REJECTED) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/registrants?status=3`);
                navigate(`/events/${eventId}/people/registrants?status=3`);
            },
            selectedTab: searchParams?.get('status') == '3',
        },
        {
            tabName: 'Confirmed',
            count: Number(registrantStatusCount?.CONFIRMED) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/registrants?status=6`);
                navigate(`/events/${eventId}/people/registrants?status=6`);
            },
            selectedTab: searchParams?.get('status') == '6',
        },
        {
            tabName: 'Attendees',
            count: Number(registrantStatusCount?.ATTENDED) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/registrants?attendeeStatus=2`);
                navigate(`/events/${eventId}/people/registrants?attendeeStatus=2`);
            },
            selectedTab: searchParams?.get('attendeeStatus') == '2',
        },
        {
            tabName: 'Paid',
            count: Number(registrantStatusCount?.PAID) || 0,
            navigation: () => {
                setCurrentUrl(`/events/${eventId}/people/registrants?paymentStatus=2`);
                navigate(`/events/${eventId}/people/registrants?paymentStatus=2`);
            },
            selectedTab: searchParams?.get('paymentStatus') == '2',
        }
    ];

    if(eventReduxData?.registrationType === EventRegistrationTypes.OPEN && (!tickets || tickets.length === 0 || tickets.every((ticket) => ticket.enableApproval !== EnableApprovalStatus.ENABLE)))
    {
        _.remove(tabs, (tab) => tab.tabName === 'In Review');
        _.remove(tabs, (tab) => tab.tabName === 'Rejected');
    }

    if (!(tickets && tickets?.length > 0 && tickets?.some((ticket) => ticket.type === TicketType.PAID))) {
        _.remove(tabs, (tab) => tab.tabName === 'Paid');
    }

    if (!(registrantStatusCount && Object.keys(registrantStatusCount).includes('APPROVED'))) {
        _.remove(tabs, (tab) => tab.tabName === 'Approved');
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const eventStartDate = new Date(Number(eventReduxData?.eventStartDateTime) * 1000);
    eventStartDate.setHours(0, 0, 0, 0);

    const isCurrentDateSameorAfterEventStartDate = currentDate.getTime() >= eventStartDate.getTime();
    if (!isCurrentDateSameorAfterEventStartDate) {
        _.remove(tabs, (tab) => tab.tabName === 'Attendees');
    }

    const isWaitListEnabledForTicket = tickets && tickets?.length > 0 && tickets?.some((ticket): boolean => {
        return ticket?.enableWaitlistStatus === EnableWaitlistStatus.ENABLE;
    });
    if (!isWaitListEnabledForTicket) {
        _.remove(tabs, (tab) => tab.tabName === 'Waitlist');
    }

    return [...tabs];
}
