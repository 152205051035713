import { Typography, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import APP_CONSTANTS from '../scripts/constants';
import eventBus from '../scripts/event-bus';
// { CONTENT } from '../scripts/i18n';

/**
 * @returns
 * Functional component to render a popup
 * Variable to store initial state of title of the popup
 * Variable to store initial state of the content of the popup
 * Variable to store the initial state of accept button click event in the popup
 * Variable to store the initial state of accept event in the popup
 * Variable to store the initial state of reject button click event in the popup
 * Variable to store the initial state of object during the accept event in the popup
 * Variable to store the state which toggles the popup to show or hide
 * Function which handles the reject button click event
 * Function which handles the accept button click event
 */

const PopUp = (): React.JSX.Element  => 
{
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [acceptEvent, setAcceptEvent] = useState('');
    const [component, setComponent] = useState(null);
    const [acceptBtn, setAcceptBtn] = useState('');
    const [rejectBtn, setRejectBtn] = useState('');
    const [acceptObj, setAcceptObj] = useState(new Object());
    const [show, setShow] = useState(false);
    const [cancelEvent, setCancelEvent] = useState('');


    const handleRejectClose = (): void => 
    {
        if (cancelEvent) 
        {
            eventBus.dispatch(cancelEvent, {
                result: false,
            });
        }
        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RESULT, {
            result: false,
        });

        eventBus.dispatch('selected-row-id', null);

        setShow(false);
    };

    const handleAcceptClose = (): void => 
    {
        eventBus.dispatch(acceptEvent, {
            result: true,
            acceptObj
        });

        eventBus.dispatch('selected-row-id', null);

        // setShow(false);
    };

    useEffect(():()=>void => 
    {
        const popupEvents = (data): void => 
        {
            setShow(data.open);
            setModalTitle(data.title);
            setModalContent(data.content);
            setAcceptEvent(data.acceptEvent);
            setAcceptBtn(data.acceptBtn);
            setRejectBtn(data.rejectBtn);
            setComponent(data.component);
            if (data.acceptObj) 
            {
                setAcceptObj(data.acceptObj);
            }
            if (data.cancelEvent) 
            {
                setCancelEvent(data.cancelEvent);
            }

        };

        const closePopup = (close): void => 
        {
            setShow(false);
        };

        eventBus.on(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, popupEvents);
        eventBus.on(APP_CONSTANTS.EVENTS.POPUP_EVENT.CLOSE_POPUP_EVENT, closePopup);
        return ():void => 
        {
            eventBus.remove(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, popupEvents);
            eventBus.remove(APP_CONSTANTS.EVENTS.POPUP_EVENT.CLOSE_POPUP_EVENT, closePopup);
        };

    }, []);

    return (
        <>
            <Modal show={show} onHide={handleRejectClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Typography className="title">{modalTitle}</Typography>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {component ? component : <Typography className="content">{modalContent}</Typography>}
                </Modal.Body>
                {acceptBtn || rejectBtn ?
                    <Modal.Footer>
                        {rejectBtn ? <Button size="large" className="link-btn" style={acceptBtn? null : {
                            marginRight: '0px'
                        }} onClick={handleRejectClose}>{rejectBtn}</Button> : ''}
                        {acceptBtn ? <Button className='btn-delete' onClick={handleAcceptClose}>{acceptBtn}</Button> : ''}
                    </Modal.Footer> : ''}
            </Modal>
        </>
    );
};
export default PopUp;
