import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CloseIconComponent, FormControlComponent, FormLabelComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { Form } from "react-bootstrap";
import { Box, Stack } from "@mui/material";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { CONTENT } from "../../../scripts/i18n";
import moment from "moment";
import { createEventStage, getEventStageActivityLogs, updateEventStage } from "../../../scripts/apis/eventStage";
import { IEventReduxValues, IEventsDispatch } from "../interfaces/create-events_interface";
import './styles.scss';
import DatePicker from "../../../common/FormComponents/DatePicker";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActivityLogComponent from "../../../common/ActivityLog/ActivityLogComponent";
import { ActivityLogResponse } from "../../../interfaces/activity-log_interface";
import { eventStageActivityLog } from "../../../scripts/activityLogGenerator/activitylog-generator";
import { convertTimestampWithTimezone } from "../../../scripts/helpers";
import { Stage } from "../../../pages/Events/interfaces";

interface AddStageProps {
    eventId: string | number;
    setRefresh: (refresh: boolean) => void;
    stageData?: Stage;
    selectedDate?: string;
    stageId?: string | number;
    heading: string;
    handlePopupClose: () => void;
    description: string;
}

const AddStage: React.FC<AddStageProps> = (props): React.JSX.Element => 
{
    const eventId = props?.eventId;
    const setRefresh = props?.setRefresh;
    const stageData = props?.stageData;
    const selectedDate = props?.selectedDate;
    const { heading, handlePopupClose, description } = props;

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const startDateTime = eventReduxData.eventStartDateTime;
    const startYear = moment.unix(Number(startDateTime)).year();
    const startMonth = moment.unix(Number(startDateTime)).month();
    let selectedDateWithYear;
    if(selectedDate)
    {
        const [day, monthStr] = selectedDate.split(' ');
        const month = moment().month(monthStr).format("M");
        let year = startYear;

        if (Number(month) - 1 < startMonth) {
            year += 1;
        }

        selectedDateWithYear = moment({ year, month: Number(month) - 1, day: parseInt(day, 10) });
    }

    const [spinner, setSpinner] = useState<boolean>(false);
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    const [showActivityLog, setShowActivityLog] = useState<boolean>(false);

    const csrfTokenData = useSelector((state): string => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Stage Name is required'),
        description: Yup.string().notRequired(),
        startDate: Yup.string().required('Start Date is required'),
        endDate: Yup.string().required('End Date is required'),
        // maximumParticipants: Yup.number()
        //     .min(1, 'Minimum participant should be greater than 0')
        //     .required('Maximum Participants is required'),
    });

    const formik = useFormik({ 
        initialValues: {
            title: stageData ? stageData?.title : '',
            description: stageData ? stageData?.description : '',
            startDate: stageData ? moment.unix(Number(stageData?.startDateTime)).format('MM/DD/YYYY') : (selectedDateWithYear ? selectedDateWithYear.format('MM/DD/YYYY') : moment.unix(Number(eventReduxData?.eventStartDateTime)).format('MM/DD/YYYY')),
            endDate: stageData ? moment.unix(Number(stageData?.endDateTime)).format('MM/DD/YYYY') : (selectedDateWithYear ? selectedDateWithYear.format('MM/DD/YYYY') : moment.unix(Number(eventReduxData?.eventStartDateTime)).format('MM/DD/YYYY')),
            // maximumParticipants: stageData ? stageData?.maximumParticipants : 0,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setSpinner(true);

            const startDateMoment = moment(values.startDate);
            const endDateMoment = moment(values.endDate);

            const eventStartMoment = moment.unix(Number(eventReduxData.eventStartDateTime));
            const eventEndMoment = moment.unix(Number(eventReduxData.eventEndDateTime));

            let startDateTime, endDateTime;

            const eventDuration = moment.duration(eventEndMoment.diff(eventStartMoment));
            const isSameDayEvent = Math.round(eventDuration?.asDays()) === 0;

            const currentTimezone = moment.tz.guess();

            if(isSameDayEvent)
            {
                const startDateTimestamp = moment(values.startDate).startOf('day').unix();
                const endDateTimestamp = moment(values.startDate).endOf('day').subtract(1, 'minute').unix();
                // startDateTime = moment(eventStartMoment).unix();
                // endDateTime = moment(eventEndMoment).unix();
                startDateTime = convertTimestampWithTimezone(startDateTimestamp, currentTimezone, eventReduxData?.timeZone);
                endDateTime = convertTimestampWithTimezone(endDateTimestamp, currentTimezone, eventReduxData?.timeZone);
            }
            else
            {
                const startDateTimestamp = moment(values.startDate).startOf('day').unix();
                const endDateTimestamp = moment(values.endDate).endOf('day').subtract(1, 'minute').unix();
                // startDateTime = startDateMoment.startOf('day').unix();
                // endDateTime = endDateMoment.endOf('day').subtract(1, 'minute').unix();
                startDateTime = convertTimestampWithTimezone(startDateTimestamp, currentTimezone, eventReduxData?.timeZone);
                endDateTime = convertTimestampWithTimezone(endDateTimestamp, currentTimezone, eventReduxData?.timeZone);
            }

            const data = {
                title: values.title,
                description: values.description,
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                // maximumParticipants: values.maximumParticipants,
            };

            try 
            {
                let stageCreated;
                let successMessage;
        
                if (stageData) {
                    stageCreated = await updateEventStage(eventId, stageData?.id, data);
                    successMessage = 'Stage Updated';
                } else {
                    stageCreated = await createEventStage(eventId, data, csrfTokenData);
                    successMessage = 'Stage Created';
                }
        
                if (stageCreated) {
                    setSpinner(false);
                    setRefresh(true);
                    toast.success(successMessage);
                    handleDrawerClose();
                }
            } 
            catch (error) 
            {
                setSpinner(false);
                toast.error('Error in ' + (stageData ? 'updating' : 'creating') + ' stage');
            }
            finally
            {
                setSpinner(false);
            }
        },
    });

    const handleDrawerClose = (): void => {

        handlePopupClose();
        setShowActivityLog(false);
    };

    const handleBackToPopup = (): void =>
    {
        setShowActivityLog(false);
    }

    const handleNestedSidebarOpen = (): void =>
    {
        setShowActivityLog(true);
    };

    const fetchActivityLogs = async (): Promise<void> =>
    {
        try 
        {
            const activityLogs = await getEventStageActivityLogs(eventId, stageData?.id);
            if(activityLogs)
            {
                setActivityLogs(activityLogs);
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching activity logs');
        }
    };

    useEffect(() =>
    {
        if(stageData)
        {
            fetchActivityLogs();
        }
    }, []);

    const renderFormFields = (): React.JSX.Element => {
        return (
            <>
                {/* Stage Name */}
                <Box className="popup-container-spacing">
                    <FormLabelComponent label='Stage Name' required />
                    <FormControlComponent
                        type="text"
                        name="title"
                        placeholder="Enter Name"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.title && !!formik.errors.title}
                    />
                    {formik.touched.title && formik.errors.title && <div className="error-msg">{formik.errors.title}</div>}
                </Box> 

                {/* Description */}
                <Box className="popup-container-spacing">
                    <FormLabelComponent label='Description' />
                    <FormControlComponent
                        type="text"
                        as="textarea"
                        name="description"
                        placeholder="Enter a description"
                        rows={5}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.description && !!formik.errors.description}
                    />
                </Box> 

                {/* Start Date */}
                <Box className="popup-container-spacing">
                    <div className="stage-timing-cont">
                        <FormLabelComponent label='Select Starting and End Date' required noBottomMargin />
                        <p className="timezone">{`(${eventReduxData?.timeZone})`}</p>
                    </div>
                    {/* <MuiDatePicker min={moment.unix(Number(eventReduxData?.eventStartDateTime)).format('YYYY-MM-DD')} max={moment.unix(Number(eventReduxData?.eventEndDateTime)).format('YYYY-MM-DD')} value={String(formik.values?.startDate)} onChange={(newValue) => {
                        formik.setFieldValue('startDate', newValue?.format('MM/DD/YYYY'));
                        formik.setFieldValue('endDate', newValue?.endOf('day').format('MM/DD/YYYY HH:mm:ss'));
                    }} /> */}
                    {/* <MuiDateRangePicker min={moment.unix(Number(eventReduxData?.eventStartDateTime)).format('YYYY-MM-DD')} max={moment.unix(Number(eventReduxData?.eventEndDateTime)).format('YYYY-MM-DD')} value={[String(formik.values?.startDate), String(formik.values?.endDate)]} onChange={(newValue): void => 
                    {
                        formik.setFieldValue('startDate', newValue[0]?.format('MM/DD/YYYY'));
                        formik.setFieldValue('endDate', newValue[1]?.format('MM/DD/YYYY')); 
                    }} 
                    desktopPaperStyle={{
                        position: 'relative',
                        left: '232px'
                    }}
                    /> */}
                    <div className="stage-timing">
                        <DatePicker 
                            value={moment(formik.values.startDate).unix()}
                            onChange={(newValue) => {
                                formik.setFieldValue('startDate', newValue);
                            }}
                            minDate={Number(eventReduxData?.eventStartDateTime)}
                            maxDate={Number(eventReduxData?.eventEndDateTime)}
                        />
                        <p className="me-1 ms-1">-</p>
                        <DatePicker 
                            value={moment(formik.values.endDate).unix()}
                            onChange={(newValue) => {
                                formik.setFieldValue('endDate', newValue);
                            }}
                            minDate={Number(eventReduxData?.eventStartDateTime)}
                            maxDate={Number(eventReduxData?.eventEndDateTime)}
                        />
                    </div>
                    {formik.touched.startDate && formik.errors.startDate && <div className="error-msg">{formik.errors.startDate}</div>}
                </Box>

                {/* Maximum number of participants */}
                {/* <Box className="popup-container-spacing">
                    <FormLabelComponent label='Maximum number of participants' required />
                    <FormControlComponent
                        type="number"
                        name="maximumParticipants"
                        placeholder="0000"
                        rows={5}
                        value={formik.values.maximumParticipants}
                        onKeyDown={(evt): void => 
                        {
                            return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                        }}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.maximumParticipants && !!formik.errors.maximumParticipants}
                    />
                    {formik.touched.maximumParticipants && formik.errors.maximumParticipants && <div className="error-msg">{formik.errors.maximumParticipants}</div>}
                </Box>  */}
            </>
        )
    }

    const renderHeader = (): React.JSX.Element => {
        return (
            <div className='popup-header'>
                <div className='popup-header-content'>
                    {showActivityLog ? (
                        <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} onClick={handleBackToPopup} />
                    ) : (
                        <div className='popup-header-icon'><FontAwesomeIcon icon={['fal', 'tent-double-peak']} /></div>
                    )}

                    <div className='popup-header-text'>
                        <h3>
                            {heading}
                        </h3>
                        <p>
                            {description}
                        </p>
                    </div>
                </div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => handlePopupClose()} />
            </div>
        );
    }

    const renderMainContent = (): React.JSX.Element => {
        if (showActivityLog && stageData) {
            return (
                <ActivityLogComponent activityLog={eventStageActivityLog(activityLogs, stageData)} />
            )
        }
        else {
            return (
                renderFormFields()
            )
        }

    }

    const renderFooter = (): React.JSX.Element => {

        if(showActivityLog)
        {
            return <></>
        }
        return (
            <div className='popup-footer'>
                <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    <div className='popup-footer-left'>
                        {/* {<FontAwesomeIcon className="popup-delete-icon" icon={['fal', 'trash']} onClick={() => setShowDeletePopup(true)} />} */}
                        {
                            stageData &&
                            <div className="stage-activity-log-button">
                                <CustomButton 
                                    name=""
                                    btnType="secondary"
                                    startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                                    style={{ width: '32px' }}
                                    onClick={handleNestedSidebarOpen}
                                />
                            </div>
                        }
                    </div>

                    <Stack direction={'row'} spacing={1} display={'flex'}>
                        <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton btnType='primary' disabled={spinner} loading={spinner} name={'Save'} type='submit' />
                    </Stack>
                </Stack>
                
            </div>
        );
    }

    return (
        <div id="addStage">
            {renderHeader()}

            <Form noValidate onSubmit={(values): void => {
                return formik.handleSubmit(values);
            }} autoComplete="off">
                <div className="popup-container">
                    {renderMainContent()}
                </div>
                
                {renderFooter()}
            </Form>
        </div>
    );
};

export default AddStage;