import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Event } from "../../../pages/Events/interfaces";

import APP_CONSTANTS from "../../../scripts/constants";

import { FormControlComponent, FormLabelComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { CustomButton } from "../../../common/FormComponents/Buttons";

import { updateEvent } from "../../../scripts/apis/events";

import toast from "react-hot-toast";

import './styles.scss';

interface IEventPublicLinkProps {
    eventDetails: Event;
    setRefreshEventData: React.Dispatch<React.SetStateAction<boolean>>;
}

const EventPublicLink: React.FC<IEventPublicLinkProps> = ({ eventDetails, setRefreshEventData }): React.ReactElement =>
{

    const { eventId } = useParams();

    const [publicURL, setPublicURL] = useState<string>(eventDetails?.link || '');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        setPublicURL(eventDetails?.link || '');
    }, [eventDetails?.link])

    const handleSubmit = async (): Promise<void> => {
        setIsSubmitting(true);
        try 
        {
            const data= {
                link: publicURL
            }
            const updateSyncCrm = await updateEvent(eventId as string, data);
            if(updateSyncCrm)
            {
                toast.success('Public URL updated successfully');
                setRefreshEventData(true);
            }
        }
        catch (error) 
        {   
            toast.error((error as Error)?.message || 'Failed to update Public URL');
            console.log(error)
        }
        finally
        {
            setIsSubmitting(false);
        }
    }

    return (
        <div id="eventPublicLink">
            <div className="event-public-link-container">
                <div className="title-description">
                    <h3 className="title">{'Public URL'}</h3>
                    <p className="description">{'Host your event landing page in this URL.'}</p>
                </div>

                <div className="public-url-container">
                    <div className="w-100">
                        <FormLabelComponent label="Enter URL" />
                        <FormControlComponent 
                            type="text"
                            value={publicURL}
                            onChange={(e) => setPublicURL(e.target.value)}
                        />
                        <p className="sample-url">{`${APP_CONSTANTS.APP_DOMAIN}/${publicURL}`}</p>
                    </div>
                    <CustomButton 
                        name="Update"
                        btnType="primary"
                        onClick={handleSubmit}
                        loading={isSubmitting}
                        disabled={isSubmitting || publicURL === eventDetails?.link}
                    />
                </div>
            </div>
        </div>
    )
}

export default EventPublicLink;