import { EventPhotoSharing, EventPhotoSharingFolder, GroupedPhotos } from '../../interfaces/event-photo-sharing_interface';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomSkeleton from '../../../../common/CustomSkeleton';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import checkedIcon from '../../../../../src/assets/icons/checked_blue.svg';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { approveUserUploadedPhotos, deletePhotos, hidePhotos, markDefaultPhotos, unmarkDefaultPhotos } from '../../../../scripts/apis/eventPhotoSharing';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventPhotoSharingStatus } from '../../enum/event-photo-sharing.enum';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import PopUpView from '../../../../common/PopupView';
import { DownloadButton } from './DownloadButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import toast from 'react-hot-toast';

import './styles.scss';
import CustomTooltip from '../../../../common/Tooltip';

interface PhotosProps {
    photos: EventPhotoSharing[];
    fetchPhotosForFolder: () => Promise<void>;
    hasMore: boolean;
    folderId: string | number;
    eventId: string | number;
    setFoldersRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
    folderData?: EventPhotoSharingFolder;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    minimalView?: boolean;
    openAddPhotosDrawer: () => void;
    photosCount: number;
    isPublished: boolean;
}


const Photos: React.FC<PhotosProps> = (props): React.JSX.Element =>
{

    const navigate = useNavigate();

    const currentpath = useLocation().pathname;
    const isHidden = currentpath?.includes('hidden');
    const isUserUploads = currentpath?.includes('user-uploads');
    const isApproved = currentpath?.includes('approved');
    const isFolder = /photo-sharing\/\d+$/.test(currentpath);
    const isDefault = currentpath?.includes('default');
    

    const { fetchPhotosForFolder, photos, hasMore, folderId, eventId, setOffset, setRefresh, minimalView, photosCount, openAddPhotosDrawer, folderData, isPublished, setFoldersRefresh } = props;

    const [selectedPhotos, setSelectedPhotos] = useState<EventPhotoSharing[]>([]);
    const [select, setSelectTrue] = useState<boolean>(false);
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [previewImg, setPreviewImg] = useState<any>(undefined);

    const [approvalSpinner, setApprovalSpinner] = useState<boolean>(false);

    const getPhotoHeaderTitle = () => 
    {
        if (isDefault) return 'Default';
        if (isUserUploads) return 'User Uploads';
        if (isApproved) return 'Approved';
        if (isHidden) return 'Hidden';
        return folderData?.name || 'Photos';
    };

    const getPhotoHeaderSubtitle = () => 
    {
        if (isUserUploads) return `Manage photos uploaded by your guests • ${photosCount || ''} Photos`;
        if (isApproved) return `Manage all user uploaded approved photos • ${photosCount || ''} Photos`;
        if (isHidden) return `Manage all the hidden photos here • ${photosCount || ''} Photos`;
        if (isDefault) return `Manage all the default photos here • ${photosCount || ''} Photos`;
        return `${folderData?.count} Photos`;
    };

    const getEmptyPhotoTitle = () => 
    {
        if (isDefault) return 'No photos selected as Default';
        if (isUserUploads) return 'No photos uploaded by users';
        if (isApproved) return 'No approved photos uploaded by users';
        if (isHidden) return 'No photos selected as Hidden';
        return 'Add photos to get started';
    };
    
    const handleHideFn = async () =>
    {
        try 
        {
            
            const groupedPhotos = selectedPhotos.reduce((acc: GroupedPhotos, photo) => {
                const photoSharingFolderId = photo.photoSharingFolderId;
                if (!acc[photoSharingFolderId]) {
                    acc[photoSharingFolderId] = [];
                }
                acc[photoSharingFolderId].push(photo.id);
                return acc;
            }, {});

            const data = {
                filter: Object.keys(groupedPhotos).map(folderId => ({
                    photoIds: groupedPhotos[folderId],
                    folderId: folderId,
                })),
                status: isHidden ? EventPhotoSharingStatus.ACTIVE : EventPhotoSharingStatus.HIDE,
            };
            const photosHidden = await hidePhotos(eventId, data);
            if(photosHidden)
            {
                setSelectedPhotos([]);
                setRefresh(true);
                setSelectTrue(false)
                setFoldersRefresh(true);
                setOffset(0);
            }
        } 
        catch (error) {
            console.log('Error in hiding photos', error);
        }
    };

    const deleteFn = async () =>
    {
        let deleteSuccess = true;

        eventBus.on('delete-event-photos', async ():Promise<void> => 
        { 
            if(deleteSuccess)
            {
                const data = {
                    photoIds: selectedPhotos?.map(item => item.id),
                };
                try 
                {
                    const photosDeleted = await deletePhotos(eventId, folderId || selectedPhotos[0].photoSharingFolderId, data);
                    if(photosDeleted)
                    {
                        setSelectedPhotos([]);
                        setSelectTrue(false)
                        setRefresh(true);
                        setFoldersRefresh(true);    
                        setOffset(0);
                        setSelectTrue(false);
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.CLOSE_POPUP_EVENT, true);
                    }
                } 
                catch (error) 
                {
                    console.log(error);
                }
                finally
                {
                    setRefresh(true);
                    navigate(0);
                }   
            }
        });
    };

    const handleMarkDefaultFn = async () =>
    {
        try 
        {
            const data = {
                photoIds: selectedPhotos?.map(item => item.id),
            };

            const markedDefault = await markDefaultPhotos(eventId, folderId, data);
            if(markedDefault)
            {
                setSelectedPhotos([]);
                setSelectTrue(false)
                setRefresh(true);
                setFoldersRefresh(true);
                setOffset(0);
            }
        } 
        catch (error) 
        {
            console.log('Error in marking default photos', error);
        }
    };

    const handleunMarkDefaultFn = async () =>
        {
            try 
            {
                const data = {
                    photoIds: selectedPhotos?.map(item => item.id),
                };
    
                const markedDefault = await unmarkDefaultPhotos(eventId, data);
                if(markedDefault)
                {
                    setSelectedPhotos([]);
                    setSelectTrue(false)
                    setRefresh(true);
                    setFoldersRefresh(true);
                    setOffset(0);
                }
            } 
            catch (error) 
            {
                console.log('Error in marking default photos', error);
            }
        };

    const handleDeleteFn = () => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
            open: true,
            title: 'Delete Photos',
            content: `Are you sure you want to ${isUserUploads ? 'reject' : 'delete'} ${selectedPhotos.length} photos?`,
            acceptBtn: 'Delete',
            acceptEvent: 'delete-event-photos',
            rejectBtn: 'Cancel',
        });

        deleteFn();
    };

    const handleApproveFn = async () =>
    {
        const photoIds = selectedPhotos?.map(item => item.id);
        setApprovalSpinner(true);

        try 
        {
            const photosApproved = await approveUserUploadedPhotos(eventId, { photoIds });
            if(photosApproved)
            {
                setSelectedPhotos([]);
                setSelectTrue(false);
                setRefresh(true);
                setFoldersRefresh(true);
                setOffset(0);
                toast.success('Photos approved successfully');
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error('Error in approving photos');
        }
        finally
        {
            setApprovalSpinner(false);
        }
    };

    return(
        <div id="photos">
            <div className='photo-header-container'>
                <div className="photo-header">
                    {isFolder && (
                        <div className='photo-header-back' onClick={() => navigate(`/events/${eventId}/photo-sharing`)}>
                            <FontAwesomeIcon icon={['fal', 'arrow-left']} />
                        </div>
                    )}
                    <div className='photo-header-text'>
                        <div className='photo-header-title'>
                            {getPhotoHeaderTitle()}
                        </div>
                        <div className='photo-header-subtitle'>
                            {getPhotoHeaderSubtitle()}
                        </div>
                    </div>
                </div>
                <div className='photo-header-actions'>
                    {!select &&
                        <div className={minimalView ? "photo-options-unselected-minimal" : "photo-options-unselected"}>
                            <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'check-square']} />} btnType='secondary' name='Select' onClick={() => { setSelectTrue(true); }} disabled={photos.length === 0 } />
                            <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'layer-group']} />} btnType='secondary' name='Select All' onClick={() => { setSelectTrue(true); setSelectedPhotos(photos); }} disabled={photos.length === 0} />
                        </div>
                    }
                    {
                        (select || selectedPhotos?.length > 0) && (
                        <div className={minimalView ? "photo-options-minimal" : "photo-options"}>
                         
                            {selectedPhotos?.length > 0 ? (
                                <>
                                    <div className='photos-count-text'>
                                        {selectedPhotos.length} Photos Selected
                                    </div>
                                    {!isUserUploads && !isApproved && (
                                        <CustomButton
                                        btnType="secondary"
                                        name={isHidden ? "Unhide" : "Hide"}
                                        startIcon={
                                            <FontAwesomeIcon
                                            icon={["fal", isHidden ? "eye" : "eye-slash"]}
                                            />
                                        }
                                        onClick={handleHideFn}
                                        />
                                    )}
                                    {!isUserUploads && !isApproved && !isHidden && !isDefault &&(
                                        <CustomButton
                                        btnType="secondary"
                                        name="Make default"
                                        onClick={handleMarkDefaultFn}
                                        />
                                    )}

                                    {isDefault &&(
                                        <CustomButton
                                        btnType="secondary"
                                        name="Remove Default"
                                        onClick={handleunMarkDefaultFn}
                                        />
                                    )}
                                    
                                    {isUserUploads && (
                                        <CustomButton
                                        loading={approvalSpinner}
                                        onClick={handleApproveFn}
                                        startIcon={<FontAwesomeIcon icon={["fal", "check"]} />}
                                        btnType="primary_outline"
                                        name="Approve"
                                        />
                                    )}
                                </>
                            ) : (
                                <div></div>
                            )}
                            {((select || selectedPhotos?.length > 0) && photos?.length > 0) && (
                                <CustomButton
                                    btnType="secondary"
                                    name="Deselect All"
                                    onClick={() => {
                                        setSelectedPhotos([]);
                                        setSelectTrue(false);
                                }}/>
                            )}
                            {selectedPhotos?.length > 0 && 
                                <CustomButton
                                        startIcon={<FontAwesomeIcon icon={["fal", "trash"]} />}
                                        btnType="danger"
                                        name={""}
                                        onClick={handleDeleteFn}
                                />
                            }
                        </div>
                        )
                    }
                    {isFolder && !select && (
                        <CustomTooltip title={isPublished ? "" : "Event is not published"}>
                            <div>
                                <CustomButton
                                    name=""
                                    btnType="primary"
                                    startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                                    onClick={() => {
                                        if (isPublished) {
                                            openAddPhotosDrawer();
                                        }
                                    }}
                                    disabled={!isPublished}
                                />
                            </div>
                        </CustomTooltip>
                    )}
                </div>
            </div>
            {photos.length > 0 ? (
                <InfiniteScroll
                    dataLength={photos.length * 25}
                    pullDownToRefreshThreshold={50}
                    next={fetchPhotosForFolder}
                    hasMore={hasMore}
                    loader={<Skeleton count={4}/>}
                    scrollableTarget="photos"
                    height={'calc(100vh - 320px)'}

                // endMessage={'No more photos to show'}
                >
                    <div className={minimalView ? "photos-container-minimal" : "photos-container"}>
                        {photos?.length > 0 && photos?.map((item, index) => {
                            const isSelected = selectedPhotos.some(selectedItem => selectedItem.id === item.id);
                            return (
                                <div className="image-wrapper">
                                    <LazyLoadImage alt={''} src={item?.imageUrl?.webUrl} key={index} effect='blur' placeholder={<CustomSkeleton />} onClick={() => {
                                        if(select) 
                                        {
                                            setSelectedPhotos(isSelected ? prevItems => prevItems.filter(prevItem => prevItem.id !== item.id) : prevItems => [...prevItems, item])
                                        }
                                        else
                                        {
                                            setOpenPopup(true);
                                            setPreviewImg(item || '');
                                        }
                                    }}/>
                                    {/* <img src={item?.imageUrl?.webUrl} key={index} alt='image photos' onClick={() => {
                                        if(select)
                                        {
                                            if (isSelected) {
                                                setSelectedPhotos(prevItems => prevItems.filter(prevItem => prevItem.id !== item.id));
                                            } else {
                                                setSelectedPhotos(prevItems => [...prevItems, item]);
                                            } 
                                        } 
                                        else
                                        {
                                            setOpenPopup(true);
                                            setPreviewImg(item || '');
                                        }
                                    }} /> */}
                                    {isSelected && <div className="selected-icon"><img src={checkedIcon} /></div>}
                                </div>
                            )
                        })}
                    </div>
                </InfiniteScroll>
            ) : (
                <div className="empty-photo-container">
                    <div className="empty-photo-title">{getEmptyPhotoTitle()}</div>
                </div>
            )}         
                
            {
                openPopup && <PopUpView extraButtons={[<DownloadButton downloadableUrl={previewImg?.imageUrl?.downloadableUrl} fileName={previewImg?.name} />]} popupContentStyle={{ width: 'fit-content', height: 'fit-content', maxWidth: '75%' }} popupStyle={{ background: 'var(--Component-colors-Alpha-alpha-white-80', backdropFilter: 'blur(24px)' }} onClose={(): void => 
                {
                    setOpenPopup(false); 
                }}>
                    <img src={previewImg?.imageUrl?.webUrl} className="popup-img" style={{ objectFit: 'contain' }} />
                </PopUpView>
            }
        </div>
    )
};

export const Skeleton = ({ count }: { count?: number }): React.JSX.Element =>
{
    return (
        <div className="skeleton-container">
            {Array.from({ length: count ? count : 16 }).map((_, index) => ( 
                <CustomSkeleton key={index} animation='pulse' height={160} variant='rect' width={200} />
            ) )}
        </div>
    )
};

export default Photos;