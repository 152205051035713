import React, { useContext, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { CONTENT } from '../../../scripts/i18n';
import eventBus from '../../../scripts/event-bus';
import { getEventById, publishEvent, updateEvent } from '../../../scripts/apis/events';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { addEvent } from '../../../redux/events/Events';
import './styles.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import APP_CONSTANTS from '../../../scripts/constants';
import Ticket, { RegistrationTypes } from './Registrations/Tickets/Ticket';
import TicketsPageProvider from '../../../contexts/EventDetailsRegistration/TicketsPageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalStorage } from '../../../scripts/LocalStorage';
import { ticketsCount } from '../../../scripts/apis/eventTickets';
import { uniqueTimezoneArr } from '../../../components/Events/timezoneGenerateFunction';
import { IEventReduxValues, IEventsDispatch } from '../../../components/Events/interfaces/create-events_interface';
import SideBarTabsNavigation from '../../../common/SideBarTabsNavigation/SideBarTabsNavigation';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import MuiChip from '../../../common/FormComponents/MuiChip';
import { Event, EventProspectJobStatus } from '../interfaces';
import { getAllEventCategories } from '../../../scripts/apis/eventCategory';
import { EditKey, EventLocationType, EventRegistrationTypes, EventStatus, EventTypes } from '../enum';
import toast from 'react-hot-toast';
import NotFoundPage from '../../Registration/Preview/NotFoundPage';
import CustomTooltip from '../../../common/Tooltip';
import EditableHeader from '../../../common/EditableHeader';
import DeletePopup from '../../../common/DeletePopup';
import { UserContext } from '../../../contexts/UserContext';
import { UserViewType } from '../../Settings/enum/users.enum';
import { Integrations, IntegrationsState } from '../../Settings/interface/integration_interface';
import { IntegrationTypes } from '../../Settings/enum/integrations.enum';
import EditEventPopup from '../../../components/Events/EventComponents/EditEventPopup';
import PopupDialog from '../../../common/PopupDialog';

const EventDetailsBar: React.FC<{ eventId: number; secondaryNavbar?: boolean; tab?: string; subTab?: string; }> = (props: { eventId?: number; secondaryNavbar?: boolean; tab?: string; subTab?: string }): React.JSX.Element => 
{

    const navigate = useNavigate();
    const tab = props.tab;

    const eventId = String(props.eventId);

    const { ticketId } = useParams();

    const {
        userDetails
    } = useContext(UserContext);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const integrations = useSelector((state: IntegrationsState): Integrations[] =>
    {
        return state.integrations.value;
    });

    const secondaryNavBar = props.secondaryNavbar;
    const dispatch = useDispatch();
    const currentPath = useLocation().pathname;

    const [isPublishEventEnabled, setPublishedEventEnabled] = useState(false);
    const [publishEventsRequiredData, setPublishEventsRequiredData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [eventData, setEventData] = useState<Event | null>(null);
    const [eventCategory, setEventCategory] = useState<string>('');
    const [showPublishDialog, setShowPublishDialog] = useState<boolean>(false);
    const [showCreateEventPopup, setShowCreateEventPopup] = useState<boolean>(false);

    const regTicketPaths = {
        TICKETS: '/events/' + eventId + '/registrations/tickets',
        TICKETS_BY_ID: '/events/' + eventId + '/registrations/tickets/' + ticketId,
        TICKET_GROUPS: '/events/' + eventId + '/registrations/tickets/groups',
    };

    const handleCreatePopupClose = (): void => {
        setShowCreateEventPopup(false);
    };

    const registrationsSecondaryNav = [
        // {
        //     title: 'Details',
        //     to: '/events/' + eventId + '/registrations/details',
        //     data: <Details data-id={eventId} eventReduxData={eventReduxData} />,
        //     selected: tab === 'details',
        // },
        {
            title: 'Ticket',
            to: '/events/' + eventId + '/registrations/tickets',
            data: <TicketsPageProvider eventId={Number(eventId)} ><Ticket eventReduxData={eventReduxData} tab={tab} eventId={Number(eventId)} /></TicketsPageProvider>,
            selected: tab === 'tickets',
            disabled: eventReduxData?.registrationType === RegistrationTypes.OPEN
        },
        // {
        //     title: 'Registration Form',
        //     to: '/events/' + eventId + '/registrations/form',
        //     data: <RegistrationForm reduxData={eventReduxData} data-id={eventId} />,
        //     selected: tab === 'regForm',
        // }
    ];

    if (eventReduxData?.registrationType === RegistrationTypes.OPEN && Object.values(regTicketPaths).includes(currentPath)) 
    {

        const ticketsTabObj = {
            title: 'Ticket',
            to: '/events/' + eventId + '/registrations/tickets',
            data: <NotFoundPage />,
            selected: tab === 'tickets',
            disabled: false
        };

        registrationsSecondaryNav.splice(1, 0, ticketsTabObj);
    }

    const PublishtheEvent = async (): Promise<void> => 
    {
        // const publishSuccess = true;
        // eventBus.on('publish-event', async (object): Promise<void> => 
        // {
        //     const acceptObj = object.acceptObj;
        //     if (publishSuccess) 
        //     {
        //         if (acceptObj.id) 
        //         {
        try 
        {
            const published = await publishEvent(Number(eventId));
            if (published) 
            {
                setShowPublishDialog(false);
                toast.success('Event Published Successfully');
                dispatch(addEvent({
                    status: published.status
                }));
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error(_.startCase((error as Error).message.toLowerCase()));
        }

        //         }
        //     }

        // });

    };

    const orgLink = LocalStorage.get('@Org')?.link;

    const handlePublish = async (): Promise<void> => 
    {
        try 
        {
            if (isPublishEventEnabled) 
            {
                setShowPublishDialog(true);
            }
            else 
            {
                if (publishEventsRequiredData.length > 0) 
                {
                    toast.error('Fill up the required details');
                }
                else 
                {
                    toast.error('Fill up the required details');
                }
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error(_.startCase((error as Error).message.toLowerCase()));
        }
    };

    const handleCopyLink = (): void => 
    {
        const orgLink = LocalStorage.get('@Org')?.link;
        navigator.clipboard.writeText(`${APP_CONSTANTS.PROTOCOL}://${orgLink}.${APP_CONSTANTS.APP_MAIN_DOMAIN}/${eventData?.link}`);

        toast.success('Event Link Copied');
    };

    const handleEditApproval = (): void =>
    {
        setShowCreateEventPopup(true);
    }

    const renderEventDetailsBar = (): React.JSX.Element =>
    {
        return (
            <div className="event-nav-bar-container">
                <div className={`events-name-block ${userDetails?.viewType === UserViewType.MINIMAL && 'minimal'}`}>
                    <Box className="events-name-redirect-icon-container">
                        {/* {
                            userDetails?.viewType === UserViewType.MINIMAL &&
                            <CustomTooltip title="Back to Events">
                                <FontAwesomeIcon cursor={"pointer"} icon={['fal', 'arrow-left']} onClick={() => navigate(userDetails?.viewType === UserViewType.MINIMAL ? `/events?eventPhase=${EventPhase.UPCOMING}` : `/events`)} />
                            </CustomTooltip> 
                        } */}
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <EditableHeader 
                                text={eventReduxData?.title || ''}
                                onChange={(value    ) => {
                                    dispatch(addEvent({
                                        title: value
                                    }))
                                }}
                                placeholder="Event Name"
                                handleSave={(): void => 
                                {
                                    editEventsFromTable();
                                }}
                            />

                            {
                                eventData?.status === EventStatus.PUBLISHED && eventReduxData.type === 1 ?
                                    <CustomTooltip title="Copy Event Public Link">
                                        <FontAwesomeIcon icon={['fal', 'copy']} className="event-link-copy-icon" onClick={(): void => 
                                        {
                                            handleCopyLink();
                                        }} />
                                    </CustomTooltip>
                                    : null
                            }
                        </Stack>

                        <Stack direction={'row'} spacing={0.5}>
                            {/* <CustomTooltip
                                title="Event Category"
                            >
                                <div>
                                    <MuiChip label={eventCategory} chipColor='grey' />
                                </div>
                            </CustomTooltip> */}

                            <CustomTooltip
                                title={eventData?.registrationType === EventRegistrationTypes.INVITE ? "Requires Approval to attend" : "Approval not Required"}
                            >
                                <CustomButton btnType='tertiary' onClick={handleEditApproval} name='' startIcon={<MuiChip label={eventData?.registrationType === EventRegistrationTypes.INVITE ? 'Approval Required' : 'Open'} chipColor='grey' />} />
                            </CustomTooltip>
                        </Stack>
                    </Box>
                    <div className="action-buttons">
                        {
                            eventData?.type === EventTypes.HOST &&
                            <a href={`${APP_CONSTANTS.PROTOCOL}://${orgLink}.${APP_CONSTANTS.APP_MAIN_DOMAIN}/${eventData?.link}/preview`} target='_blank' rel="noreferrer">
                                <CustomButton btnType='secondary' name='Preview Event' type='button' />
                            </a>
                        }

                        {
                            eventData?.type === EventTypes.HOST &&
                            <CustomButton disabled={eventData?.status === EventStatus.PUBLISHED} btnType='primary' name={eventData?.status === EventStatus.PUBLISHED ? 'Published' : 'Publish'} onClick={() => 
                            {
                                if(eventData?.status !== EventStatus.PUBLISHED)
                                {
                                    handlePublish();
                                }
                            }} />
                        }

                        {   
                            eventData && eventData?.locationType === EventLocationType.VIRTUAL && integrations && integrations?.some((integration) => integration.type === IntegrationTypes.ZOOM) &&
                            <a href={eventData?.webinarStartUrl} target='_blank'>
                                <CustomButton 
                                    btnType='primary'
                                    name='Start Webinar'
                                    type='button'
                                    disabled={!eventData?.webinarStartUrl || moment().unix() >= Number(eventData?.eventStartDateTime)}
                                />
                            </a>
                        }
                    </div>
                </div>
                 {
                    showCreateEventPopup && eventData &&(
                        <PopupDialog
                            modalId="editEventPopup"
                            show={showCreateEventPopup}
                            cancelClick={handleCreatePopupClose}
                            modalContent={
                                <EditEventPopup eventData={eventData} handleEditPopupClose={handleCreatePopupClose} routeFromId={false} fromOverview={true}  editKey={EditKey.APPROVAL_REQUIRED} />
                            }
                        />
                    )
                }
            </div>
        )
    };

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            const eventsData = await getEventById(String(eventId));
            if (eventsData && !(_.isEmpty(eventsData))) 
            {
                // eventBus.on('event-details-bar', (data) => {
                //     setEventStatus(data.eventStatus);
                //     setEventHeading(data.heading);
                //     setTopBtn(data.topButton);
                // });

                setEventData(eventsData);
                const eventCategoryData = await getAllEventCategories();
                if (eventCategoryData) 
                {
                    const defaultCategory = _.filter(eventCategoryData, function (elem): boolean 
                    {
                        return elem.id === eventsData?.eventCategoryId;
                    });
                    if (defaultCategory && defaultCategory[0]?.name !== '') 
                    {
                        setEventCategory(defaultCategory[0]?.name);
                    }
                }

                const defaultTimezone = _.filter(uniqueTimezoneArr, function (elem): boolean 
                {
                    return elem.value === eventsData.timezone;
                });

                if (defaultTimezone) 
                {
                    dispatch(addEvent({
                        timezoneDisplayName: defaultTimezone[0]?.name ? defaultTimezone[0]?.name : ''
                    }));
                }

                dispatch(addEvent({
                    title: eventsData.title,
                    description: eventsData.description,
                    link: eventsData.link,
                    timeZone: eventsData.timezone,
                    type: eventsData.type,
                    registrationType: eventsData.registrationType,
                    registrationFormId: eventsData.registrationFormId,
                    eventStartDate: moment.unix(eventsData.eventStartDateTime).format('YYYY-MM-DD'),
                    eventStartTime: moment.unix(eventsData.eventStartDateTime).format('HH:mm'),
                    eventEndDate: moment.unix(eventsData.eventEndDateTime).format('YYYY-MM-DD'),
                    eventEndTime: moment.unix(eventsData.eventEndDateTime).format('HH:mm'),
                    eventStartDateTime: eventsData.eventStartDateTime,
                    eventEndDateTime: eventsData.eventEndDateTime,
                    closingDateTime: eventsData.closingDateTime,
                    logo: eventsData.eventLogo,
                    banner: eventsData.eventBanner,
                    brandColor: eventsData.brandColor,
                    country: eventsData.country ? eventsData.country : '',
                    state: eventsData.state ? eventsData.state : '',
                    status: eventsData.status,
                    registerOptions: eventsData?.registerOptions,
                    eventCategoryId: eventsData?.eventCategoryId,
                    landingPageType: eventsData?.landingPageType,
                    eventFooterLinks: eventsData?.eventFooterLinks,
                    landingPageLayout: eventsData?.landingPageLayout,
                    bannerPreviewImg: eventsData?.eventBanner,
                    about: eventsData?.about,
                    enableWaitlistStatus: eventsData?.enableWaitlistStatus,
                    locationName: eventsData?.locationDetails?.name,
                    locationURL: eventsData?.locationDetails?.url,
                    integrationType: eventsData?.integrationType,
                    leadCaptureFormId: eventsData?.leadCaptureFormId,
                    invoiceDetails: eventsData?.invoiceDetails,
                    vcardSettings: eventsData?.vcardSetting,
                    syncCrm: eventsData?.syncCrm,
                    calendarInvitation: eventsData?.calendarInvitation,
                    calendarDescription: eventsData?.calendarDescription,
                    enableTransactionalMails: eventsData?.enableTransactionalMails,
                    prospectJobStatus: eventsData?.prospectJobStatus,
                }));

                if (eventsData?.type === EventTypes.SPONSORED || eventsData?.type === EventTypes.SPEAKER || eventsData?.type === EventTypes.ATTENDEE)
                {
                    setPublishedEventEnabled(true);
                    setRefresh(false);
                }
                else 
                {
                    const requiredDetails: string[] = [];
                    if (!eventsData.registrationFormId && eventsData?.type === EventTypes.HOST) 
                    {
                        requiredDetails.push('registrationFormId');
                    }
                    if (!eventsData.closingDateTime && eventsData?.type === EventTypes.HOST) 
                    {
                        requiredDetails.push('closingDateTime');
                    }
                    if (!eventsData.eventBanner && eventsData?.type === EventTypes.HOST) 
                    {
                        requiredDetails.push('eventBanner');
                    }
                    setPublishEventsRequiredData(requiredDetails);
                    setPublishedEventEnabled(false);
                    setRefresh(false);                    
                }
            }
        }
        catch (error) 
        {
            console.log(error);
            if((error as any)?.statusCode === 404) 
            {
                navigate('/events?eventPhase=2');
                toast.error(_.startCase((error as Error)?.message?.toLowerCase()));
            }
        }
    };

    useEffect((): void => 
    {
        eventBus.on('event-details-refreshed', (data): void => 
        {
            if (data.refresh) 
            {
                setRefresh(data.refresh);
            }
        });
        fetchData();
    }, []);

    const fetcheventDetails = async (): Promise<void> => 
    {
        if (!isPublishEventEnabled) 
        {
            setPublishEventsRequiredData([]);
            try 
            {
                const eventsData = await getEventById(String(eventId));
                if (eventsData) 
                {
                    if (eventsData.registrationFormId && eventsData.closingDateTime && eventsData.maximumRegistration && eventsData.eventBanner) 
                    {
                        try 
                        {
                            const eventTicketCount = await ticketsCount(Number(eventId));
                            if (eventTicketCount) 
                            {
                                if (eventTicketCount !== 0) 
                                {
                                    setPublishedEventEnabled(true);
                                    setRefresh(false);
                                }
                                else 
                                {
                                    setPublishedEventEnabled(false);
                                    setRefresh(false);
                                }
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }
                    }
                    else 
                    {
                        const requiredDetails: string[] = [];
                        if (!eventsData.registrationFormId && eventsData?.type === EventTypes.HOST) 
                        {
                            requiredDetails.push('registrationFormId');
                        }
                        if (!eventsData.closingDateTime && eventsData?.type === EventTypes.HOST) 
                        {
                            requiredDetails.push('closingDateTime');
                        }
                        if (!eventsData.maximumRegistration && eventsData?.type === EventTypes.HOST) 
                        {
                            requiredDetails.push('maximumRegistration');
                        }
                        if (!eventsData.eventBanner && eventsData?.type === EventTypes.HOST) 
                        {
                            requiredDetails.push('eventBanner');
                        }

                        try 
                        {
                            const eventTicketCount = await ticketsCount(Number(eventId));
                            if (eventTicketCount === 0 && eventsData?.type === EventTypes.HOST) 
                            {
                                requiredDetails.push('ticketGroup');
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }                        
                        setPublishEventsRequiredData(requiredDetails);
                        setPublishedEventEnabled(false);
                        setRefresh(false);
                    }
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
        else 
        {
            fetchData();
        }
    };

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetcheventDetails();
        }
    }, [refresh]);

    const editEventsFromTable = async (): Promise<void> => 
    {
        if(eventReduxData?.title === eventData?.title) return;

        if(eventReduxData?.title === '') 
        {
            toast.error('Event Name cannot be empty');
            dispatch(addEvent({ 
                title: eventData?.title
            }));
            return;
        }

        const eventDetails = {
            title: eventReduxData?.title,
        };
        try 
        {
            const eventsDataUpdated = await updateEvent(String(eventId), eventDetails);
            if (eventsDataUpdated) 
            {
                setRefresh(true);

                eventBus.dispatch('event-overview-refreshed', {
                    refresh: true
                });

                eventBus.dispatch('Refresh-events-top-bar', {
                    refresh: true
                });

                if (!(eventsDataUpdated instanceof Error)) 
                {
                    dispatch(addEvent({
                        title: eventsDataUpdated.title
                    }));
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    return (
        <div id="subEventDetailsContainer">
            <div id="eventDetailsBar">
                {renderEventDetailsBar()}
            </div>

            {
                showPublishDialog &&
                <DeletePopup 
                    show={showPublishDialog}
                    modalTitle='Publish Event'
                    modalContent={`Event link cannot be updated once the event is published.  https://${orgLink}.eventhq.com/${eventReduxData?.link} ?`}
                    acceptBtn='Publish'
                    rejectBtn={CONTENT.EVENTS_PAGE.DELETE_POPUP.CANCEL_BTN}
                    acceptClick={PublishtheEvent}
                    cancelClick={(): void => setShowPublishDialog(false)}
                    acceptButtonVariant='primary'
                />
            }

            <Box className="event-details-block-with-tab">

                {
                    secondaryNavBar &&
                    <SideBarTabsNavigation
                        navigationBlocks={[
                            {
                                title: 'Overview',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/overview'); 
                                },
                                selected: ['overview', 'speakers', 'sponsors', 'vendors'].some(path => currentPath?.includes(path)),
                            },
                            {
                                title: 'Registrations',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/registrations'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/registrations'
                            },
                            {
                                title: 'Agenda',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/agenda'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/agenda',
                            },
                            // {
                            //     title: 'Tickets',
                            //     navigation: ():void => 
                            //     {
                            //         return  navigate('/events/' + eventId + '/tickets'); 
                            //     },
                            //     selected: currentPath === '/events/' + eventId + '/tickets'
                            // },
                            {
                                title: 'Registrants',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/people/registrants'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/people/registrants'
                            },
                            {
                                title: 'Sponsors',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/people/sponsors'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/people/sponsors'
                            },
                            {
                                title: 'Speakers',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/people/speakers'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/people/speakers'
                            },
                            {
                                title: 'Vendors',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/people/vendors'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/people/vendors'
                            },
                            {
                                title: 'Budget',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/budget'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/budget'
                            },
                            {
                                title: 'Marketing',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/marketing'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/marketing'
                            },
                            {
                                title: 'Analytics',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/analytics'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/analytics'
                            },
                            {
                                title: 'Access Control',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/access-control'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/access-control',
                            },
                            {
                                title: 'Connect',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/connect'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/connect',
                            },
                            {
                                title: 'Photo Sharing',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/photo-sharing'); 
                                },
                                selected: currentPath?.includes('photo-sharing'),
                            },
                        ]}
                    />
                }
            </Box>
          
        </div>
    );
};

export default EventDetailsBar;



const isDateinPast = (date): boolean => 
{
    const currentDate = moment.now();
    const difference = currentDate - (date * 1000);

    if (difference < 0) 
    {
        return true;
    }
    else if (difference <= 5 * 60 * 1000) 
    {
        return false;
    }
    else if (difference >= 5 * 60 * 1000) 
    {
        return true;
    }
    else 
    {
        return false;
    }
};