import { useState } from "react";
import { EventRegistrantFeedbackCount, EventSession, EventStage } from "../../../../pages/Events/interfaces";
import moment from "moment";
import { CONTENT } from "../../../../scripts/i18n";
import { Avatar, AvatarGroup } from "@mui/material";
import CustomTooltip from "../../../../common/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidatableTabs from "../../../../common/ValidateableTabs";
import { createColumnHelper } from "@tanstack/react-table";
import { EventRegistrant, EventRegistrantFeedback } from "../../../../pages/Events/interfaces/event-registrant_interface";
import { buildInitialsForPicture, camelCaseToWords } from "../../../../scripts/helpers";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import _ from "lodash";
import TablePopupDetails from "../../../../common/TablePopup/TablePopupDetails";
import AddSession from "../AddSession";
import RegistrantDetailedInfo from "../../EventRegistrants/RegistrantDetails/RegistrantDetailedInfo";
import { CustomButton } from "../../../../common/FormComponents/Buttons";

export const SessionFeedbackComponent: React.FC<{ sessionData: EventSession; stageData: EventStage | null; onSessionFeedbackRowClick: (row: EventRegistrant) => void; onExpandClick: (component: 'sessionFeedbacks' | 'registrantsInfo') => void; setRefresh: React.Dispatch<React.SetStateAction<boolean>>; feedbackResponseCount: EventRegistrantFeedbackCount; feedbacksData: EventRegistrant[]; handleInScreenPopupClose: () => void; }> = ({ sessionData, stageData, onSessionFeedbackRowClick, onExpandClick, setRefresh, feedbackResponseCount, feedbacksData, handleInScreenPopupClose }) =>
{
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const SessionDetails = () =>
    {

        const speakerImage = sessionData?.speakerIds && sessionData?.speakerIds?.length > 0 ? sessionData.speakerIds.map((item) => 
        {
            const ids = _.find(sessionData?.speakers, ['id', String(item)]);
            if (ids) 
            {
                return ids?.additionalInfo?.image;
            }
        }) : [];

        const speakerName = sessionData?.speakerIds && sessionData?.speakerIds?.length > 0 ? sessionData.speakerIds.map((item) => 
        {
            const ids = _.find(sessionData?.speakers, ['id', String(item)]);
            if (ids) 
            {
                return ids?.name;
            }
        }) : [];

        return (
            <div className="session-details">
                <div className="session-info">
                    <div className="session-timing-container">
                        <p className="data-value">{stageData?.title}</p>
                        <p className="session-info-separator" />
                        <p className="data-value">{`${moment.unix(Number(sessionData?.startDateTime))?.format('HH:mm A')}-${moment.unix(Number(sessionData?.endDateTime))?.format('HH:mm A')}`}</p>
                        <p className="session-info-separator" />
                        <p className="data-value">{CONTENT.EVENTS_MODULE.SESSION.SESSION_TYPES[sessionData?.type as keyof typeof CONTENT.EVENTS_MODULE.SESSION.SESSION_TYPES]}</p>
                    </div>
                    <header className="session-title">{sessionData?.title}</header>
                </div>
                <AvatarGroup max={5} total={sessionData?.speakerIds?.length}>
                    {speakerImage?.length > 0 && speakerImage.map((item, index) => 
                        {
                            return (
                                <CustomTooltip title={speakerName[index]} key={index}>
                                    <Avatar key={index} alt="speaker image" src={item} style={{ width: 24, height: 24 }}>
                                        {item ? null : speakerName[index]?.charAt(0)}
                                    </Avatar>
                                </CustomTooltip>
                            )
                        }
                    )}
                </AvatarGroup>
            </div>
        )
    }

    const tabDetails = [
        {
            title: 'Feedbacks',
            selected: selectedTab === 0,
            count: feedbackResponseCount?.all,
            data: <SessionFeedbackTable feedbacks={feedbacksData} onSessionFeedbackRowClick={(row: EventRegistrant) => onSessionFeedbackRowClick(row)} onExpandClick={(componentType: 'sessionFeedbacks' | 'registrantsInfo') => onExpandClick(componentType)} />
        },
        {
            title: 'Details',
            selected: selectedTab === 1,
            data: <SessionDetailsTab handleInScreenPopupClose={handleInScreenPopupClose} sessionData={sessionData} setRefresh={setRefresh} />
        }
    ]

    return (
        <div className="agenda-in-screen-popup-container">
            <SessionDetails />
            <ValidatableTabs 
                tabData={tabDetails} 
                value={selectedTab}
                onTabChange={setSelectedTab}
            />
        </div>
    )
}

const SessionFeedbackTable: React.FC<{ onSessionFeedbackRowClick: (row: EventRegistrant) => void; onExpandClick: (componentType: 'sessionFeedbacks' | 'registrantsInfo') => void; feedbacks?: EventRegistrant[] }> = ({ onSessionFeedbackRowClick, onExpandClick, feedbacks }): React.JSX.Element =>
{

    const columnHelper = createColumnHelper<EventRegistrant>();
    const columns = [
        columnHelper.accessor('companyLogo' as any, {
            cell: (row) => {
                const clearbitFName = row?.row?.original?.clearbitData?.givenName;
                const clearbitLName = row?.row?.original?.clearbitData?.familyName;
                const name = clearbitFName && clearbitLName ? buildInitialsForPicture('', clearbitFName, clearbitLName) : row?.row?.original?.firstName ? buildInitialsForPicture('', row?.row?.original?.firstName, '') : buildInitialsForPicture('', row?.row?.original?.firstName, '');

                return (
                    <Avatar className="logo-avatar-img" src={row?.row?.original?.clearbitData && row?.row?.original?.clearbitData?.companyLogo ? row?.row?.original?.clearbitData?.companyLogo : ''}>
                        {!row?.row?.original?.clearbitData?.companyLogo ? name : ''}
                    </Avatar>
                );
            },
            header: 'Image',
            enableSorting: false,
            id: 'companyLogo',
        }),
        columnHelper.accessor('name' as any, {
            cell: (row) => {
                return (
                    <div>
                        <p className="bolded-cellContent">{`${row.row.original.firstName} ${row.row.original?.lastName || ''}`}</p>
                        <p className="cellContent">{row.row.original?.email}</p>
                    </div>
                )
            },
            header: 'Name',
            size: 180,
            minSize: 180,
            maxSize: 180,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'firstName'
        }),
        columnHelper.accessor('feedback', {
            cell: (row) => {
                return (
                    <div>
                        <p className="cellContent">{`${row.row.original.feedbackData?.formData['rateYourExperience']}`}</p>
                    </div>
                )
            },
            header: 'NPS',
            size: 20,
            minSize: 20,
            maxSize: 20,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'feedback'
        }),
        columnHelper.accessor('expand' as any, {
            cell: (row) => {
                return (
                    <CustomButton  
                        startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-right-and-arrow-down-left-from-center']} />}
                        btnType="tertiary_grey"
                        onClick={() => {
                            onSessionFeedbackRowClick(row.row.original);
                            onExpandClick('registrantsInfo');
                        }}
                        name=""
                    />
                )
            },
            header: '',
            size: 20,
            minSize: 20,
            maxSize: 20,
            enableSorting: false,
        }),
    ]

    return (
        <div className="session-feedback-table">
            <TanstackTable 
                data={feedbacks as EventRegistrant[]}
                initialColumns={columns}
                rowCount={feedbacks?.length || 0}
                showSpinner={false}
                height="480px"
                showBottomPadding={false}
                hidePagination
            />
        </div>
    )
}

export const RegistrantFeedbacks: React.FC<{ registrantData: EventRegistrant | null }> = ({ registrantData }): React.ReactElement =>
{
    const imageSrc = `${registrantData?.clearbitData ? registrantData?.clearbitData?.companyLogo : ''}`;
    const altImage= `${buildInitialsForPicture(registrantData?.email, registrantData?.firstName, registrantData?.lastName || '')}`;
    const title = `${registrantData?.firstName} ${registrantData?.lastName}`;
    const subTitle = `${registrantData?.clearbitData ? registrantData?.clearbitData?.company : ''}`;
    const email = `${registrantData?.email}`;
    const icp = registrantData?.icp;
    const buyerPersona = registrantData?.buyerPersona;

    const registrantsBasedOnRating = () =>
    {
        const rating = registrantData?.feedbackData?.formData['rateYourExperience'];
        if(rating)
        {
            return Number(rating) >= 9 ? ['Promoter'] : Number(rating) >= 7 ? ['Passive'] : ['Detractor'];
        }
    }

    const [selectedTab, setSelectedTab] = useState<number>(0);

    const tabDetails = [
        {
            title: 'Responses',
            data: <RegistrantSessionResponses feedbackData={registrantData?.feedbackData} />
        },
        {
            title: 'Details',
            data: <RegistrantDetailedInfo registrantData={registrantData as EventRegistrant} source="agenda" />
        }
    ]

    return (
        <div className="registrant-feedbacks">
            <TablePopupDetails 
                title={title}
                subTitle={subTitle}
                email={email}
                icp={icp}
                buyerPersona={buyerPersona}
                imageSrc={imageSrc}
                altImage={altImage}
                otherInitialBadges={registrantsBasedOnRating()}
            />
            <ValidatableTabs 
                tabData={tabDetails} 
                value={selectedTab}
                onTabChange={setSelectedTab}
            />
        </div>
    )
}

const RegistrantSessionResponses: React.FC<{ feedbackData?: EventRegistrantFeedback }> = ({ feedbackData }): React.ReactElement =>
{
    return (
        <ol className="registrant-session-responses">
            {
                feedbackData && Object.keys(feedbackData?.formData).map((item, index) => 
                {
                    return (
                        <div className="response-item" key={index}>
                            <p className="response-index">{`${index+1}:`}</p>
                            <div className="response-item-content">
                                <p className="response-item-title">{camelCaseToWords(item)}</p>
                                <p className="response-item-content">{feedbackData?.formData[item] || '-'}</p>
                            </div>
                        </div>
                    )
                })
            }
        </ol>
    )
}

const SessionDetailsTab: React.FC<{ sessionData: EventSession; setRefresh: React.Dispatch<React.SetStateAction<boolean>>; handleInScreenPopupClose: () => void; }> = ({ sessionData, setRefresh, handleInScreenPopupClose }): React.ReactElement =>
{
    return (
        <div className="session-details-tab">
            <AddSession 
                eventId={sessionData?.eventId as string} 
                stageId={sessionData?.stageId as string} 
                setRefresh={setRefresh} 
                sessionData={sessionData} 
                stageData={sessionData?.stage} 
                source="feedbacks"
                handleInScreenPopupClose={handleInScreenPopupClose}
            />
        </div>
    )
}
