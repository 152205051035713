import React, { useContext } from 'react'
import Stepper, { CheckoutStep } from '../../../../../common/Stepper';
import FilterEventTargetListComponent from '../Components/ByPastEvents/FilterEventTargetListComponent';
import BuildTargetListComponent from '../Components/ByPastEvents/BuildTargetListComponent';
import ViewAllEventInTargetListComponent from '../Components/ByPastEvents/ViewAllEventInTargetListComponent';
import { useDispatch, useSelector } from 'react-redux';
import { creatTargetListByEventId, updateEventTargetList } from '../../../../../scripts/apis/eventTargetList';
import { EventTargetList, IEventTargetListDispatch, IEventTargetListReduxData } from '../../../interfaces/event-target-list-interface';
import { useNavigate } from 'react-router-dom';
import { resetTargetList } from '../../../../../redux/events/eventTargetList/targetList';
import { EventTargetListContext } from '../../../../../contexts/EventTargetList/EventTargetListContext';
import _ from 'lodash';

const TargetListByPastEventsPage: React.FC = (): JSX.Element =>
{

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listId, existingTargetList } = useContext(EventTargetListContext);

  const eventTargetListReduxData = useSelector((state: IEventTargetListDispatch): IEventTargetListReduxData => 
  {
    return state.eventTargetList.value;
  });
  const csrfTokenData = useSelector((state: any): string => 
  {
    return state['csrfTokenValue'].value.csrfToken;
  });

  const submitTargetList = async () =>
  {
    try
    {
      const eventId = eventTargetListReduxData.eventId;

      const mergedAudienceIds = _.union(
        existingTargetList?.audienceIds || [],
        eventTargetListReduxData.audienceIds || []
      );

      const mergedTargetListAudienceIds = _.union(
        existingTargetList?.targetListAudienceIds || [],
        eventTargetListReduxData.targetListAudienceIds || []
      );

      const mergedSources = _.union(
        eventTargetListReduxData.source || [],
        existingTargetList?.source || []
      );

      const eventTargetList: EventTargetList = {
        name: eventTargetListReduxData.name,
        description: eventTargetListReduxData.description,
        type: eventTargetListReduxData.type,
        source: mergedSources,
        audienceIds: [], // Placeholder, will be sent in batches
        targetListAudienceIds: mergedTargetListAudienceIds,
      };

    // Chunk audienceIds into batches of 100
    const audienceIdBatches = _.chunk(mergedAudienceIds, 100);

    let createdListId = listId;

    for (const batch of audienceIdBatches) {
      const payload = {
        ...eventTargetList,
        audienceIds: batch, // Send only the current batch
      };

      if (createdListId) 
      {
        await updateEventTargetList(eventId, createdListId, payload);
      }
      else 
      {
        const result = await creatTargetListByEventId(eventId, payload, csrfTokenData);
        createdListId = String(result?.id);
      }
    }
      navigate(`/events/${eventId}/target-list/${createdListId}`);
      dispatch(resetTargetList());
    }
    catch (error) 
    {
      console.error('Error submitting target list:', error);
      throw error;
    }
  };

  const CHECKOUT_STEPS: CheckoutStep[] = [
    {
      name: "Past Events",
      Component: <ViewAllEventInTargetListComponent />
    },
    {
      name: "Filters",
      Component: <FilterEventTargetListComponent />
    },
    {
      name: "Build List",
      Component: <BuildTargetListComponent />,
      onComplete: async () =>
      {
        try 
        {
          await submitTargetList();
        }
        catch (error)
        {
          console.error("Submission failed:", error);
        }
      },
    },
  ];

  return (<Stepper props={ CHECKOUT_STEPS } />)
}

export default TargetListByPastEventsPage;

