import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getRegistrationForms, registrationFormsCount } from '../../../../scripts/apis/registrationForm';
import _ from 'lodash';
import SelectRegistrationForm from '../../../../components/Events/EventRegistrations/SelectRegistrationForm';
import './styles.scss';
import CardComponent from '../../../../components/Events/Registrations/CardComponent';
import { getFormEmbedCodeSnippet } from '../../../../scripts/apis/events';
import { RegistrationFormType } from '../../../Registration/enum/custom-field.enum';
import { EventAdditionalForms } from '../../interfaces';
import { RegistrationForm } from '../../../Registration/interfaces/registration-form_interface';
import NPSFormSelectionDetails from '../../../../components/Events/EventRegistrations/NPSForm/NPSFormSelectionDetails';
import PopupDialog from '../../../../common/PopupDialog';


const RegistrationFormsSection: React.FC<{eventId: number; registrationFormId: string; additionalForms?: EventAdditionalForms;}> = ({eventId, registrationFormId, additionalForms}): React.JSX.Element => 
{
    const [formName, setFormName] = useState({
        registrationForm: '',
        npsForm: ''
    });
    const [codeSnippet, setCodeSnippet] = useState('');
    const [registrationForms, setRegistrationForms] = useState<RegistrationForm[]>([]);
    const [selectedPopupComponent, setSelectedPopupComponent] = useState<React.ReactNode>(null);
    
    const handlePopupClose = (): void =>
    {
        setSelectedPopupComponent(null);
    }

    useEffect((): void => 
    {
        const fetchData = async (): Promise<void> => 
        {
            const count = await registrationFormsCount();
            const forms = await getRegistrationForms(count, 0, undefined, `${RegistrationFormType.REGISTRATION_FORM},${RegistrationFormType.NPS}`);
            const code = await getFormEmbedCodeSnippet(eventId);
            setCodeSnippet(code);
            setRegistrationForms([...forms]);
            if (forms) 
            {
                const defaultRegistrationForm = _.find(forms, function (elem): boolean 
                {
                    if (registrationFormId !== '') 
                    {
                        return elem?.id.toString() === registrationFormId;
                    }
                    else 
                    {
                        return false;
                    }
                });

                const defaultNPSForm = _.find(forms, function (elem): boolean
                {
                    if (additionalForms?.npsForm?.id !== '') 
                    {
                        return elem?.id.toString() === additionalForms?.npsForm?.id;
                    }
                    else 
                    {
                        return false;
                    }
                });

                setFormName({
                    registrationForm: defaultRegistrationForm && defaultRegistrationForm.name ? defaultRegistrationForm.name : '',
                    npsForm: defaultNPSForm && defaultNPSForm?.name ? defaultNPSForm?.name : ''
                });
                // setFormDescription({
                //     registrationForm: defaultRegistrationForm?.description || '',
                //     npsForm: defaultNPSForm?.description || ''
                // });
            }
        };
        fetchData();
    }, [registrationFormId, additionalForms]);

    const handleEditRegistrationFormDetail = (type?: RegistrationFormType): void => 
    {
        const forms = () =>
        {
            switch(type)
            {
                case RegistrationFormType.REGISTRATION_FORM:
                    return registrationForms?.filter((form) => form?.type === RegistrationFormType.REGISTRATION_FORM);
                case RegistrationFormType.NPS:
                    return registrationForms?.filter((form) => form?.type === RegistrationFormType.NPS);
                default:
                    return [];
            }
        }
        const selectedFormType = () =>
        {
            switch(type)
            {
                case RegistrationFormType.REGISTRATION_FORM:
                    return <SelectRegistrationForm formName={formName?.registrationForm} regForms={forms()} eventId={String(eventId)} registrationFormId={registrationFormId} codeSnippet={codeSnippet} heading={'Registration Form'} handlePopupClose={handlePopupClose} />;
                case RegistrationFormType.NPS:
                    return <NPSFormSelectionDetails regForms={forms()} eventId={String(eventId)} codeSnippet={codeSnippet} additionalForms={additionalForms} heading={'NPS Form'} handlePopupClose={handlePopupClose} />
                default:
                    return '';
            }
        }

        setSelectedPopupComponent(selectedFormType());
    };

    return (
        <div id="registrationFormsSection">
            <div className="header-container">
                <p className="header-text">
                    {'Forms'}
                </p>
            </div>
            <Grid container spacing={2} className="reg-forms-section-container">
                <Grid item xl={4} lg={4} md={4} sm={4} className="selected-form-container">
                    <CardComponent  header={'Registration Form'} contentHeading={formName?.registrationForm || 'Select Form'} onClick={() => handleEditRegistrationFormDetail(RegistrationFormType.REGISTRATION_FORM)} cardMinHeight='90px' contentHeadingBold />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} className="selected-form-container">
                    <CardComponent header={'NPS Form'} contentHeading={formName?.npsForm || 'Select Form'} onClick={() => handleEditRegistrationFormDetail(RegistrationFormType.NPS)} cardMinHeight='90px' contentHeadingBold />
                </Grid>
            </Grid>
            {
                selectedPopupComponent &&
                <PopupDialog
                    modalId={'formsPopup'}
                    show={!!selectedPopupComponent}
                    cancelClick={handlePopupClose}
                    modalContent={selectedPopupComponent}
                />
            }
        </div>
    );
};

export default RegistrationFormsSection;