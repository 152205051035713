import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import { UserContext } from "../../../../contexts/UserContext";

import { UserViewType } from "../../../Settings/enum/users.enum";
import { EventTypes } from "../../enum";

import useEventOverviewData from "../../../../hooks/eventOverviewData";

import EventBuyerPersonaSettings from "../../../../components/Events/EventSettings/EventBuyerPersonaSettings";
import EventICPSettings from "../../../../components/Events/EventSettings/EventICPSettings";
import EventModeratorSettings from "../../../../components/Events/EventSettings/EventModeratorSettings";
import DeleteEventComponent from "../../../../components/Events/EventSettings/DeleteEventComponent";
import IntegrationSync from "../../../../components/Events/EventSettings/IntegrationSync";
import EventCalendarDescription from "../../../../components/Events/EventSettings/EventCalendarDescription";
import EventTransactionEmailToggle from "../../../../components/Events/EventSettings/EventTransactionEmailToggle";
import EventPublicLink from "../../../../components/Events/EventSettings/EventPublicLink";
import EventsHeaderBar from "../../../../common/EventsHeaderBar";

import './styles.scss';

const EventSettingsPage: React.FC = (): React.JSX.Element =>
{

    const { eventId } = useParams();
    
    const {
        userDetails
    } = useContext(UserContext);

    const [eventDetails, , , , , , setRefreshEventData] = useEventOverviewData({
        eventId: Number(eventId)
    });

    const renderEventSettings = (): React.JSX.Element =>
    {
        return (
            <div>
                <EventsHeaderBar title="Event Settings" showBorder />
                <EventICPSettings eventDetails={eventDetails} setRefreshEventData={setRefreshEventData} />
                <EventBuyerPersonaSettings eventDetails={eventDetails} setRefreshEventData={setRefreshEventData} />
                {eventDetails && eventDetails?.type === EventTypes.HOST && <EventCalendarDescription eventDetails={eventDetails} setRefreshEventData={setRefreshEventData} />}
                <IntegrationSync eventDetails={eventDetails} setRefreshEventData={setRefreshEventData} />
                {eventDetails && eventDetails?.type === EventTypes.HOST && <EventTransactionEmailToggle eventDetails={eventDetails} setRefreshEventData={setRefreshEventData} />}
                {eventDetails && eventDetails?.type === EventTypes.HOST && <EventPublicLink eventDetails={eventDetails} setRefreshEventData={setRefreshEventData} />}
                {eventDetails && eventDetails?.type === EventTypes.HOST && <EventModeratorSettings />}
                <DeleteEventComponent />
            </div>
        );
    };

    const renderMinimalView = (): React.JSX.Element =>
    {
        return (
            <div className="event-settings-minimal">
                {renderEventSettings()}
            </div>
        )
    };

    return (
        <div id="eventSettingsPage">
            {
                userDetails?.viewType === UserViewType.MINIMAL ? renderMinimalView() : renderEventSettings()
            }
        </div>
    );
}

export default EventSettingsPage;