import { Box } from '@mui/material';
import React, { } from 'react';
import { useParams } from 'react-router-dom';
import eventBus from '../../scripts/event-bus';
import Overview from './EventDetails/Overview/Overview';
//import Analytics from './EventDetails/Analytics/Main/Analytics';
import EventDetailsBar from './EventDetails/EventDetailsBar';
import './styles.scss';
import { useSelector } from 'react-redux';
import EventBudget from './EventBudget/EventBudgetMain';
import EventMarketingProvider from '../../contexts/EventMarketing/EventMarketingContext';
import MarketingPage from './EventDetails/Marketing/MarketingPage';
import EventRegistrationDetails from './EventDetails/Registrations/EventRegDetails';
import UtmComponent from './EventDetails/Registrations/EventShareLink/UTMTable';
import { IEventReduxValues, IEventsDispatch } from '../../components/Events/interfaces/create-events_interface';
import TicketsPageProvider from '../../contexts/EventDetailsRegistration/TicketsPageContext';
import Ticket from './EventDetails/Registrations/Tickets/Ticket';
import EventAccessControlProvider from '../../contexts/EventAccessControl/EventAccessControlContext';
import EventAccessControlPage from './EventDetails/Accesscontrol/EventAccessControlPage';
import EventStageProvider from '../../contexts/EventStage/EventStageContext';
import StagePage from './EventDetails/Stage/StagePage';
import PhotosPage from './EventDetails/PhotoSharing/PhotosPage';
import EventPhotoSharingProvider from '../../contexts/EventPhotoSharing/EventPhotoSharingContext';
import CouponsPage from './EventDetails/EventTicketCoupons/CouponsPage';
import CouponsProvider from '../../contexts/EventCoupons/EventCouponsContext';
import EventAnalyticsPage from './EventDetails/EventAnalytics/pages/EventAnalyticsPage';
import { IBillingInfo } from '../../redux/billing/billing-redux_interface';
import SpeakersProvider from '../../contexts/SpeakersPageContext';
import SpeakersPage from '../Speakers/SpeakersPage';
import SponsorsPageProvider from '../../contexts/SponsorsPageContext';
import SponsorsPage from '../Sponsors/SponsorsPage';
import VendorsProvider from '../../contexts/VendorsPageContext';
import VendorsPage from '../Vendors/VendorsPage';
import EventRegistrantsPage from './EventDetails/People/EventRegistrants/EventRegistrantsPage';
import EventTargetListPage from './EventDetails/TargetList/EventTargetListPage';
import EventTargetListProvider from '../../contexts/EventTargetList/EventTargetListContext';
import CreateEventTargetListPage from './EventDetails/TargetList/CreateEventTargetListPage';
import EventTargetListAudiencePage from './EventDetails/TargetList/EventTargetListAudiencePage';
import EventSettingsPage from './EventDetails/EventSettings/EventSettingsPage';
import ViewAllDevicesComponent from './EventDetails/Accesscontrol/Components/Devices/ViewAllDevicesComponent';
import ViewAllNotificationsComponent from './EventDetails/Accesscontrol/Components/Notifications/ViewAllNotificationsComponent';
import EventSocialSharePage from './EventDetails/EventSocialShare/EventSocialSharePage';
import LandingPagePreview from './EventDetails/Overview/LandingPagePreview';
import EventProspectsPage from './EventDetails/EventProspects/EventProspectsPage';

const EventsInfo = (props): React.JSX.Element =>         
{  

    const secondaryNavBar = props['secondary-navbar'];
    const navBarAndTopBar = props['navabar-topbar'];

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const { eventId, listId } = useParams();
    const pageData = props.data;
    
    const isEventOverviewComponent = pageData === 'overview';
    const isMarketingComponent = pageData === 'marketing';
    const isEventAnalyticsComponent = pageData === 'analytics';
    const isEventBudgetComponent = pageData === 'budget';
    const isEventRegistrationComponent = pageData === 'details';
    const isCouponsComponent = pageData === 'coupons';
    const isUtmComponent = pageData === 'utm';
    const isTicketsComponent = pageData === 'tickets';
    const isRegistrantsComponent = pageData === 'registrants';
    const isEventSponsorComponent = pageData === 'sponsors';
    const isEventSpeakerComponent = pageData === 'speakers';
    const isEventVendorComponent = pageData === 'vendors';
    const isCheckInComponent = pageData === 'checkIn';
    const isAllDevicesComponent = pageData === 'allDevices';
    const isCheckInNotificationsComponent = pageData === 'checkInNotifications';
    const isEventAgendaComponent = pageData === 'agenda';
    const isEventTargetListComponent = pageData === 'targetList';  
    const isCreateEventTargetListComponent = pageData === 'createTargetList';
    const isUpdateEventTargetListComponent = pageData === 'updateTargetList';
    const isEventTargetListAudienceComponent = pageData === 'targetListAudience'
    const isPhotoSharingComponent = pageData === 'photoSharing';
    const isEventSettingsComponent = pageData === 'settings';
    const isEventProspectsComponent = pageData === 'prospects';
    const isRegistrationsComponent = pageData === 'registrations';
    const isEventSocialShareComponent = pageData === 'socialShare';
    const isLandingPageComponent = pageData === 'landingPage';


    eventBus.dispatch('event-details-bar', {
        heading: eventReduxData.title,
        eventStatus: eventReduxData.status,
        backBtn: true,
    });

    return (
        <Box id="eventsInfo">
            {!navBarAndTopBar && <EventDetailsBar eventId={Number(eventId)} secondaryNavbar={secondaryNavBar} tab={props.tab} subTab={props.subTab} />}
            <Box className="w-100 content-box" sx={{
                display: 'flex', flexDirection: 'row' 
            }}>
                {isEventOverviewComponent && <Overview eventId={Number(eventId)} />}
                {isMarketingComponent && <EventMarketingProvider eventId={Number(eventId)} ><MarketingPage /></EventMarketingProvider>}
                {isEventAnalyticsComponent && <EventAnalyticsPage eventId={eventId} />}
                {isEventBudgetComponent && <EventBudget data-id={eventId} />}
                {isUtmComponent && <UtmComponent eventId={eventId} eventLink={eventReduxData['link'] ? eventReduxData['link'] : eventReduxData.link ? eventReduxData.link : null} />}
                {isCouponsComponent && <CouponsProvider eventId={eventId}><CouponsPage /></CouponsProvider>}
                {isTicketsComponent && <TicketsPageProvider eventId={Number(eventId)} ><Ticket eventReduxData={eventReduxData} eventId={Number(eventId)} /></TicketsPageProvider>}
                {isRegistrantsComponent && <EventRegistrantsPage eventId={eventId as string} />}
                {isEventSponsorComponent && <SponsorsPageProvider cardView={false} eventId={eventId}><SponsorsPage eventId={eventId as string} /> </SponsorsPageProvider>}
                {isEventSpeakerComponent && <SpeakersProvider eventId={eventId} cardView={false} ><SpeakersPage  eventId={eventId} /></SpeakersProvider>}
                {(isEventVendorComponent && orgBillingInfo?.billingModules?.vendor) && <VendorsProvider cardView={false} eventId={eventId}><VendorsPage eventId={eventId} /></VendorsProvider>}
                {(isCheckInComponent && orgBillingInfo?.billingModules?.checkInApp) && <EventAccessControlProvider eventId={eventId || ''}><EventAccessControlPage /></EventAccessControlProvider>}
                {(isAllDevicesComponent && orgBillingInfo?.billingModules?.checkInApp) && <EventAccessControlProvider eventId={eventId || ''}><ViewAllDevicesComponent /></EventAccessControlProvider>}
                {(isCheckInNotificationsComponent && orgBillingInfo?.billingModules?.checkInApp) && <EventAccessControlProvider eventId={eventId || ''}><ViewAllNotificationsComponent /></EventAccessControlProvider>}
                {isEventAgendaComponent && <EventStageProvider eventId={eventId || ''} eventReduxData={eventReduxData}><StagePage /></EventStageProvider>}
                {isEventTargetListComponent && <EventTargetListProvider eventId={eventId || ''} listId={listId || ''} ><EventTargetListPage /> </EventTargetListProvider>}
                {isCreateEventTargetListComponent && <EventTargetListProvider eventId={eventId || ''} listId={listId || ''} ><CreateEventTargetListPage /> </EventTargetListProvider>}
                {isUpdateEventTargetListComponent && <EventTargetListProvider eventId={eventId || ''} listId={listId || ''} ><CreateEventTargetListPage /> </EventTargetListProvider>}
                {isEventTargetListAudienceComponent && <EventTargetListAudiencePage eventId={eventId || ''} listId={listId || ''} /> }
                {isPhotoSharingComponent && <EventPhotoSharingProvider eventId={eventId || ''} ><PhotosPage /></EventPhotoSharingProvider>}
                {isEventSettingsComponent && <EventSettingsPage />}
                {isEventSocialShareComponent && <EventSocialSharePage eventId={eventId} />}
                {/* Basic plan registrations section */}
                {/* {isRegistrationsComponent && <BasicRegistrationsPage eventId={eventId || 0} />} */}
                {/* {isEventRegistrationComponent && eventReduxData.type === 1 ? <EventRegistrationDetails eventReduxData={eventReduxData} eventId={Number(eventId)} /> : null} */}
                {isRegistrationsComponent && <EventRegistrationDetails eventId={Number(eventId)} />}
                {isLandingPageComponent && <LandingPagePreview eventId={Number(eventId)} />}

                {isEventProspectsComponent && <EventProspectsPage eventId={eventId} />}
            </Box>
        </Box>
    );
};
export default EventsInfo;