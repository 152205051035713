import React from 'react';
import { Button } from '@mui/material';
import { IButtonsInterface } from './IReusableFormComponents';
import { Spinner } from 'react-bootstrap';
import './styles.scss';

export const CustomButton = (props: IButtonsInterface): React.JSX.Element =>
{
    const buttonClasses = {
        primary: 'primary-button',
        secondary: 'secondary-button',
        danger: 'danger-button',
        infoBlue: 'info-blue-button',
        tertiary: 'tertiary-button',
        danger_outline: 'danger-outline',
        primary_outline: 'primary-outline',
        tertiary_grey: 'tertiary-grey',
        linkBtn: 'link-button',
        tertiary_outline: 'tertiary-outline',
    };

    const { startIcon, btnType, name, onClick, loading, type, style, endIcon, width, mainDivStyle, children } = props;
    const isIconButton = name === '' && startIcon;

    return(
        <div id="customButton" style={mainDivStyle ? mainDivStyle : { width: width || '' }}>
            <Button disableRipple ref={props?.ref} style={style} disabled={props?.disabled} startIcon={!loading && startIcon} endIcon={endIcon} type={type} className={`${buttonClasses[btnType]} ${isIconButton && 'icon-btn'}`} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => 
            {
                if(onClick)
                {
                    return onClick(event); 
                }
            }}>{loading ? 
                    (
                        <Spinner className="spinner" size="sm" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ):
                    (
                        name ? name : null
                    )
                }
                {children}      
            </Button>
        </div>
    );
};