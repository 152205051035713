// eslint-disable-next-line import/no-unresolved
import { EventSponsor, EventSponsorGraph, EventSponsorTier } from '../../pages/Events/interfaces';
import { Sponsor } from '../../pages/Sponsors/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

// GET Methods
/**
 * Get the count of event sponsors.
 * @param {string | number} eventId - The ID of the event.
 * @param {[string, string][]} queryParams - Additional query parameters.
 * @returns {Promise<number>} - The count of event sponsors.
 */
export const getEventSponsorsCount = async (eventId: string | number, queryParams: [string, string][] = []): Promise<number> => {
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/count' + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

/**
 * Get a list of event sponsors.
 * @param {number} pageSize - The number of sponsors per page.
 * @param {number} offset - The offset for pagination.
 * @param {string | number} eventId - The ID of the event.
 * @param {number} [sponsorId] - The ID of the sponsor.
 * @param {number} [status] - The status of the sponsor.
 * @param {[string, any][]} queryParams - Additional query parameters.
 * @returns {Promise<Sponsor[]>} - A list of sponsors.
 */
export const getEventSponsors = async (pageSize: number, offset: number, eventId: string | number, sponsorId?: number, status?: number, queryParams: [string, any][] = []): Promise<Sponsor[]> => {
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        sponsorId,
        status,
        ...filteredParams
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

/**
 * Get an event sponsor by ID.
 * @param {number} eventId - The ID of the event.
 * @param {number} sponsorId - The ID of the sponsor.
 * @returns {Promise<EventSponsor>} - The event sponsor.
 */
export const getEventSponsorById = async (eventId: number, sponsorId: number): Promise<EventSponsor> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/' + sponsorId;
    return triggerApi(API_URL, 'GET', '');
};

/**
 * Get the count of sponsor tiers for an event.
 * @param {string | number} eventId - The ID of the event.
 * @returns {Promise<number>} - The count of sponsor tiers.
 */
export const sponsorTierCount = async (eventId: string | number): Promise<number> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/tier/count';
    return triggerApi(API_URL, 'GET', '');
};

/**
 * Get all sponsor tiers for an event.
 * @param {number} pageSize - The number of tiers per page.
 * @param {number} offset - The offset for pagination.
 * @param {string | number} eventId - The ID of the event.
 * @param {number} [status] - The status of the tiers.
 * @returns {Promise<EventSponsorTier[]>} - A list of sponsor tiers.
 */
export const getAllSponsorTiers = async (pageSize: number, offset: number, eventId: string | number, status?: number): Promise<EventSponsorTier[]> => {
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        status,
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/tier?' + query + '&offset=0';
    return triggerApi(API_URL, 'GET', '');
};

/**
 * Get a sponsor tier by tier ID.
 * @param {number | string} eventId - The ID of the event.
 * @param {number | string} tierId - The ID of the tier.
 * @returns {Promise<EventSponsorTier>} - The sponsor tier.
 */
export const getSponsorTierByTierId = async (eventId: number | string, tierId: number | string): Promise<EventSponsorTier> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/tier/' + tierId;
    return triggerApi(API_URL, 'GET', '');
};

/**
 * Get event sponsor graph data.
 * @param {string | number} eventId - The ID of the event.
 * @returns {Promise<EventSponsorGraph>} - The graph data.
 */
export const getEventSponsorGraphData = async (eventId: string | number): Promise<EventSponsorGraph> => {
    const query = convertJSONToGETParams({
        icp: true,
        buyerPersona: true,
        arr: true
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/graph?' + query;
    return triggerApi(API_URL, 'GET', '');
};

// POST Methods
/**
 * Create event sponsors.
 * @param {string | number} eventId - The ID of the event.
 * @param {any} EVENT_SPONSOR_DATA - The data for the event sponsors.
 * @param {string} csrfToken - The CSRF token.
 * @returns {Promise<EventSponsor>} - The created event sponsor.
 */
export const createEventSponsors = async (eventId: string | number, EVENT_SPONSOR_DATA: Partial<Sponsor>, csrfToken: string): Promise<EventSponsor> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/v1/' + eventId;
    return triggerApi(API_URL, 'POST', EVENT_SPONSOR_DATA, csrfToken);
};

/**
 * Uploads an image for a specific event sponsor.
 *
 * @param sponsorId - The unique identifier of the speaker.
 * @param image - The image file to be uploaded, encapsulated in a FormData object.
 * @param csrfToken - The CSRF token for authentication and security.
 * @returns A promise that resolves to a boolean indicating the success of the upload operation.
 */
export const uploadEventSponsorImage = async (sponsorId: string | number, image: FormData, csrfToken: string): Promise<boolean> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/v1/' + sponsorId + '/upload-image';
    return triggerApi(API_URL, 'POST', image, csrfToken, false);
};

/**
 * Create a sponsor tier.
 * @param {string | number} EVENT_ID - The ID of the event.
 * @param {any} SPONSOR_TIER_DATA - The data for the sponsor tier.
 * @param {string} csrfToken - The CSRF token.
 * @returns {Promise<EventSponsorTier>} - The created sponsor tier.
 */
export const createSponsorTier = async (EVENT_ID: string | number, SPONSOR_TIER_DATA: Partial<EventSponsorTier>, csrfToken: string): Promise<EventSponsorTier> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + EVENT_ID + '/tier';
    return triggerApi(API_URL, 'POST', SPONSOR_TIER_DATA, csrfToken);
};

// PUT Methods
/**
 * Update an event sponsor.
 * @param {string | number} EVENT_ID - The ID of the event.
 * @param {string | number} SPONSOR_ID - The ID of the sponsor.
 * @param {any} EVENT_SPONSOR_DATA - The data for the event sponsor.
 * @returns {Promise<EventSponsor>} - The updated event sponsor.
 */
export const updateEventSponsor = async (EVENT_ID: string | number, SPONSOR_ID: string | number, EVENT_SPONSOR_DATA: Partial<Sponsor>): Promise<EventSponsor> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + EVENT_ID + '/' + SPONSOR_ID;
    return triggerApi(API_URL, 'PUT', EVENT_SPONSOR_DATA);
};

/**
 * Updates an event sponsor's details for a specific event.
 *
 * @param SPONSOR_ID - The unique identifier of the sponsor.
 * @param EVENT_SPONSOR_DATA - Partial data of the sponsor to be updated.
 * @returns A promise that resolves to the updated event sponsor object.
 */
export const updateEventSponsorV1 = async (EVENT_ID: string | number, SPONSOR_ID: string | number, EVENT_SPONSOR_DATA: Partial<Sponsor>): Promise<EventSponsor> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/v1/' + EVENT_ID + '/' + SPONSOR_ID;
    return triggerApi(API_URL, 'PUT', EVENT_SPONSOR_DATA);
};

/**
 * Update a sponsor tier.
 * @param {number | string} EVENT_ID - The ID of the event.
 * @param {number | string} SPONSOR_TIER_ID - The ID of the sponsor tier.
 * @param {any} SPONSOR_TIER_DATA - The data for the sponsor tier.
 * @returns {Promise<EventSponsorTier>} - The updated sponsor tier.
 */
export const updateSponsorTier = async (EVENT_ID: string | number, SPONSOR_TIER_ID: string | number, SPONSOR_TIER_DATA: Partial<EventSponsorTier>): Promise<EventSponsorTier> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + EVENT_ID + '/tier/' + SPONSOR_TIER_ID;
    return triggerApi(API_URL, 'PUT', SPONSOR_TIER_DATA);
};

// DELETE Methods
/**
 * Remove an event sponsor.
 * @param {string | number} eventId - The ID of the event.
 * @param {string | number} sponsorId - The ID of the sponsor.
 * @returns {Promise<boolean>} - Whether the sponsor was removed.
 */
export const removeEventSponsor = async (eventId: string | number, sponsorId: string | number): Promise<boolean> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/' + sponsorId;
    return triggerApi(API_URL, 'DELETE', '');
};

/**
 * Delete a sponsor tier.
 * @param {string | number} EVENT_ID - The ID of the event.
 * @param {string | number} SPONSOR_TIER_ID - The ID of the sponsor tier.
 * @returns {Promise<boolean>} - Whether the sponsor tier was deleted.
 */
export const deleteSponsorTier = async (EVENT_ID: string | number, SPONSOR_TIER_ID: string | number): Promise<boolean> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + EVENT_ID + '/tier/' + SPONSOR_TIER_ID;
    return triggerApi(API_URL, 'DELETE', '');
};