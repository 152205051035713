import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import CalendarDescriptionEditor from "./CalendarDescriptionEditor";
import { FormControlLabel } from "@mui/material";
import { CalendarInvitation, EventStatus } from "../../../pages/Events/enum";
import { CustomSwitch } from "../../../common/StyledComponents/Switch.styled";
import CustomTooltip from "../../../common/Tooltip";
import { Event } from "../../../pages/Events/interfaces";

import './styles.scss';

interface IEventCalendarDescriptionProps {
    eventDetails: Event;
    setRefreshEventData: React.Dispatch<React.SetStateAction<boolean>>;
}

const EventCalendarDescription: React.FC<IEventCalendarDescriptionProps> = ({ eventDetails, setRefreshEventData }): React.ReactElement =>
{
    const isPublished = eventDetails?.status === EventStatus.PUBLISHED;

    const openDrawer = () =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Calendar Invitation',
            component: <CalendarDescriptionEditor setRefreshEventData={setRefreshEventData} eventDetails={eventDetails} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    }

    const handleSwitchClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        openDrawer();
    };

    return (
        <div id="eventCalendarDescription">
            {/* calendar description */}
            <div className="calender-description-container">
                <div className="title-description">
                    <h3 className="title">{'Calendar Invitation'}</h3>
                    <p className="description">{'This will enable Google calendar invites to the confirmed registrants.'}</p>
                </div>

                <div className="approval-toggle-container">
                    <FormControlLabel
                        control={
                            <CustomTooltip title={isPublished ? "" : "Event is not published"}>
                                <div onClick={handleSwitchClick}>
                                    <CustomSwitch checked={Number(eventDetails?.calendarInvitation) === CalendarInvitation.ENABLED} disabled={!isPublished} />
                                </div>
                            </CustomTooltip>
                        }
                        label={""}
                    />
                </div>
            </div>
        </div>
    )
}

export default EventCalendarDescription;