import React, { createContext, useLayoutEffect, useMemo, useState } from "react";
import { userMe } from "../scripts/apis/users";
import { User } from "../interfaces/settings/user_interface";
import { Organization } from "../interfaces/settings/organization_interface";
import { orgMe } from "../scripts/apis/organisation";
import { LocalStorage } from "../scripts/LocalStorage";

interface IUserContext {
    userDetails: User | undefined;
    orgDetails: Organization | undefined;
    setUserContextRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setOrgRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserContext = createContext<IUserContext>({ 
    userDetails: undefined, 
    setUserContextRefresh: () => {},
    orgDetails: undefined,
    setOrgRefresh: () => {}
});

const UserProvider: React.FC<{ children: React.ReactNode; }> = ({ children }): React.JSX.Element => {

    const [userDetails, setUserDetails] = useState<User>();
    const [orgDetails, setOrgDetails] = useState<Organization>();
    const [userContextRefresh, setUserContextRefresh] = useState<boolean>(false);
    const [orgRefresh, setOrgRefresh] = useState<boolean>(false);

    const fetchUserData = async () => 
    {
        try 
        {
            const userData = await userMe();
            if (userData) 
            {
                setUserDetails(userData);
                LocalStorage.set('@UserMe', userData);
            }
        } 
        catch (error) 
        {
            console.log('Error fetching user data', error);
        }
    };

    const fetchUserOrgData = async () => {
        try 
        {
            const orgData = await orgMe();
            if (orgData)
            {
                setOrgDetails(orgData);
                LocalStorage.set('@Org', orgData);
            }
        } 
        catch (error) 
        {
            console.log('Error fetching org data', error);
        }
    };

    useLayoutEffect(() => {
        fetchUserData();
        fetchUserOrgData();
    }, []);

    useMemo(() => {
        if (userContextRefresh)
        {
            fetchUserData();
            setUserContextRefresh(false);
        }
    }, [userContextRefresh]);

    useMemo(() => {
        if (orgRefresh)
        {
            fetchUserOrgData();
            setOrgRefresh(false);
        }
    }, [orgRefresh]);

    return (
        <UserContext.Provider value={{
            userDetails,
            setUserContextRefresh,
            orgDetails,
            setOrgRefresh
        }}>
            {children}
        </UserContext.Provider>
    )
};  

export default UserProvider;