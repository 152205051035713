import { FormLabelComponent } from "../../../../common/FormComponents/ReusableFormComponents";
import FormPreview from "../../../../pages/Registration/Preview/FormPreview";
import { RegistrationForm } from "../../../../pages/Registration/interfaces/registration-form_interface";
import { useEffect, useState } from "react";
import { getRegistrationFormById } from "../../../../scripts/apis/registrationForm";
import CustomSpinner from "../../../../common/CustomSpinner";

interface INPSFormPreviewProps { 
    formId: string;
    formType: 'nps' | 'sessionFeedback';
}

const NPSFormPreview: React.FC<INPSFormPreviewProps> = ({ formType, formId }): React.JSX.Element =>
{
    const [formDetails, setFormDetails] = useState<RegistrationForm | null>(null);
    const [spinner, setSpinner] = useState(true);

    const fetchFormDetailsById = async (): Promise<void> =>
    {
        try 
        {
            const formDetails = await getRegistrationFormById(formId);
            if(formDetails)
            {
                setFormDetails(formDetails);
            }
        } 
        catch (error) 
        {
            console.log('Error fetching form details by id: ', error);
        }
        finally
        {
            setSpinner(false);
        }
    }

    useEffect(() =>
    {
        fetchFormDetailsById();
    }, [formId])

    return (
        <div className="nps-form-preview-content">
            <FormLabelComponent label={`${formType === 'nps' ? 'Event' : 'Session Feedback'} Form Preview`} noBottomMargin />
            <div className="nps-form-preview-container">
                {
                    spinner ? 
                        <CustomSpinner height={'692px'} /> : 
                        <FormPreview 
                            formId={formId} 
                            registrationFormData={{ registrationForm: formDetails }} 
                            width={'100%'} 
                        />
                }
            </div>
        </div>
    )
}

export default NPSFormPreview;