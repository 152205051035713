/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Stack, Tooltip, Typography, createFilterOptions } from '@mui/material';
import { Form } from 'react-bootstrap';
import { CONTENT } from '../../../scripts/i18n';
import moment from 'moment-timezone';
import { addEvent } from '../../../redux/events/Events';
import { useDispatch, useSelector } from 'react-redux';
import APP_CONSTANTS from '../../../scripts/constants';
import { createEvent, createEventRegistrationToolMap, getEventActivityLogs, getMapEvents, updateEvent } from '../../../scripts/apis/events';
import eventBus from '../../../scripts/event-bus';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreateEventCategory from '../EventCategory/CreateEventCategory';
import { getAllEventCategories } from '../../../scripts/apis/eventCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalStorage } from '../../../scripts/LocalStorage';
import { AutocompleteComponentWithFilterOption, CloseIconComponent, FormControlComponent, FormLabelComponent, SelectComponent, TimezoneSelectComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import { uniqueTimezoneArr } from '../timezoneGenerateFunction';
import { Event, EventCategory } from '../../../pages/Events/interfaces';
import { renderCheckBoxComponent, renderDateComponent, renderDropDownComponent, renderInputComponent, renderRadioGroupComponent } from '../../../pages/Settings/CustomField/CustomTypeComponent';
import { CustomFieldLocations, CustomFieldType, customFieldCountByLocation, customFieldsByLocation } from '../../../pages/Settings/CustomField/CustomField.helper';
import { ICreateEventProps, IEventReduxValues, IEventsDispatch } from '../interfaces/create-events_interface';
import { eventLocationOptions, eventTypeOptions } from '../../../contexts/EventsPageContext';
import CustomSpinner from '../../../common/CustomSpinner';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import './styles.scss';
import { MapEvent } from '../../../pages/Events/interfaces/event-registration-tools_interface';
import { EventTypes } from '../../../pages/Events/enum';
import { IntegrationTypes } from '../../../pages/Settings/enum/integrations.enum';
import { Integrations, IntegrationsState } from '../../../pages/Settings/interface/integration_interface';
import DateTimePicker from '../../../common/FormComponents/DateTimePicker';
import { combineDateTime, convertTimestampWithTimezone } from '../../../scripts/helpers';
import toast from 'react-hot-toast';
import CustomTooltip from '../../../common/Tooltip';
import { ActivityLogResponse } from '../../../interfaces/activity-log_interface';
import ActivityLogViewer from '../../../common/ActivityLog/ActivityLogViewer';
import ActivityLogComponent from '../../../common/ActivityLog/ActivityLogComponent';
import { eventsActivityLog } from '../../../scripts/activityLogGenerator/activitylog-generator';

const CreateEvent= (props: ICreateEventProps): React.JSX.Element => 
{

    const eventData = props.eventData;
    const filter = createFilterOptions();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const integrations = useSelector((state: IntegrationsState): Integrations[] =>
    {
        return state.integrations.value;
    });

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const [eventTitleLength, setEventTitleLength] = useState(0);
    const [eventCategoryDetails, setEventCategoryDetails] = useState([]);
    const [spinner, setSpinner] = useState(false);
    // const [endDateEnabled, setEndDataEnabled] = useState(false);
    // const [endTimeEnabled, setEndTimeEnabled] = useState(false);
    const [customFormState, setCustomFormState] = useState({
    });
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsSpinner, setCustomFieldsSpinner] = useState(false);
    const [mapEvents, setMapEvents] = useState<MapEvent[]>([]);
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    // const [integrations, setIntegrations] = useState<Integrations[]>([]);
    const isLumaIntegrated = integrations?.some((integration) => integration.type === IntegrationTypes.LUMA);

    const orgLink = LocalStorage.get('@Org')?.link;

    const eventMapOptionLabel = mapEvents?.length > 0 && mapEvents?.reduce((acc, event) => {
        acc[event.id] = event.name;
        return acc;
    }, {});

    const fetchEventsCustomFieldData = async ():Promise<void> => 
    {
        try 
        {
            setCustomFieldsSpinner(true);
            const cfcount = await customFieldCountByLocation(CustomFieldLocations.EVENT);
            if(cfcount)
            {
                try 
                {
                    const customFields = await customFieldsByLocation(cfcount,0,CustomFieldLocations.EVENT);
                    if(customFields)
                    {
                        setCustomFields(customFields);
                        setCustomFieldsSpinner(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setCustomFieldsSpinner(false);
                }
            }
            
        }
        catch (error) 
        {
            console.log(error);
            setCustomFieldsSpinner(false);
        }
        
    };

    const handleTimeZoneChange = (event: React.SyntheticEvent, newValue: string): void => 
    {

        formik.setFieldValue('timezone', newValue);

        if (newValue) 
        {
            const timezoneForSelectedValue = _.find(uniqueTimezoneArr, function (item: {name: string; value: string}) : boolean
            {
                return item.name === newValue;
            }).value;

            dispatch(addEvent({
                timezoneDisplayName: newValue
            }));

            dispatch(addEvent({
                timeZone: timezoneForSelectedValue ? timezoneForSelectedValue : ''
            }));
        }
    };

    const handleEventType = (event: React.ChangeEvent<HTMLInputElement>): void => 
    {
        formik.setFieldValue('type', event.target.value);
        formik.setFieldValue('link', '');
        dispatch(addEvent({
            type: event.target.value,
            link: ''
        }));
    };

    const handleEventLocation = (event: React.ChangeEvent<HTMLInputElement>): void => 
    {
        formik.setFieldValue('locationType', Number(event.target.value));
        dispatch(addEvent({
            locationType: event.target.value
        }));
    };

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.CLOSE_EVENT, {
            open: false
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        if (props?.routeFromId) 
        {
            navigate('/events');
        }
    };

    const handleEventCategoryChange = (event: React.SyntheticEvent, newValue: string): void => 
    {
        if (newValue && newValue.includes('Add :')) 
        {
            const eventCategoryName = newValue.split('Add : ')[1];

            setTimeout((): void => 
            {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                    componentHeight: '334px',
                    componentWidth: '600px',
                    iconHeading: 'grid-2-plus',
                    component: <CreateEventCategory setEventCategoryDetails={setEventCategoryDetails} eventCategoryName={eventCategoryName} />,
                });

                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                    open: true
                });
            });
        }
        else 
        {
            formik.setFieldValue('eventCategoryId', newValue);

            const selectedEventCategory = _.filter(eventCategoryDetails, function (group: EventCategory) : boolean
            {
                return newValue === group.name;
            })[0];
            // setGroup(selectedEventCategory);

            dispatch(addEvent({
                eventCategory: newValue ? newValue : ''
            }));

            dispatch(addEvent({
                eventCategoryId: selectedEventCategory ? selectedEventCategory.id : ''
            }));
        }
    };

    const categoryFilterOptions = (options: any, params: any):any[] => 
    {
        const filtered = filter(options, params);

        if (params.inputValue !== '' && !options.includes(params.inputValue)) {
            filtered.push('Add : ' + params.inputValue);
        }

        return filtered;
    };

    const handleInputChange = (event, id): void => 
    {
        const { name, value } = event.target;
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleDropDownInputChange = (name, value, id): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleBoxChange = (name, value, id): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleCheckboxChange = (item, data): void => 
    {

        if(customFormState[data.label + ' ' + data.id])
        {
            const selectedIndex = customFormState[data.label + ' ' + data.id].indexOf(item.name);
            const updatedSelectedOptions = [...customFormState[data.label + ' ' + data.id]];

            if (selectedIndex === -1) 
            {
                updatedSelectedOptions.push(item.name);
            }
            else 
            {
                updatedSelectedOptions.splice(selectedIndex, 1);
            }

            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: updatedSelectedOptions,
                }; 
            });
        }
        else
        {
            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: [item.name],
                }; 
            });
        }
        
    };

    const handleEditEventCategory = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
            componentHeight: '334px',
            componentWidth: '600px',
            iconHeading: 'grid-2-plus',
            component: <CreateEventCategory setEventCategoryDetails={setEventCategoryDetails} eventCategoryName={formik.values.eventCategoryId} isEdit eventCategoryId={eventReduxData?.eventCategoryId} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
            open: true
        });
    };

    const handleNestedSidebarOpen = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.UPDATE_EVENT, {
            component: <ActivityLogViewer><ActivityLogComponent activityLog={eventsActivityLog(activityLogs, eventData as Event)} /></ActivityLogViewer>
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.OPEN_EVENT, {
            open: true,
            right: '501'
        });
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required')
            .max(100, 'Event Title length exceeded'),
        link: Yup.string().matches(/^[a-zA-Z0-9_-]+$/, 'Enter valid Public URL').test('isValidLink', 'Public URL is required', (value): boolean => 
        {
            if (formik.values.type === 1) 
            {
                if (!value) 
                {
                    return false;
                }
                else 
                {
                    return true;
                }
            }
            else 
            {
                return true;
            }
        }),
        locationType: Yup.number()
            .required('Please select the Event location'),
        type: Yup.number()
            .required('Please select the Event Type'),
        timezone: Yup.string()
            .required('Timezone is required'),
        registrationType: Yup.number(),
        // eventStartDateTime: Yup.string()
        //     .required('Start Date is required'),
        // eventEndDateTime: Yup.string()
        //     .required('End Date is required'),
        status: Yup.number(),
        eventCategoryId: Yup.string()
            .required('Event Category is required'),
        eventMapId: Yup.string().notRequired(),
    });

    const currentTimezone = moment.tz.guess();

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            title: eventData?.title ? eventData?.title : '',
            link: eventData?.link ? eventData?.link : '',
            type: eventData?.type ? eventData?.type : 1,
            registrationType: eventData?.registrationType ? eventData?.registrationType : 1,
            locationType: eventData?.locationType ? eventData?.locationType : 1,
            timezone: '',
            // eventStartDateTime: eventReduxData.eventStartDateTime ? eventReduxData.eventStartDateTime : '',
            // eventEndDateTime: eventReduxData.eventEndDateTime ? eventReduxData.eventEndDateTime : '',
            startDate: moment.tz(moment.unix(Number(eventData?.eventStartDateTime)), eventData?.timezone as string).format('DD/MM/YYYY'),
            endDate: moment.tz(moment.unix(Number(eventData?.eventEndDateTime)), eventData?.timezone as string).format('DD/MM/YYYY'),
            startTime: moment.tz(moment.unix(Number(eventData?.eventStartDateTime)), eventData?.timezone as string).format('HH:mm'),
            endTime: moment.tz(moment.unix(Number(eventData?.eventEndDateTime)), eventData?.timezone as string).format('HH:mm'),
            status: eventData?.status ? eventData?.status : 0,
            eventCategoryId: '',
            eventMapId: eventData?.lumaEventId ? eventData?.lumaEventId : '',
        },
        onSubmit: (): void => 
        {          
            const { title, link, type, locationType, registrationType } = formik.values;

            const eventStartDateTime = convertTimestampWithTimezone(combineDateTime(formik.values.startDate, formik.values.startTime), currentTimezone, eventReduxData?.timeZone);
            const eventEndDateTime = convertTimestampWithTimezone(combineDateTime(formik.values.endDate, formik.values.endTime), currentTimezone, eventReduxData?.timeZone);

            if (!(_.isEmpty((eventData)))) 
            {
                const isEventStartDateTimeChanged = moment.unix(eventData.eventStartDateTime).format('YYYY-MM-DD HH:mm') !== moment.unix(Number(eventStartDateTime)).format('YYYY-MM-DD HH:mm');
                const isEventEndDateTimeChanged = moment.unix(eventData.eventEndDateTime).format('YYYY-MM-DD HH:mm') !== moment.unix(Number(eventEndDateTime)).format('YYYY-MM-DD HH:mm');
                const isEventDataChanged = eventData.title !== title || eventData.link !== link || eventData.type !== type || eventData.locationType !== locationType || eventData.timezone !== eventReduxData?.timeZone || eventData.eventCategoryId !== eventReduxData?.eventCategoryId || eventData.registrationType !== registrationType || eventData.landingPageType !== eventReduxData?.landingPageType || customFormState;
                
                if (!(_.isEmpty((eventData))) && (isEventStartDateTimeChanged || isEventEndDateTimeChanged || isEventDataChanged)) 
                {
                    updateEventDetails();
                }
                else 
                {
                    return;
                }
            }
            else 
            {
                addEventDetails();
            }
        }
    });

    const updateEventDetails = async (): Promise<void> => 
    {
        const eventStartDateTime = combineDateTime(formik.values.startDate, formik.values.startTime);
        const eventEndDateTime = combineDateTime(formik.values.endDate, formik.values.endTime);

        if (Number(eventEndDateTime) < Number(eventStartDateTime)) 
        {
            toast.error('Event End Timing should be Greater than Event Start Timing');            
            return;
        }
        setSpinner(true);

        const eventDetails = {
            title: formik.values.title,
            ...(formik.values.type === EventTypes.HOST && { link: formik.values.link }),
            type: formik.values.type,
            locationType: formik.values.locationType,
            registrationType: formik.values.registrationType,
            timezone: eventReduxData.timeZone,
            eventStartDateTime,
            eventEndDateTime,
            closingDateTime: eventEndDateTime,
            eventCategoryId: String(eventReduxData.eventCategoryId),
        };
        if(customFormState)
        {
            const newEventDetails = {
                ...eventDetails,
                ['additionalFieldData']:{
                    ...customFormState
                }
            };

            try 
            {
                const eventsDataUpdated = await updateEvent(String(eventData?.id), newEventDetails);
                if (!eventsDataUpdated) return;

                if (eventsDataUpdated) {
                    dispatch(addEvent({
                        type: Number(eventsDataUpdated.type),
                        eventStartDateTime: eventsDataUpdated.eventStartDateTime,
                        eventEndDateTime: eventsDataUpdated.eventEndDateTime,
                        closingDateTime: eventsDataUpdated.eventEndDateTime,
                    }));
                }

                if(formik.values.eventMapId) {
                    const eventMapObj = {
                        type: 5,
                        mapEvent: [
                            {
                                eventId: eventData?.id,
                                integrationMapId: formik.values?.eventMapId
                            }
                        ]
                    };
            
                    try {
                        await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                    } catch (error) {
                        console.log('Error with mapping the event' ,error);
                    }
                }
            
                if (!props.fromOverview) {
                    props?.setRefresh(true);
                }
                toast.success('Event details updated successfully');
            
                eventBus.dispatch('event-overview-refreshed', { refresh: true });
                eventBus.dispatch('event-details-refreshed', {
                    refresh: true 
                });
                eventBus.dispatch('Refresh-events-top-bar', { refresh: true });
            
                handleDrawerClose();
            }
            catch (error) 
            {
                console.log(error);
                // toast.error((error as Error)?.message || 'Error updating event details');
            }
            finally
            {
                setSpinner(false);
            }
        }
        else 
        {
            try {
                const eventsDataUpdated = await updateEvent(String(eventData?.id), eventDetails);
                if (!eventsDataUpdated) return;

                if (eventsDataUpdated) {
                    dispatch(addEvent({
                        type: Number(eventsDataUpdated.type),
                        eventStartDateTime: eventsDataUpdated.eventStartDateTime,
                        eventEndDateTime: eventsDataUpdated.eventEndDateTime,
                        closingDateTime: eventsDataUpdated.eventEndDateTime,
                    }));
                }
            
                if(formik.values.eventMapId) {
                    const eventMapObj = {
                        type: 5,
                        mapEvent: [
                            {
                                eventId: eventData?.id,
                                integrationMapId: formik.values?.eventMapId
                            }
                        ]
                    };
            
                    try {
                        await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                    } catch (error) {
                        console.log('Error with mapping the event' ,error);
                    }
                }
            
                if (!props.fromOverview) {
                    props?.setRefresh(true);
                }

                toast.success('Event details updated successfully');
            
                eventBus.dispatch('event-overview-refreshed', { refresh: true });
                eventBus.dispatch('event-details-refreshed', {
                    refresh: true 
                });
                eventBus.dispatch('Refresh-events-top-bar', { refresh: true });
            
                handleDrawerClose();
            } catch (error) {
                console.log(error);
                toast.error((error as Error)?.message || 'Error updating event details');
            } finally {
                setSpinner(false);
            }
        }
    };

    const addEventDetails = async () : Promise<void> => 
    {
        setSpinner(true);

        const eventStartDateTime = combineDateTime(formik.values.startDate, formik.values.startTime);
        const eventEndDateTime = combineDateTime(formik.values.endDate, formik.values.endTime);

        if (Number(eventEndDateTime) < Number(eventStartDateTime)) 
        {
            toast.error('Event End Timing should be Greater than Event Start Timing');
            setSpinner(false);
            return;
        }
        
        const eventDetails = {
            title: formik.values.title,
            ...(formik.values.type === EventTypes.HOST && { link: formik.values.link }),
            type: formik.values.type,
            locationType: formik.values.locationType,
            timezone: eventReduxData.timeZone,
            registrationType: formik.values.registrationType,
            eventStartDateTime,
            eventEndDateTime,
            closingDateTime: Number(eventData?.eventEndDateTime) !== Number(eventEndDateTime) ? eventEndDateTime : eventData?.closingDateTime,
            status: 1,
            eventCategoryId: String(eventReduxData.eventCategoryId),
        };

        if(customFormState)
        {
            const newEventDetails = {
                ...eventDetails,
                ['additionalFieldData']:{
                    ...customFormState
                }
            };

            try {
                const eventCreated = await createEvent(newEventDetails, csrfTokenData);
                if (eventCreated) {
                    if(formik.values?.eventMapId) {
                        const eventMapObj = {
                            type: 5,
                            mapEvent: [
                                {
                                    eventId: eventCreated.id,
                                    integrationMapId: formik.values?.eventMapId
                                }
                            ]
                        };
    
                        try {
                            await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                        } catch (error) {
                            console.log('Error with mapping the event' ,error);
                        }
                    }
        
                    if (!(eventCreated instanceof Error)) {
                        dispatch(addEvent({
                            eventStartDate: moment.unix(eventCreated?.eventStartDateTime).format('YYYY-MM-DD'),
                            eventStartTime: moment.unix(eventCreated?.eventStartDateTime).format('HH:mm'),
                            eventEndDate: moment.unix(eventCreated?.eventEndDateTime).format('YYYY-MM-DD'),
                            eventEndTime: moment.unix(eventCreated?.eventEndDateTime).format('HH:mm'),
                            eventStartDateTime: eventCreated.eventStartDateTime,
                            eventEndDateTime: eventCreated.eventEndDateTime,
                            closingDateTime: eventCreated.eventEndDateTime,
                            eventCategoryId: eventCreated.eventCategoryId,
                            shortName: eventCreated.link,
                            type: eventCreated.type
                        }));
        
                        setSpinner(false);
                        handleDrawerClose();
                        props?.setRefresh(true);
        
                        toast.success('Event Created');
        
                        navigate('/events/' + Number(eventCreated.id) + '/overview');
                    }
                }
            } catch (error) {
                if(error?.message === 'EVENT LINK EXISTS')
                {
                    toast.error('Event Link already exists');
                }
                console.log(error);
            } finally {
                setSpinner(false);
            }
        } 
        else 
        {
            try {
                const eventCreated = await createEvent(eventDetails, csrfTokenData);
                if (eventCreated) {
                    if(formik.values?.eventMapId) {
                        const eventMapObj = {
                            type: 5,
                            mapEvent: [
                                {
                                    eventId: eventCreated.id,
                                    integrationMapId: formik.values?.eventMapId
                                }
                            ]
                        };
    
                        try {
                            await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                        } catch (error) {
                            console.log('Error with mapping the event' ,error);
                        }
                    }
        
                    if (!(eventCreated instanceof Error)) {
                        dispatch(addEvent({
                            eventStartDate: moment.unix(eventCreated.eventStartDateTime).format('YYYY-MM-DD'),
                            eventStartTime: moment.unix(eventCreated.eventStartDateTime).format('HH:mm'),
                            eventEndDate: moment.unix(eventCreated.eventEndDateTime).format('YYYY-MM-DD'),
                            eventEndTime: moment.unix(eventCreated.eventEndDateTime).format('HH:mm'),
                            eventStartDateTime: eventCreated.eventStartDateTime,
                            eventEndDateTime: eventCreated.eventEndDateTime,
                            closingDateTime: eventCreated.eventEndDateTime,
                            eventCategoryId: eventCreated.eventCategoryId,
                            shortName: eventCreated.link,
                            type: eventCreated.type
                        }));
        
                        setSpinner(false);
                        handleDrawerClose();
                        props?.setRefresh(true);
        
                        toast.success('Event Created');
        
                        navigate('/events/' + Number(eventCreated.id) + '/overview');
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                setSpinner(false);
            }
        }
       

    };

    const fetchMapEvents = async (): Promise<void> => {
        try 
        {
            const mapEventsData = await getMapEvents(5);
            if(mapEventsData)
            {
                setMapEvents(mapEventsData?.mapEvents);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, ():void => 
        {
            navigate(0);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data):void => 
        {
            setSpinner(data.spinner);
        });

        let eventCategoryData;
        try 
        {
            eventCategoryData = await getAllEventCategories();
            if (eventCategoryData) 
            {
                setEventCategoryDetails([...eventCategoryData]);
            }
        }
        catch (error) 
        {
            console.log(error);
        }

        if (!(_.isEmpty((eventData)))) 
        {
            dispatch(addEvent({
                title: eventData.title,
                description: eventData.description,
                eventStartDate: moment.unix(eventData.eventStartDateTime).format('YYYY-MM-DD'),
                eventStartTime: moment.unix(eventData.eventStartDateTime).format('HH:mm'),
                eventEndDate: moment.unix(eventData.eventEndDateTime).format('YYYY-MM-DD'),
                eventEndTime: moment.unix(eventData.eventEndDateTime).format('HH:mm'),
                timeZone: eventData.timezone,
                type: eventData.type,
                registrationType: eventData.registrationType,
                link: eventData.link,
                eventCategoryId: eventData.eventCategoryId ? eventData.eventCategoryId : '',
                status: eventData.status ? eventData.status : 1,
                landingPageType: eventData?.landingPageType,
                eventStartDateTime: eventData.eventStartDateTime,
                eventEndDateTime: eventData.eventEndDateTime,
            }));
            // let words = eventData.description.split(" ");
            // let wordsLength = words.length;
            setEventTitleLength(eventData?.title?.length);

            formik.setFieldValue('eventMapId', eventData?.lumaEventId ? eventData?.lumaEventId : '');

            const defaultCategory = _.filter(eventCategoryData, function (elem) : boolean
            {
                return elem.id === eventData?.eventCategoryId;
            });

            const defaultTimezone = _.filter(uniqueTimezoneArr, function (elem) : boolean
            {
                return elem.value === eventData.timezone;
            });

            if (defaultTimezone) 
            {
                dispatch(addEvent({
                    timezoneDisplayName: defaultTimezone[0]?.name ? defaultTimezone[0]?.name : ''
                }));
                formik.setFieldValue('timezone', defaultTimezone[0]?.name ? defaultTimezone[0]?.name : '');
            }

            if (defaultCategory) 
            {
                dispatch(addEvent({
                    eventCategory: defaultCategory[0]?.name ? defaultCategory[0]?.name : ''
                }));
                formik.setFieldValue('eventCategoryId', defaultCategory[0]?.name ? defaultCategory[0]?.name : '');
            }
        }
    };

    // const fetchIntegrations = async (): Promise<void> =>
    // {
    //     try 
    //     {
    //         const integrationsData = await getIntegrationsData();
    //         if(integrationsData)
    //         {
    //             setIntegrations(integrationsData);
    //         }
    //     } 
    //     catch (error) 
    //     {
    //         console.log('Error fetching integrations', error)
    //     }
    // };

    const fetchEventActivityLogs = async (): Promise<void> =>
    {
        try 
        {
            const activityLogs = await getEventActivityLogs(eventData?.id);
            if(activityLogs)
            {
                setActivityLogs(activityLogs);
            }
        } 
        catch (error)
        {
            console.log(error);
        }
    };

    useEffect((): void => 
    {
        fetchData();
        fetchEventsCustomFieldData();
        fetchMapEvents();
        // fetchIntegrations();
        if(eventData?.additionalFieldData)
        {
            setCustomFormState({
                ...eventData?.additionalFieldData
            });
            
        }

        if(eventData) fetchEventActivityLogs();

        // if (props?.integrations)
        // {
        //     if (props?.integrations?.some((integration) => integration.type === IntegrationTypes.LUMA)) 
        //     {
        //         setIsLumaIntegrated(true);
        //     }
        // }
    }, []);

    return (
        <Box id="createEvent">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Form noValidate autoComplete="off" onSubmit={(values: React.FormEvent<HTMLFormElement>): void => 
            {
                return formik.handleSubmit(values);
            }}>
                {
                    eventData &&
                    <div className="events-activity-log-button">
                        <CustomButton 
                            name=""
                            btnType="secondary"
                            startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                            style={{ width: '32px' }}
                            onClick={handleNestedSidebarOpen}
                        />
                    </div>
                }

                {/* Event Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Event Name'} required={true} />
                    <FormControlComponent
                        value={formik.values.title}
                        placeholder={'Name'}
                        type={'text'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                        {
                            formik.setFieldValue('title', event.target.value);
                            dispatch(addEvent({
                                title: event.target.value
                            }));
                            setEventTitleLength(event.target.value.length);
                        }}
                        isInvalid={formik.errors.title && formik.touched.title}
                    />
                    <Box className="title-length-error-cont">
                        {formik.errors.title && formik.touched.title ? <Typography className="error-msg">{formik.errors.title}</Typography> : <div/>}
                        <Typography className="title-length">{eventTitleLength}/100</Typography>
                    </Box>
                </Box>

                {/* Event Timing & Timezone */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Select Date & Time'} required={true} />
                    <Box className="event-timing-wrapper">
                        <div className="event-timing-container">
                            <div className="start-timing">
                                <DateTimePicker 
                                    dateValue={moment(formik.values.startDate, 'DD/MM/YYYY').unix()}
                                    timeValue={formik.values.startTime}
                                    onDateChange={(newValue): void => {
                                        formik.setFieldValue('startDate', newValue);
                                    }}
                                    onTimeChange={(newValue): void => { 
                                        formik.setFieldValue('startTime', newValue);
                                    }}
                                    dateFormat='ddd, MMM D'
                                    showYearDropdown
                                />
                            </div>
                            <p>-</p>
                            <div className="end-timing">
                                <DateTimePicker 
                                    dateValue={moment(formik.values.endDate, 'DD/MM/YYYY').unix()}
                                    timeValue={formik.values.endTime}
                                    onDateChange={(newValue): void => {
                                        formik.setFieldValue('endDate', newValue);
                                    }}
                                    onTimeChange={(newValue): void => { 
                                        formik.setFieldValue('endTime', newValue);
                                    }}
                                    dateFormat='ddd, MMM D'
                                    minDate={moment(formik.values.startDate, 'DD/MM/YYYY').unix()}
                                    maxDate={moment(formik.values.startDate, 'DD/MM/YYYY').add(30, 'days').unix()}
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        
                        {/* {
                            formik.values.timezone !== currentTimezone &&
                            <div className="your-timezone">
                                <FormLabelComponent label={'Your Timezone'} />
                                <div className="event-timing-container">
                                    <div className="start-timing">
                                        <DateTimePicker 
                                            disabled={true}
                                            dateValue={moment.unix(convertTimestampWithTimezone(Number(eventData?.eventStartDateTime), eventData?.timezone as string, currentTimezone)).format('DD/MM/YYYY')}
                                            timeValue={moment.unix(convertTimestampWithTimezone(Number(eventData?.eventStartDateTime), eventData?.timezone as string, currentTimezone)).format('HH:mm')}
                                            dateFormat='ddd, MMM D'
                                            showYearDropdown
                                            onDateChange={(): void => {}}
                                            onTimeChange={(): void => {}}
                                        />
                                    </div>
                                    <p>-</p>
                                    <div className="end-timing">
                                        <DateTimePicker 
                                            disabled={true}
                                            dateValue={moment.unix(convertTimestampWithTimezone(Number(eventData?.eventEndDateTime), eventData?.timezone as string, currentTimezone)).format('DD/MM/YYYY')}
                                            timeValue={moment.unix(convertTimestampWithTimezone(Number(eventData?.eventEndDateTime), eventData?.timezone as string, currentTimezone)).format('HH:mm')}
                                            onDateChange={(): void => {}}
                                            onTimeChange={(): void => {}}
                                            dateFormat='ddd, MMM D'
                                            showYearDropdown
                                        />
                                    </div>
                                </div>
                            </div>
                        } */}

                        <Box className="event-timezone-component">
                            <Box className="w-100">
                                <TimezoneSelectComponent defaultValue={formik.values?.timezone} value={formik.values?.timezone} optionsArr={uniqueTimezoneArr} onChange={handleTimeZoneChange} placeholder='Select a timezone' />
                                {formik.errors.timezone && formik.touched.timezone ? <Typography className="error-msg">{formik.errors.timezone}</Typography> : null}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                
                {/* Event Location Type */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Event Location Type'} required={true} />
                    <SelectComponent options={eventLocationOptions} value={Number(formik.values.locationType)} onChange={handleEventLocation} optionLabels={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EVENT_LOCATION_OPTIONS} defaultPlaceholder={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.EVENT_LOCATION_SELECT_BTN_LABEL} />
                    {formik.errors.locationType && formik.touched.locationType ? <Typography className="error-msg">{formik.errors.locationType}</Typography> : null}
                </Box>

                {/* Event Type */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Event Type'} required={true} />
                    <SelectComponent options={eventTypeOptions} value={String(formik.values.type)} onChange={handleEventType} optionLabels={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EVENT_TYPE_OPTIONS} defaultPlaceholder={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.EVENT_TYPE_SELECT_BTN_LABEL} />
                    {formik.errors.type && formik.touched.type ? <Typography className="error-msg">{formik.errors.type}</Typography> : null}
                </Box>

                {/* Event Category */}
                <Box className="sidebar-container-spacing">
                    <Box className="tooltip-box">
                        <FormLabelComponent label={'Event Category'} required={true} />
                        <CustomTooltip
                            title="Categorize events based on your preference"
                        >
                            <FontAwesomeIcon
                                icon={['fal', 'info-circle']}
                                className="info-icon"
                            />
                        </CustomTooltip>
                    </Box>
                    <Box className="event-category-container">
                        <Box width={'100%'}>
                            <AutocompleteComponentWithFilterOption value={formik.values.eventCategoryId || ''} defaultValue={formik.values.eventCategoryId || ''} onChange={handleEventCategoryChange} filterOptions={categoryFilterOptions} keyToShow='name' optionsArr={eventCategoryDetails} placeholder={eventCategoryDetails.length > 0 ? CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.PLACEHOLDER.EVENT_CATEGORY_GROUP_SELECT : CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.PLACEHOLDER.EVENT_CATEGORY_GROUP_EMPTY_SELECT} />
                        </Box>
                        {formik.values?.eventCategoryId && <CustomButton btnType='secondary' name='Edit' onClick={handleEditEventCategory} />}
                    </Box>
                    {formik.errors.eventCategoryId && formik.touched.eventCategoryId ? <Typography className="error-msg">{formik.errors.eventCategoryId}</Typography> : null}
                </Box>

                {formik.values.type === 1 && <div>
                    {/* Public URL */}
                    <Box className="sidebar-container-spacing">
                        <Box className="tooltip-box">
                            <FormLabelComponent label={'Public URL'} required />
                            <CustomTooltip
                                title="Host your event landing page in this URL"
                            >
                                <FontAwesomeIcon
                                    icon={['fal', 'info-circle']}
                                    className="info-icon"
                                />
                            </CustomTooltip>
                        </Box>
                        <FormControlComponent
                            type={'text'}
                            value={formik.values.link}
                            placeholder='Public URL'
                            onChange={(event): void => 
                            {
                                formik.setFieldValue('link', event.target.value);
                                // setEventLinkLength(event.target.value.length);
                                dispatch(addEvent({
                                    link: event.target.value
                                }));
                            }}
                            isInvalid={formik.errors.link && formik.touched.link}
                        />
                        <Typography className="short-link-example">{`https://${orgLink}.eventhq.com/`}{eventReduxData.link}</Typography>
                        {formik.errors.link && formik.touched.link ? <Typography className="error-msg">{formik.errors.link}</Typography> : null}
                    </Box>

                    {/* Require Approval */}
                    <Box className="sidebar-container-spacing">
                        <Box className="approval-container">
                            <Box className="approval-icon-label-container">
                                <FontAwesomeIcon icon={['fal', 'user-check']} className="approval-icon" />
                                <FormLabelComponent label={'Requires Approval to attend'} noBottomMargin />
                            </Box>
                            <FormControlLabel
                                checked={formik.values.registrationType === 2}
                                control={<CustomSwitch
                                    onChange={(event:  React.ChangeEvent<HTMLInputElement>): void => 
                                    {
                                        formik.setFieldValue('registrationType', event.target.checked ? 2 : 1);
                                        dispatch(addEvent({
                                            registrationType: event.target.checked ? 2 : 1
                                        }));
                                    }}
                                />}
                                label={''}
                            />
                        </Box>
                    </Box>
                </div>}

                {/* Luma event map */}
                {isLumaIntegrated && <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Event Map'} />
                    <SelectComponent showNone='Select an event from Luma' selectedAsString options={mapEvents} value={formik.values?.eventMapId} onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                    {
                        formik.setFieldValue('eventMapId', event.target.value);
                    }} optionLabels={eventMapOptionLabel} defaultPlaceholder={'Select an event from Luma'} />
                </Box>}

                {/* Custom fields */}
                {customFields.length > 0 &&
                customFieldsSpinner ? <CustomSpinner height={'100%'}/> :
                    <Box className="custom-field-container">
                        {customFields.map((item): React.ReactElement => 
                        {
                            if (item.type === CustomFieldType.NUMBER) 
                            {
                                return renderInputComponent(item, 'number', handleInputChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.SINGLE_LINE) 
                            {
                                return renderInputComponent(item, 'text', handleInputChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.MULTI_LINE_TEXT) 
                            {
                                return renderInputComponent(item, 'textarea', handleInputChange, customFormState?.[item.name + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.CHECKBOXES) 
                            {
                                return renderCheckBoxComponent(item, handleCheckboxChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.DROPDOWN) 
                            {
                                return renderDropDownComponent(item, handleDropDownInputChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.RADIO) 
                            {
                                return renderRadioGroupComponent(item, handleBoxChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.DATE) 
                            {
                                return renderDateComponent(item, handleInputChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                        })
                        }
                    </Box>
                }

                <Box width={'500px'} className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton type="submit" loading={spinner} name={!(_.isEmpty(eventData)) ? 'Update Event' : 'Create Event'} btnType={'primary'} />
                    </Stack>
                </Box>

            </Form >
        </Box >
    );
};

export default CreateEvent;