import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { FormControlComponent, FormLabelComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { useState } from "react";
import { updateEvent } from "../../../scripts/apis/events";
import DeletePopup from "../../../common/DeletePopup";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../interfaces/create-events_interface";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { addEvent } from "../../../redux/events/Events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InvoiceSettings: React.FC<{eventId: string | number; isInvoiceDetailsPresent?: boolean, handlePopupClose: () => void }> = ({ eventId, isInvoiceDetailsPresent, handlePopupClose }): React.JSX.Element =>
{

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const dispatch = useDispatch();

    const [spinner, setSpinner] = useState<boolean>(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);

    const validationSchema = Yup.object({ 
        invoicePrefix: Yup.string()
            .required('Please fill in this field')
            .min(2)
            .max(8)
            .matches(/^[a-zA-Z0-9]+$/, 'No special characters allowed'),
        invoiceNumber: Yup.number()
            .required('Please fill in this field')
            .min(1)
            .max(9999),
    });


    const handleFormSubmit = async (values: { invoicePrefix: string; invoiceNumber: number }) => {
        const data = {
            invoiceDetails: {
                invoicePrefix: values.invoicePrefix,
                invoiceNumber: String(values.invoiceNumber),
            }
        };

        try 
        {
            const updateInvoiceSettings = await updateEvent(String(eventId), data);
            if(updateInvoiceSettings)
            {
                dispatch(addEvent({
                    ...eventReduxData,
                    invoiceDetails: {
                        invoicePrefix: values.invoicePrefix,
                        invoiceNumber: String(values.invoiceNumber),
                    }
                }))
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setSpinner(false);
            handlePopupClose();
        }
    };

    const renderHeader = (): React.JSX.Element => {
        return (
            <div className='popup-header'>
                <div className='popup-header-content'>
                    <div className='popup-header-icon'><FontAwesomeIcon icon={['fal', 'file']} /></div>
                    <div className='popup-header-text'>
                        <h3>
                            {'Invoice Settings'}
                        </h3>
                        <p>
                            {'Customise your invoice.'}
                        </p>
                    </div>
                </div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => handlePopupClose()} />
            </div>
        );
    }

    return (
        <div id="invoiceSettings">
            {renderHeader()}
            <Formik
                initialValues={{
                    invoicePrefix: eventReduxData?.invoiceDetails?.invoicePrefix || '',
                    invoiceNumber: eventReduxData?.invoiceDetails?.invoiceNumber || 1
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={async () => { 
                    setShowConfirmationPopup(true);
                }}
            >
                {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }): React.ReactElement =>
                    {
                        return(
                            <Form onSubmit={handleSubmit}>
                                <div className="popup-container">
                                    <div className="invoice-prefix">
                                        <div className="invoice-prefix-input">
                                            <FormLabelComponent label="Invoice Prefix" required />
                                            <FormControlComponent
                                                type="text"
                                                disabled={isInvoiceDetailsPresent}
                                                name="invoicePrefix"
                                                placeholder="INVOICE1"
                                                value={values.invoicePrefix}
                                                onChange={(event) => setFieldValue('invoicePrefix', event.target.value.toUpperCase())}
                                                maxLength={8}
                                                isInvalid={(errors.invoicePrefix && touched.invoicePrefix) as boolean}
                                            />
                                        </div>
                                        {(errors.invoicePrefix && touched.invoicePrefix) && <div className="error-msg">{errors.invoicePrefix}</div>}
                                    </div>
                                    <div className="invoice-number">
                                        <FormLabelComponent label="Invoice Number" required/>
                                        <FormControlComponent
                                            type="text"
                                            disabled={isInvoiceDetailsPresent}
                                            name="invoiceNumber"
                                            placeholder="0001"
                                            value={values.invoiceNumber}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (/^\d*$/.test(value)) {
                                                    setFieldValue('invoiceNumber', value);
                                                }
                                            }}
                                            maxLength={4}
                                            isInvalid={(errors.invoiceNumber && touched.invoiceNumber) as boolean}
                                        />
                                        {(errors.invoiceNumber && touched.invoiceNumber) && <div className="error-msg">{errors.invoiceNumber}</div>}
                                    </div>
                                </div>

                                {!isInvoiceDetailsPresent && 
                                    <div className="popup-footer">
                                        <CustomButton name="Cancel" btnType="secondary" onClick={handlePopupClose} />
                                        <CustomButton name="Save" loading={spinner} disabled={spinner} btnType="primary" onClick={() => handleSubmit()} />
                                    </div>
                                }

                                {
                                    (showConfirmationPopup && !isInvoiceDetailsPresent) &&
                                    <DeletePopup
                                        show={showConfirmationPopup} 
                                        modalTitle="Confirmation"
                                        modalContent="Once you save the invoice details, it cannot be changed. Are you sure you want to save?"
                                        cancelClick={() => setShowConfirmationPopup(false)}
                                        extraActions={[
                                            <CustomButton width="100%" style={{ width: "100%" }} key="no" name="No" btnType="secondary" onClick={() => setShowConfirmationPopup(false)} />,
                                            <CustomButton width="100%" style={{ width: "100%" }} key="yes" name="Yes" btnType="primary" onClick={() => 
                                                { 
                                                    setShowConfirmationPopup(false); 
                                                    handleFormSubmit(values); 
                                                }} 
                                            />
                                        ]}
                                    />
                                }
                            </Form>
                        )
                    } 
                }
            </Formik>
        </div>
    )
};

export default InvoiceSettings;