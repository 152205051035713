// import { motion } from 'framer-motion';
// import TanstackTable from '../TanstackTable/TanstackTable';

// import './styles.scss';

// interface TablePopupProps {
//     popupContent: React.ReactNode;
//     rows: any[];
//     columns: any[];
//     count: number;
//     pageSize: number;
//     currentPage: number;
//     updateCurrentPage: (page: number) => void;
//     updatePageSize: (size: number) => void;
//     height: string;
//     drawerOpen: boolean;
//     onRowClick: (row: any) => void;
// }

// const TablePopup: React.FC<TablePopupProps> = (props) => {

//     const {
//         popupContent,
//         columns,
//         count,
//         currentPage,
//         height,
//         pageSize,
//         rows,
//         updateCurrentPage,
//         updatePageSize,
//         drawerOpen,
//         onRowClick
//     } = props;

//     return (
//         <div id="tablePopup">
//             <div className="popup-table-container">
//                 {/* Table Wrapper */}
//                 <motion.div
//                     className="table-wrapper"
//                     style={{ height }}
//                     animate={{ width: drawerOpen ? '50%' : '100%' }}
//                     transition={{ type: 'spring', stiffness: 300, damping: 30 }}
//                 >
//                     <TanstackTable
//                         data={rows}
//                         initialColumns={columns}
//                         rowCount={count}
//                         showNoRowsOverlay={count == 0}
//                         pageSize={pageSize}
//                         currentPage={currentPage - 1}
//                         updateCurrentPage={updateCurrentPage as any}
//                         updatePageSize={updatePageSize}
//                         showSpinner={false}
//                         onRowClick={(row) => onRowClick(row)}
//                         showPageSizeOptions={false}
//                     />
//                 </motion.div>

//                 {/* Drawer */}
//                 {drawerOpen && (
//                     <motion.div
//                         className="popup"
//                         animate={{ width: drawerOpen ? '50%' : '0%' }}
//                         transition={{ type: 'spring', stiffness: 300, damping: 30 }}
//                         style={{ height }}
//                     >
//                        {popupContent}
//                     </motion.div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default TablePopup;

import React from 'react';
import TanstackTable from '../TanstackTable/TanstackTable';
import './styles.scss';

interface TablePopupProps {
    popupContent: React.ReactNode;
    rows?: any[];
    columns?: any[];
    count?: number;
    showSpinner?: boolean;
    pageSize?: number;
    currentPage?: number;
    updateCurrentPage?: (page: number) => void;
    updatePageSize?: (size: number) => void;
    height?: string;
    drawerOpen: boolean;
    onRowClick?: (row: any) => void;
    leftPinnedColumns?: string[];
    rightPinnedColumns?: string[];
    selectedRow?: any;
    component?: React.ReactNode;
    checkboxSelection?: boolean;
    selectedRows?: any[];
    setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
    hidePagination?: boolean;
}

const TablePopup: React.FC<TablePopupProps> = (props) => {
    const {
        popupContent,
        columns,
        count,
        showSpinner,
        currentPage,
        height,
        pageSize,
        rows,
        updateCurrentPage,
        updatePageSize,
        drawerOpen,
        onRowClick,
        leftPinnedColumns,
        rightPinnedColumns,
        selectedRow,
        component,
        hidePagination
    } = props;

    return (
        <div id="tablePopup">
            <div className={`popup-table-container ${drawerOpen ? 'drawer-open' : ''}`} style={{ height: height ? height : '' }}>
                {
                    component ?
                        <div className="component-wrapper">
                            {component}
                        </div>
                        :
                        <div className="table-wrapper">
                            <TanstackTable
                                data={rows as any[]}
                                initialColumns={columns as any[]}
                                rowCount={count as number}
                                showNoRowsOverlay={count == 0}
                                pageSize={pageSize}
                                currentPage={(currentPage as any) - 1}
                                updateCurrentPage={updateCurrentPage as any}
                                updatePageSize={updatePageSize}
                                showSpinner={showSpinner || false}
                                onRowClick={(row) => onRowClick ? onRowClick(row) : null}
                                showPageSizeOptions={!drawerOpen}
                                leftPinnedColumns={leftPinnedColumns}
                                rightPinnedColumns={rightPinnedColumns}
                                selectedRow={selectedRow}
                                showBottomPadding={false}
                                checkboxSelection={props?.checkboxSelection}
                                selectedRows={props?.selectedRows}
                                setSelectedRows={props?.setSelectedRows}
                                hidePagination={hidePagination}
                            />
                        </div>
                }

                {/* Drawer */}
                {drawerOpen && (
                    <div className="popup">
                       {popupContent}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TablePopup;
