import { FormControlLabel } from "@mui/material";
import { CustomSwitch } from "../../../common/StyledComponents/Switch.styled";
import { useEffect, useState } from "react";
import { EnableTransactionalMails } from "../../../pages/Events/enum";
import { updateEvent } from "../../../scripts/apis/events";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import './styles.scss';
import { Event } from "../../../pages/Events/interfaces";

interface IEventTransactionEmailToggleProps { 
    eventDetails: Event;
    setRefreshEventData: React.Dispatch<React.SetStateAction<boolean>>;
}

const EventTransactionEmailToggle: React.FC<IEventTransactionEmailToggleProps> = ({ eventDetails, setRefreshEventData }): React.JSX.Element => 
{
    const { eventId } = useParams();

    const [enableTransactionEmail, setEnableTransactionEmail] = useState<boolean>(false);

    const handleTransactionEmailToggle = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> =>
    {
        setEnableTransactionEmail(!enableTransactionEmail);
        try 
        {
            const data= {
                enableTransactionalMails: event.target.checked ? EnableTransactionalMails.ENABLE : EnableTransactionalMails.DISABLE
            }
            const transactionEmailUpdated = await updateEvent(eventId as string, data);
            if(transactionEmailUpdated)
            {
                toast.success(`Event Transactional Email ${event.target.checked ? 'Enabled' : 'Disabled'} successfully`);
                setRefreshEventData(true);
            }
        } 
        catch (error) 
        {
            toast.error((error as Error)?.message || 'Failed to update Sync status');
        }
    }

    useEffect(() => 
    {
        if(eventDetails && eventDetails?.enableTransactionalMails)
        {
            setEnableTransactionEmail(eventDetails.enableTransactionalMails === EnableTransactionalMails.ENABLE);
        }
    }, [eventDetails?.enableTransactionalMails]);

    return (
        <div className="transaction-toggle-component">
            <div className="title-description">
                <h3 className="title">{'Event Transactional Emails'}</h3>
                <p className="description">{'This will enable transaction emails for the event.'}</p>
            </div>
            
            <div className="switch-container">
                <FormControlLabel
                    checked={enableTransactionEmail}
                    control={
                        <CustomSwitch
                            onChange={(event) => handleTransactionEmailToggle(event)}
                        />
                    }
                    label={''}
                />
            </div>
        </div>
    )
};

export default EventTransactionEmailToggle;