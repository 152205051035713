import './styles.scss';

const CompetitorPage: React.FC = (): React.JSX.Element => {
    return (
        <div className="competitor-page">
            Coming Soon..
        </div>
    );
};

export default CompetitorPage;