import { useContext } from 'react';
import './styles.scss';
import { UserContext } from '../contexts/UserContext';
import { UserViewType } from '../pages/Settings/enum/users.enum';


interface EventsHeaderBarProps 
{
    title: string | React.JSX.Element;
    buttons?: React.JSX.Element[];
    showBorder?: boolean;
    titleRightNode?: React.JSX.Element;
}

const EventsHeaderBar: React.FC<EventsHeaderBarProps> = (props): React.JSX.Element => 
{
    const { title, buttons, showBorder = false, titleRightNode } = props;

    const {
        userDetails,
    } = useContext(UserContext);
    

    return (
        <div id={ userDetails?.viewType === UserViewType.NORMAL ? "eventsHeaderBar" : "eventsHeaderBarMinimal" }>
            <div className={`events-header-bar ${showBorder ? 'bordered' : ''}`}>
                <div className="title-container">
                    <h3 className="title">
                        {title}
                    </h3>
                    {titleRightNode && <div className="title-right-node">{titleRightNode}</div>}
                </div>
                <div className="buttons-container">
                    {
                        buttons && buttons.map((button, index) => (
                            <div key={index}>
                                {button}
                            </div>
                        )) 
                    }
                </div>
            </div>
        </div>
    )
}; 

export default EventsHeaderBar;