/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useRef, useState } from 'react';
import { CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { Button, Stack, Typography, FormControlLabel } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addEvent } from '../../../redux/events/Events';
import { useDispatch, useSelector } from 'react-redux';
import { isImageBuffer, validateEmptyHtml } from '../../../scripts/helpers';
import { useDropzone } from 'react-dropzone';
import { Form } from 'react-bootstrap';
import { updateEvent, uploadImage } from '../../../scripts/apis/events';
import { CONTENT } from '../../../scripts/i18n';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ColorPicker from '../../../common/ColorPicker';
import { Event, EventFooterLinks } from '../../../pages/Events/interfaces';
import { IEventReduxValues, IEventsDispatch } from '../interfaces/create-events_interface';
import './styles.scss';
import { CalendarInvitation, EventLandingPageTheme, EventLocationType, LandingPageLayout } from '../../../pages/Events/enum';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { useJsApiLoader, Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import LexicalEditor from '../../../lexical';
import toast from 'react-hot-toast';

import landingPageDark from '../../../assets/landingpageAssets/landingPageDark.png';
import landingPageLight from '../../../assets/landingpageAssets/landingPageLight.png';
import landingPageNature from '../../../assets/landingpageAssets/landingPageNature.png';
import landingPageStandard from '../../../assets/landingpageAssets/landingPageStandard.png';
import PopupDialog from '../../../common/PopupDialog';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const LandingPageDetails = (props: {eventDetails: Event}): React.JSX.Element => 
{

    const dispatch = useDispatch();
    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });
    const csrfTokenData = useSelector((state: any) => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const eventId = props?.eventDetails?.id;

    const [spinner, setSpinner] = useState(false);
    const [eventBannerChanged, setEventBannerChanged] = useState(false);
    const [bannerImage, setBannerImage] = useState('');
    const [aboutEvent, setAboutEvent] = useState('');
    const [footerLinks, setFooterLinks] = useState(
        props?.eventDetails?.eventFooterLinks ?
            props?.eventDetails?.eventFooterLinks :
            [{
                name: '',
                link: ''
            }]
    );
    const [landingPagectaColor, setLandingPagectaColor] = useState('#CDD0D5');
    const [showThemeSelectPopup, setShowThemeSelectPopup] = useState<boolean>(false);

    const landingPageThemeImg = (theme: EventLandingPageTheme): string =>
    {
        switch(theme)
        {
            case EventLandingPageTheme.DARK:
                return landingPageDark;
            case EventLandingPageTheme.LIGHT:
                return landingPageLight;
            case EventLandingPageTheme.STANDARD:
                return landingPageStandard;
            case EventLandingPageTheme.NATURE:
                return landingPageNature;
            default:
                return '';
        }
    }

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);
    };

    const handleAddFooterLink = (): void => 
    {
        setFooterLinks([...footerLinks, {
            name: '', link: ''
        }]);
    };

    const handleFooterLinkInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) : void => 
    {
        const { name, value } = event.target;
        setFooterLinks((prevData): EventFooterLinks[] =>  
        {
            return prevData.map((item, i): EventFooterLinks => 
            {
                return (i === index ? {
                    ...item, [name]: value 
                } : item); 
            }); 
        });
    };

    const handleRemoveFootertLink = (event: React.SyntheticEvent, index: number): void => 
    {
        const list = [...footerLinks];

        list.splice(index, 1);
       
        setFooterLinks(list);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        multiple: false,
        onDrop: (acceptedFiles) : void => 
        {
            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = () : void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    dispatch(
                        addEvent({
                            banner: file,
                        })
                    );
                    setEventBannerChanged(true);
                    setBannerImage(URL.createObjectURL(file));
                }
                else 
                {
                    setEventBannerChanged(false);
                    setBannerImage('');
                    toast.error('Invalid file format or file size exceeds 10MB');
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        },
    });

    const Preview = (): React.JSX.Element => 
    {
        return (
            <div sx={{
                aspectRatio: props?.eventDetails?.landingPageLayout === 1 ? '1/1 !important' : props?.eventDetails?.landingPageLayout === 2 ? '16/9 !important' : 'auto' 
            }} className="preview-container">
                <img src={bannerImage && bannerImage !== '' ? bannerImage : ''} style={{
                    aspectRatio: props?.eventDetails?.landingPageLayout === 1 ? '1/1 !important' : props?.eventDetails?.landingPageLayout === 2 ? '16/9 !important' : 'auto' 
                }} />
            </div>
        );
    };

    const handleLayoutChange = (type: number): void => 
    {
        formik.setFieldValue('landingPageLayout', type);
    };

    const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event?.preventDefault();
        setSpinner(true);
    
        const parser = new DOMParser();
        const htmlElement = formik.values.aboutEvent ? parser.parseFromString(formik.values.aboutEvent, 'text/html') : '';
    
        if (htmlElement) {
            let images = htmlElement.querySelectorAll('img');
            if (images?.length > 0) {
                images.forEach((image) => {
                    if (image.alt?.includes('lexicalimage')) {
                        image.src = `{{${image?.alt}}}`;
                    }
                });
            }
        }
    
        const validPayload = {
            landingPageLayout: formik.values.landingPageLayout,
            description: formik.values?.overview,
            about: htmlElement && htmlElement?.body?.innerHTML || formik.values?.aboutEvent,
            eventFooterLinks: footerLinks.length > 0 ? footerLinks : null,
            brandColor: landingPagectaColor,
            country: formik.values.country,
            locationDetails: {
                name: formik.values.locationName,
                url: formik.values.locationURL,
                city: formik.values.locationCity,
                latitude: formik.values.locationLatitude,
                longitude: formik.values.locationLongitude,
                pincode: formik.values.pincode,
            },
            state: formik.values.state,
            landingPageTheme: formik.values.landingPageTheme,
            landingPageSections: formik.values.landingPageSections,
            // calendarInvitation: formik.values.enableCalendarInvitation ? CalendarInvitation.ENABLED : CalendarInvitation.DISABLED,
            // calendarDescription: formik.values.calendarDescription,
        };
    
        let eventUpdated: boolean | Event = false;
        let bannerUpdated: boolean | string = false;
    
        try {
            eventUpdated = await updateEvent(eventId, validPayload);
        } catch (error) {
            console.log(error);
        }
    
        if (eventBannerChanged) {
            const banner = new FormData();
            banner.append('file', eventReduxData.banner);
            try {
                bannerUpdated = await uploadImage(eventId, 'eventBanner', banner, csrfTokenData);
            } catch (error) {
                console.log(error);
            }
        }
    
        if (bannerUpdated || eventUpdated) {
            eventBus.dispatch('Refresh-events-top-bar', {
                refresh: true,
            });
            eventBus.dispatch('event-overview-refreshed', {
                refresh: true,
            });
            eventBus.dispatch('event-details-refreshed', {
                refresh: true,
            });
    
            toast.success('Event details updated successfully');
            handleDrawerClose();
        }
    
        setSpinner(false);
    };

    const validationSchema = Yup.object().shape({
        aboutEvent: Yup.string().notRequired(),
            // .matches(/<p\b[^>]*>(?:(?!<br\b[^>]*>)[\s\S])*<\/p>/i, 'Enter valid Content')
            // .required('About Event is required'),
        aboutEventHtmlText: Yup.string().notRequired(),
        overview: Yup.string().nullable(),
        locationName: Yup.string().notRequired(),
        locationURL: Yup.string().notRequired(),
        country: Yup.string().notRequired(),
        calendarDescription: Yup.string().notRequired(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            aboutEvent: props?.eventDetails?.about || '',
            aboutEventHtmlText: props?.eventDetails?.about || '',
            landingPageLayout: props?.eventDetails?.landingPageLayout || '',
            overview: props?.eventDetails?.description || '',
            locationName: props?.eventDetails?.locationDetails?.name || '',
            locationURL: props?.eventDetails?.locationDetails?.url || '',
            state: props?.eventDetails?.state || '',
            locationCity: props?.eventDetails?.locationDetails?.city || '',
            locationLatitude: props?.eventDetails?.locationDetails?.latitude || '',
            locationLongitude: props?.eventDetails?.locationDetails?.longitude || '',
            pincode: props?.eventDetails?.locationDetails?.pincode || '',
            country: props?.eventDetails?.country || '',
            enableCalendarInvitation: props?.eventDetails?.calendarInvitation === CalendarInvitation.ENABLED ? true : false,
            calendarDescription: props?.eventDetails?.calendarDescription || '',
            landingPageTheme: props?.eventDetails?.landingPageTheme || EventLandingPageTheme.STANDARD,
            landingPageSections: props?.eventDetails?.landingPageSections,
        },
        onSubmit: async (): Promise<void> => 
        {
            handleSubmit();
        }
    });

    useEffect((): void => 
    {
        setBannerImage(props?.eventDetails?.eventBanner || '');
        setLandingPagectaColor(props?.eventDetails?.brandColor || '#CDD0D5');
        setAboutEvent(props?.eventDetails?.about || '');
    }, []);

    const landingPageSectionOptions = [
        {
            id: 'schedule',
            name: 'Agenda',
            value: formik.values?.landingPageSections?.schedule || false,
            icon: 'calendar' as IconName
        },
        {
            id: 'speakers',
            name: 'Speakers',
            value: formik.values?.landingPageSections?.speakers || false,
            icon: 'microphone' as IconName
        },
        {
            id: 'sponsors',
            name: 'Sponsors',
            value: formik.values?.landingPageSections?.sponsors || false,
            icon: 'dollar' as IconName
        }
    ]

    const { isLoaded } = import.meta.env.VITE_LOCATION_API_KEY ? useJsApiLoader({
        googleMapsApiKey: import.meta.env.VITE_LOCATION_API_KEY,
        libraries: ['places'],
    }) : {
        isLoaded: false
    };
 
    const [place, setPlace] = useState(formik.values.locationName);
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
 
    const onLoad = (autocomplete: any) =>
    {
        autocompleteRef.current = autocomplete;
    };
 
    const onPlaceChanged = () =>
    {
        if (autocompleteRef.current)
        {
            const selectedPlace = autocompleteRef.current?.getPlace();
            if(selectedPlace)
            {
                const countryComponent = selectedPlace.address_components?.find((component) => 
                {
                    return component.types.includes('country');
                });
    
                if (countryComponent) 
                {
                    const countryName = countryComponent.long_name;
                    formik.setFieldValue('country', countryName);
                }
                formik.setFieldValue('locationName', selectedPlace.name);
                formik.setFieldValue('locationURL', selectedPlace.url);

                const addressComponents = selectedPlace.address_components;
                const geometry = selectedPlace.geometry;

                let cityName = '';
                let latitude = 0;
                let longitude = 0;
                let stateName = '';
                let pincode = '';
                if(addressComponents)
                {
                    addressComponents.forEach((component) => {
                        if (component.types.includes('administrative_area_level_1')) {
                            stateName = component.short_name;
                        }
                        if (component.types.includes('locality')) {
                            cityName = component.long_name;
                        }
                        if (component.types.includes('postal_code')) {
                            pincode = component.long_name;
                        }
                    });
                }

                if (geometry && geometry.location) {
                    latitude = geometry.location.lat();
                    longitude = geometry.location.lng();
                }

                formik.setFieldValue('state', stateName);
                formik.setFieldValue('locationCity', cityName);
                formik.setFieldValue('locationLatitude', String(latitude));
                formik.setFieldValue('locationLongitude', String(longitude));
                formik.setFieldValue('pincode', pincode);
               
                setPlace(selectedPlace?.name || '');
            }
            
        }
    };

    const SelectThemePopup = (): React.JSX.Element =>
    {
        const themeOptions = [
            {
                name: 'Standard',
                img: landingPageStandard,
                value: EventLandingPageTheme.STANDARD
            },
            {
                name: 'Dark',
                img: landingPageDark,
                value: EventLandingPageTheme.DARK
            },
            {
                name: 'Light',
                img: landingPageLight,
                value: EventLandingPageTheme.LIGHT
            },
            {
                name: 'Nature',
                img: landingPageNature,
                value: EventLandingPageTheme.NATURE
            }
        ]

        return (
            <div className="select-landingPage-theme-popup">
                <div className="title-container">
                    <h3 className="title">{'Select Theme'}</h3>
                    <CustomButton 
                        name=''
                        btnType='secondary'
                        startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />}
                        onClick={() => setShowThemeSelectPopup(!showThemeSelectPopup)}
                    />
                </div>
                <div className="default-theme-list">
                    {
                        themeOptions.map((theme, index) => (
                            <div className="default-theme-card-container" key={index}>
                                <button className="default-theme-card">
                                    <img 
                                        src={theme.img}
                                        alt={theme?.name}
                                        className="default-theme-image"
                                    />
                                    <div className="use-theme-button-overlay">
                                        <CustomButton 
                                            btnType='primary'
                                            name='Use Theme'
                                            onClick={() => {
                                                formik.setFieldValue('landingPageTheme', theme.value);
                                                setShowThemeSelectPopup(!showThemeSelectPopup);
                                            }}
                                        />
                                    </div>
                                </button>
                                <p className="theme-name">{theme?.name}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

    return (
        <div id="landingPageDetails">
            <CloseIconComponent onClick={handleDrawerClose} />

            <Form 
                noValidate 
                autoComplete="off" 
                onSubmit={(values): void => 
                {
                    return formik.handleSubmit(values);
                }}
                onKeyDown={(event): void =>
                {
                    if (event.key === 'Enter') 
                    {
                        event.preventDefault();
                    }
                }}
            >
                <div className="sidebar-container-spacing">
                    <FormLabelComponent label="Theme" required />
                    <div className="event-landing-page-type-wrapper">
                        <div className="landing-page-theme-container">
                            <img 
                                src={landingPageThemeImg(formik.values.landingPageTheme)}
                                alt="landing page theme"
                                className="landing-page-theme-image"
                            />
                            <div className='landing-page-theme-overlay'>
                                <CustomButton name='Edit Theme' btnType='primary' onClick={() => setShowThemeSelectPopup(!showThemeSelectPopup)} />
                            </div>
                        </div>
                        {/* <div onClick={() : void => 
                        {
                            handleLayoutChange(1); 
                        }} 
                        className={formik.values.landingPageLayout === LandingPageLayout.ONE_TO_ONE ? 'active-landing-page-type-container' : 'landing-page-type-container'}>
                            {formik.values.landingPageLayout === LandingPageLayout.ONE_TO_ONE ? <SquareType selected={true}/> : <SquareType />}
                        </div>
                        <div onClick={() : void => 
                        {
                            handleLayoutChange(2); 
                        }}
                        className={formik.values.landingPageLayout === LandingPageLayout.SIXTEEN_TO_NINE ? 'active-landing-page-type-container' : 'landing-page-type-container'}>
                            {formik.values.landingPageLayout === LandingPageLayout.SIXTEEN_TO_NINE ? <LandscapeType selected={true}/> : <LandscapeType/>}
                        </div> */}
                    </div>
                </div>
                <div className="sidebar-container-spacing">
                    <FormLabelComponent label="Banner" />
                    <div className="event-banner-box">
                        {bannerImage && bannerImage !== '' ?
                            <div className="event-banner-preview-box"><Preview />
                                <div className="icon-box">
                                    <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={(): void => 
                                    {
                                        dispatch(addEvent({
                                            banner: '',
                                        }));
                                        setBannerImage('');
                                    }} />
                                </div>
                            </div>
                            :
                            <div {...getRootProps()} className="empty-upload-box">
                                <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} className="upload-icon" />
                                <input {...getInputProps()} hidden accept="image/*" type="file" />
                                <div>
                                    <Typography className="drag-drop-text">{'Browse File'}</Typography>
                                    <Typography className="drag-drop-text">{formik.values.landingPageLayout === LandingPageLayout.ONE_TO_ONE ? 'Select 1:1' : 'Select 16:9'}</Typography>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="sidebar-container-spacing">
                    <FormLabelComponent label="Brand Color" />
                    <ColorPicker selectedColor={landingPagectaColor} setSelectedColor={setLandingPagectaColor} />
                </div>
                <div className="sidebar-container-spacing">
                    <FormLabelComponent label="Overview" />
                    <FormControlComponent type="text" rows={4} placeholder="Description" defaultValue={formik.values.overview} value={formik.values.overview} as="textarea" minHeight="64px !important" maxHeight="64px !important" onChange={(event): void => 
                    {
                        formik.setFieldValue('overview', event.target.value);
                    }} />
                    {formik.touched.overview && formik.errors.overview ? <Typography className="error-msg">{formik.errors.overview}</Typography> : null}
                </div>
                <div className="sidebar-container-spacing">
                    <LexicalEditor label='About Event' toolBarOptions={['FontColor', 'BlockFormat', 'Bold', 'Italic', 'InsertImage', 'InsertEmbed', 'InsertPageBreak', 'ElementFormat']} minHeight={'250px'} slashCommands={['Heading 1', 'Heading 2', 'Heading 3', 'Paragraph', 'Divider', 'Bulleted List', 'Embed Youtube Video']} value={formik.values.aboutEvent} handleChange={(html: string, json: any) => { 
                            formik.setFieldValue('aboutEventHtmlText', validateEmptyHtml(html));
                            
                            formik.setFieldValue('aboutEvent', html);
                     }} />
                    {formik.touched.aboutEventHtmlText && formik.errors.aboutEventHtmlText ? <Typography className="error-msg">{formik.errors.aboutEventHtmlText}</Typography> : null}
                </div>
                {(props?.eventDetails?.locationType === EventLocationType.ONSITE || props?.eventDetails?.locationType === EventLocationType.HYBRID) && <>
                    {isLoaded && (<div className="sidebar-container-spacing">
                       <FormLabelComponent label={'Event Location'} />
                           <GoogleAutocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                               <FormControlComponent
                                   value={place}
                                   placeholder={'Search for a location...'}
                                   type={'text'}
                                   onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                   {
                                       setPlace(event.target.value);
                                   }}
                               />
                           </GoogleAutocomplete>
                   </div>
                       )}
                </>}
                <div className="sidebar-container-spacing landing-page-sections-container">
                    {
                        landingPageSectionOptions.map((section) => {
                            return (
                                <div key={section.name} className="landing-page-section">
                                    <div className="landing-page-section-label-container">
                                        <FontAwesomeIcon icon={['fal', section.icon]} className="section-icon" />
                                        <FormLabelComponent label={section.name} noBottomMargin />
                                    </div>
                                    <FormControlLabel
                                        checked={formik.values.landingPageSections[section.id as keyof typeof formik.values.landingPageSections]}
                                        control={<CustomSwitch
                                            onChange={(event:  React.ChangeEvent<HTMLInputElement>): void => 
                                            {
                                                formik.setFieldValue(`landingPageSections.${section.id}`, event.target.checked);
                                            }}
                                        />}
                                        label={''}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="sidebar-container-spacing">
                    {footerLinks && footerLinks.length > 0 && footerLinks.map((item, index): React.JSX.Element => 
                    {
                        return (
                            <>
                                <div className="footer-link-container" key={index}>
                                    <div className="footer-heading-remove-icon-cont">
                                        <FormLabelComponent label={`Footer Link ${index + 1}`} />
                                        <FontAwesomeIcon className="footer-link-remove-icon" onClick={(event): void => 
                                        {
                                            handleRemoveFootertLink(event, index); 
                                        }} icon={['fal', 'circle-minus']} />
                                    </div>
                                    <div className="footer-name-url-cont">
                                        <FormControlComponent name="name" placeholder="Name" onChange={(event): void => 
                                        {
                                            handleFooterLinkInputChange(event, index); 
                                        }} value={item?.name || ''} type='text' />
                                        <FormControlComponent name="link" placeholder="URL" onChange={(event): void => 
                                        {
                                            handleFooterLinkInputChange(event, index); 
                                        }} value={item?.link || ''} type='text' />
                                    </div>
                                </div>
                            </>
                        );
                    })}
                    {footerLinks?.length <= 5 && <Button className="add-footer-link-button" onClick={handleAddFooterLink}>{'+ Add Footer Link'}</Button>}
                </div>
                <div className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton type="submit" loading={spinner} name={'Save'} btnType={'primary'} />
                    </Stack>
                </div>

                {
                    showThemeSelectPopup &&
                    <PopupDialog 
                        show={showThemeSelectPopup}
                        modalContent={<SelectThemePopup />}
                        cancelClick={() => setShowThemeSelectPopup(!showThemeSelectPopup)}
                        modalId='landingPage-theme-popup'
                    />
                }
            </Form>
        </div>
    );
};

export default LandingPageDetails;

// Old location details inputs

{/* <div className="sidebar-container-spacing">
<FormLabelComponent label="Country" />
<CountryDropdown
    value={formik.values.country}
    onChange={(value: string): void => 
    {
        formik.setFieldValue('country', value);
    }}
    classes='country-dropdown'
/>
</div>
<div className="sidebar-container-spacing">
<FormLabelComponent label="Event Location Name" />
<FormControlComponent type="text" rows={4} placeholder="Eg. Bengaluru, India" value={formik.values.locationName} onChange={(event): void => 
{
    formik.setFieldValue('locationName', event.target.value);
}} />
{formik.touched.locationName && formik.errors.locationName ? <Typography className="error-msg">{formik.errors.locationName}</Typography> : null}
</div>
<div className="sidebar-container-spacing">
<FormLabelComponent label="Event Location URL" />
<FormControlComponent type="text" rows={4} placeholder="URL" value={formik.values.locationURL} onChange={(event): void => 
{
    formik.setFieldValue('locationURL', event.target.value);
}} />
{formik.touched.locationURL && formik.errors.locationURL ? <Typography className="error-msg">{formik.errors.locationURL}</Typography> : null}
</div> */}