/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addVendors } from '../../redux/vendors/Vendors';
import { createVendor, getVendorsActivityLog, updateVendor } from '../../scripts/apis/vendors';
import _ from 'lodash';
import { createEventVendor, removeEventVendor, updateEventVendor } from '../../scripts/apis/eventVendor';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './styles.scss';
import { CONTENT } from '../../scripts/i18n';
import { CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { renderCheckBoxComponent, renderDateComponent, renderDropDownComponent, renderInputComponent, renderRadioGroupComponent  } from '../../pages/Settings/CustomField/CustomTypeComponent';
import { CustomFieldLocations, CustomFieldType, customFieldCountByLocation, customFieldsByLocation } from '../../pages/Settings/CustomField/CustomField.helper';
import { VendorState, IVendors } from './IVendors';
import CustomSpinner from '../../common/CustomSpinner';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeletePopup from '../../common/DeletePopup';
import toast from 'react-hot-toast';
import { ActivityLogResponse } from '../../interfaces/activity-log_interface';
import ActivityLogViewer from '../../common/ActivityLog/ActivityLogViewer';
import ActivityLogComponent from '../../common/ActivityLog/ActivityLogComponent';
import { vendorsActivityLog } from '../../scripts/activityLogGenerator/activitylog-generator';

interface IVendorsProps {
    'data-eventId'?: string | number;
    'vendor-data'?: IVendors;
    disabled?: boolean;
    eventVendor?: boolean;
    addEventVendor?: boolean;
    eventVendorData?: IVendors;
    editable?: boolean;
    setRefresh: (refresh: boolean) => void;
    routeFromId?: boolean;
    routeFromEventVendor?: boolean;
    createEventVendor?: boolean;
    createorUpdateGlobalVendor?: boolean;
    isTableView?: boolean;
    handlePopupClose: () => void;
    heading: string;
    bottombtn?: boolean;
}
const Vendors  = (props: IVendorsProps): React.JSX.Element => 
{
    const eventId = props['data-eventId'];
    const vendorsData = props['vendor-data'];
    const disabled = props.disabled;
    const eventVendor = props.eventVendor;
    const addEventVendor = props.addEventVendor;
    const eventVendorData = props.eventVendorData;
    const eventVendorEditable = props.editable;  
    const heading = props.heading;
    const [showActivityLog, setShowActivityLog] = useState(false);

    const vendorsReduxData = useSelector((state: VendorState): IVendors => 
    {
        return state.vendors.value;
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const [showDescription, setShowDescription] = useState(false);
    // const [vendorDescriptionLength, setVendorDescriptionLength] = useState(0);
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsSpinner, setCustomFieldsSpinner] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });
    const [customFormState, setCustomFormState] = useState({
    });

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[a-zA-Z ]*$/, 'Enter a valid Vendor Name')
            .required('Name is required'),
        email: Yup.string()
            .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, 'Enter a valid email')
            .required('Email is required'),
        vendorName: Yup.string()
            .matches(/^[a-zA-Z ]*$/, 'Enter a valid Contact Person Name')
            .required('Contact Person is required'),
        description: Yup.string().test('isValidDescription', 'Maximum words Exceeded', (value: string): boolean => 
        {
            if (value && value.trim().length > 0) 
            {
                const words = value.trim().split(/\s+/);
                const wordsLength = words.length;
                // setVendorDescriptionLength(wordsLength);
                if (wordsLength > 250) 
                {
                    return false;
                }
                else 
                {
                    return true;
                }
            }
            else 
            {
                // setVendorDescriptionLength(0);
                return true;
            }
        }),
        linkedIn: Yup.string()
            .matches(/(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/, 'Please enter a valid LinkedIn URL'),
        twitter: Yup.string()
            .matches(/(https:\/\/x.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/, 'Please enter a valid Twitter URL')
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: vendorsReduxData.name ? vendorsReduxData.name : '',
            email: vendorsReduxData.email ? vendorsReduxData.email : '',
            vendorName: vendorsReduxData.vendorName ? vendorsReduxData.vendorName : '',
            description: vendorsReduxData.description ? vendorsReduxData.description : '',
            linkedIn: vendorsReduxData?.social?.linkedIn ? vendorsReduxData?.social?.linkedIn : '',
            twitter: vendorsReduxData?.social?.twitter ? vendorsReduxData?.social?.twitter : '',
        },
        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);
            // let eventVendorCreated;
            const vendorDetails = {
                name: formik.values?.name,
                email: formik.values?.email,
                // url: formik.values?.url,
                vendorName: formik.values?.vendorName,
                description: formik.values?.description,
                social: {
                    linkedIn: formik.values?.linkedIn,
                    twitter: formik.values?.twitter
                }
            };

            if (!eventId) 
            {
                if (_.isEmpty(vendorsData)) 
                {
                    const newVendorInfo = {
                        ...vendorDetails,
                        additionalFieldData: customFormState ? {
                            ...customFormState 
                        } : null
                    };

                    try 
                    {
                        const vendorsCreated = await createVendor(newVendorInfo, csrfTokenData);
                        if (vendorsCreated) 
                        {
                            props.setRefresh(true);
                            toast.success('Vendor Added Successfully');
                            handleDrawerClose();
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                        toast.error( _.startCase((error as Error).message.toLowerCase()))
                    }
                    

                }
                else 
                {
                    if (vendorsData.name !== formik.values.name || vendorsData.email !== formik.values.email || vendorsData.description !== formik.values.description || vendorsData?.social?.linkedIn !== formik.values.linkedIn || vendorsData?.social?.twitter !== formik.values.twitter || customFormState) 
                    {
                        const newVendorInfo = {
                            ...vendorDetails,
                            additionalFieldData: customFormState ? {
                                ...customFormState 
                            } : null
                        };
                        try 
                        {
                            const vendorsUpdated = await updateVendor(newVendorInfo, vendorsData.id);
                            if (vendorsUpdated) 
                            {
                                props.setRefresh(true);
                                handleDrawerClose();
                            }

                        }
                        catch (error) 
                        {
                            console.log(error);
                            toast.error( _.startCase((error as Error).message.toLowerCase()))
                        }
                    }
                    else 
                    {
                        setSpinner(false);
                    }
                }
            }
            else 
            {
                if (!eventVendorEditable) 
                {
                    try 
                    {
                        const vendorsUpdated = await createVendor(vendorDetails, csrfTokenData);
                        if (vendorsUpdated) 
                        {
                            const eventVendorDetails = {
                                status: 1,
                                vendorId: vendorsUpdated.id,
                                eventId: eventId,
                            };
                            try 
                            {
                                const eventVendorCreated = await createEventVendor(eventVendorDetails, eventId, csrfTokenData);
                                if (eventVendorCreated) 
                                {
                                    props.setRefresh(true);
                                    toast.success('Vendor Added Successfully');
                                    handleDrawerClose();
                                }
                            }
                            catch (error) 
                            {
                                console.log(error);
                                toast.error( _.startCase((error as Error).message.toLowerCase()))
                            }
                        }

                    }
                    catch (error) 
                    {
                        console.log(error);
                        toast.error( _.startCase((error as Error).message.toLowerCase()))

                    }
                }
                else 
                {
                    const eventVendorDetails = {
                        status: 1,
                        vendorId: eventVendorData.id
                    };
                    try 
                    {
                        const vendorsUpdated = await updateEventVendor(eventVendorData.id, eventVendorDetails);
                        if (vendorsUpdated) 
                        {
                            props.setRefresh(true);
                            toast.success('Vendor details Updated Successfully');
                            handleDrawerClose();
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                        toast.error( _.startCase((error as Error).message.toLowerCase()))
                    }

                }
            }
        }
    });

    // const handleDescription = () : React.JSX.Element => 
    // {
    //     if (showDescription) 
    //     {
    //         return (
    //             <React.Fragment>
    //                 <Grid container>
    //                     <Grid item xs={12}>
    //                         <Form.Group>
    //                             <Form.Label className="label-name">{CONTENT.VENDORS.FORMS.LABEL.DESCRIPTION}</Form.Label>
    //                             <InputGroup className="full-width-input-group">
    //                                 <Form.Control
    //                                     as="textarea"
    //                                     className="rounded-end text-box-description"
    //                                     type="text"
    //                                     size="lg"
    //                                     defaultValue={formik.values.description}
    //                                     onChange={(event): void => 
    //                                     {
    //                                         dispatch(addVendors({
    //                                             description: event.target.value
    //                                         }));
    //                                         setVendorDescriptionLength(event.target.value.length);
    //                                         formik.setFieldValue('description', event.target.value);
    //                                     }}
    //                                     name={'description'}
    //                                     disabled={disabled ? disabled : null}
    //                                 />
    //                             </InputGroup>
    //                             {formik.errors.description && formik.touched.description ? <Typography className="error-msg">{formik.errors.description}</Typography> : null}
    //                         </Form.Group>
    //                     </Grid>
    //                 </Grid>
    //                 <Typography className="description-length">{vendorDescriptionLength}{CONTENT.VENDORS.FORMS.LABEL.DESCRIPTION_LENGTH}</Typography>
    //             </React.Fragment>
    //         );
    //     }
    //     else 
    //     {
    //         return (<Button className="add-description-btn" onClick={(): void => 
    //         {
    //             setShowDescription(true);
    //         }}>{CONTENT.VENDORS.FORMS.BUTTON.ADD_DESCRIPTION}</Button>);
    //     }
    // };

    const handleDrawerClose = (): void => 
    {
        props?.handlePopupClose();
        setShowActivityLog(false);

        eventBus.dispatch('selected-row-id', null);

        if (props?.routeFromId) 
        {
            navigate('/vendors');
        }

        if (props?.routeFromEventVendor && eventId) 
        {
            navigate('/events/' + eventId + '/people/vendors');
        }
    };

    const handleBackToPopup = (): void =>
    {
        setShowActivityLog(false);
    }
    
    const handleInputChange = (event, id): void => 
    {
        const { name, value } = event.target;
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleDropDownInputChange = (name, value, id): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleBoxChange = (name, value, id): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleCheckboxChange = (item, data): void => 
    {

        if (customFormState[data.label + ' ' + data.id]) 
        {
            const selectedIndex = customFormState[data.label + ' ' + data.id].indexOf(item.name);
            const updatedSelectedOptions = [...customFormState[data.label + ' ' + data.id]];

            if (selectedIndex === -1) 
            {
                updatedSelectedOptions.push(item.name);
            }
            else 
            {
                updatedSelectedOptions.splice(selectedIndex, 1);
            }

            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: updatedSelectedOptions,
                }; 
            });
        }
        else 
        {
            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: [item.name],
                }; 
            });
        }

    };

    const removeVendor = async ():Promise<void> =>
    {
        try 
        {
            const vendorRemoved = await removeEventVendor(eventId, vendorsData?.id);
            if (vendorRemoved) 
            {
                toast.success(`Sponsor ${vendorsData?.name} deleted successfully`);
                setShowDeletePopup(false);
                handleDrawerClose();
                props?.setRefresh(true);
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error(`${error?.message}`);
        }
    };

    const handleNestedSidebarOpen = (): void =>
    {
        setShowActivityLog(true);
    };

    const fetchVendorsCustomFieldData = async ():Promise<void> => 
    {
        try 
        {
            setCustomFieldsSpinner(true);
            const cfcount = await customFieldCountByLocation(CustomFieldLocations.VENDOR);
            if(cfcount)
            {
                try 
                {
                    const customFields = await customFieldsByLocation(cfcount,0,CustomFieldLocations.VENDOR);
                    if(customFields)
                    {
                        setCustomFields(customFields);
                        setCustomFieldsSpinner(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setCustomFieldsSpinner(false);
                }
            }
            
        }
        catch (error) 
        {
            console.log(error);
            setCustomFieldsSpinner(false);
        }
        
    };

    const fetchVendorsActivityLogs = async ():Promise<void> =>
    {
        try 
        {
            const activityLogs = await getVendorsActivityLog(vendorsData?.id);
            if(activityLogs)
            {
                setActivityLogs(activityLogs);
            }
        } 
        catch (error)
        {
            console.log(error);
        }
    };

    useEffect(():void => 
    {
        if (vendorsData) 
        {
            fetchVendorsActivityLogs();

            dispatch(addVendors({
                name: vendorsData && vendorsData.name ? vendorsData.name : '',
                email: vendorsData && vendorsData.email ? vendorsData.email : '',
                // url: vendorsData && vendorsData.url ? vendorsData.url : '',
                vendorName: vendorsData && vendorsData.vendorName ? vendorsData.vendorName : '',
                description: vendorsData && vendorsData.description ? vendorsData.description : '',
                social: vendorsData && vendorsData?.social ? vendorsData?.social : '',
            }));
            // setShowDescription(true);

            if (vendorsData?.additionalFieldData) 
            {
                setCustomFormState({
                    ...vendorsData?.additionalFieldData 
                });

            }
        }
        fetchVendorsCustomFieldData();
    }, []);

    useEffect(():void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, ():void => 
        {
            navigate(0);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data):void => 
        {
            setSpinner(data.spinner);
        });

    }, []);

    const renderFormFields = (): React.ReactElement =>
    {
        return (
            <>
                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.VENDORS.FORMS.LABEL.NAME} required />
                    <FormControlComponent
                        value={formik.values.name}
                        placeholder={CONTENT.VENDORS.FORMS.PLACEHOLDER.NAME}
                        type="text"
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('name', event.target.value);
                        }}
                        disabled={disabled ? disabled : null}
                        required
                        isInvalid={formik.errors.name && formik.touched.name}
                    />
                    {formik.errors.name && formik.touched.name ? <Typography className="error-msg">{formik.errors.name}</Typography> : null}
                </div>

                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.VENDORS.FORMS.LABEL.VENDOR_NAME} required />
                    <FormControlComponent
                        value={formik.values.vendorName}
                        type="text"
                        placeholder={'Enter Contact Person Name'}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('vendorName', event.target.value);
                        }}
                        disabled={disabled ? disabled : null}
                        required
                        isInvalid={formik.errors.vendorName && formik.touched.vendorName}
                    />
                    {formik.errors.vendorName && formik.touched.vendorName ? <Typography className="error-msg">{formik.errors.vendorName}</Typography> : null}
                </div>

                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.VENDORS.FORMS.LABEL.DESCRIPTION} />
                    <FormControlComponent
                        as="textarea"
                        rows={3}
                        type="text"
                        value={formik.values.description}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('description', event.target.value);
                        }}
                        disabled={disabled ? disabled : null}
                        isInvalid={formik.errors.description && formik.touched.description}
                    />
                </div>

                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.VENDORS.FORMS.LABEL.EMAIL} required />
                    <FormControlComponent
                        type="text"
                        placeholder={CONTENT.VENDORS.FORMS.PLACEHOLDER.EMAIL}
                        value={formik.values.email}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('email', event.target.value?.toLowerCase());
                        }}
                        disabled={disabled ? disabled : null}
                        required
                        isInvalid={formik.errors.email && formik.touched.email}
                    />
                    {formik.errors.email && formik.touched.email ? <Typography className="error-msg">{formik.errors.email}</Typography> : null}
                </div>

                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.VENDORS.FORMS.LABEL.SOCIAL_LINK.LINKEDIN} />
                    <FormControlComponent
                        type="text"
                        placeholder={'URL'}
                        value={formik.values.linkedIn}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('linkedIn', event.target.value);
                        }}
                        disabled={disabled ? disabled : null}
                        isInvalid={formik.errors.linkedIn && formik.touched.linkedIn}
                    />
                    {formik.errors.linkedIn && formik.touched.linkedIn ? <Typography className="error-msg">{formik.errors.linkedIn}</Typography> : null}
                </div>

                <div className="popup-container-spacing">
                    <FormLabelComponent label={CONTENT.VENDORS.FORMS.LABEL.SOCIAL_LINK.TWITTER} />
                    <FormControlComponent
                        type="text"
                        placeholder={'URL'}
                        value={formik.values.twitter}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('twitter', event.target.value);
                        }}
                        disabled={disabled ? disabled : null}
                        isInvalid={formik.errors.twitter && formik.touched.twitter}
                    />
                    {formik.errors.twitter && formik.touched.twitter ? <Typography className="error-msg">{formik.errors.twitter}</Typography> : null}
                </div>

                {customFields.length > 0 &&
                 <div className="custom-field-container">
                     {customFields.length > 0 &&
                     customFieldsSpinner ? <CustomSpinner height={'100%'}/> :
                         customFields.map((item): React.ReactElement => 
                         {
                             if (item.type === CustomFieldType.NUMBER) 
                             {
                                 return renderInputComponent(item, 'number', handleInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.SINGLE_LINE) 
                             {
                                 return renderInputComponent(item, 'text', handleInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.MULTI_LINE_TEXT) 
                             {
                                 return renderInputComponent(item, 'textarea', handleInputChange, customFormState?.[item.name + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.CHECKBOXES) 
                             {
                                 return renderCheckBoxComponent(item, handleCheckboxChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.DROPDOWN) 
                             {
                                 return renderDropDownComponent(item, handleDropDownInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.RADIO) 
                             {
                                 return renderRadioGroupComponent(item, handleBoxChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.DATE) 
                             {
                                 return renderDateComponent(item, handleInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                         })
                     }
                 </div>
                }
            </>
        )
    }

    const renderHeader = () => 
    {
        if(disabled || (props?.createEventVendor && props?.addEventVendor)) {
            return <></>
        }

        return (
            <div className='popup-header'>
                <div className='popup-header-content'>
                    {showActivityLog ? (
                        <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} onClick={handleBackToPopup} />
                    ) : (
                        <div className='popup-header-icon'><FontAwesomeIcon icon={['fal', 'diamond']} /></div>
                    )}

                    <div className='popup-header-text'>
                        <h3>
                            {showActivityLog ? 'Activty' : heading}
                        </h3>
                        <p>
                            {showActivityLog ? vendorsData?.name : 'Update vendor name and other details.'}
                        </p>
                    </div>
                </div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => props.handlePopupClose()} />
            </div>
        );
    }

    const renderMainContent = () => {
        if(showActivityLog && vendorsData){
            return <ActivityLogComponent activityLog={vendorsActivityLog(activityLogs, vendorsData)} />
        }else {
            return renderFormFields();
        }
    }

    const renderFooter = () => {
        if(disabled || showActivityLog) {
            return <></>
        }
        
        return (
            <>
                {(vendorsData && (props['vendor-data']))  ?
                    <div className="popup-footer">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        <div className='popup-footer-left'>
                            {!props?.isTableView && eventId && <FontAwesomeIcon className="popup-delete-icon" icon={['fal', 'trash']} onClick={() => setShowDeletePopup(true)} />}

                            {
                                vendorsData &&
                                <CustomButton
                                    name=""
                                    btnType="secondary"
                                    startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                                    style={{ width: '32px' }}
                                    onClick={handleNestedSidebarOpen}
                                />
                            }
                        </div>
                        <Stack direction={'row'} spacing={1} display={'flex'}>
                            <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                            <CustomButton btnType='primary' loading={spinner} name={'Save'} type='submit' />
                        </Stack>
                    </Stack>
                    </div> 
                    :
                    <div className="popup-footer">
                        <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'} width={'100%'}>
                            <Stack direction={'row'} spacing={1} display={'flex'}>
                                <CustomButton onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} btnType='secondary' type='button' />
                                <CustomButton onClick={formik.handleSubmit} type="submit" name={'Save'} loading={spinner} btnType='primary' />
                            </Stack>
                        </Stack>
                    </div>

                }
            </>
        );
    }

    return (
        <div id="createVendors">
            {renderHeader()}

            <Form noValidate autoComplete="off" onSubmit={(values: React.FormEvent<HTMLFormElement>): void => 
            {
                return formik.handleSubmit(values);
            }}>

                <div className={(disabled) ? 'popup-container p-0' : 'popup-container'}>
                   {renderMainContent()}
                </div>

                {renderFooter()}
            </Form>

            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={removeVendor}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                    }}
                    modalContent={`Are you sure you want to delete ${vendorsData?.name}?`}
                    modalTitle='Delete Vendor'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='trash'
                />
            }
        </div>
    );
};

export default Vendors;