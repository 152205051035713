import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FormControlComponent, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { createFolder, deleteFolder, updateFolder } from '../../../scripts/apis/eventPhotoSharing';
import { useSelector } from 'react-redux';
import { EventPhotoSharingFolder } from '../../../pages/Events/interfaces/event-photo-sharing_interface';

import './styles.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';


const AddFolder: React.FC<{ eventId: string | number, setFoldersRefresh: React.Dispatch<React.SetStateAction<boolean>>, existingFolderData?: EventPhotoSharingFolder | undefined, setRefresh: React.Dispatch<React.SetStateAction<boolean>> }> = (props): React.JSX.Element =>
{
    const { eventId, setFoldersRefresh, existingFolderData, setRefresh } = props;
    const navigate = useNavigate();
    
    const csrfTokenData = useSelector((state): string => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const [spinner, setSpinner] = useState<boolean>(false);

    const validationSchema = Yup.object({ 
        folderName: Yup.string().required('Required')
    });

    const handleDialogClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, {
            open: false,
        });
    };

    const handleDelete = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
            open: true,
            title: 'Delete Photos',
            content: `Are you sure you want to delete ${existingFolderData?.name}?`,
            acceptBtn: 'Delete',
            acceptEvent: 'delete-event-photos-folder',
            rejectBtn: 'Cancel',
        });

        deleteExistingFolder();
    };

    const deleteExistingFolder = async (): Promise<void> => 
    {
        let deleteSuccess = true;

        eventBus.on('delete-event-photos-folder', async ():Promise<void> => 
        { 
            if(deleteSuccess)
            {
                try 
                {
                    const folderDeleted = await deleteFolder(eventId, existingFolderData?.id || '');
                    if(folderDeleted)
                    {
                        handleDialogClose();
                        navigate(`/events/${eventId}/photo-sharing`);
                        setFoldersRefresh(true);
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.CLOSE_POPUP_EVENT, true);
                    }
                } 
                catch (error) 
                {
                    console.log(error);
                }
            }
        });
    };

    return(
        <div id="addFolder">
            <div className="header">
                <h3>{existingFolderData ? 'Update Folder' : 'Create Folder'}</h3>
                <p>Please enter a name for this folder</p>
            </div>

            <Formik
                initialValues={{
                    folderName: existingFolderData?.name || '',
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={async (values) => { 
                    setSpinner(true);
                    if(existingFolderData)
                    {
                        try 
                        {
                            const folderUpdated = await updateFolder(eventId, existingFolderData.id, { name: values.folderName });
                            if(folderUpdated)
                            {
                                setSpinner(false);
                                setFoldersRefresh(true);
                                handleDialogClose();
                            }
                        } 
                        catch (error) 
                        {
                            console.log('Error in updating folder', error);
                            toast.error((error as Error)?.message || 'Error in updating folder');
                        }
                    }
                    else
                    {
                        try 
                        {
                            const folderCreated = await createFolder(eventId, { name: values.folderName }, csrfTokenData);
                            if(folderCreated)
                            {
                                setSpinner(false);
                                navigate(`/events/${eventId}/photo-sharing/${Number(folderCreated.id)}`);
                                setFoldersRefresh(true);
                                setRefresh(true);
                                handleDialogClose();
                            }
                        } 
                        catch (error) 
                        {
                            console.log('Error in creating folder', error);
                            toast.error((error as Error)?.message || 'Error in updating folder');
                        }
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, errors, touched }): React.ReactElement =>
                    {
                        return(
                            <div>
                                <Form onSubmit={handleSubmit}>
                                    <div>
                                        <FormLabelComponent label='Folder Name' required />
                                        <FormControlComponent 
                                            type='text'
                                            name='folderName' 
                                            value={values.folderName} 
                                            onChange={handleChange} 
                                            isInvalid={(touched.folderName && errors.folderName) as boolean}
                                        />
                                        {touched.folderName && errors.folderName && <div className="error-msg">{errors.folderName}</div>}
                                    </div>

                                    {existingFolderData && <CustomButton btnType='danger_outline' name='Delete Folder' style={{ padding: 'unset', margin: '16px 0 0' }} startIcon={<FontAwesomeIcon icon={['fal', 'trash']} />} onClick={handleDelete} />}

                                    <div className="button-container w-100">
                                        <CustomButton style={{ width: '100%', height: '44px', maxHeight: '44px', padding: '10px var(--spacing-xl, 16px)', fontSize: '16px', fontWeight: 600 }} btnType='secondary' name='Cancel' onClick={handleDialogClose} />
                                        <CustomButton style={{ width: '100%', height: '44px', maxHeight: '44px', padding: '10px var(--spacing-xl, 16px)',  fontSize: '16px', fontWeight: 600 }} loading={spinner} btnType='primary' name='Confirm' type='submit' />
                                    </div>
                                </Form>
                            </div>
                        )
                    } 
                }
            </Formik>
        </div>
    )
};

export default AddFolder;