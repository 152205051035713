import { useEffect, useState } from "react";
import { useTablePagination } from "../../../../contexts/TablePaginationContext";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addToTargetList, getAllRegistrantsInSponsorPortal, getSponsorPortalRegistrantsCount, sponsorPortalRegistrants } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import _ from "lodash";
import toast from "react-hot-toast";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import { registrantFilterColumnOptions, registrantInitialColumnData, useRegistrantColumnHelper } from "../../../Events/EventDetails/People/EventRegistrants/registrantColumn.helper";
import TanstackToolbarWithServerFilter from "../../../../common/TanstackTable/TanstackToolbarWithServerFilter";
import { useLocation, useNavigate } from "react-router-dom";

const PortalRegistrants: React.FC = (): React.JSX.Element => 
{

  const searchParams = new URLSearchParams();
  const navigate = useNavigate();
  const currentpath = useLocation().pathname;

  const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

  const [registrantCount, setRegistrantCount] = useState<number>(0);
  const [registrants, setRegistrants] = useState<any[]>([]);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const [selectedRegistrants, setSelectedRegistrants] = useState<string[]>([]);
  const [addTargetListSpinner, setAddTargetListSpinner] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [componentMounted, setComponentMounted] = useState(false);
  const [currentUrl, setCurrentUrl] = useState<string>('');

  const columns =
    useRegistrantColumnHelper(
      registrantInitialColumnData.filter(
        item => !['email', 'status', 'paymentStatus', 'attendeeStatus', 'ticket', 'shareLink', 'actions'].includes(item.field)
      )
    );

  const RegistrantsToolbar = (): React.JSX.Element => {

    const tabs = [
      {
        tabName: 'All',
        selectedTab: true,
        count: registrantCount,
        navigation: (): void => {

        }
      }
    ];

    const addToTargetListSponsorPortal = async (): Promise<void> => {
      setAddTargetListSpinner(true);
      try {

        // const initialTargets = await getAllTargetList();
        // const initialTargetIds = initialTargets?.map((target: any) => target.id);

        // const updatedTargetListIds = [...initialTargetIds, ...selectedRegistrants]; 

        const targetListUpdated = await addToTargetList({ registrantIds: selectedRegistrants });
        if (targetListUpdated) {
          toast.success('Added to target list');
        }
      }
      catch (error) {
        toast.error('Failed to add to target list');
      }
      finally {
        setAddTargetListSpinner(false);
        setSelectedRegistrants([]);
      }
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
      filters.forEach(filter => {
        searchParams.append(filter.selectedColumn, filter.inputValue); 
      });
      setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
      navigate(`${currentpath}?${searchParams.toString()}`);
    };

    return (
      <div className="registrants-toolbar">
        {/* <FormTabs tabs={tabs} /> */}

        <div className="button-container">
          {
            selectedRegistrants?.length > 0 ?
            <>
              <CustomButton name='Cancel' btnType="secondary" onClick={() => setSelectedRegistrants([])} />
              <CustomButton name='Add to Target List' loading={addTargetListSpinner} btnType="primary" onClick={() => addToTargetListSponsorPortal()} />
            </> :
            <>
              <TanstackToolbarWithServerFilter
                  columns={columns}
                  filterColumns={registrantFilterColumnOptions(undefined, undefined, undefined, true, true)}
                  handleApplyFilters={handleApplyFilters}
                  setCurrentUrl={setCurrentUrl}
                  minimalView={true}
              />
            </>
          }
        </div>

      </div>
    )
  };

  const fetchData = async (): Promise<void> => {
    if (!pageSize) return;
    try {
      const params: [string, any][] = [];
      const query = new URLSearchParams(currentUrl.split('?')[1]);
      query.forEach((value, key) => {
          params.push([key, value]);
      });

      const encodedParams: [string, any][] = params?.map(([key, value]) => [key, encodeURIComponent(value)]);

      const count = await getSponsorPortalRegistrantsCount(...encodedParams);
      if (count) {
        setRegistrantCount(count);
        const registrantsData = await getAllRegistrantsInSponsorPortal(pageSize, (currentPage - 1), ...encodedParams);
        
        if (registrantsData) 
        {
          setIsEmpty(registrantsData?.length === 0);
          setRegistrants(registrantsData);
        }
      }
    }
    catch (error) {

    }
    finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    setComponentMounted(true);
  }, []);

  useEffect((): void => {
    fetchData();
  }, [currentPage, pageSize, currentUrl]);

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(async (): Promise<void> => {
  //     if (searchText?.length >= 2) {
  //       try {
  //         const registrantData = await sponsorPortalRegistrants(searchText);

  //         if (registrantData) {
  //           setRegistrants(registrantData);
  //           setShowSpinner(false);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       } finally {
  //         setShowSpinner(false);
  //       }
  //     }
  //     else if (searchText === '' && componentMounted) {
  //       fetchData();
  //     }
  //   }, 1000);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchText, componentMounted]);

  return (
    <div id="portalRegistrants">
      <div style={{ height: 'calc(100vh - 300px)' }}>
        {/* {
          !isEmpty &&
          <div className="search-container">
            <FormControlComponent
              placeholder="Search for a registrant"
              type="text"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              startIcon={'search'}
              clearable
            />
          </div>
        } */}
        <RegistrantsToolbar />
        {/* {!isEmpty ? ( */}
          <div className="portal-table">
            <TanstackTable
              data={registrants}
              initialColumns={columns}
              checkboxSelection
              selectedRows={selectedRegistrants}
              setSelectedRows={setSelectedRegistrants}
              rowCount={registrantCount}
              pageSize={pageSize}
              currentPage={currentPage - 1}
              updatePageSize={updatePageSize}
              updateCurrentPage={updateCurrentPage as any}
              leftPinnedColumns={['companyLogo', 'firstName']}
              showSpinner={showSpinner}
              hidePagination={searchText?.length >= 2}
              height="calc(100vh - 340px)"
              showNoRowsOverlay={registrantCount == 0}
              showBottomPadding={false}
            />
          </div>
        {/* ) : (
          <TableEmptyComponent infoText={'No Registrants available'} />
        )} */}
      </div>
    </div>
  )
};

export default PortalRegistrants;