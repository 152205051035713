import AnalyticsPageTabs from "../components/Common/AnalyticsPageTabs";
import { UserViewType } from "../../../../Settings/enum/users.enum";
import { UserContext } from "../../../../../contexts/UserContext";
import RevenueAnalytics from "./RevenueAnalytics";
import { useContext } from "react";
import './styles.scss';
import { MinimalViewComponent } from "../../../../../common/MinimalView/MinimalViewComponent";
import HeaderBar from "../../../../../common/Headerbar";
import { useLocation, useNavigate } from "react-router-dom";
import NpsAnalytics from "./NpsAnalytics";
import EventsHeaderBar from "../../../../../common/EventsHeaderBar";

const EventAnalyticsPage: React.FC<{ eventId: string | number }> = ({ eventId }): React.JSX.Element =>
{
    const { userDetails } = useContext(UserContext);
    const minimalView = userDetails?.viewType === UserViewType.MINIMAL;
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;
    const isRevenue = currentpath.endsWith('revenue');
    const isNps = currentpath.endsWith('nps');

    const analyticsTabObj = [
        {
            tabName: 'Revenue',
            selectedTab: isRevenue,
            navigation: () => navigate(`/events/${eventId}/analytics/revenue`)
        },
        {
            tabName: 'NPS',
            selectedTab: isNps,
            navigation: () => navigate(`/events/${eventId}/analytics/nps`)  
        },
    ]

    const eventAnalyticsNormalView = () =>
    {
        return (
            <div id="eventAnalyticsPage">
                <div className="event-analytics-header-container">
                    <HeaderBar 
                        title={'Analytics'}
                        buttons={[]}
                    />
                </div>
                <AnalyticsPageTabs eventId={eventId} />
                <div>
                    <RevenueAnalytics eventId={eventId} />
                </div>
            </div>
        )
    }

    const eventAnalyticsMinimalView = () =>
    {
        return (
            <div id="eventAnalyticsMinimalView">
                <MinimalViewComponent
                    title={<EventsHeaderBar title={'Analytics'} showBorder/>}
                    titleBarStyle={{ padding: '0px' }}
                    description={'View your analytics here'}
                    tabData={analyticsTabObj}
                    showCharts={false}
                    component={
                        <div>
                            {isRevenue && <RevenueAnalytics eventId={eventId} />}
                            {isNps && <NpsAnalytics eventId={eventId} />}
                        </div>
                    }
                    componentType="table"
                    componentOverflow="auto"
                />
            </div>
        )
    };

    return minimalView ? eventAnalyticsMinimalView() : eventAnalyticsNormalView();

};

export default EventAnalyticsPage;