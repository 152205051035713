export enum EventLeadCaptureStatus 
    {
    ACTIVE = 1,
    INACTIVE = 2,
    DELETED = 3
}

export enum EventLeadCaptureType
    {
        ORGANISATION = 1,
        SPONSOR = 2
}

export enum EventLeadCaptureLeadType
    {
    COLD = 1,
    WARM = 2,
    HOT = 3
}

