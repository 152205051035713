import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { createSponsorTier, deleteSponsorTier, getSponsorTierByTierId, updateSponsorTier } from '../../../../../scripts/apis/eventSponsors';
import './styles.scss';
import { useSelector } from 'react-redux';
import { FormControlComponent, FormLabelComponent } from '../../../../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import toast from 'react-hot-toast';
import { EventSponsorTier } from '../../../interfaces';

interface ICreateSponsorGroupProps {
    eventId: string | number;
    existingSponsorTier?: EventSponsorTier;
    tierName?: string;
    onClose: () => void;
    onUpdate: (newSponsorTier?: EventSponsorTier) => void;
}

const CreateSponsorGroup: React.FC<ICreateSponsorGroupProps> = ({ eventId, existingSponsorTier, tierName = '', onClose, onUpdate }): React.JSX.Element => {
    const csrfToken = useSelector((state: any) => state['csrfTokenValue'].value.csrfToken);

    const [spinner, setSpinner] = useState(false);
    const [groupName, setGroupName] = useState(existingSponsorTier?.name || tierName || '');
    const [sponsoredAmount, setSponsoredAmount] = useState<number | ''>('');

    const fetchSponsorTierData = async (): Promise<void> => {
        if (existingSponsorTier?.id) {
            try {
                const sponsorData = await getSponsorTierByTierId(eventId, existingSponsorTier?.id);
                if (sponsorData) {
                    setGroupName(sponsorData.name || '');
                    setSponsoredAmount(sponsorData.amount || '');
                }
            } catch (error) {
                console.error('Error fetching sponsor tier data:', error);
            }
        }
    };

    const handleFormSubmit = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault();
        setSpinner(true);

        const sponsorGroupData = {
            name: groupName,
            amount: (sponsoredAmount === '' ? 0 : sponsoredAmount),
        };

        try {
            if (existingSponsorTier?.id) {
                const updatedTier = await updateSponsorTier(eventId, existingSponsorTier?.id, sponsorGroupData);
                if(updatedTier)
                {
                    onUpdate(updatedTier);
                    toast.success('Sponsor Tier Updated');
                }
            } else {
                const createdTier = await createSponsorTier(eventId, sponsorGroupData, csrfToken);
                if (createdTier) {
                    onUpdate(createdTier);
                    toast.success('Sponsor Tier Created');
                }
            }
            onClose();
        } catch (error) {
            console.error('Error saving sponsor tier:', error);
            toast.error((error as Error)?.message || 'Failed to save sponsor tier');
        } finally {
            setSpinner(false);
        }
    };

    const handleDelete = async (): Promise<void> => {
        if (existingSponsorTier?.id) {
            try {
                setSpinner(true);
                await deleteSponsorTier(eventId, existingSponsorTier?.id);
                toast.success('Sponsor Tier Deleted');
                onUpdate();
                onClose();
            } catch (error) {
                console.error('Error deleting sponsor tier:', error);
                toast.error('Failed to delete sponsor tier');
            } finally {
                setSpinner(false);
            }
        }
    };

    useEffect(() => {
        fetchSponsorTierData();
    }, []);

    return (
        <Box id="createSponsorGroup">
            <div className="heading-container">
                <h2 className="title">{existingSponsorTier ? 'Edit Sponsor Tier' : 'Create Sponsor Tier'}</h2>
                <p className="description">Please enter sponsor tier name and amount for this event.</p>
            </div>

            <div>
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Sponsor Tier Name" required />
                    <FormControlComponent
                        value={groupName}
                        type="text"
                        placeholder="e.g., Gold Sponsor"
                        onChange={(event): void => setGroupName(event.target.value)}
                        required
                    />
                </Box>

                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Sponsored Amount" />
                    <FormControlComponent
                        value={sponsoredAmount}
                        type="number"
                        asInputGroup
                        inputGroupText="₹"
                        placeholder="00000"
                        onChange={(event): void => setSponsoredAmount(event.target.value === '' ? '' : Number(event.target.value))}
                        onKeyDown={(evt): void => {
                            if (['e', 'E', '+', '-'].includes(evt.key)) evt.preventDefault();
                        }}
                    />
                </Box>

                <Box className="dialog-submit-button-container" marginTop="48px" justifyContent="space-between">
                    {existingSponsorTier && (
                        <CustomButton disabled={spinner} btnType="danger" name="Delete" onClick={handleDelete} />
                    )}
                    <Box className="dialog-submit-inner-container">
                        <CustomButton btnType="secondary" name="Cancel" onClick={onClose} />
                        <CustomButton
                            btnType="primary"
                            name={existingSponsorTier ? 'Edit Sponsor Tier' : 'Create Sponsor Tier'}
                            disabled={spinner || groupName === ''}
                            type="submit"
                            onClick={handleFormSubmit}
                            loading={spinner}
                        />
                    </Box>
                </Box>
            </div>
        </Box>
    );
};

export default CreateSponsorGroup;