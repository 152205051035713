// eslint-disable-next-line import/named
import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, RadioProps, Typography } from '@mui/material';
import React from 'react';
import { Form } from 'react-bootstrap';
import { EmptyRadioBtn, RadioBtn } from '../../../common/StyledComponents/CustomRadio.styled';
import { BpCheckedIcon, BpIcon } from '../../../common/StyledComponents/CustomCheckBox.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss'
import { AutocompleteComponent, FormControlComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { CustomFieldType } from '../../Registration/enum/custom-field.enum';

export const renderInputComponent = (data, inputType, handleInputChange, value, disabled): React.ReactElement => 
{
    const name = data.default ? data.default : data?.propertyKey;    
    return (
        <Box className="component-spacing">
            {data.label && <Form.Label className="custom-field-label">
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.label 
                    }}
                />
                {(value && typeof(value) === 'string' && value?.includes('linkedin')) && <FontAwesomeIcon className="customfield-link-icon" icon={['fal', 'arrow-up-right-from-square']} onClick={() => {
                    let url = value;
                    if (!url.startsWith('https://')) {
                        url = 'https://' + url;
                    }
                    window.open(url, '_blank');
                }} />}

            </Form.Label>}

            {data.helpText && <Typography className="custom-help-text">
                {
                    <div
                        dangerouslySetInnerHTML={{
                            __html: data.helpText 
                        }}
                    />
                }
            </Typography>}
            {/* <Form.Control
                className='component-info-placeholder'
                type={(String(value)?.startsWith('+')) ? 'tel' : inputType}
                size="lg"
                as={inputType === 'textarea' ? 'textarea' : undefined}
                placeholder={data.placeholder ? data.placeholder : ''}
                onChange={(e): void => 
                {
                    handleInputChange(e, data.id); 
                }}
                onKeyDown={(event: React.KeyboardEvent): void => 
                {
                    if(event.key === 'ArrowUp' || event.key === 'ArrowDown') 
                    {
                        event.preventDefault();
                    }
                }}
                name={name}
                disabled={disabled ? disabled : null}
                value={value}
                min={inputType === 'number' && data.fieldNumeric && data.fieldNumeric.min ? data.fieldNumeric.min : null}
                max={inputType === 'number' && data.fieldNumeric && data.fieldNumeric.max ? data.fieldNumeric.max : null}
                defaultValue={inputType === 'number' && data.fieldNumeric && data.fieldNumeric.default ? data.fieldNumeric.default : null}
            /> */}
            <FormControlComponent 
                type={(String(value)?.startsWith('+')) ? 'tel' : inputType}
                as={data?.type === CustomFieldType.MULTI_LINE ? 'textarea' : undefined}
                rows={data?.type === CustomFieldType.MULTI_LINE ? 3 : 1}
                placeholder={data.placeholder ? data.placeholder : ''}
                onChange={(e): void => 
                {
                    handleInputChange(e, data.id); 
                }}
                onKeyDown={(event: React.KeyboardEvent): void => 
                {
                    if(event.key === 'ArrowUp' || event.key === 'ArrowDown') 
                    {
                        event.preventDefault();
                    }
                }}
                name={name}
                disabled={disabled ? disabled : null}
                value={value || ''}
                min={inputType === 'number' && data.fieldNumeric && data.fieldNumeric.min ? data.fieldNumeric.min : null}
                max={inputType === 'number' && data.fieldNumeric && data.fieldNumeric.max ? data.fieldNumeric.max : null}
                defaultValue={inputType === 'number' && data.fieldNumeric && data.fieldNumeric.default ? data.fieldNumeric.default : undefined}
            />
        </Box>
    );
};

const OptionRadioGroup = (props: RadioProps): React.ReactElement => 
{
    const { checked, onChange } = props;
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<RadioBtn />}
            icon={<EmptyRadioBtn />}
            checked={checked}
            onChange={onChange}
            {...props}
            size='small'
        />
    );
};

const OptionCheckBox = (props): React.ReactElement => 
{
    const { checked, onChange } = props;

    return (
        <Checkbox
            sx={{
                '&:hover': {
                    bgcolor: 'transparent' 
                },
            }}
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            disableRipple
            color="default"
            checked={checked}
            onChange={onChange}
            inputProps={{
                'aria-label': 'Checkbox demo' 
            }}
            // className="component-label"
            size='small'
        />
    );
};

export const renderCheckBoxComponent = (data, handleCheckBoxChange, value, disabled): React.ReactElement => 
{
    const componentOptions = data?.fieldOptions || data?.componentOptions || [];
    return (
        <Box className="component-spacing">
            {data.label && <Form.Label className="custom-field-label">
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.label 
                    }}
                />
            </Form.Label>}
            {data.helpText && <Typography className="custom-help-text">
                {
                    <div
                        dangerouslySetInnerHTML={{
                            __html: data.helpText 
                        }}
                    />
                }
            </Typography>}

            <Box className={'checkbox-container'}>
                {componentOptions && componentOptions.length > 0
                    ? componentOptions.map((item, idx): React.ReactElement => 
                    {
                        return (
                            <Box key={idx} className="options-box">
                                <FormControlLabel
                                    control={
                                        <OptionCheckBox
                                            label={item.label}
                                            checked={value && value.length > 0 ? value.includes(
                                                item.name
                                            ) : null}
                                            onChange={(): void =>
                                            {
                                                return handleCheckBoxChange(item, data); 
                                            }
                                            }
                                        />
                                    }
                                    label={<Typography className="option-label">{item.name}</Typography>}
                                    name={
                                        'checkbox_' +
                                        item.name.toLowerCase()
                                    }
                                    disabled={disabled ? disabled : null}
                                />
                            </Box>
                        );
                    })
                    : null}
            </Box>
        </Box>
    );
};

export const renderDropDownComponent = (data, handleDropDownInputChange, value, disabled): React.ReactElement => 
{
    const componentOptions = data?.fieldOptions || data?.componentOptions || [];
    return (
        <Box className="component-spacing">
            {data.label && <Form.Label className="custom-field-label">
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.label 
                    }}
                />
            </Form.Label>}
            {data.helpText && <Typography className="custom-help-text">
                {
                    <div
                        dangerouslySetInnerHTML={{
                            __html: data.helpText 
                        }}
                    />
                }
            </Typography>}

            <AutocompleteComponent
                value={value ? value : 'Select'}
                // popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                onChange={(e, value): void => 
                {
                    handleDropDownInputChange(data?.propertyKey, value, data.id); 
                }}
                // className="autocomplete-dropdown"
                optionsArr={componentOptions.map((item): string => 
                {
                    return `${item?.name}`;
                })}
                // selectOnFocus
                // clearOnBlur
                disabled={disabled ? disabled : null}
                placeholder='Select'
            />
        </Box>
    );
};

export const renderRadioGroupComponent = (data, handleBoxChange, value, disabled): React.ReactElement => 
{
    const componentOptions = data?.fieldOptions || data?.componentOptions || [];
  
    return (
        <Box className="component-spacing">
            {data.label && <Form.Label className="custom-field-label">
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.label 
                    }}
                />
            </Form.Label>}
            {data.helpText && <Typography className="custom-help-text">
                {
                    <div
                        dangerouslySetInnerHTML={{
                            __html: data.helpText 
                        }}
                    />
                }
            </Typography>}
            <RadioGroup>
                <Box className={'radio-group-container'}>
                    {componentOptions && componentOptions.length > 0
                        ? componentOptions.map((item, idx): React.ReactElement => 
                        {
                            return (
                                <Box key={idx} className="options-box">
                                    <FormControlLabel
                                        value={String(idx)}
                                        control={
                                            <OptionRadioGroup
                                                checked={value ? value ===
                                                item.name : data?.propertyKey ===
                                            item.name}
                                                onChange={(): void =>
                                                {
                                                    if(handleBoxChange)
                                                    {
                                                        return handleBoxChange(
                                                            data?.propertyKey,
                                                            item.name,
                                                            data.id
                                                        ); 

                                                    }
                                                   
                                                }
                                                }
                                            />
                                        }
                                        label={<Typography className="option-label">{item.name}</Typography>}
                                        name={'radio_' + data?.propertyKey}
                                        className="component-label"
                                        disabled={disabled ? disabled : null}
                                    />
                                </Box>
                            ); 
                        })
                        : null}
                </Box>
            </RadioGroup>
        </Box>
    );
};

export const renderDateComponent = (data, handleInputChange, value, disabled): React.ReactElement => 
{
    return (
        <Box className="component-spacing">
            {data.label && <Form.Label className="custom-field-label">
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.label 
                    }}
                />
            </Form.Label>}
            <Form.Control
                type="date"
                className="date-time-picker"
                onChange={(e): void => 
                {
                    handleInputChange(e, data.id); 
                }}
                name={data?.propertyKey}
                value={value && value}
                disabled={disabled ? disabled : null}
            />
        </Box>
    );
};

export const renderParaggraphComponent = (data): React.ReactElement => 
{
    return <Box className="component-spacing">
        {data.label && <Form.Label className="custom-field-label">
            <div
                dangerouslySetInnerHTML={{
                    __html: data.label 
                }}
            />
        </Form.Label>}
        <Box> {data.paragraph ? data.paragraph : null}</Box>
    </Box>;
};

export const renderHeaderComponent = (data): React.ReactElement => 
{
    return <Box className="component-spacing">
        {data.label && <Form.Label className="custom-field-label">
            <div
                dangerouslySetInnerHTML={{
                    __html: data.label 
                }}
            />
        </Form.Label>}
        <Box> {data.heading ? data.heading : null}</Box>
    </Box>;
};

export const renderLegalComponent = (data, handleBoxChange): React.ReactElement => 
{
    return (
        <Box className="gdpr-component-container">
            {data.fieldGDPR && data.fieldGDPR.length > 0 && data.fieldGDPR.map((item: { paragraph: string, fieldOptions: { name: string, value: string } }, index: number): React.ReactElement => 
            {
                return (
                    <Box className="component-spacing" key={index}>
                        {item.paragraph && item.paragraph !== '' &&
                            <Typography className="gdpr-paragraph">
                                {item.paragraph}
                            </Typography>}
                        {
                            item.fieldOptions && item.fieldOptions.name !== '' &&
                            <FormControlLabel
                                control={
                                    <OptionCheckBox
                                        onChange={(): void =>
                                        {
                                            return handleBoxChange(
                                                item.fieldOptions.name,
                                                data.id
                                            ); 
                                        }
                                        }
                                        checked={item.fieldOptions.name === '1'}
                                    />
                                }
                                label={
                                    <div dangerouslySetInnerHTML={{
                                        __html: item.fieldOptions.name 
                                    }} className="gdpr-checkbox-label" />
                                }
                            />
                        }
                    </Box>
                );
            })}
        </Box>
    );
};
