import React, { useState, useRef, useEffect, ChangeEvent, useMemo } from 'react';
import moment from 'moment';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TimePickerProps {
    value: string;
    onChange: (time: string) => void;
    disabled?: boolean;
}

const TimePicker: React.FC<TimePickerProps> = ({ value, onChange, disabled }) => {

    const timeValue = useMemo(() => value, [value]);

    const [inputValue, setInputValue] = useState<string>(timeValue ? moment(timeValue, 'HH:mm').format('HH:mm') : '');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openDirection, setOpenDirection] = useState<'up' | 'down'>('down'); // Track dropdown direction
    const [dropdownPosition, setDropdownPosition] = useState<{ top: number; left: number } | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLInputElement>(null); // Ref for the input

    // Generate time options in 10-minute intervals
    const timeOptions = () => {
        const times: string[] = [];
        const startOfDay = moment().startOf('day');
        for (let i = 0; i < 144; i++) { // 24 hours * 6 intervals per hour = 144
            times.push(startOfDay.clone().add(i * 10, 'minutes').format('HH:mm'));
        }
        return times;
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(!disabled)
        {
            const timeInput = e.target.value;
            setInputValue(timeInput);
            if (moment(timeInput, 'HH:mm', true).isValid()) {
                onChange(timeInput);
            }
        }
    };

    const handleButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if(!disabled)
        {
            if (!isOpen) {
                const buttonRect = buttonRef.current!.getBoundingClientRect();
                const calendarHeight = 200; // Approximate height of the time dropdown
                const calendarWidth = 120;  // Approximate width of the time dropdown
                const windowHeight = window.innerHeight;
                const windowWidth = window.innerWidth;
    
                // Determine available space
                let top = buttonRect.bottom + window.scrollY;
                let left = buttonRect.left + window.scrollX;
    
                // Check if there's enough space below; if not, position above the button
                if (top + calendarHeight > windowHeight + window.scrollY) {
                    top = buttonRect.top + window.scrollY - calendarHeight - 30;
                }
    
                // Check if there's enough space to the right; if not, position to the left
                if (left + calendarWidth > windowWidth + window.scrollX) {
                    left = buttonRect.right + window.scrollX - calendarWidth;
                }
                setDropdownPosition({ top, left });
            }
            setIsOpen((prev) => !prev);
        }
    };

    // Determine if there's enough space to open the dropdown downwards, otherwise open it upwards
    const updateDropdownDirection = () => {
        const inputRect = buttonRef.current?.getBoundingClientRect();
        if (inputRect) {
            const spaceBelow = window.innerHeight - inputRect.bottom;
            const spaceAbove = inputRect.top;

            // If there's more space above or insufficient space below, open upwards
            if (spaceBelow < 200 && spaceAbove > spaceBelow) {
                setOpenDirection('up');
            } else {
                setOpenDirection('down');
            }
        }
    };

    useEffect(() => {
        if (isOpen && dropdownRef.current && !disabled) {
            // Scroll to selected option
            const selectedIndex = timeOptions().indexOf(inputValue);
            if (selectedIndex !== -1) {
                const dropdownItemHeight = 40; // Assume each item in the dropdown has a height of 29px
                const scrollPosition = selectedIndex * dropdownItemHeight;
                dropdownRef.current.scrollTop = scrollPosition - (dropdownRef.current.clientHeight / 2) + (dropdownItemHeight / 2);
            }
        }
    }, [isOpen, inputValue]);

    useEffect(() => {
        if (isOpen && !disabled) {
            updateDropdownDirection(); // Update the dropdown direction when it's opened
        }
    }, [isOpen]);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setInputValue(timeValue ? moment(timeValue, 'HH:mm').format('HH:mm') : '');
    }, [value]);

    const handleTimeSelect = (time: string) => {
        if(disabled)
        {
            return
        }
        setInputValue(time);
        onChange(time);
        setIsOpen(false);
    };

    return (
        <div id="time-picker">
            <div className="time-picker">
                <input
                    type="time"
                    value={inputValue}
                    onChange={handleInputChange}
                    onClick={handleButtonClick}
                    ref={buttonRef} // Attach ref to the input
                    className="time-input"
                    placeholder="HH:mm"
                    disabled={disabled}
                />
                {isOpen && dropdownPosition && (
                    <div
                        className="timer-dropdown"
                        ref={dropdownRef}
                        style={{
                            position: 'fixed',
                            top: dropdownPosition.top + 8,
                            left: dropdownPosition.left,
                            zIndex: 1000,
                            opacity: 1,
                        }}
                    >
                        {timeOptions().map((time, index) => (
                            <div
                                key={index}
                                className={`dropdown-item ${inputValue === time ? 'selected' : ''}`}
                                onClick={() => handleTimeSelect(time)}
                            >
                                {time}
                                {inputValue === time && <FontAwesomeIcon icon={['fal', 'check']} />}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TimePicker;
