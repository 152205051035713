import { Avatar, Box, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Vendors from '../../components/Vendors/Vendors';  
import { useDispatch } from 'react-redux';
import { resetVendors } from '../../redux/vendors/Vendors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DataImport from '../../common/DataImport';
import { VendorsContext } from '../../contexts/VendorsPageContext';
import { getVendorById, getVendorsActivityLog } from '../../scripts/apis/vendors';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { createColumnHelper } from '@tanstack/react-table';
import { Vendor } from './interfaces';
import { TableActions } from '../../common/TableActions';
import TableEmptyComponent from '../../common/TableEmptyComponent';
import vendorEmptyImg from '../../assets/icons/empty-state/vendorEmpty.svg';
import { IVendors } from '../../components/Vendors/IVendors';
import { CustomButton } from '../../common/FormComponents/Buttons';
import DeletePopup from '../../common/DeletePopup';
import HeaderBar from '../../common/Headerbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddVendors from '../Events/EventDetails/People/EventVendors/AddVendors';
import toast from 'react-hot-toast';
import CardComponent from '../../components/Events/Registrations/CardComponent';
import EventsCardHeaderComponent from '../../components/Events/EventComponents/EventsCardHeaderComponent';
import TanstackToolbarWithServerFilter from '../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { UserContext } from '../../contexts/UserContext';
import { UserViewType } from '../Settings/enum/users.enum';
import { MinimalViewComponent } from '../../common/MinimalView/MinimalViewComponent';
import CustomSpinner from '../../common/CustomSpinner';
import ColumnVisibilityDropdown from '../../common/TanstackTable/ColumnVisibilityDropdown';
import CustomTooltip from '../../common/Tooltip';

import mailIcon from '../../assets/icons/envelope-light.svg';
import twitterIcon from '../../assets/icons/twitter-icon.svg';
import linkedInIcon from '../../assets/icons/linkedin-solid.svg';

import './styles.scss';
import Breadcrumbs from '../../common/Breadcrumbs';
import { ActivityLogResponse } from '../../interfaces/activity-log_interface';
import ActivityLogComponent from '../../common/ActivityLog/ActivityLogComponent';
import { vendorsActivityLog } from '../../scripts/activityLogGenerator/activitylog-generator';
import TablePopup from '../../common/TablePopup/TablePopup';
import { buildInitialsForPicture } from '../../scripts/helpers';
import TablePopupDetails from '../../common/TablePopup/TablePopupDetails';
import VendorsAbout from '../../components/Vendors/VendorsAbout';
import EventsHeaderBar from '../../common/EventsHeaderBar';
import PopupDialog from '../../common/PopupDialog';

const VendorsPage: React.FC<{ eventId?: string | number }> = (props):React.JSX.Element =>
{

    const { vendorId } = useParams();
    const { eventId } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [accordionExpansion, setAccordionExpansion] = useState<string | boolean>('enrichment');
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    const [selectedPopupComponent, setSelectedPopupComponent] = useState<React.ReactNode>(null);

    const searchParams = new URLSearchParams();

    const {
        userDetails
    } = useContext(UserContext);

    const { 
        rows, 
        vendorsDataCount, 
        isEmpty, 
        vendors, 
        setRefresh, 
        deleteVendorsFromTable, 
        pageSize, 
        currentPage, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner, 
        showDeletePopup,
        setSelectedVendor,
        setShowDeletePopup,
        selectedVendor,
        cardView,
        isTableView,
        setIsTableView,
        setCurrentUrl
    } = useContext(VendorsContext);

    const leftNodes = [
        <CustomTooltip title={'Table View'} key={0}>
            <div>
                <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />} name='' btnType='tertiary' onClick={() => navigate('/events/' + eventId + '/people/vendors')} />
            </div>
        </CustomTooltip>
    ];
    

    const handleEdit = (row: IVendors, routeFromId?: boolean):void => 
    {
        dispatch(resetVendors());
        setSelectedPopupComponent(<Vendors createorUpdateGlobalVendor={true} vendor-data={row} is-editable={true} setRefresh={setRefresh} routeFromId={routeFromId} heading={'Edit Vendor'} handlePopupClose={handleVendorPopupClose} />);
    };

    const openDrawer = (createComp = false):void => 
    {
        if (createComp) 
        {
            dispatch(resetVendors());
            setSelectedPopupComponent(<Vendors createEventVendor={false} createorUpdateGlobalVendor={true} setRefresh={setRefresh} heading={'Create Vendor'} handlePopupClose={handleVendorPopupClose} />);
        }
    };

    const createVendor = ():void => 
    {
        dispatch(resetVendors());
        setSelectedPopupComponent(<Vendors createEventVendor={true} data-eventId={eventId} eventVendor={true} setRefresh={setRefresh} heading={'Create Vendor'} handlePopupClose={handleVendorPopupClose} />);
    };

    const addVendor = ():void => 
    {
        dispatch(resetVendors());
        setSelectedPopupComponent(<AddVendors data-id={eventId ?? ''} setRefresh={setRefresh} heading={'Add Vendor'} handlePopupClose={handleVendorPopupClose} />);
    };

    const editVendor = (vendorInfo: Vendor, routeFromEventVendor?: boolean):void => 
    {
        dispatch(resetVendors());
        setSelectedPopupComponent(<Vendors createEventVendor={true} vendor-data={vendorInfo} is-editable={true} setRefresh={setRefresh} routeFromEventVendor={routeFromEventVendor} data-eventId={eventId} heading='Edit Vendor' handlePopupClose={handleVendorPopupClose}/>);
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    useEffect(():void => 
    {
        const fetchDataFromRoute = async ():Promise<void> => 
        {
            if (vendors && vendors.length && vendorId) 
            {
                try 
                {
                    const vendorDataById = await getVendorById(Number(vendorId));
                    if (vendorDataById) 
                    {
                        handleEdit(vendorDataById as IVendors, true);
                    }
                    else if (!vendorDataById) 
                    {
                        toast.error('The speaker you\'re trying to access doesn\'t exist');
                        navigate('/vendors');
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        };
        fetchDataFromRoute();
    }, [vendors]);

    useEffect((): void => 
    {
        if(!pageSize)
        {
            updatePageSize(25);
        }
    }, [pageSize]);  

    const columnHelper = createColumnHelper<Vendor>();

    const vendorColumns = [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (
                    <div>
                        <p className="bolded-cellContent">{row.getValue()}</p>
                        {userDetails?.viewType === UserViewType.MINIMAL && <p className="cellContent">{row.row.original.email}</p>}
                    </div>
                )
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        ...(userDetails?.viewType === UserViewType.NORMAL ? [columnHelper.accessor('email', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Email',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'email'
        })] : []),
        columnHelper.accessor('vendorName', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Contact Person',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'vendorName'
        }),
        columnHelper.accessor('description', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Description',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'description'
        }),
        columnHelper.accessor('social.linkedIn', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Website/Social Media Link',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'social.linkedIn'
        }),
        columnHelper.accessor('actions', {
            cell: (row) => {
                return (
                    <TableActions 
                        actions={[
                        {
                            title: 'Edit',
                            onClick: (): void => 
                            {
                                handleEdit(row?.row?.original as IVendors);
                            }
                        }, 
                        {
                            title: 'Delete',
                            onClick: (): void => 
                            {
                                setSelectedVendor(row?.row?.original as IVendors);
                                setShowDeletePopup(true);
                            }
                        }]}
                    />
                );
            },
            header: '',
            size: 40,
            enableSorting: false,
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState(vendorColumns);

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Email',
            id: 'email',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Vendor Name',
            id: 'vendorName',
            meta: {
                type: 'string'
            }
        },
    ];

    const handleVendorPopupClose = (): void => {
        setSelectedVendor(null);
        setSelectedPopupComponent(null);
    };

    const handlePopupClose = (): void =>
    {
        setSelectedPopupComponent(null);
        setSelectedVendor(null);
        setDrawerOpen(false);
    };

    const handleMinimalViewRowClick = async (row: Vendor): Promise<void> =>
    {
        setDrawerOpen(true);
        setSelectedVendor(row);
        await fetchSpeakerActivityLogs(row);
    };

    const fetchSpeakerActivityLogs = async (row: Vendor): Promise<void> =>
    {
        try 
        {
            if(row)
            {
                const activityLogsFromDb = await getVendorsActivityLog(row.id);
                if(activityLogsFromDb)
                {
                    setActivityLogs(activityLogsFromDb);
                }
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const handleAccoridon = (propertyId: string) => 
    {
        return (event: React.SyntheticEvent, newExpanded: boolean): void => 
        {
            setAccordionExpansion(newExpanded ? propertyId : false);
        };
    };

    const tablePopupTabData = 
    [
        {
            title: 'About',
            data: <VendorsAbout createorUpdateGlobalVendor={true} vendor-data={selectedVendor} is-editable={true} setRefresh={setRefresh} handlePopupClose={handlePopupClose} />
        },
        {
            title: 'Activity',
            data: <ActivityLogComponent activityLog={vendorsActivityLog(activityLogs, selectedVendor)} />
        }
    ];

    const renderHeader = () =>
        {
            const headerClassName = userDetails?.viewType === UserViewType.NORMAL ? 'event-vendors-header' : 'event-vendors-header-minimal';
    
            return (
                <EventsHeaderBar
                    title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Vendors 
                        </div>
                    }
                    buttons={[
                        isTableView ? (
                            <CustomTooltip key={2} title='Grid View'>
                                <div>
                                    <CustomButton 
                                        startIcon={<FontAwesomeIcon icon={['fal', 'grid']} />} 
                                        name='' 
                                        btnType='tertiary_outline' 
                                        onClick={() => setIsTableView && setIsTableView(false)} 
                                    />
                            </div>
                        </CustomTooltip>
                    ) : (
                        <CustomTooltip key={2} title='Table View'>
                            <div>
                                <CustomButton 
                                    startIcon={<FontAwesomeIcon icon={['fal', 'square-list']} />} 
                                    name='' 
                                    btnType='tertiary_outline' 
                                    onClick={() => setIsTableView && setIsTableView(true)} 
                                />
                            </div>
                        </CustomTooltip>
                    )
                ]}
            />
        )

        // return (
        //     <div className={headerClassName}>
        //         <CustomButton 
        //             startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} 
        //             name=''
        //             btnType='tertiary'
        //             onClick={() => navigate('/events/' + eventId + '/overview')}
        //         />
        //         <Breadcrumbs 
        //             origin={['Overview']}
        //             active='Vendors'
        //         />
        //     </div>
        // )
    };

    const handleImportCsvSuccess = (): void =>
    {
        setRefresh(true);
    }

    // const renderCardViewForVendorsPage = (): React.JSX.Element => {
    //     return (
    //         <div id="eventVendorsPage">
    //             <div className="vendors-container">
    //                 <EventsCardHeaderComponent heading='Vendors' count={vendorsDataCount} leftNodes={leftNodes} />

    //                 <Grid container spacing={2}>
    //                     {
    //                         vendors?.length > 0 && vendors?.map((vendor: Vendor, index: number) => {

    //                             const vendorContact = (<div className="vendor-contact-container"><p>{vendor?.vendorName}</p><FontAwesomeIcon icon={['fal', 'envelope']} /></div>);
    //                             const vendorSocialsArr: {icon: string, link: string}[] = [];
    //                             const vendorImage = (<div><Avatar className="card-empty-vendor-avatar" src={vendor?.name?.charAt(0)}><p>{vendor?.name?.charAt(0)?.toUpperCase()}</p></Avatar></div>);

    //                             vendorSocialsArr?.unshift({
    //                                 icon: mailIcon,
    //                                 link: vendor?.email
    //                             });

    //                             if(vendor?.social?.twitter)
    //                             {
    //                                 vendorSocialsArr.push({
    //                                     icon: twitterIcon,
    //                                     link: vendor?.social?.twitter
    //                                 });
    //                             }
    //                             if(vendor?.social?.linkedIn)
    //                             {
    //                                 vendorSocialsArr.push({
    //                                     icon: linkedInIcon,
    //                                     link: vendor?.social?.linkedIn
    //                                 });
    //                             }

    //                             const vendorName = (<div className="vendor-name-container"><p>{vendor?.name}</p></div>);

    //                             const sponsorSocials = (<div className="vendor-socials-icons">{vendorSocialsArr.map((social, index) => { return <Box component={'img'} key={index} src={social.icon} height={'1rem'} width={'1rem'} onClick={(event) => 
    //                                 {
    //                                     if(social.link !== vendor?.email)
    //                                     {
    //                                         window.open(social.link, '_blank');
    //                                         event.stopPropagation();
    //                                     }
    //                                     else
    //                                     {
    //                                         navigator.clipboard.writeText(`${vendor.email}`);
    //                                         toast.success('Email copied to clipboard');
    //                                         event.stopPropagation();
    //                                     }
    //                                 }} 
    //                                 /> 
    //                             })}</div>);

    //                             const footerContent = (
    //                                 <div className="vendor-social-contents">
    //                                     {sponsorSocials}
    //                                 </div>
    //                             );

    //                             return (
    //                                 <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
    //                                     <CardComponent 
    //                                         cardMinHeight='124px'
    //                                         header={vendorImage}
    //                                         contentHeading={vendorName}
    //                                         headerBold
    //                                         gapNotRequired
    //                                         footerLeft={footerContent}
    //                                         onClick={() => editVendor(vendor)}
    //                                     />
    //                                 </Grid>
    //                             )
    //                         })

    //                     }

    //                     <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
    //                         <CardComponent 
    //                             emptyText='+ Add Vendors'
    //                             emptyCardHeight='92px'
    //                             onClick={addVendor}
    //                         />
    //                     </Grid>
    //                 </Grid>
    //             </div>
    //         </div>
    //     )
    // };

    const renderNormalViewForVendorsPage = (): React.JSX.Element => { 
        return (
            <div style={{ height: (isTableView && eventId) ? 'calc(100vh - 57px)' : '', overflow: (isTableView && eventId) ? 'hidden' : '' }} id={eventId ? "eventVendorsPage" : "vendorsPage"}>
                {eventId ? renderHeader() :
                <HeaderBar
                    title={<p>Vendors</p>}
                    // buttons={[
                    //             <CustomButton name='Add Vendor' btnType='primary' onClick={() => openDrawer(true)} />
                    //         ]}
                />}
                <div style={{
                    height: 'calc(100vh - 60px)'
                }}>
                    {
                        isEmpty ?
                            <TableEmptyComponent emptyImg={vendorEmptyImg} openDrawer={openDrawer} infoText={'No Vendor'} subInfoText={'Add your first Vendor'} />
                            :
                            <div className="h-100">
                                {/* <VendorsPageToolbar 
                                    count={vendorsDataCount}
                                    rows={vendors}
                                    setRows={setRows}
                                    columns={vendorColumns}
                                    setColumns={setAllColumns}
                                /> */}
                                <TanstackToolbarWithServerFilter 
                                    columns={vendorColumns}
                                    setColumns={setAllColumns}
                                    tabs={[{
                                        tabName: 'All',
                                        count: vendorsDataCount,
                                        selectedTab: true,
                                        navigation: () => {}
                                    }]}
                                    setCurrentUrl={setCurrentUrl as any}
                                    filterColumns={filterColumns}
                                    handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                    rightActionButtons={[
                                        <CustomTooltip title="Import CSV">
                                            <div>
                                                <DataImport onImportSuccess={handleImportCsvSuccess} iconButton={true} licenseKey={import.meta.env.VITE_VENDOR_SHEET_ID} eventId={eventId ? eventId : null} />
                                            </div>
                                        </CustomTooltip>,
                                    ]}
                                    rightCornerButton={
                                        <CustomButton
                                            name=''
                                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                                            btnType='primary'
                                            onClick={() => {
                                                if (eventId) {
                                                    addVendor()
                                                }
                                                else {
                                                    createVendor()
                                                }
                                            }}
                                        />
                                    }
                                />
                                <TanstackTable 
                                    initialColumns={allColumns}
                                    data={rows}
                                    rowCount={vendorsDataCount}
                                    pageSize={pageSize}
                                    currentPage={currentPage - 1}
                                    updateCurrentPage={updateCurrentPage as any}
                                    updatePageSize={updatePageSize}
                                    showSpinner={showSpinner}
                                    onRowClick={(row) => handleEdit(row)}
                                    rightPinnedColumns={['actions']}
                                    // height={eventId ? 'calc(100% - 184px)' : 'calc(100% - 128px)'}
                                    showNoRowsOverlay={vendorsDataCount==0}
                                />
                            </div>
                    }
                </div>
            </div>
        )
    };


    const renderMinimalViewForVendorsPage = (): React.JSX.Element => { 

        const title = eventId ? renderHeader() : 'Vendors';
        const description = eventId ? '' : 'Manage your Vendors and their account permissions here.';
        const titlebarStyle = eventId && { padding: '0', borderBottom: '1px solid #F2F4F7' } as React.CSSProperties;

        return (
            <div id={eventId ? "eventVendorsPage" : "vendorsPageMinimalView"}>
                <MinimalViewComponent
                    title={title}
                    description={description}
                    titleBarStyle={titlebarStyle}
                    tabData={[
                        {
                            tabName: 'All',
                            count: vendorsDataCount,
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            columns={vendorColumns}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown 
                            columns={vendorColumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomTooltip title="Import CSV">
                            <div>
                                <DataImport onImportSuccess={handleImportCsvSuccess} iconButton={true} licenseKey={import.meta.env.VITE_VENDOR_SHEET_ID} eventId={eventId ? eventId : null} />
                            </div>
                        </CustomTooltip>,
                        <CustomButton
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => {
                                if (eventId) {
                                    addVendor()
                                }
                                else {
                                    createVendor()
                                }
                            }}
                        />
                    ]}
                    component=
                    {
                        isEmpty ?
                            <TableEmptyComponent
                                emptyImg={vendorEmptyImg}
                                openDrawer={openDrawer}
                                infoText={'No Vendor'}
                                subInfoText={'Add your first Vendor'}
                            />
                            :

                            <TablePopup
                                rows={vendors}
                                columns={drawerOpen ? allColumns?.filter((item, index) => index < 3) : allColumns}
                                count={vendorsDataCount}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                updatePageSize={updatePageSize}
                                updateCurrentPage={updateCurrentPage as any}
                                // height={eventId ? 'calc(100vh - 215px)' : 'calc(100vh - 123px)'}
                                drawerOpen={drawerOpen}
                                onRowClick={(row) => handleMinimalViewRowClick(row)}
                                selectedRow={selectedVendor}
                                rightPinnedColumns={['actions']}

                                popupContent={
                                    <TablePopupDetails
                                        onPopupClose={handlePopupClose}
                                        title={`${selectedVendor?.name} `}
                                        email={`${selectedVendor?.email}`}
                                        altImage={`${buildInitialsForPicture(selectedVendor?.email, selectedVendor?.name)}`}
                                        tabData={tablePopupTabData}
                                    />
                                }
                            />
                    }
                    componentType='table'
                />
            </div>
        )
    };

    const renderMinimalCardViewForVendorsPage = (): React.JSX.Element => { 

        const title = eventId ? renderHeader() : 'Vendors';
        const description = eventId ? '' : 'Manage your Vendors and their account permissions here.';
        const titlebarStyle = eventId && { padding: '0', borderBottom: '1px solid #F2F4F7' } as React.CSSProperties;

        return (
            <div id={eventId ? "eventVendorsPage" : "vendorsPageMinimalView"}>
                <MinimalViewComponent
                    title={title}
                    description={description}
                    titleBarStyle={titlebarStyle}
                    tabData={[
                        {
                            tabName: 'All',
                            count: vendorsDataCount,
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            columns={vendorColumns}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        <CustomTooltip title="Import CSV">
                            <div>
                                <DataImport onImportSuccess={handleImportCsvSuccess} iconButton={true} licenseKey={import.meta.env.VITE_VENDOR_SHEET_ID} eventId={eventId ? eventId : null} />
                            </div>
                        </CustomTooltip>,
                        <CustomButton
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => {
                                if (eventId) {
                                    addVendor()
                                }
                                else {
                                    createVendor()
                                }
                            }}
                        />
                    ]}
                    component=
                    {
                        <Grid container spacing={2}>
                            {
                                vendors?.length > 0 && vendors?.map((vendor: Vendor, index: number) => {

                                    const vendorContact = (<div className="vendor-contact-container"><p>{vendor?.vendorName}</p><FontAwesomeIcon icon={['fal', 'envelope']} /></div>);
                                    const vendorSocialsArr: { icon: string, link: string }[] = [];
                                    const vendorImage = (<div><Avatar className="card-empty-vendor-avatar" src={vendor?.name?.charAt(0)}><p>{vendor?.name?.charAt(0)?.toUpperCase()}</p></Avatar></div>);

                                    vendorSocialsArr?.unshift({
                                        icon: mailIcon,
                                        link: vendor?.email
                                    });

                                    if (vendor?.social?.twitter) {
                                        vendorSocialsArr.push({
                                            icon: twitterIcon,
                                            link: vendor?.social?.twitter
                                        });
                                    }
                                    if (vendor?.social?.linkedIn) {
                                        vendorSocialsArr.push({
                                            icon: linkedInIcon,
                                            link: vendor?.social?.linkedIn
                                        });
                                    }

                                    const vendorName = (<div className="vendor-name-container"><p>{vendor?.name}</p></div>);

                                    const sponsorSocials = (<div className="vendor-socials-icons">{vendorSocialsArr.map((social, index) => {
                                        return <Box component={'img'} key={index} src={social.icon} height={'1rem'} width={'1rem'} onClick={(event) => {
                                            if (social.link !== vendor?.email) {
                                                window.open(social.link, '_blank');
                                                event.stopPropagation();
                                            }
                                            else {
                                                navigator.clipboard.writeText(`${vendor.email}`);
                                                toast.success('Email copied to clipboard');
                                                event.stopPropagation();
                                            }
                                        }}
                                        />
                                    })}</div>);

                                    const footerContent = (
                                        <div className="vendor-social-contents">
                                            {sponsorSocials}
                                        </div>
                                    );

                                    return (
                                        <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <CardComponent
                                                cardMinHeight='124px'
                                                header={vendorImage}
                                                contentHeading={vendorName}
                                                headerBold
                                                gapNotRequired
                                                footerLeft={footerContent}
                                                onClick={() => editVendor(vendor)}
                                            />
                                        </Grid>
                                    )
                                })

                            }

                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <CardComponent
                                    emptyText='+ Add Vendors'
                                    emptyCardHeight='90px'
                                    onClick={addVendor}
                                />
                            </Grid>
                        </Grid>
                    }
                    componentType='table'
                />
            </div>
        )
    };

    return (
        <>

            {
                showSpinner ? <CustomSpinner width="100%" height="100lvh" />
                    : (
                        <>
                            {
                                userDetails?.viewType === UserViewType.MINIMAL && (
                                    (!isTableView && eventId) ? renderMinimalCardViewForVendorsPage() : renderMinimalViewForVendorsPage()
                                )
                            }

                            {
                                userDetails?.viewType === UserViewType.NORMAL && renderNormalViewForVendorsPage()
                            }
                        </>
                    )
            }

            {/* {
                (isTableView || !cardView) && userDetails?.viewType === UserViewType.NORMAL && renderCardViewForVendorsPage()
            }

            {
                (!isTableView && cardView) && renderCardViewForVendorsPage()
            }

            {
                showSpinner ? <CustomSpinner width="100%" height="100lvh" /> : (userDetails?.viewType === UserViewType.MINIMAL && (isTableView || !cardView)) && renderMinimalViewForVendorsPage()
            } */}

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteVendorsFromTable(selectedVendor as IVendors)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedVendor(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedVendor?.name}?`}
                        modalTitle='Delete Vendor'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }
            {
                selectedPopupComponent &&
                <PopupDialog
                    modalId='vendorsPopup'
                    show={!!selectedPopupComponent}
                    cancelClick={handleVendorPopupClose}
                    modalContent={selectedPopupComponent}
                />
            }
        </>
    );
};

export default VendorsPage;