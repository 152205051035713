import React, { useEffect } from 'react';
import AppRouter from './routes';
import ThemeProvider from './contexts/ThemeContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import { clarity } from 'react-microsoft-clarity';
import './styles/index.scss';
import BillingProvider from './contexts/Billing/BillingContext';

const App = (): React.JSX.Element => 
{

    useEffect(():void => 
    {
        if (import.meta.env.VITE_NODE_ENV !== 'local') 
        {
            clarity.init(import.meta.env.VITE_CLARITY_ID);
        }

        if (import.meta.env.VITE_NODE_ENV === 'demo') 
        {
            const script = document.createElement('script');
            script.setAttribute('defer', '');
            script.src = 'https://api.pirsch.io/pirsch.js';
            script.id = 'pirschjs';
            script.setAttribute('data-code', import.meta.env.VITE_PIRSCH_ID_TOKEN);
            document.head.appendChild(script);
        }
    }, []);

    return (
        <ThemeProvider>
            <Routes>
                <Route path="/*" element={<BillingProvider><AppRouter /></BillingProvider>} />
            </Routes>
        </ThemeProvider>
    );
};

export default App;
