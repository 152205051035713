import React, { useRef, useEffect, useState } from 'react';
import { CSVBoxButton } from '@csvbox/react';
import { LocalStorage } from '../scripts/LocalStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomButton } from './FormComponents/Buttons';
import { useNavigate } from 'react-router-dom';
import APP_CONSTANTS from '../scripts/constants';
import toast from 'react-hot-toast';
import eventBus from '../scripts/event-bus';

interface DataImportProps
{
    licenseKey: string;
    dynamicColumns?: any[];
    eventId?: string | number | null;
    targetListId?: string | number | null;
    leadTrackerListId?: string | number | null;
    options?: any;
    btnType?: 'primary' | 'secondary';
    name?: string;
    startIcon?: React.ReactNode;
    iconButton?: boolean;
    disabled?: boolean;
    onImportSuccess?: () => void;
    customButton?: React.ReactNode;
}

const DataImport = ({
    licenseKey,
    dynamicColumns,
    eventId = null,
    leadTrackerListId = null,
    targetListId = null,
    options = null,
    btnType = 'secondary',
    name = 'Import',
    startIcon = <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} />,
    iconButton = false ,
    disabled = false,
    customButton = null,
    onImportSuccess
}: DataImportProps): React.JSX.Element => 
{
    const userMe = LocalStorage.get('@UserMe');
    const org = LocalStorage.get('@Org');

    const user = {
        user_id: userMe.id,
        orgId: org.id
    };

    if (eventId) 
    {
        user['eventId'] = eventId;
    }

    if (targetListId)
    {
        user['targetListId'] = targetListId;
    }

    if (leadTrackerListId)
    {
        user['leadTrackerListId'] = leadTrackerListId;
    }

    const csvBoxButtonRef = useRef(null);

    const [triggerLaunch, setTriggerLaunch] = useState(false);
    const [currentLicenseKey, setCurrentLicenseKey] = useState(licenseKey);
    const [keyForRemount, setKeyForRemount] = useState(0);
    const navigate = useNavigate();

    useEffect((): void => 
    {
        setCurrentLicenseKey(licenseKey);
        csvBoxButtonRef.current = null;
        setKeyForRemount((prevKey): number => 
        { 
            return prevKey + 1;
        });
    }, [licenseKey]);

    useEffect(() : void => 
    {
        if (triggerLaunch && csvBoxButtonRef.current) 
        {
            csvBoxButtonRef.current.openModal();
            setTriggerLaunch(false);
        }
    }, [triggerLaunch]);

    useEffect(() => 
    {
        return (): void => 
        {
            csvBoxButtonRef.current = null;
        };
    }, [licenseKey]);

    return (
        <>
            <CSVBoxButton
                key={keyForRemount}
                ref={(el: CSVBoxButton): void => 
                {
                    csvBoxButtonRef.current = el;
                }}
                environment={{
                    env_name: import.meta.env.VITE_NODE_ENV,  
                    base_url: import.meta.env.VITE_NODE_ENV === 'local' ? import.meta.env.VITE_INTERNAL_CSVBOX_TEST_NGROK_URL : APP_CONSTANTS.API_DOMAIN,
                    authorized_domain: APP_CONSTANTS.APP_DOMAIN,
                }}
                licenseKey={currentLicenseKey}
                user={user}
                dynamicColumns={dynamicColumns}
                onImport={(result, data): void => 
                {
                    console.log('Import started')
                    if (result) 
                    {
                        if(onImportSuccess)
                        {
                            console.log('Import success')
                            onImportSuccess();
                        }
                    }
                    else 
                    {
                        console.log('fail');
                        toast.error('Failed to import data');
                    }
                }}
                options={options ? options : ''}
                render={(launch, isLoading): React.JSX.Element => 
                {  
                    return (
                        customButton ?
                        <div 
                            className={`custom-import-button ${isLoading || disabled ? 'disabled' : ''}`} 
                            onClick={() => {
                                setTriggerLaunch(true)
                            }}
                        >
                            {customButton}
                        </div>:
                        iconButton ?
                        <CustomButton name="" btnType="secondary" disabled={isLoading || disabled} onClick={(): void => 
                            {
                                setTriggerLaunch(true);
                            }}
                            startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-from-bracket']} />}
                        />
                        :
                        <CustomButton 
                            btnType={btnType}
                            name={name}
                            startIcon={startIcon}
                            disabled={isLoading} 
                            onClick={(): void => 
                            {
                                setTriggerLaunch(true);
                            }}
                        />
                    );
                }}
            >
                {name}
            </CSVBoxButton>
        </>
    );
};

export default DataImport;