import { AutocompleteComponent, FormLabelComponent, RadioGroupComponent, SwitchToggleInput } from "../../../../common/FormComponents/ReusableFormComponents";
import { EventAdditionalForms } from "../../../../pages/Events/interfaces";
import { RegistrationForm } from "../../../../pages/Registration/interfaces/registration-form_interface";
import eventBus from "../../../../scripts/event-bus";
import './styles.scss';
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { EventNpsScheduleType, EventSessionFeedbackScheduleType, VoiceFeedback } from "../../../../pages/Events/enum";
import DateTimePicker from "../../../../common/FormComponents/DateTimePicker";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from 'yup';
import { CopyBlock } from "react-code-blocks";
import { Stack } from "@mui/material";
import { CONTENT } from "../../../../scripts/i18n";
import { updateEvent } from "../../../../scripts/apis/events";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import NPSFormPreview from "./NPSFormPreview";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../interfaces/create-events_interface";
import { combineDateTime } from "../../../../scripts/helpers";

interface NPSFormSelectionDetailsProps {
    regForms: RegistrationForm[];
    eventId: string;
    codeSnippet: string | any;
    additionalForms?: EventAdditionalForms;
    heading: string;
    handlePopupClose: () => void;
}

const NPSFormSelectionDetails: React.FC<NPSFormSelectionDetailsProps> = (props): React.JSX.Element => {
    const { regForms, eventId, codeSnippet, additionalForms, heading, handlePopupClose } = props;
    const [showFormEmbed, setShowFormEmbed] = useState(false);  
    const [formPreviewType, setFormPreviewType] = useState<'nps' | 'sessionFeedback' | null>(null);
    

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const npsFeebackTimingOptions = [
        {
            name: 'When event ends.', value: EventNpsScheduleType.EVENT_END
        },
        {
            name: '10 mins before event ends.', value: EventNpsScheduleType.TEN_MINS_BEFORE_EVENT_ENDS
        },
        {
            name: 'Schedule a specific time.', value: EventNpsScheduleType.CUSTOM
        }
    ]

    const surveyFeebackTimingOptions = [
        {
            name: 'When session ends.', value: EventSessionFeedbackScheduleType.SESSION_END
        },
        {
            name: '2 mins before session ends.', value: EventSessionFeedbackScheduleType.TWO_MINS_BEFORE_SESSION_ENDS
        },
        {
            name: '10 mins before session ends', value: EventSessionFeedbackScheduleType.TEN_MINS_AFTER_SESSION_ENDS
        }
    ]

    const codeBlock = (): React.JSX.Element => 
    {
        const codeSnippetScript = `<script src="${codeSnippet?.sdkUrl}"></script>\n<script>\n\tFormSDK.init({\n\t\ttoken: "${codeSnippet?.token}",\n\t\tonSuccess: (data) => console.log("Success:", data),\n\t\tonError: (error) => console.error("Error:", error),\n\t});\n</script>`;
        return <CopyBlock
        language="html"
        text={codeSnippetScript}
        codeBlock={true}
        showLineNumbers={false}
        customStyle={{fontSize: '14px', }}
        />;
    };

    const handleEmbedCodeClick = (): void =>
    {
        setShowFormEmbed(true);
    };

    const handleBackToPopup = (): void =>
    {
        setShowFormEmbed(false);
        setFormPreviewType(null);
        formik.setFieldValue('header', heading);
        formik.setFieldValue('description', 'Add the NPS Form for the Event');
    }

    const handleFormChange = (newValue: string | null, type: 'nps' | 'survey'): void => {
        const selectedFormData = _.filter(regForms, function (form): boolean {
            return newValue === form.name;
        })[0];

        if(type === 'nps') {
            if (selectedFormData) {
                formik.setFieldValue('selectedNpsFormId', selectedFormData.id);
                formik.setFieldValue('selectedNpsFormName', selectedFormData.name);
            }
            else {
                formik.setFieldValue('selectedNpsFormId', '');
                formik.setFieldValue('selectedNpsFormName', '');
            }
        } else {
            if (selectedFormData) {
                formik.setFieldValue('selectedSurveyFormId', selectedFormData.id);
                formik.setFieldValue('selectedSurveyFormName', selectedFormData.name);
            }
            else {
                formik.setFieldValue('selectedSurveyFormId', '');
                formik.setFieldValue('selectedSurveyFormName', '');
            }
        }
    };

    const handleNestedSidebarOpen = (type: 'nps' | 'sessionFeedback'): void =>
    {
        setFormPreviewType(type);
        formik.setFieldValue('header', `${type === 'nps' ? 'Event' : 'Session'} NPS Form Preview`);
        formik.setFieldValue('description', `${type === 'nps' ? formik.values.selectedNpsFormName : formik.values.selectedSurveyFormName}`);
    };

    const handleDrawerClose = (): void => 
    {
        handlePopupClose();

    };

    const validationSchema = Yup.object().shape({
        selectedNpsFormId: Yup.string().test('required', 'Please select a form', function (value) {
            if(this.parent.enableNpsForm) {
                return value && value !== '' || false;
            }
            return true;
        }),
        selectedSurveyFormId: Yup.string().test('required', 'Please select a form', function (value) {
            if(this.parent.enableSurveyForm) {
                return value && value !== '' || false;
            }
            return true;
        }),
        feedbackScheduleTypeNpsForm: Yup.number().required('Please select a schedule type'),
        feedbackScheduleTypeSurveyForm: Yup.number().required('Please select a schedule type'),
    });

    const handleFormSubmit = async (): Promise<void> => {
        try 
        {
            const data: { additionalForms: Partial<EventAdditionalForms> } = {
                additionalForms: {
                    npsForm: null as any,
                    sessionFeedbackForm: null as any
                }
            };

            let timestamp = 0;
            if(formik.values.feedbackScheduleTypeNpsForm === EventNpsScheduleType.CUSTOM)
            {
                timestamp = combineDateTime(moment(`${formik.values.eventFeedbackDate}`), formik.values.eventFeedbackTime); 
            }
            
            if (formik.values.enableNpsForm) {
                data.additionalForms.npsForm = {
                    id: formik.values.selectedNpsFormId,
                    voiceFeedback: formik.values.enableVoiceFeedbackNpsForm,
                    npsDateTime: formik.values.feedbackScheduleTypeNpsForm,
                    timestamp
                };
            }
            
            if (formik.values.enableSurveyForm) {
                data.additionalForms.sessionFeedbackForm = {
                    id: formik.values.selectedSurveyFormId,
                    voiceFeedback: formik.values.enableVoiceFeedbackSurveyForm,
                    feedbackDateTime: formik.values.feedbackScheduleTypeSurveyForm
                };
            }

            const dataUpdated = await updateEvent(eventId, data);
            if (dataUpdated) 
            {
                eventBus.dispatch('event-overview-refreshed', { refresh: true });
                handleDrawerClose();
                toast.success('NPS Details updated');
            }
        } 
        catch (error) 
        {
            toast.error((error as Error)?.message || 'Error updating NPS Details');
            console.log(error);
        }
        finally
        {
            formik.setFieldValue('spinner', false);
        }
    };

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            enableNpsForm: additionalForms?.npsForm?.id ? true : false,
            enableSurveyForm: additionalForms?.sessionFeedbackForm?.id ? true : false,
            selectedNpsFormId: additionalForms?.npsForm?.id || '',
            selectedNpsFormName: '',
            selectedSurveyFormId: additionalForms?.sessionFeedbackForm?.id || '',
            selectedSurveyFormName: '',
            enableVoiceFeedbackNpsForm: additionalForms?.npsForm?.voiceFeedback || VoiceFeedback.DISABLE,
            enableVoiceFeedbackSurveyForm: additionalForms?.sessionFeedbackForm?.voiceFeedback || VoiceFeedback.DISABLE,
            feedbackScheduleTypeNpsForm: Number(additionalForms?.npsForm?.npsDateTime) || EventNpsScheduleType.EVENT_END,
            feedbackScheduleTypeSurveyForm: Number(additionalForms?.sessionFeedbackForm?.feedbackDateTime) || EventSessionFeedbackScheduleType.SESSION_END,
            eventFeedbackDate: additionalForms?.npsForm?.timestamp && String(additionalForms?.npsForm?.timestamp) !== '0' ? moment.unix(Number(additionalForms?.npsForm?.timestamp)).format('YYYY-MM-DD') : moment.unix(Number(eventReduxData?.eventEndDateTime)).format('YYYY-MM-DD'),
            eventFeedbackTime: additionalForms?.npsForm?.timestamp && String(additionalForms?.npsForm?.timestamp) !== '0' ? moment.unix(Number(additionalForms?.npsForm?.timestamp)).format('HH:mm A') : moment().format('HH:mm A'),
            spinner: false,
            header: heading,
            description: 'Add the NPS Form for the Event'
        },
        validationSchema: validationSchema,
        onSubmit: async () => {
            formik.setFieldValue('spinner', true);
            handleFormSubmit();
        },
    });

    useEffect(() =>
    {
        if(additionalForms)
        {
            if(additionalForms?.npsForm?.id)
            {
                const defaultNPSForm = _.find(regForms, function (elem): boolean
                {
                    if (additionalForms?.npsForm?.id !== '') 
                    {
                        return elem?.id.toString() === additionalForms?.npsForm?.id;
                    }
                    else 
                    {
                        return false;
                    }
                });
                if(defaultNPSForm)
                {
                    formik.setFieldValue('selectedNpsFormName', defaultNPSForm.name);
                }
            }
            if(additionalForms?.sessionFeedbackForm?.id)
            {
                const defaultSurveyForm = _.find(regForms, function (elem): boolean
                {
                    if (additionalForms?.sessionFeedbackForm?.id !== '') 
                    {
                        return elem?.id.toString() === additionalForms?.sessionFeedbackForm?.id;
                    }
                    else 
                    {
                        return false;
                    }
                });
                if(defaultSurveyForm)
                {
                    formik.setFieldValue('selectedSurveyFormName', defaultSurveyForm.name);
                }
            }
        }
    }, []);

    const renderFormContent = (): React.JSX.Element => {
        return (
            <div className="nps-form-selection-details-container">
                <div>
                    {/* Enable NPS toggle */}
                    <SwitchToggleInput
                        label={'Enable NPS'}
                        checked={formik.values.enableNpsForm}
                        onChange={(event) => formik.setFieldValue('enableNpsForm', event.target.checked)}
                    />

                    {/* NPS Form Details */}
                    {
                        formik.values.enableNpsForm &&
                        <div className="nps-details-container">
                            <div className="w-100">
                                <div className="form-selection-label-container">
                                    <FormLabelComponent label={'Select NPS Form'} required />
                                    <div className="form-selection-label-buttons">
                                        <CustomButton
                                            name=""
                                            onClick={handleEmbedCodeClick}
                                            startIcon={<FontAwesomeIcon icon={['fal', 'square-terminal']} />}
                                            btnType="tertiary_grey"
                                        />
                                        {
                                            formik.values?.selectedNpsFormId && formik.values?.selectedNpsFormId !== '' &&
                                            <CustomButton
                                                name=""
                                                onClick={() => handleNestedSidebarOpen('nps')}
                                                startIcon={<FontAwesomeIcon icon={['fal', 'eye']} />}
                                                btnType="tertiary_grey"
                                            />
                                        }
                                    </div>
                                </div>
                                <div>
                                    <AutocompleteComponent
                                        value={formik.values.selectedNpsFormName}
                                        onChange={(event, value) => handleFormChange(value, 'nps')}
                                        optionsArr={regForms}
                                        keyToShow="name"
                                        placeholder={'Select Form'}
                                    />
                                    {formik.errors.selectedNpsFormId && <div className="error-msg">{formik.errors.selectedNpsFormId}</div>}
                                </div>
                            </div>
                            <SwitchToggleInput
                                label={'Enable Voice Feedback'}
                                checked={formik.values.enableVoiceFeedbackNpsForm === VoiceFeedback.ENABLE}
                                onChange={(event) => formik.setFieldValue('enableVoiceFeedbackNpsForm', event.target.checked ? VoiceFeedback.ENABLE : VoiceFeedback.DISABLE)}
                            />
                            <div className="voice-feedback-container">
                                <FormLabelComponent label={'Select Date & Time'} required />
                                <RadioGroupComponent
                                    options={npsFeebackTimingOptions}
                                    value={formik.values.feedbackScheduleTypeNpsForm}
                                    onChange={(event) => formik.setFieldValue('feedbackScheduleTypeNpsForm', Number(event.target.value) as EventNpsScheduleType)}
                                />
                            </div>
                            {
                                formik.values.feedbackScheduleTypeNpsForm === EventNpsScheduleType.CUSTOM &&
                                <DateTimePicker
                                    dateValue={moment(formik.values.eventFeedbackDate).unix()}
                                    timeValue={formik.values.eventFeedbackTime}
                                    onDateChange={(date) => formik.setFieldValue('eventFeedbackDate', date)}
                                    onTimeChange={(time) => formik.setFieldValue('eventFeedbackTime', time)}
                                    dateFormat="ddd, MMM D"
                                    minDate={Number(eventReduxData?.eventStartDateTime)}
                                    maxDate={Number(moment.unix(Number(eventReduxData?.eventEndDateTime)).add(7, 'days').unix())}
                                />
                            }
                        </div>
                    }
                </div>

                <div>
                    {/* Enable Session Feedback Toggle */}
                    <SwitchToggleInput
                        label={'Enable Session Feedback'}
                        checked={formik.values.enableSurveyForm}
                        onChange={(event) => formik.setFieldValue('enableSurveyForm', event.target.checked)}
                    />

                    {/* Event Session Feedback details */}
                    {
                        formik.values.enableSurveyForm &&
                        <div className="nps-details-container">
                            <div className="w-100">
                                <div className="form-selection-label-container">
                                    <FormLabelComponent label={'Select Survey Form'} required />
                                    <div className="form-selection-label-buttons">
                                        {
                                            formik.values?.selectedSurveyFormId && formik.values?.selectedSurveyFormId !== '' &&
                                            <CustomButton
                                                name=""
                                                onClick={() => handleNestedSidebarOpen('sessionFeedback')}
                                                startIcon={<FontAwesomeIcon icon={['fal', 'eye']} />}
                                                btnType="tertiary_grey"
                                            />
                                        }
                                    </div>
                                </div>
                                <AutocompleteComponent
                                    value={formik.values.selectedSurveyFormName}
                                    onChange={(event, value) => handleFormChange(value, 'survey')}
                                    optionsArr={regForms}
                                    keyToShow="name"
                                    placeholder={'Select Form'}
                                />
                            </div>
                            <SwitchToggleInput
                                label={'Enable Voice Feedback'}
                                checked={formik.values.enableVoiceFeedbackSurveyForm === VoiceFeedback.ENABLE}
                                onChange={(event) => formik.setFieldValue('enableVoiceFeedbackSurveyForm', event.target.checked ? VoiceFeedback.ENABLE : VoiceFeedback.DISABLE)}
                            />
                            <div className="voice-feedback-container">
                                <FormLabelComponent label={'Select Date & Time'} required />
                                <RadioGroupComponent
                                    options={surveyFeebackTimingOptions}
                                    value={formik.values.feedbackScheduleTypeSurveyForm}
                                    onChange={(event) => formik.setFieldValue('feedbackScheduleTypeSurveyForm', Number(event.target.value) as EventSessionFeedbackScheduleType)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const renderHeader = (): React.JSX.Element => {
        return (
            <div className='popup-header'>
                <div className='popup-header-content'>
                    {showFormEmbed || formPreviewType ? (
                        <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} onClick={handleBackToPopup} />
                    ) : (
                        <div className='popup-header-icon'><FontAwesomeIcon icon={['fal', 'file']} /></div>
                    )}

                    <div className='popup-header-text'>
                        <h3>
                            {formik.values.header}
                        </h3>
                        <p>
                            {formik.values.description}
                        </p>
                    </div>
                </div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => handlePopupClose()} />
            </div>
        );
    }

    const renderMainContent = (): React.JSX.Element => 
    {
        if (showFormEmbed)
        {
            return codeBlock();
        }
        else if(formPreviewType)
        {
            return <NPSFormPreview formType={formPreviewType} formId={formPreviewType === 'nps' ? formik.values.selectedNpsFormId : formik.values.selectedSurveyFormId} />
        }
        else
        {
            return renderFormContent();
        }
    }

    const renderFooter = (): React.JSX.Element => {

        if(showFormEmbed || formPreviewType)
        {
            return <></>
        }
        return (
            <div className="popup-footer">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton btnType='primary' disabled={formik.values.spinner} loading={formik.values.spinner} name={'Save'} type='submit' onClick={() => formik.handleSubmit()} />
                    </Stack>
            </div>
        )
    }

    return (
        <div id="npsFormSelectionDetails">

            {renderHeader()}

            <div className="popup-container">
                {renderMainContent()}   
            </div>

            {renderFooter()}
        </div>
    )
}

export default NPSFormSelectionDetails