import { useContext, useEffect, useState } from "react";
import { generateAudienceColumnsForTanstackTable } from "../../../Audience/audienceColumn.helper";
import { Audience } from "../../../Audience/interfaces";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import {
    deleteEventTargetListAudience,
    getEventTargetAudiencesByListId,
    getEventTargetAudiencesCountByListId,
    getEventTargetListById,
} from "../../../../scripts/apis/eventTargetList";
import { useTablePagination } from "../../../../contexts/TablePaginationContext";
import { DeleteTargetListAudience, EventTargetList } from "../../interfaces/event-target-list-interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import TanstackToolbarWithServerFilter from "../../../../common/TanstackTable/TanstackToolbarWithServerFilter";
import { UserContext } from "../../../../contexts/UserContext";
import { UserViewType } from "../../../Settings/enum/users.enum";
import { audienceFilterColumns } from "./helpers/TargetListContants";
import DeletePopup from "../../../../common/DeletePopup";
import toast from 'react-hot-toast';
import { getEncodedParams } from "../../../../scripts/helpers";
import { MinimalViewComponent } from "../../../../common/MinimalView/MinimalViewComponent";
import ColumnVisibilityDropdown from "../../../../common/TanstackTable/ColumnVisibilityDropdown";

interface EventTargetListAudiencePageProps 
{
    eventId: string;
    listId: string;
}

const EventTargetListAudiencePage: React.FC<EventTargetListAudiencePageProps> = (props) => 
{
    const eventId = props.eventId;
    const listId = props.listId;

    const {
        userDetails
    } = useContext(UserContext);


    const navigate = useNavigate();
    const [eventRegistrant, setEventRegistrant] = useState<Audience[]>([]);

    let audienceColumns = [...generateAudienceColumnsForTanstackTable()];

    const [allColumns, setAllColumns] = useState(audienceColumns);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [targetListAudienceCount, setTargetListAudienceCount] = useState(1);
    const [targetList, setTargetList] = useState<EventTargetList>();
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const { pageSize, currentPage, updatePageSize, updateCurrentPage } = useTablePagination();

    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const handleDeleteClick = async () => {
        if(targetList)
        {
            const updateTargetList: DeleteTargetListAudience = 
            {
                audienceIds: selectedRowIds,
            }; 
            await deleteEventTargetListAudience(eventId, listId, updateTargetList);        
        }
        setShowDeletePopup(false);
        toast.success(`Selected Target List Audience deleted successfully`);
        setSelectedRowIds([]);
        setRefresh(true);
    };

    const handleCancelClick = () => {
        setSelectedRowIds([]);
    };

    const handleBackClick = () => {
        navigate(`/events/${eventId}/target-list`);
    };

    const fetchRegistrantCount = async () => {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await getEventTargetAudiencesCountByListId(eventId, listId, encodedParams);
            setTargetListAudienceCount(count);
        }
        catch (error) 
        {
            console.error(error);
        }
    }

    const fetchRegistrantData = async () =>
    {
        try {
            const encodedParams = getEncodedParams(currentUrl);
            const allRegistrants = await getEventTargetAudiencesByListId( eventId, listId, pageSize || 25, currentPage - 1, encodedParams,);
            if (allRegistrants) {
                setIsEmpty(allRegistrants?.length === 0 && encodedParams.toString().length === 0);
                setShowSpinner(false);
            }
            setEventRegistrant(allRegistrants);
            setRefresh(false);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchTargetList = async () => 
    {
        try {
            const targetList = await getEventTargetListById(eventId, listId);
            const count = targetList.audienceIds.length + targetList.targetListAudienceIds.length;
            setTargetListAudienceCount(count);
            setTargetList(targetList);
        } catch (error) {}
    };

    useEffect(() =>
    {
        fetchRegistrantData();
        fetchTargetList();
        fetchRegistrantCount();
    }, []);

    useEffect((): void => 
    {
        if (currentPage && pageSize) {
            fetchRegistrantData();
            fetchRegistrantCount();
        }
    }, [pageSize, currentPage, targetListAudienceCount, currentUrl]);

    useEffect(():void => 
    {
        fetchTargetList();
    }, [refresh]);


    const renderEventTargetListAudienceComponent = (): React.JSX.Element => {
        return (
            <div id="eventTargetListAudienceComponent">
                <div id="inside-event-module-name-section">
                    <div onClick={handleBackClick} className="back-arrow">
                        <FontAwesomeIcon icon={["fal", "arrow-left"]} />
                        <div className="target-list-text">Target List</div>
                    </div>
                    <div className="target-list-name">
                        <div>/</div>
                        <div>{targetList?.name}</div>
                    </div>

                    <MuiChip label={"Static"} chipColor={"grey"} />
                </div>
                <div className="w-100 table-top-container">
                    {selectedRowIds.length > 0 ? (                    
                        <TanstackToolbarWithServerFilter
                            columns={audienceColumns}
                            setColumns={setAllColumns}
                            setCurrentUrl={setCurrentUrl}
                            leftActionButtons={[
                                <CustomButton 
                                    btnType="danger" 
                                    name="Delete" 
                                    onClick={() => setShowDeletePopup(true)} 
                                />,
                                <CustomButton
                                    btnType="secondary"
                                    name="Cancel"
                                    onClick={handleCancelClick}
                                />,
                                <div className="table-header-text">
                                    {" "}
                                    {selectedRowIds.length} records selected{" "}
                                </div>]}
                            handleApplyFilters={handleApplyFilters}
                            filterColumns={audienceFilterColumns}
                        />
                    ) : (
                        <TanstackToolbarWithServerFilter
                            columns={audienceColumns}
                            setColumns={setAllColumns}
                            setCurrentUrl={setCurrentUrl}
                            tabs={[
                                {
                                    tabName: "All",
                                    count: targetListAudienceCount,
                                    navigation: () => {},
                                    selectedTab: true,
                                },
                            ]}
                            handleApplyFilters={handleApplyFilters}
                            filterColumns={audienceFilterColumns}
                            rightCornerButton={
                                <CustomButton
                                    name="+ Add Targets"
                                    btnType="secondary"
                                    style={{ fontWeight: "600" }}
                                    onClick={() =>
                                        navigate(`/events/${eventId}/target-list/details/${listId}`)
                                    }
                                />
                            }
                        />
                    )}
                    {isEmpty ? (
                        <div className="empty-component" >
                            <TableEmptyComponent infoText={"No Target List Audience Exists"} subInfoText={'Add Targets to this List'}/>
                        </div>
                    ) : (
                        <TanstackTable
                            checkboxSelection={true}
                            data={eventRegistrant}
                            initialColumns={allColumns}
                            rowCount={targetListAudienceCount}
                            showSpinner={showSpinner}
                            selectedRows={selectedRowIds}
                            setSelectedRows={setSelectedRowIds}
                            pageSize={pageSize}
                            currentPage={currentPage - 1}
                            updateCurrentPage={updateCurrentPage as any}
                            updatePageSize={updatePageSize}
                            rightPinnedColumns={["actions"]}
                            // height={"calc(100vh - 240px)"}
                            fullWidth
                        />
                    )}
                </div>
            </div>
        );
    };

    const renderHeaderActions = (): React.JSX.Element =>
    {
        return (
            <div className="bulk-actions">
                <div className="bulk-actions-buttons">
                    <CustomButton
                        btnType="danger"
                        name="Delete"
                        onClick={() => setShowDeletePopup(true)}
                    />
                    <CustomButton
                        btnType="secondary"
                        name="Cancel"
                        onClick={handleCancelClick}
                    />                  
                </div>
                <p className="table-header-text">{`${selectedRowIds?.length} records selected`}</p>
            </div>
        )
    }

    const renderMinimalTargetListAudienceComponent = (): React.JSX.Element => {
        return (
            <div id="eventTargetListAudienceComponentMinimal">
                <MinimalViewComponent
                    title={
                        <div className="targetlist-header-container">
                            <div onClick={handleBackClick} className="back-arrow">
                                <FontAwesomeIcon icon={["fal", "arrow-left"]} />
                                <div className="target-list-text">Target List</div>
                            </div>
                            <div className="target-list-name">
                                <div>/</div>
                                <div>{targetList?.name}</div>
                            </div>

                            <MuiChip label={"Static"} chipColor={"grey"} />
                        </div>
                    }
                    titleBarStyle={{ padding: '0px' }}
                    tabData={selectedRowIds && selectedRowIds?.length == 0 ? [
                        {
                            tabName: "All",
                            count: targetListAudienceCount,
                            navigation: () => { },
                            selectedTab: true,
                        },
                    ] : undefined}
                    additionalHeaderContent={
                        selectedRowIds &&
                            selectedRowIds.length > 0 ? renderHeaderActions() : undefined
                    }
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            key={1}
                            columns={audienceColumns}
                            filterColumns={audienceFilterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown
                            key={2}
                            columns={audienceColumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomButton
                            key={3}
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() =>
                                navigate(`/events/${eventId}/target-list/details/${listId}`)
                            }
                        />
                    ]}
                    component={
                        <div className="minimalView-table-container">
                            {isEmpty ?
                                <TableEmptyComponent infoText={"No Target List Audience Exists"} subInfoText={'Add Targets to this List'} />
                                : (
                                    <TanstackTable
                                        checkboxSelection={true}
                                        data={eventRegistrant}
                                        initialColumns={allColumns}
                                        rowCount={targetListAudienceCount}
                                        showSpinner={showSpinner}
                                        selectedRows={selectedRowIds}
                                        setSelectedRows={setSelectedRowIds}
                                        pageSize={pageSize}
                                        currentPage={currentPage - 1}
                                        updateCurrentPage={updateCurrentPage as any}
                                        updatePageSize={updatePageSize}
                                        rightPinnedColumns={["actions"]}
                                        showBottomPadding={false}
                                        // height={"calc(100vh - 240px)"}
                                    />
                                )}
                        </div>
                    }
                    componentType="table"
                />
            </div>
        );
    };


    return (
        <>
            {
                userDetails?.viewType === UserViewType.MINIMAL ? renderMinimalTargetListAudienceComponent() : renderEventTargetListAudienceComponent()
            }

            {showDeletePopup && (
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={handleDeleteClick}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                    }}
                    modalContent={`Are you sure you want to delete the selected Target List?`}
                    modalTitle='Delete Target List Audience'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='trash'
                />
            )}
        </>
    )
};

export default EventTargetListAudiencePage;
