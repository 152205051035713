import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment';
import CustomSpinner from '../../../../common/CustomSpinner';
import CreateRegistrationDetails from '../../../../components/Events/EventRegistrations/CreateRegistrationDetails';
import { Event } from '../../interfaces';
import './styles.scss';
import CardComponent from '../../../../components/Events/Registrations/CardComponent';
import { RegistrationDetailsKey } from '../../enum';
import PopupDialog from '../../../../common/PopupDialog';

const RegistrationDetailsSection: React.FC<{eventDetails: Event; spinner: boolean;}> = (props: {eventDetails: Event; spinner: boolean;}): React.JSX.Element => 
{
    
    const { eventDetails, spinner } = props;
    const [registrationDetailsKey, setRegistrationDetailsKey] = useState<RegistrationDetailsKey>(RegistrationDetailsKey.ALL);
    
    const [showRegistrationDetailsPopup, setShowRegistrationDetailsPopup] = useState<boolean>(false);

    const handleEditRegistrationDetails = (key: RegistrationDetailsKey): void => 
    {
        setShowRegistrationDetailsPopup(true);
        setRegistrationDetailsKey(key);
    };

    const handleCloseRegistrationDetailsPopup = (): void =>
    {
        setShowRegistrationDetailsPopup(false);
        setRegistrationDetailsKey(RegistrationDetailsKey.ALL);
    }

    const registrationDetails: {name: string; value: string | number; key: RegistrationDetailsKey}[] = [
        {
            name: 'Capacity',
            value: `${eventDetails.maximumRegistration ? eventDetails.maximumRegistration : 'Unlimited'}`,
            key: RegistrationDetailsKey.MAXIMUM_REGISTRATIONS
        },
        {
            name: 'Waitlist',
            value: `${Number(eventDetails.enableWaitlistStatus) === 1 ? 'On' : 'Off'}`,
            key: RegistrationDetailsKey.ENABLE_WAITLIST
        },
        {
            name: 'Closing Date',
            value: `${eventDetails?.closingDateTime !== null ? moment.unix(eventDetails?.closingDateTime).format('DD/MM/YYYY') : '-'} ${eventDetails?.closingDateTime !== null ? moment.unix(eventDetails?.closingDateTime).format('hh:mm A') : ''}`,
            key: RegistrationDetailsKey.REGISTRATION_CLOSING_TIME
        },
        {
            name: 'Redirect',
            value: `${eventDetails?.registerOptions?.redirectionUrl ? eventDetails?.registerOptions.redirectionUrl : eventDetails?.registerOptions?.message ? eventDetails?.registerOptions.message : '-'}`,
            key: RegistrationDetailsKey.POST_SUBMISSION
        },
    ];

    return (
        <>
            {spinner ? <CustomSpinner height={'100%'} /> :
                <Box id="details-container">
                    <div className={'w-100'}>
                        <Grid container spacing={2}>
                            {
                                registrationDetails?.map((item, index) => {
                                    return (
                                        <Grid key={index} item xl={3} lg={3} md={6} sm={12} xs={12}>
                                            <CardComponent 
                                                header={item.name}
                                                contentHeading={<p className='cont-heading-sub' style={{ marginTop: '4px', fontWeight: 500 }}>{item.value}</p>}
                                                contentHeadingBold
                                                cardMinHeight='80px'
                                                onClick={() => handleEditRegistrationDetails(item.key)}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </div>
                    {
                        showRegistrationDetailsPopup && (
                            <PopupDialog
                                modalId="editRegistrationPopup"
                                show={showRegistrationDetailsPopup} 
                                cancelClick={handleCloseRegistrationDetailsPopup}
                                modalContent={
                                    <CreateRegistrationDetails eventDetails={eventDetails} handlePopupClose={handleCloseRegistrationDetailsPopup} editRegistrationKey={registrationDetailsKey}  />
                                }
                            />
                        )
                    }
                </Box>
            }
        </>

    );
};

export default RegistrationDetailsSection;