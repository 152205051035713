import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import toast from 'react-hot-toast';
import MuiChip from '../FormComponents/MuiChip';
import _ from 'lodash';
import TablePopupTabs from './TablePopupTabs';
import { IMuiChipProps } from '../FormComponents/IReusableFormComponents';

interface TablePopupTabs {
    title: string;
    data: React.ReactNode;
}

interface TablePopupDetailsProps {
    onPopupClose?: () => void;
    tabData?: TablePopupTabs[];
    imageSrc?: string;
    altImage?: string;
    title: string;
    subTitle?: string;
    email?: string;
    otherInitialBadges?: string[];
    otherInitialBadgesColor?: IMuiChipProps['chipColor'];
    icp?: string[];
    buyerPersona?: string[];
    profileBadge?: React.ReactNode;
    popupHeader?: React.ReactNode;
}

const TablePopupDetails: React.FC<TablePopupDetailsProps> = (props): React.JSX.Element =>
{

    const { onPopupClose, tabData, imageSrc, altImage, title, subTitle, email, icp, buyerPersona, otherInitialBadges, otherInitialBadgesColor, profileBadge, popupHeader } = props;
    return (
        <div id="tablePopupDetails">
            <div className="table-popup-details-container">
                {popupHeader && (
                    <>{popupHeader}</>
                )}
                <div className="header-container">
                    <div className="user-basic-info">
                        <div className="image-container">
                            {
                                imageSrc && imageSrc !== null && imageSrc !== '' && imageSrc !== undefined ? 
                                <img 
                                    src={imageSrc}
                                    className="user-logo"
                                    alt="user-company-logo"
                                />
                                :
                                <div className="user-logo">
                                    {altImage}
                                </div>
                            }
                            {profileBadge && (
                                <div className="profile-badge">
                                    {profileBadge}
                                </div>
                            )}
                        </div>
                        <div className="user-details">
                            <h3 className="user-name">{title}</h3>
                            {subTitle && subTitle !== '' && subTitle !== undefined && <h3 className="user-company">{subTitle}</h3>}
                            {email && 
                                <div className="user-email">
                                    {email}
                                    <FontAwesomeIcon cursor={'copy'} onClick={() => {
                                        navigator.clipboard.writeText(email);
                                        toast.success('Email copied to clipboard');
                                    }} icon={['fal', 'copy']} />
                                </div>
                            }
                            {((otherInitialBadges && otherInitialBadges.length > 0) || (icp && icp.length > 0) || (buyerPersona && buyerPersona.length > 0)) && (
                                <div className="icp-bp-container">
                                    {otherInitialBadges && otherInitialBadges.length > 0 && (
                                        <MuiChip label={otherInitialBadges.toString()} chipColor={otherInitialBadgesColor ?? 'blue'} />
                                    )}
                                    {icp && icp.length > 0 && (
                                        <MuiChip circleIcon label={icp.toString()} chipColor="blue" />
                                    )}
                                    {buyerPersona && buyerPersona.length > 0 && (
                                        <MuiChip circleIcon label={buyerPersona.toString()} chipColor="red" />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {
                        onPopupClose && 
                        <FontAwesomeIcon cursor={'pointer'} icon={['fal', 'xmark']} onClick={onPopupClose} />
                    }
                </div>
                {
                    tabData &&
                    <div className="popup-details-tabs-wrapper">
                        <TablePopupTabs tabData={tabData} />
                    </div>
                }
            </div>
        </div>
    );
};

export default TablePopupDetails;
