/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, FormControlLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import eventBus from '../../../scripts/event-bus';
import { createRegistrationFormFields, updateRegistrationForm, updateRegistrationFormFields } from '../../../scripts/apis/registrationForm';
import APP_CONSTANTS from '../../../scripts/constants';
import { useDispatch } from 'react-redux';
import { resetFormField } from '../../../redux/registrationsForms/FormComponentDisplayField';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import LexicalEditor from '../../../lexical';
import { FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { CustomFieldDisabled } from '../enum/custom-field.enum';
import { RegistrationForm } from '../interfaces/registration-form_interface';
import { validateEmptyHtml } from '../../../scripts/helpers';
import { createLeadCaptureField, updateLeadCaptureField } from '../../../scripts/apis/sponsorPortal/sponsorPortal';
import toast from 'react-hot-toast';

interface IDateInputSideDrawerProps {
    drawerTitle : string;
    formId: string | number;
    csrfTokenData: string;
    setRefresh: (refresh: boolean) => void;
    componentType: number;
    existingComponentData?: any;
    RegistrationFormData: RegistrationForm;
    leadCaptureFormId?: string | number;
}

const dateFormat = [
    {
        name: 'mm/dd/yyy',
    },
    {
        name: 'dd/mm/yyyy',
    },
    {
        name: 'yyyy/mm/dd',
    },
];

const DateInputSideDrawer = ({ drawerTitle, formId, csrfTokenData, setRefresh, componentType, existingComponentData, RegistrationFormData, leadCaptureFormId }: IDateInputSideDrawerProps): React.JSX.Element => 
{
    const dispatch = useDispatch();
    const [backBtnClicked, setBackBtnClicked] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    const isExistingField = RegistrationFormData?.formFields?.some((customField) => customField.id === existingComponentData?.id);

    const validationSchema = Yup.object().shape({
        componentMandatory: Yup.number(),
        labelHtmlText: Yup.string().required('Label is required'),
        label: Yup.string(),
        helpText: Yup.string(),
        dateFormat: Yup.string(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            componentMandatory: 2,
            label: existingComponentData?.label ? existingComponentData.label : '',
            labelHtmlText: existingComponentData?.label ? existingComponentData.label : '',
            helpText: existingComponentData?.helpText ? existingComponentData.helpText : '',
            dateFormat: 'dd/mm/yyyy',
        },
        onSubmit: async (values): Promise<void> => 
        {
            const componentDetails = {
                type: componentType,
                label: values.label === '<p><br></p>' ? null : values.label,
                default: existingComponentData?.default ? existingComponentData.default : null,
                helpText: values.helpText === '<p><br></p>' ? null : values.helpText,
                fieldMandatory: values.componentMandatory,
                placeholder: existingComponentData?.placeholder ? existingComponentData.placeholder : null,
                fieldDisabled: CustomFieldDisabled.NOT_DISABLED,
                fieldLocations: existingComponentData?.fieldLocations?.length > 0 ? existingComponentData?.fieldLocations : [5],
                fieldName: existingComponentData?.fieldName ? existingComponentData.fieldName : values.label,
            };

            // lead capture form api handlers
            if(leadCaptureFormId)
            {
                return handleSubmissionLeadCaptureForms(componentDetails);
            }

            if (existingComponentData && isExistingField) 
            {
                setSpinner(true);
                updateFormComponent(componentDetails);
            }
            else if (!isExistingField && existingComponentData)
            {
                setSpinner(true);
                updateFormComponent(componentDetails);
                // updateForm();
            }
            else
            {
                setSpinner(true);
                createFormComponent(componentDetails);
            }
        }
    });

    const createFormComponent = async (componentDetails): Promise<void> => 
    {
        try 
        {
            const componentUpdated = await createRegistrationFormFields(Number(formId), componentDetails, csrfTokenData);
            if (componentUpdated) 
            {
                setSpinner(false);
                toast.success('Component successfully saved');
                setBackBtnClicked(true);
                setRefresh(true);
                dispatch(resetFormField());
                eventBus.dispatch('event-update-form-component', true);
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
        }
    };

    const updateFormComponent = async (componentDetails): Promise<void> => 
    {
        try 
        {
            const componentUpdated = await updateRegistrationFormFields(Number(formId), existingComponentData.id , componentDetails);

            if (componentUpdated) 
            {
                setSpinner(false);
                toast.success('Component successfully saved');
                setBackBtnClicked(true);
                setRefresh(true);
                dispatch(resetFormField());
                eventBus.dispatch('event-update-form-component', true);
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
        }
    };

    const updateForm = async (): Promise<void> =>
    {
        const fields = RegistrationFormData?.fields;
        fields.push(existingComponentData?.id);
        try 
        {
            const formUpdated = await updateRegistrationForm(Number(formId), {fields});
            if(formUpdated)
            {
                setSpinner(false);
                toast.success('Component successfully saved');
                setBackBtnClicked(true);
                setRefresh(true);
                dispatch(resetFormField());
                eventBus.dispatch('event-update-form-component', true);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const handleSubmissionLeadCaptureForms = async (data: any): Promise<void> =>
    {
        setSpinner(true);

        const createLeadCaptureFormField = async (): Promise<void> =>
        {
            try 
            {
                const fieldCreated = await createLeadCaptureField(leadCaptureFormId, data);
                if (fieldCreated) 
                {
                    setSpinner(false);
                    toast.success('Field created successfully');
                    setBackBtnClicked(true);
                    setRefresh(true);
                    eventBus.dispatch('event-update-form-component', true);
                }
            } 
            catch (error) 
            {
                console.log(error);
                toast.error('Error in creating field');
            }
            finally
            {
                setSpinner(false);
            }
        };

        const updateLeadCaptureFormField = async (): Promise<void> =>
        {
            try 
            {
                const fieldUpdated = await updateLeadCaptureField(leadCaptureFormId, existingComponentData?.id, data);
                if (fieldUpdated) 
                {
                    setSpinner(false);
                    toast.success('Field updated successfully');
                    setBackBtnClicked(true);
                    setRefresh(true);
                    eventBus.dispatch('event-update-form-component', true);
                }
            } 
            catch (error) 
            {
                console.log(error);
                toast.error('Error in updating field');
            }
            finally
            {
                setSpinner(false);
            }
        };

        if (existingComponentData && isExistingField) 
        {
            updateLeadCaptureFormField();
        }
        else
        {
            createLeadCaptureFormField();
        }
    };

    useEffect(():void => 
    {
        if(backBtnClicked)
        {
            eventBus.dispatch('event-open-form-Component-drawer', {
                componentType: 0
            });
            setBackBtnClicked(false);
            dispatch(resetFormField());
            eventBus.dispatch('event-update-form-component', true);
        }
       
    },[backBtnClicked]);

    
    return(
        <Box id='dateInputSideDrawer'>
            <Box padding={leadCaptureFormId ? '15px 24px' : '13px 24px'} className="drawer-header">
                <Box className="drawer-inner-cont" onClick={():void => 
                    {
                        setBackBtnClicked(true);
                        
                    }}>
                    <FontAwesomeIcon icon={['fal', 'arrow-left']} className='back-icon' />
                    <Typography className='component-type-name'>{drawerTitle}</Typography>
                </Box>
            </Box>
            <Box className="drawer-content">
                <Box className="required-block">
                    <Typography className='form-label'>{'Required'}</Typography>
                    <FormControlLabel
                        checked={formik.values.componentMandatory === 1}
                        control={
                            <CustomSwitch
                                sx={{
                                    m: 1 
                                }}
                                name="required"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                                {
                                    formik.setFieldValue('componentMandatory', event.target.checked ? 1 : 2);
                                }}
                            />}
                        label={null}
                        className='captcha-switch'
                    />
                </Box>
                <Box className="editor-block">
                    <LexicalEditor noDrag placeHolder='Label' toolBarOptions={['Bold', 'Italic', 'Underline']} minHeight='40px' maxHeight='96px' label='Label' required value={formik.values.label} handleChange={(html, json) => {
                        formik.setFieldValue('labelHtmlText', validateEmptyHtml(html));
                        
                        formik.setFieldValue('label', validateEmptyHtml(html));
                    }} />
                    {formik.touched.labelHtmlText && formik.errors.labelHtmlText && <div className="error-msg">{formik.errors.labelHtmlText}</div>}
                </Box>
                <Box className="editor-block">
                    <LexicalEditor noDrag placeHolder='Description' toolBarOptions={['Bold', 'Italic', 'Underline']} minHeight='96px' label='Description' value={formik.values.helpText} handleChange={(html, json) => {
                        formik.setFieldValue('helpText', html);
                    }} />
                </Box>
                <Box className="editor-block">
                    <FormLabelComponent label='Date Format' />
                    <Select
                        value={formik.values.dateFormat}
                        defaultValue={formik.values.dateFormat}
                        displayEmpty
                        className="date-format-select"
                        IconComponent={(props): React.JSX.Element => 
                        {
                            return (
                                <FontAwesomeIcon {...props} icon={['fal', 'chevron-down']} className={`material-icons ${props.className}`} />
                            ); 
                        }}
                        onChange={(event): void => 
                        {
                            formik.setFieldValue('dateFormat', event.target.value);
                        }}
                        renderValue={(selected: string): React.ReactElement => 
                        {
                            if (selected) 
                            {
                                return (
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <Typography>{selected}</Typography>
                                    </Box>
                                );
                            }
                            return <Typography className="date-placeholder">Input Type</Typography>;
                        }}
                    >
                        {
                            dateFormat.map((elem, index): React.ReactElement => 
                            {
                                return (
                                    <MenuItem key={index} value={elem.name}>
                                        <Typography>{elem.name}</Typography>
                                    </MenuItem>
                                );
                            }
                            )}
                    </Select>
                </Box>
            </Box>
            <Box className="bottom-btn-block">
                <CustomButton btnType='primary' name='Save' loading={spinner} type='submit' onClick={() => formik.handleSubmit()} />
            </Box>
        </Box>
    );
};

export default DateInputSideDrawer;