// eslint-disable-next-line import/no-unresolved
import { EventSpeaker, EventSpeakerGraph } from '../../pages/Events/interfaces';
import { Speaker } from '../../pages/Speakers/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

// GET Methods

/**
 * Get the count of event speakers
 * @param {string | number} eventId 
 * @param {[string, string][]} queryParams 
 * @returns {Promise<number>}
 */
export const eventSpeakersCount = async (eventId: string | number, queryParams: [string, string][] = []): Promise<number> => {
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + eventId + '/count' + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

/**
 * Get the list of event speakers
 * @param {number} pageSize 
 * @param {number} offset 
 * @param {string | number} eventId 
 * @param {number} [speakerId] 
 * @param {number} [status] 
 * @param {[string, any][]} queryParams 
 * @returns {Promise<Speaker[]>}
 */
export const getEventSpeakers = async (pageSize: number, offset: number, eventId: string | number, speakerId?: number, status?: number, queryParams: [string, any][] = []): Promise<Speaker[]> => {
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        status,
        speakerId,
        ...filteredParams
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + eventId + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

/**
 * Get graph data for event speakers
 * @param {string | number} eventId 
 * @returns {Promise<EventSpeakerGraph>}
 */
export const getEventSpeakerGraphData = async (eventId: string | number): Promise<EventSpeakerGraph> => {
    const query = convertJSONToGETParams({
        icp: true,
        buyerPersona: true,
        arr: true
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + eventId + '/graph?' + query;
    return triggerApi(API_URL, 'GET', '');
};

// POST Methods

/**
 * Add a new event speaker
 * @param {any} EVENT_SPEAKER_DATA 
 * @param {number} EVENT_ID 
 * @param {string} csrfToken 
 * @returns {Promise<EventSpeaker>}
 */
export const addEventSpeaker = async (EVENT_SPEAKER_DATA: Partial<Speaker>, EVENT_ID: number, csrfToken: string): Promise<EventSpeaker> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + EVENT_ID;
    return triggerApi(API_URL, 'POST', EVENT_SPEAKER_DATA, csrfToken);
};

/**
 * Creates a new event speaker for a specific event.
 *
 * @param eventSpeakerData - Partial data of the speaker to be created.
 * @param eventId - The ID of the event to which the speaker is being added.
 * @param csrfToken - The CSRF token for authentication.
 * @returns A promise that resolves to the created speaker object.
 */
export const createEventSpeaker = async (eventSpeakerData: Partial<Speaker>, eventId: string | number, csrfToken: string): Promise<EventSpeaker> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/v1/' + eventId;
    return triggerApi(API_URL, 'POST', eventSpeakerData, csrfToken);
};

/**
 * Uploads an image for a specific event speaker.
 *
 * @param speakerId - The unique identifier of the speaker.
 * @param image - The image file to be uploaded, encapsulated in a FormData object.
 * @param csrfToken - The CSRF token for authentication and security.
 * @returns A promise that resolves to a boolean indicating the success of the upload operation.
 */
export const uploadEventSpeakerImage = async (speakerId: string | number, image: FormData, csrfToken: string): Promise<boolean> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/v1/' + speakerId + '/upload-image';
    return triggerApi(API_URL, 'POST', image, csrfToken, false);
};

// PUT Methods

/**
 * Update an existing event speaker
 * @param {any} EVENT_SPEAKER_DATA 
 * @param {any} SPEAKER_ID 
 * @returns {Promise<EventSpeaker>}
 */
export const updateEventSpeaker = async (EVENT_SPEAKER_DATA: Partial<Speaker>, SPEAKER_ID: string | number): Promise<EventSpeaker> => {
    // const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + SPEAKER_ID;
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/v1/' + SPEAKER_ID;
    return triggerApi(API_URL, 'PUT', EVENT_SPEAKER_DATA);
};

// DELETE Methods

/**
 * Remove an event speaker
 * @param {string | number} EVENT_ID 
 * @param {string | number} SPEAKER_ID 
 * @returns {Promise<boolean>}
 */
export const removeEventSpeaker = async (EVENT_ID: string | number, SPEAKER_ID: string | number): Promise<boolean> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + EVENT_ID + '/' + SPEAKER_ID;
    return triggerApi(API_URL, 'DELETE', '');
};