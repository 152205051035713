import { useState } from "react";

import './styles.scss';

interface EditableHeaderProps {
    text: string;
    fullWidth?: boolean;
    placeholder: string;
    handleSave?: () => void;
    onChange: (value: string) => void;
}

const EditableHeader: React.FC<EditableHeaderProps> = (props): React.JSX.Element =>
{
    const { text, fullWidth, placeholder, handleSave, onChange } = props;

    const [isEditing, setIsEditing] = useState<boolean>(false);

    return (
        <div id="editableHeader" onClick={() => setIsEditing(true)}>
            {
                isEditing ? (
                    <input
                        type="text"
                        autoFocus
                        value={text}
                        placeholder={placeholder}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={() => 
                        {
                            if(handleSave)
                            {
                                handleSave();
                            }
                            setIsEditing(false);
                        }}
                        style={{ width: fullWidth ? '100%' : `${text.length || placeholder.length}ch` }}
                        className="header-edit-mode"
                    />
                ) : (
                    <h3 className={`header-view-mode ${!text ? 'header-placeholder' : ''}`}>
                        {text || placeholder}
                    </h3>
                )
            }
        </div>
    )
};

export default EditableHeader;