import React, { useContext } from 'react';
import useEventOverviewData from '../../../../hooks/eventOverviewData';
import EventOverviewBar from './EventOverviewBar';
import './styles.scss';
import CustomSpinner from '../../../../common/CustomSpinner';
import GoalsProvider from '../../../../contexts/GoalsContext';
import RegistrationsGraph from './RegistrationsGraph';
import GoalsCard from './Goals/GoalsCard';
import { UserContext } from '../../../../contexts/UserContext';
import { UserViewType } from '../../../Settings/enum/users.enum';
import RegistrationFormsSection from '../Registrations/RegistrationFormsSection';
import EventsHeaderBar from '../../../../common/EventsHeaderBar';

const Overview: React.FC<{eventId: number}> = (props: {eventId: number}): React.JSX.Element => 
{
    const eventId = props?.eventId;

    const [eventDetails, spinner, , eventCategory,customFields, integrations] = useEventOverviewData({
        eventId: eventId 
    });

    const {
        userDetails
    } = useContext(UserContext);

    // const handleEditEvent = (): void => 
    // {
    //     eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
    //         heading: CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EDIT.HEADING,
    //         component: <CreateEvent eventData={eventDetails} routeFromId={false} fromOverview={true} customFields={customFields} isLumaIntegrated={isLumaIntegrated} />,
    //         hideCloseButton: true
    //     });

    //     eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
    //         open: true,
    //         width: '500px !important'
    //     });
    // };

    const renderOverview = () => 
    {
        return (
            <>  
                <div className="overview-content">
                    <EventsHeaderBar
                        title='Overview'
                        showBorder={true}
                    />
                    <EventOverviewBar eventDetails={eventDetails} eventCategory={eventCategory} customFields={customFields} integrations={integrations} spinner={spinner} />  
                </div>
                <RegistrationsGraph eventId={eventId} />
                <GoalsProvider eventId={eventId}><GoalsCard /></GoalsProvider>
                <RegistrationFormsSection registrationFormId={String(eventDetails?.registrationFormId)} additionalForms={eventDetails?.additionalForms} eventId={eventId}/>
            </>
        )
    };

    const renderMinimalViewForOverview = () => 
    {
        return (
            <div id="eventOverviewMinimalContainer">
                <div id="overview">
                    {renderOverview()}
                </div>
            </div>
        )
    };

    const renderNormalViewForOverview = () => 
    {
        return (
            <div id="overview">
                {renderOverview()}
            </div>
        )
    };
    
    return (
        <div style={{ backgroundColor: '#FCFCFD', width: '100%' }}>
            {spinner ?
                <CustomSpinner height={'100lvh'} /> :
                userDetails?.viewType === UserViewType.MINIMAL ? renderMinimalViewForOverview() : renderNormalViewForOverview()   
            }
        </div>
    );
};

export default Overview;

