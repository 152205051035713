import React, { useEffect, useState } from "react";
import { EnrichmentComponent } from "../../../../pages/Audience/ViewAudienceInfo";
import { EventRegistrant } from "../../../../pages/Events/interfaces/event-registrant_interface";
import './styles.scss';
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSimilarKey } from "../../../../scripts/helpers";
import { renderCheckBoxComponent, renderDateComponent, renderDropDownComponent, renderInputComponent, renderRadioGroupComponent } from "../../../../pages/Settings/CustomField/CustomTypeComponent";
import { CustomFieldType } from "../../../../pages/Registration/enum/custom-field.enum";
import { getRegistrationFormById } from "../../../../scripts/apis/registrationForm";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../interfaces/create-events_interface";
import { CustomField, CustomFieldOptions } from "../../../../pages/Registration/interfaces/custom-field_interface";
import toast from "react-hot-toast";
import _ from "lodash";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { checkUpdatedRegistrantData } from "../../../../scripts/apis/eventRegistrants";

interface RegistrantDetailedInfoProps {
    registrantData: EventRegistrant;
    eventId?: string | number;
    setRegistrants?: React.Dispatch<React.SetStateAction<EventRegistrant[]>>;
    setSelectedRegistrant?: React.Dispatch<React.SetStateAction<EventRegistrant | null>>;
    handlePopupClose?: () => void;
    source?: 'registrant' | 'agenda';
}

const RegistrantDetailedInfo: React.FC<RegistrantDetailedInfoProps> = (props): React.JSX.Element => {
    const { registrantData, eventId, setRegistrants, setSelectedRegistrant, handlePopupClose, source = 'registrant' } = props;

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => {
        return state.events.value;
    });

    const [accordionExpansion, setAccordionExpansion] = useState<string | boolean>('formAccordian');
    const [formFields, setFormfields] = useState<CustomField[]>([]);
    const [customFormState, setCustomFormState] = useState<{ [key: string]: any }>({});
    const [registrantFormData, setRegistrantFormData] = useState<{ [key: string]: any }>({});

    const handleAccoridon = (propertyId: string) => {
        return (event: React.SyntheticEvent, newExpanded: boolean): void => {
            setAccordionExpansion(newExpanded ? propertyId : false);
        };
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === 'firstName' || name === 'lastName') {
            setCustomFormState((prevState): any => {
                return {
                    ...prevState,
                    [name]: value,
                };
            });
        }
        else {
            setCustomFormState((prevState): any => {
                return {
                    ...prevState,
                    [name]: value,
                };
            });
        }
    };

    const handleDropDownInputChange = (name: string, value: string | number): void => {
        setCustomFormState((prevState): any => {
            const similarKey = getSimilarKey(prevState, name);
            const keyToUpdate = similarKey ? similarKey : name;

            return {
                ...prevState,
                [keyToUpdate]: value,
            };
        });
    };

    const handleBoxChange = (name: string, value: string | number): void => {
        setCustomFormState((prevState): any => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handleCheckboxChange = (item: CustomFieldOptions, data: CustomField): void => {

        if (data?.propertyKey) {
            if (customFormState[data?.propertyKey]) {
                const selectedIndex = customFormState[data?.propertyKey].indexOf(item.name);
                const updatedSelectedOptions = [...customFormState[data?.propertyKey]];

                if (selectedIndex === -1) {
                    updatedSelectedOptions.push(item.name);
                }
                else {
                    updatedSelectedOptions.splice(selectedIndex, 1);
                }

                setCustomFormState((prevState): any => {
                    return {
                        ...prevState,
                        [String(data?.propertyKey)]: updatedSelectedOptions,
                    };
                });
            }
            else {
                setCustomFormState((prevState): any => {
                    return {
                        ...prevState,
                        [String(data?.propertyKey)]: [item.name],
                    };
                });
            }
        }
    };

    const handleSubmit = async (): Promise<void> => {

        const validFormStatePayload = {
            'firstName': customFormState['firstName'],
            'lastName': customFormState['lastName'],
            'email': customFormState['email'],
            additionalData: _.omit(customFormState, ['email', 'firstName', 'lastName']),
            ...(registrantData?.eventTicketId ? { eventTicketId: registrantData?.eventTicketId } : {})
        };

        if (validFormStatePayload) {
            try {
                const updateRegistrantFormData = await checkUpdatedRegistrantData(eventId || '', registrantData.id, validFormStatePayload);
                if (updateRegistrantFormData) {
                    toast.success('Registrant Details updated successfully');
    
                    if (window.location.search?.includes('status') && setRegistrants) {
                        setRegistrants((prevRegistrants) => {
                            const updatedRegistrants = prevRegistrants.filter((registrant) => registrant.id != registrantData.id);
                            return updatedRegistrants;
                        });
                    }
                    else {
                        if(setRegistrants)
                        {
                            setRegistrants((prevRegistrants) => {
                                const updatedRegistrants = prevRegistrants.map((registrant) => {
                                    if (registrant.id == registrantData.id) {
                                        return {
                                            ...registrant,
                                            firstName: updateRegistrantFormData.firstName,
                                            lastName: updateRegistrantFormData.lastName,
                                            additionalData: updateRegistrantFormData.additionalData || {},
                                        };
                                    }
                                    return registrant;
                                });
                                return updatedRegistrants;
                            });
                        }
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    const fetchRegistrationFormData = async (): Promise<void> => {
        try {
            const regFormData = await getRegistrationFormById(Number(eventReduxData?.['registrationFormId']));
            if (regFormData?.formFields && regFormData?.formFields?.length > 0) {
                setFormfields(regFormData?.formFields);
            }
        }
        catch (error) {
            console.log(error);
            toast.error(_.startCase((error as Error).message.toLowerCase()));
        }
    };

    useEffect(() => {
        if(eventId && eventReduxData?.['registrationFormId'])
        {
            fetchRegistrationFormData();
        }

        if (registrantData) {
            let x = {
            };
            registrantData?.additionalData ? Object?.keys(registrantData?.additionalData).map((key): void => {
                x = {
                    ...x,
                    [key]: registrantData.additionalData[key]
                };
            }) : [];

            setCustomFormState({
                ['firstName']: registrantData['firstName'],
                ['lastName']: registrantData['lastName'],
                ['email']: registrantData['email'],
                ...x,
            });
            setRegistrantFormData({
                ['firstName']: registrantData['firstName'],
                ['lastName']: registrantData['lastName'],
                ['email']: registrantData['email'],
                ...x,
            });
        }
    }, [registrantData]);

    return (
        <div id="registrantDetailedInfo">
            <div className="registrant-detailed-info-container">
                <div className="registrant-info-accordian-container">
                    {/* Registrant form container */}
                    {
                        customFormState && formFields && formFields?.length > 0 &&
                        <div className="form-fields-accordian-container">
                            <Accordion expanded={accordionExpansion === 'formAccordian'} id={'formAccordian'} className="form-accordian" onChange={handleAccoridon('formAccordian')}>
                                <AccordionSummary
                                    className="form-accordian-summary"
                                    aria-controls="panel1a-content"
                                    id="formAccordian-header"
                                    style={{
                                        borderRadius: accordionExpansion === 'formAccordian' ? '8px 8px 0 0' : '8px',
                                    }}
                                    expandIcon={<FontAwesomeIcon className="dropdown-icon" icon={['fal', accordionExpansion === 'formAccordian' ? 'chevron-up' : 'chevron-right']} />}
                                >
                                    <div className="heading-container">
                                        <h3 className="accordian-title">{'Form Details'}</h3>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className="form-accordian-details">
                                    <div className="custom-field-container">
                                        {formFields && formFields.length > 0 && formFields.map((item) => {
                                            const similarKey = getSimilarKey(customFormState, item?.default || item?.propertyKey || '');

                                            if (item?.type === CustomFieldType.NUMBER) {
                                                return renderInputComponent(item, 'number', handleInputChange, customFormState?.[similarKey ?? ''], source !== 'registrant');
                                            }
                                            else if (item?.type === CustomFieldType.SINGLE_LINE) {
                                                return renderInputComponent(item, 'text', handleInputChange, customFormState?.[similarKey ?? ''], (item?.default === 'email' || source !== 'registrant'));
                                            }
                                            else if (item?.type === CustomFieldType.MULTI_LINE) {
                                                return renderInputComponent(item, 'textarea', handleInputChange, customFormState?.[similarKey ?? ''], source !== 'registrant');
                                            }
                                            else if (item?.type === CustomFieldType.CHECKBOXES) {
                                                return renderCheckBoxComponent(item, handleCheckboxChange, customFormState?.[similarKey ?? ''], source !== 'registrant');
                                            }
                                            else if (item?.type === CustomFieldType.DROPDOWN) {
                                                return renderDropDownComponent(item, handleDropDownInputChange, customFormState?.[similarKey ?? ''], source !== 'registrant');
                                            }
                                            else if (item?.type === CustomFieldType.RADIO) {
                                                return renderRadioGroupComponent(item, handleBoxChange, customFormState?.[similarKey ?? ''], source !== 'registrant');
                                            }
                                            else if (item?.type === CustomFieldType.DATE) {
                                                return renderDateComponent(item, handleInputChange, customFormState?.[similarKey ?? ''], source !== 'registrant');
                                            }
                                        })}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    }

                    <div className="clearbit-accordian-container">
                        {props?.registrantData?.clearbitData && <div className="registrant-enrichment-container"><EnrichmentComponent fromRegistrant={true} accordionExpansion={accordionExpansion} handleAccoridon={handleAccoridon} clearbitData={registrantData?.clearbitData} /></div>}
                    </div>
                </div>
                {
                    !_.isEqual(registrantFormData, customFormState) && source === 'registrant' &&
                    <div className="submission-container">
                        <CustomButton
                            name="Cancel"
                            btnType="secondary"
                            onClick={handlePopupClose}
                        />
                        <CustomButton
                            name="Save"
                            btnType="primary"
                            onClick={() => handleSubmit()}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default RegistrantDetailedInfo;