import { useEffect, useState } from 'react';
import { AutocompletewithTags, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { updateEvent } from '../../../scripts/apis/events';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { IBuyerPersona } from '../../../pages/Settings/BuyerPersona/interfaces';
import BuyerPersona from '../../BuyerPersona/AddBuyerPersona';
import { buyerPersonaCount, getAllBuyerPersona } from '../../../scripts/apis/buyerPersona';
import _ from 'lodash';
import { Event } from '../../../pages/Events/interfaces';

import './styles.scss';

interface IEventBuyerPersonaSettingsProps {
    eventDetails: Event;
    setRefreshEventData: React.Dispatch<React.SetStateAction<boolean>>;
}

const EventBuyerPersonaSettings: React.FC<IEventBuyerPersonaSettingsProps> = ({ eventDetails, setRefreshEventData }): React.JSX.Element =>
{
    const { eventId } = useParams();

    const [buyerPersonaNames, setBuyerPersonaNames] = useState<string[]>([]);
    const [buyerPersonaIds, setBuyerPersonaIds] = useState<string[]>(eventDetails?.buyerPersonas || []);
    const [buyerPersonaDetails, setBuyerPersonaDetails] = useState<IBuyerPersona[]>([]);

    const handleBuyerPersonaChange = (event: any, newValue: string[]): void =>
    {
        setBuyerPersonaNames(newValue);
        const ids = buyerPersonaDetails.filter((buyerPersona: IBuyerPersona) => newValue.includes(buyerPersona.name)).map((buyerPersona: IBuyerPersona) => buyerPersona.id);
        if(ids)
        {
            setBuyerPersonaIds(ids);
        }
    };

    const handleRemoveBp = (index: number): void =>
    {
        const newBpNames = buyerPersonaNames.filter((name: string, i: number) => i !== index);
        setBuyerPersonaNames(newBpNames);
        const ids = buyerPersonaDetails.filter((buyerPersona: IBuyerPersona) => newBpNames.includes(buyerPersona.name)).map((buyerPersona: IBuyerPersona) => buyerPersona.id);
        if(ids)
        {
            setBuyerPersonaIds(ids);
        }
    }

    const onBpCreate = (buyerPersonaData: IBuyerPersona): void => {
        const updatedBuyerPersonaDetails = [buyerPersonaData, ...buyerPersonaDetails];
        const updatedBuyerPersonaNames = [...buyerPersonaNames, buyerPersonaData.name];
    
        setBuyerPersonaDetails(updatedBuyerPersonaDetails);
        setBuyerPersonaNames(updatedBuyerPersonaNames);
    
        const ids = updatedBuyerPersonaDetails
            .filter((buyerPersona: IBuyerPersona) => updatedBuyerPersonaNames.includes(buyerPersona.name))
            .map((buyerPersona: IBuyerPersona) => buyerPersona.id);
    
        if (ids.length > 0) {
            setBuyerPersonaIds(ids);
        }
    };

    const handleBuyerPersonaSidebarOpen = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Create Buyer Persona',
            component: <BuyerPersona eventBP onBPCreate={onBpCreate} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const handleSubmit = async (): Promise<void> =>
    {
        try 
        {
            const buyerPersonaIds = buyerPersonaDetails.filter((buyerPersona: IBuyerPersona) => buyerPersonaNames.includes(buyerPersona.name)).map((buyerPersona: IBuyerPersona) => buyerPersona.id);
            const data = {
                buyerPersonas: buyerPersonaIds?.length > 0 ? buyerPersonaIds : null
            }

            const bpDataSaved = await updateEvent(eventId as string, data);
            if(bpDataSaved && setRefreshEventData)
            {
                toast.success('Buyer Persona Mapping saved successfully');
                setRefreshEventData(true);
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }
    };

    const handleCancelClick = (): void =>
    {
        setBuyerPersonaNames(eventDetails?.buyerPersonas?.map((buyerPersona: string) => buyerPersonaDetails.find((buyerPersonaData: IBuyerPersona) => buyerPersonaData.id === buyerPersona)?.name).filter((name): name is string => name !== undefined) || []);
        setBuyerPersonaIds(eventDetails?.buyerPersonas || []);
    };

    const fetchBuyerPersona = async (): Promise<void> =>
    {
        try 
        {
            const count = await buyerPersonaCount();
            if(count)
            {
                const buyerPersonaDetails = await getAllBuyerPersona(count, 0);
                if(buyerPersonaDetails)
                {
                    setBuyerPersonaDetails(buyerPersonaDetails);
                }
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() =>
    {
        fetchBuyerPersona();
    }, []);

    useEffect(() =>
    {
        if(eventDetails && eventDetails?.buyerPersonas && eventDetails?.buyerPersonas?.length > 0)
        {
            setBuyerPersonaIds(eventDetails?.buyerPersonas);
            const names = buyerPersonaDetails.filter((buyerPersona: IBuyerPersona) => eventDetails?.buyerPersonas && eventDetails?.buyerPersonas.includes(buyerPersona.id)).map((buyerPersona: IBuyerPersona) => buyerPersona.name);
            if(names)
            {
                setBuyerPersonaNames(names);
            }
        }
    }, [eventDetails]);

    return (
        <div id="eventBpSettings">
            <div className="event-bp-settings-container">
                <div className="title-description-container">
                    <h3 className="title">{'Buyer Persona Mapping'}</h3>
                    <p className="description">{''}</p>
                </div>
                <div className="bp-mapping-container">
                    <div className="selection-container">
                        <FormLabelComponent label="Select Buyer Persona" />
                        <AutocompletewithTags 
                            value={buyerPersonaNames}
                            onChange={handleBuyerPersonaChange}
                            options={buyerPersonaDetails.map((buyerPersona: IBuyerPersona) => buyerPersona.name)}
                            placeholder="Select Buyer Persona"
                            onRemoveClick={(index: number) => handleRemoveBp(index)}
                        />
                    </div>
                    <div className="button-container">
                        {
                            eventDetails?.buyerPersonas && !(_.isEqual(buyerPersonaIds, eventDetails?.buyerPersonas)) &&
                            <>
                                <CustomButton
                                    name="Cancel"
                                    onClick={handleCancelClick}
                                    btnType="secondary"
                                />
                                <CustomButton 
                                    btnType="primary"
                                    name="Save"
                                    onClick={handleSubmit}
                                />
                            </>
                        }
                        <CustomButton 
                            btnType="secondary"
                            name="+ Create Buyer Persona"
                            onClick={handleBuyerPersonaSidebarOpen}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventBuyerPersonaSettings;