import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import { CONTENT } from '../../../../scripts/i18n';
import { eventOverviewBarProps } from './eventOverview_interface';
import CardComponent from '../../../../components/Events/Registrations/CardComponent';
import { getEventRegistrantCount, getEventRegistrantStatusCount } from '../../../../scripts/apis/eventRegistrants';
import { getEventBudgetGraph } from '../../../../scripts/apis/eventBudget';
import { UserContext } from '../../../../contexts/UserContext';
import { UserViewType } from '../../../Settings/enum/users.enum';
import { IntegrationTypes } from '../../../Settings/enum/integrations.enum';
import PopupDialog from '../../../../common/PopupDialog';
import EditEventPopup from '../../../../components/Events/EventComponents/EditEventPopup';
import { EditKey } from '../../enum';
import Countdown from "react-countdown";

const EventOverviewBar: React.FC<eventOverviewBarProps> = (props: eventOverviewBarProps): React.JSX.Element => 
{
    const {
        userDetails
    } = useContext(UserContext);

    const [showEditEventPopup, setShowEditEventPopup] = useState<boolean>(false);
    const [editKey, setEditKey] = useState<EditKey>(EditKey.ALL);
    
    const [counts, setCounts] = useState<{
        registrants: number | string;
        checkIns: number | string;
        budget: number | string;
        expenses: number | string;
    }>({
        registrants: '-',
        checkIns: '-',
        budget: '-',
        expenses: '-'
    });

    const handleEditEventPopupClose = (): void => {
        setShowEditEventPopup(false);
        setEditKey(EditKey.ALL);
    };

    const formatEventTiming = (eventStartDateTime: number, eventEndDateTime: number, timezone?: string): string =>
    {
        // const tz = timezone || moment.tz.guess();
        const startDate = moment.unix(eventStartDateTime);
        const endDate = moment.unix(eventEndDateTime);
        const sameMonthYear = startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year');
        const isSameDay = startDate.isSame(endDate, 'day');
        const formattedDate =
            isSameDay ? `${startDate.format('hh:mm A')} - ${endDate.format('hh:mm A, DD MMM')}` : 
            sameMonthYear
            ? `${startDate.format('DD')}-${endDate.format('DD MMM YYYY')}`
            : `${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')}`;
    
        return formattedDate;
    };

    const isLumaIntegrated = props?.integrations?.some((integration): boolean => {
        return integration?.type === IntegrationTypes.LUMA;
    });

    const handleEditEvent = (key: EditKey): void => 
    {
        handleEditEventPopupClose();
        // when clicking on the event URL, navigate to the event settings page
        // if(key == EditKey.EVENT_URL)
        // {
        //     navigate('/events/' + props?.eventDetails?.id + '/settings');
        //     return;
        // }
        setEditKey(key);
        setShowEditEventPopup(true);
    };

    const countdownRenderer = ({ days, hours, minutes, seconds, completed }: { days: number; hours: number; minutes: number; seconds: number; completed: boolean }): React.ReactElement | string => { 
        if (completed) {
            return '-';
        } else {
            return (
                <div className="countdown-timer">
                    <div className="countdown-item">
                        <div className="countdown-value">{days}</div>
                        <div className="countdown-label">D</div>
                    </div>
                    <div className="countdown-item">
                        <div className="countdown-value">{hours}</div>
                        <div className="countdown-label">H</div>
                    </div>
                    <div className="countdown-item">
                        <div className="countdown-value">{minutes}</div>
                        <div className="countdown-label">M</div>
                    </div>
                    <div className="countdown-item">
                        <div className="countdown-value">{seconds}</div>
                        <div className="countdown-label">S</div>
                    </div>
                </div>
            );
        }
    };

    const eventDetails: {name: string; value: string | number | React.ReactNode; key: EditKey}[] = [
        {
            name: 'Time',
            value: formatEventTiming(props?.eventDetails?.eventStartDateTime, props?.eventDetails?.eventEndDateTime, props?.eventDetails?.timezone),
            key: EditKey.DATE_AND_TIME
        },
        {
            name: 'Format',
            value:  CONTENT.EVENTS_PAGE.EVENT_LOCATION_CHIP_LABEL[props?.eventDetails?.locationType],
            key: EditKey.EVENT_FORMAT,
        },
        {
            name: 'Type',
            value: CONTENT.EVENTS_PAGE.EVENT_TYPE_CHIP_LABEL[props?.eventDetails?.type as keyof typeof CONTENT.EVENTS_PAGE.EVENT_TYPE_CHIP_LABEL],
            key: EditKey.TYPE
        },
        {
            name: 'Starts in',
            value: <Countdown date={moment.unix(Number(props?.eventDetails?.eventStartDateTime)).tz(props?.eventDetails?.timezone as string).toDate()} renderer={countdownRenderer} />,
            key: EditKey.DATE_AND_TIME
        },
        {
            name: 'Category',
            value: props?.eventCategory || '-',
            key: EditKey.CATEGORY
        },
        {
            name: 'Event URL',
            value: props?.eventDetails?.link || '-',
            key: EditKey.EVENT_URL
        }
    ];

    const fetchRegistrantCount = async (): Promise<void> =>
    {
        try 
        {
            const registrantsCount = await getEventRegistrantCount(props?.eventDetails?.id);
            if(registrantsCount)
            {
                setCounts((prevState) => ({ 
                    ...prevState,
                    registrants: registrantsCount
                }));
            }

            const statusCounts = await getEventRegistrantStatusCount(props?.eventDetails?.id);
            if(statusCounts && statusCounts.hasOwnProperty('7'))
            {
                setCounts((prevState) => ({ 
                    ...prevState,
                    checkIns: statusCounts['8']
                }));
            }

            const eventBudgetData = await getEventBudgetGraph(props?.eventDetails?.id);
            if(eventBudgetData && eventBudgetData?.trend?.spent)
            {
                setCounts((prevState) => ({ 
                    ...prevState,
                    budget: eventBudgetData?.trend?.budget || 0,
                    expenses: eventBudgetData?.trend?.spent || 0,
                }));
            }
        } 
        catch (error) 
        {
            console.log(`Error fetching registrant count: ${error}`);
        }
    };

    useEffect(() => {
        fetchRegistrantCount();
    }, []);

    return (
        <Box className={userDetails?.viewType === UserViewType.NORMAL ? "event-overview-bar" : "event-overview-bar-minimal"}>
            <Grid container spacing={2}>
                {
                    eventDetails?.map((item, index) => {
                        if (index < 4) {
                            return (
                                <Grid key={index} item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <CardComponent 
                                        header={item.name}
                                        contentHeading={<p style={{ marginTop: '4px' }}>{item.value}</p>}
                                        contentHeadingBold
                                        cardMinHeight='80px'
                                        onClick={() => handleEditEvent(item.key)}
                                    />
                                </Grid>
                            )
                        } else {
                            return (
                                <Grid key={index} item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <CardComponent 
                                        header={item.name}
                                        contentHeading={<p style={{ marginTop: '4px' }}>{item.value}</p>}
                                        contentHeadingBold
                                        cardMinHeight='80px'
                                        onClick={() => handleEditEvent(item.key)}
                                    />
                                </Grid>
                            )
                        }
                    })
                }
            </Grid>
            {
                showEditEventPopup && (
                    <PopupDialog
                        modalId="editEventPopup"
                        show={showEditEventPopup}
                        cancelClick={handleEditEventPopupClose}
                        modalContent={
                            <EditEventPopup eventData={props?.eventDetails} handleEditPopupClose={handleEditEventPopupClose} routeFromId={false} fromOverview={true} customFields={props?.customFields} isLumaIntegrated={isLumaIntegrated} editKey={editKey} handleEditEvent={handleEditEvent} />
                        }
                    />
                )
            }
        </Box>
    );
};

export default EventOverviewBar;