import React, { useContext } from "react";
import CreateEventTargetList from "./Components/CreateEventTargetList";
import { EventTargetListContext } from "../../../../contexts/EventTargetList/EventTargetListContext";
import TargetListByPastEventsPage from "./Pages/TargetListByPastEventsPage";
import { EventTargetListSourceType } from "../../enum/event-target-list.enum";
import TargetListByAudiencePage from "./Pages/TargetListByAudiencePage";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { useSelector } from "react-redux";
import { EventTargetList, IEventTargetListDispatch, IEventTargetListReduxData } from "../../interfaces/event-target-list-interface";
import { creatTargetListByEventId, updateEventTargetList } from "../../../../scripts/apis/eventTargetList";
import { useDispatch } from "react-redux";
import { resetTargetList } from "../../../../redux/events/eventTargetList/targetList";
import _ from "lodash";

const CreateEventTargetListPage : React.FC = (): JSX.Element =>
{
    const {eventId, listId, existingTargetList, showCreate } = useContext(EventTargetListContext);
    const eventTargetListReduxData = useSelector(
        (state: IEventTargetListDispatch): IEventTargetListReduxData => {
            return state.eventTargetList.value;
        }
    );
    const csrfTokenData = useSelector((state: any): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDrawerClose = (_event: any): void =>
    {
        navigate(`/events/${eventId}/target-list`);
    }

    const source = eventTargetListReduxData?.source[0];
    const listName = eventTargetListReduxData?.name;

    const renderSourceJsx = (sourceType: EventTargetListSourceType | undefined) => {
        switch (sourceType) 
        {
            case EventTargetListSourceType.PAST_EVENT:
                return <TargetListByPastEventsPage />;
            case EventTargetListSourceType.APOLLO:
                return <div> Apollo Component</div>;
            case EventTargetListSourceType.HUBSPOT:
                return <div> HubSpot Component</div>;
            case EventTargetListSourceType.AUDIENCE:
                return <TargetListByAudiencePage />;
            default:
                return <CreateEventTargetList />;
        }
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        event.preventDefault();
        const eventId = eventTargetListReduxData.eventId;
    
        const mergedAudienceIds = _.union(
            existingTargetList?.audienceIds || [],
            eventTargetListReduxData?.audienceIds || []
        );

        const mergedTargetListAudienceIds = _.union(
            existingTargetList?.targetListAudienceIds || [],
            eventTargetListReduxData?.targetListAudienceIds || []
        );

        const mergedSources = _.union(
            [EventTargetListSourceType.AUDIENCE],
            existingTargetList?.source || []
        );
    
        const eventTargetList: EventTargetList = {
            name: eventTargetListReduxData.name,
            description: eventTargetListReduxData.description,
            type: eventTargetListReduxData.type,
            source: mergedSources,
            audienceIds: [], // Placeholder, will be sent in batches
            targetListAudienceIds: mergedTargetListAudienceIds,
        };
    
        try 
        {
            // Chunk audienceIds into batches of 100 using Lodash
            const audienceIdBatches = _.chunk(mergedAudienceIds, 100);
    
            let createdListId = listId;
    
            for (const batch of audienceIdBatches) {
                const payload = {
                    ...eventTargetList,
                    audienceIds: batch, // Send only the current batch
                };
    
                if (createdListId) {
                    await updateEventTargetList(eventId, createdListId, payload);
                } else {
                    const result = await creatTargetListByEventId(eventId, payload, csrfTokenData);
                    createdListId = String(result?.id);
                }
            }
    
            navigate(`/events/${eventId}/target-list/${createdListId}`);
            dispatch(resetTargetList());
        } catch (error) {
            console.error("Error submitting event target list:", error);
            throw error;
        }
    };


    return (
        <>
            {showCreate ? (
                <CreateEventTargetList />
            ) : (
                <div id="createTargetListPageWrapper">
                    <div className="header-container">
                        <CustomButton name={"Cancel"} btnType={"secondary"} onClick={handleDrawerClose} />
                        {/* In fututre if we add save button use the below button */}
                        {/* <CustomButton name={ 'Save' } btnType={ 'secondary' } style={ { border: 'unset' } } onClick={ handleDrawerClose } />  */}
                        <div className={`header-text ${listName ? "header-text-with-title" : ""}`}>
                            {listName ? listName : "List Name"}{" "}
                        </div>
                        {source === EventTargetListSourceType.AUDIENCE && (
                            <CustomButton 
                                name={'Finish'} 
                                btnType={"primary"} 
                                onClick={handleSubmit} 
                                disabled={eventTargetListReduxData?.audienceIds?.length === 0}
                            />
                        )}
                    </div>
                    {renderSourceJsx(source)}
                </div>
            )}
        </>
    );
};

export default CreateEventTargetListPage;
