import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { getTimeZones } from '@vvo/tzdb';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { updateEventRegistrationDetails } from '../../../scripts/apis/events';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { addEvent } from '../../../redux/events/Events';
import { Event } from '../../../pages/Events/interfaces';
import { FormControlComponent, FormLabelComponent, ToggleButtonGroupComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import './styles.scss';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import DateTimePicker from '../../../common/FormComponents/DateTimePicker';
import { combineDateTime } from '../../../scripts/helpers';
import toast from 'react-hot-toast';
import { RegistrationDetailsKey } from '../../../pages/Events/enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CreateRegistrationDetailsProps {
    eventDetails: Event;
    handlePopupClose: () => void;
    editRegistrationKey: RegistrationDetailsKey;
}


const getHeaderContent = (key: RegistrationDetailsKey): { icon: any; heading: string; description: string } => {
    switch (key) {
        case RegistrationDetailsKey.MAXIMUM_REGISTRATIONS:
            return { icon: ['fal', 'ticket-simple'], heading: 'Maximum Registrations', description: 'Modify the maximum registrations.' };
        case RegistrationDetailsKey.ENABLE_WAITLIST:
            return { icon: ['fal', 'user-clock'], heading: 'Event Waitlist', description: 'Update the event approval status.' };
        case RegistrationDetailsKey.REGISTRATION_CLOSING_TIME:
            return { icon: ['fal', 'clock'], heading: 'Registration Closing', description: 'Modify the date and time settings.' };
        case RegistrationDetailsKey.POST_SUBMISSION:
            return { icon: ['fal', 'user-check'], heading: 'Post Submission', description: 'This will be displayed after registration is successful.' };
        default:
            return { icon: ['fal', 'edit'], heading: 'Edit Registration', description: 'Modify the event Registrations.' };
    }
};

const CreateRegistrationDetails: React.FC<CreateRegistrationDetailsProps> = (props): React.JSX.Element => 
{
    const { eventDetails, handlePopupClose, editRegistrationKey } = props;
    const dispatch = useDispatch();
    const [enableWaitlist, setEnableWaitList] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const timeZones = getTimeZones();
    const timezoneOptions = [];
    const timezoneListArr = (): void => 
    {
        timeZones.map((item): void => 
        {
            const utcSplitted = item.rawFormat.split(' ')[0];
            timezoneOptions.push({
                name: 'GMT' + utcSplitted + ' ' + item.countryName,
                value: item.name,
            });
        });
    };

    const postSubmissionOptions = [
        {  
            name: 'Success Message', value: 'message',  
        },
        {
            name: 'Redirect to another page', value: 'redirectionUrl',
        }
    ];

    const { icon, heading, description } = getHeaderContent(editRegistrationKey);

    timezoneListArr();

    const validationSchema = Yup.object().shape({
        maximumRegistration: Yup.number().nullable(),
        waitlistSuccessMessage: Yup.string(),
        registrationClosingDateTimezone: Yup.string(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            maximumRegistration: eventDetails.maximumRegistration ? eventDetails.maximumRegistration : null,
            closingDate: eventDetails.closingDateTime ? moment.unix(Number(eventDetails.closingDateTime)).tz(eventDetails?.timezone as string).format('DD/MM/YYYY') : null,
            closingTime: eventDetails.closingDateTime ? moment.unix(Number(eventDetails.closingDateTime)).tz(eventDetails?.timezone as string).format('HH:mm') : null,      
            message: eventDetails?.registerOptions?.message ? eventDetails?.registerOptions.message : '',
            redirectionUrl: eventDetails?.registerOptions?.redirectionUrl ? eventDetails?.registerOptions.redirectionUrl : '',
            submissionType: eventDetails?.registerOptions?.redirectionUrl ? 'redirectionUrl' : 'message',
        },
        onSubmit: async (values): Promise<void> => 
        {

            const closingDateTime = combineDateTime(values.closingDate as string, values.closingTime as string);
            if (Number(closingDateTime) > Number(eventDetails.eventEndDateTime))
            {
                toast.error('Registration closing date & time should not beyond event end date');
                return;
            }

            const details : Event = {
                maximumRegistration: values.maximumRegistration || 0,
                closingDateTime: closingDateTime,
                enableWaitlistStatus: enableWaitlist ? 1 : 2,
                enableAuthenticationStatus: eventDetails.enableAuthenticationStatus,
                registerOptions: formik.values.submissionType === 'message' ? {
                    message: values.message,
                    redirectionUrl: null,
                } : {
                    message: null,
                    redirectionUrl: values.redirectionUrl,
                },
            };

            const isUpdateAvailable = (eventDetails.maximumRegistration !== formik.values.maximumRegistration || Number(eventDetails.closingDateTime) !== Number(closingDateTime) || eventDetails.registerOptions.message !== formik.values.message || eventDetails.registerOptions.redirectionUrl !== formik.values.redirectionUrl
            );

            if (isUpdateAvailable) 
            {
                try 
                {
                    setSpinner(true);
                    const detailsUpdated = await updateEventRegistrationDetails(Number(eventDetails.id), details);
                    if (detailsUpdated) 
                    {
                        dispatch(addEvent({
                            closingDateTime: detailsUpdated.closingDateTime,
                            registerOptions: detailsUpdated.registerOptions,
                            enableWaitlistStatus: detailsUpdated.enableWaitlistStatus
                        }));

                        toast.success('Registration Details Updated Successfully');
                        setSpinner(false);
                        handleDrawerClose();
                        eventBus.dispatch('event-details-refreshed', {
                            refresh: true 
                        });
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setSpinner(false);
                    toast.error((error as Error)?.message || 'Error in updating registration details');
                }
            }

        }
    });

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    useEffect((): void => 
    {  
        if(String(props?.eventDetails?.enableWaitlistStatus) === '1') 
        {
            setEnableWaitList(true);
        }
    }, []);

    const renderMaximumRegistrations = (): React.JSX.Element => (
        <Box className="popup-container">
            <div>
                <FormLabelComponent label="Maximum Registrations" />
                <FormControlComponent
                    type="number"
                    value={formik.values.maximumRegistration}
                    onChange={(event): void => {
                        formik.setFieldValue('maximumRegistration', event.target.value);
                        if (formik.values?.maximumRegistration === 0 || formik.values?.maximumRegistration === '') {
                            setEnableWaitList(false);
                        }
                    }}
                    onKeyDown={(evt): void => {
                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
                    }}
                    placeholder="Unlimited"
                />
                {formik.errors.maximumRegistration && formik.touched.maximumRegistration ? (
                    <Typography className="error-msg">{formik.errors.maximumRegistration}</Typography>
                ) : null}
            </div>
        </Box>
    );

    const renderEnableWaitlist = (): React.JSX.Element => (
        <Box className="popup-container">
            <Box className="approval-container">
                <FormLabelComponent label="Enable Waitlist" noBottomMargin />
                <FormControlLabel
                    control={
                        <CustomSwitch
                            checked={
                                formik.values?.maximumRegistration === null ||
                                formik.values?.maximumRegistration === 0 ||
                                formik.values?.maximumRegistration === ''
                                    ? false
                                    : enableWaitlist
                            }
                            onChange={(): void => {
                                setEnableWaitList(!enableWaitlist);
                            }}
                            name="waitList"
                        />
                    }
                    label={null}
                />
            </Box>
        </Box>
    );

    const renderRegistrationClosingTime = (): React.JSX.Element => (
        <Box className="popup-container">
            <div>
                <FormLabelComponent label="Registration Closing" required />
                <DateTimePicker
                    dateValue={moment(formik.values.closingDate, 'DD/MM/YYYY').unix()}
                    timeValue={formik.values.closingTime as string}
                    onDateChange={(newValue): void => {
                        formik.setFieldValue('closingDate', newValue);
                    }}
                    onTimeChange={(newValue): void => {
                        formik.setFieldValue('closingTime', newValue);
                    }}
                    maxDate={Number(eventDetails?.eventEndDateTime)}
                    dateFormat="dddd, MMMM D, YYYY"
                />
                {formik.errors.closingTime && formik.touched.closingTime ? (
                    <Typography className="error-msg">{formik.errors.closingTime}</Typography>
                ) : null}
            </div>
        </Box>
    );

    const renderPostSubmission = (): React.JSX.Element => (
        <Box className="popup-container">
            <div>
                <FormLabelComponent label="Upon successful submission" required />
                <ToggleButtonGroupComponent value={formik.values.submissionType} options={postSubmissionOptions} onChange={(event, value): void => {
                    console.log('value', value);
                    formik.setFieldValue('submissionType', value);
                }} />

                <div className='popup-container-spacing pt-8'>
                    {
                        formik.values.submissionType === 'message' ? (
                            <FormControlComponent
                                value={formik.values.message}
                                onChange={(event): void => {
                                    formik.setFieldValue('message', event.target.value);
                                }}
                                type="text"
                                placeholder="Description"
                            />
                        ) : (
                            <FormControlComponent
                                value={formik.values.redirectionUrl}
                                onChange={(event): void => {
                                    formik.setFieldValue('redirectionUrl', event.target.value);
                                }}
                                type="text"
                                placeholder="URL"
                            />
                        )
                    }
                </div>
            </div>
        </Box>
    );

    const renderHeader = (): React.JSX.Element => {
            return (
                <div className='popup-header'>
                    <div className='popup-header-content'>
                        <div className='popup-header-icon'><FontAwesomeIcon icon={icon} /></div>
                        <div className='popup-header-text'>
                            <h3>{heading}</h3>
                            <p>{description}</p>
                        </div>
                    </div>
                    <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => handlePopupClose()} />
                </div>
            )
        }


    const renderMainContent = (): React.JSX.Element => {
        switch (editRegistrationKey) {
            case RegistrationDetailsKey.MAXIMUM_REGISTRATIONS:
                return renderMaximumRegistrations();
            case RegistrationDetailsKey.ENABLE_WAITLIST:
                return renderEnableWaitlist();
            case RegistrationDetailsKey.REGISTRATION_CLOSING_TIME:
                return renderRegistrationClosingTime();
            case RegistrationDetailsKey.POST_SUBMISSION:
                return renderPostSubmission();
            default:
                return (
                    <>
                        {renderMaximumRegistrations()}
                        {renderEnableWaitlist()}
                        {renderRegistrationClosingTime()}
                        {renderPostSubmission()}
                    </>
                );
        }
    };

    const renderFooter = (): React.JSX.Element => (
        <Box className="popup-footer">
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <CustomButton btnType="secondary" name="Cancel" onClick={handlePopupClose} />
                <CustomButton
                    loading={spinner}
                    btnType="primary"
                    name="Save"
                    onClick={(): void => {
                        formik.handleSubmit();
                    }}
                />
            </Stack>
        </Box>
    );

    return (
        <Box id="createRegDetails">
            {renderHeader()}
            <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                {renderMainContent()}
                {renderFooter()}
            </Form>
        </Box>
    );
};

export default CreateRegistrationDetails;