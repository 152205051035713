import React, { useContext, useEffect, useState } from 'react'
import TableEmptyComponent from '../../../../../common/TableEmptyComponent';
import TablePopup from '../../../../../common/TablePopup/TablePopup';
import TablePopupDetails from '../../../../../common/TablePopup/TablePopupDetails';
import { UserContext } from '../../../../../contexts/UserContext';
import { createColumnHelper } from '@tanstack/react-table';
import { buildInitialsForPicture, camelCaseToWords } from '../../../../../scripts/helpers';
import { Avatar } from '@mui/material';
import CustomTooltip from '../../../../../common/Tooltip';
import MuiChip from '../../../../../common/FormComponents/MuiChip';
import { useTablePagination } from '../../../../../contexts/TablePaginationContext';
import RegistrantDetailedInfo from '../../../../../components/Events/EventRegistrants/RegistrantDetails/RegistrantDetailedInfo';
import { EventRegistrant, EventRegistrantFeedback } from '../../../interfaces/event-registrant_interface';
import { getAllEventFeedbacks, getAllEventFeedbacksCount } from '../../../../../scripts/apis/events';
import { IMuiChipProps } from '../../../../../common/FormComponents/IReusableFormComponents';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface NpsAnalyticsProps {
    eventId: string | number;
}

const NpsAnalytics: React.FC<NpsAnalyticsProps> = (props): React.JSX.Element => {

    const { userDetails } = useContext(UserContext);

    const { eventId } = props;
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [rows, setRows] = useState<EventRegistrant[]>([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedNpsRegistrant, setSelectedNpsRegistrant] = useState<EventRegistrant | null>(null);
    const [npsRegistrantCount, setNpsRegistrantCount] = useState<number>(0);
    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const registrantsBasedOnRating = () =>
    {
        const rating = selectedNpsRegistrant?.feedbackData?.formData['rateYourExperience'];
        if (rating) {
            const label = Number(rating) >= 9 ? `Promoter - ${rating}` : Number(rating) >= 7 ? `Passive - ${rating}` : `Detractor - ${rating}`;
            return [label];
        }
        return [];
    }

    const registrantsBasedOnRatingColor = (): IMuiChipProps['chipColor'] =>
    {
        const rating = selectedNpsRegistrant?.feedbackData?.formData['rateYourExperience'];
        if(rating)
        {
            return Number(rating) >= 9 ? 'green' : Number(rating) >= 7 ? 'yellow' : 'red';
        }
        return 'blue';
    }
    
    const handlePopupClose = (): void => 
    {
        setSelectedNpsRegistrant(null);
        setDrawerOpen(false);
    };

    const handleNext = (): void => 
    {
        const index = rows.findIndex((row) => row.id === selectedNpsRegistrant?.id);
        if(index < rows.length - 1)
        {
            setSelectedNpsRegistrant(rows[index + 1]);
        }
    }

    const handlePrevious = (): void => 
    {
        const index = rows.findIndex((row) => row.id === selectedNpsRegistrant?.id);
        if(index > 0)
        {
            setSelectedNpsRegistrant(rows[index - 1]);
        }   
    }

    const handleMinimalViewRowClick = (row: EventRegistrant): void => 
    {
        setDrawerOpen(true);
        setSelectedNpsRegistrant(row);
    };

    const EventFeedbackResponses: React.FC<{ feedbackData?: EventRegistrantFeedback }> = ({ feedbackData }): React.ReactElement =>
    {
        return (
            <ol className="event-feedbacks">
                {
                    feedbackData && Object.keys(feedbackData?.formData).map((item, index) => 
                    {
                        return (
                            <div className="response-item" key={index}>
                                <p className="response-index">{`${index+1}`}</p>
                                <div className="response-item-content">
                                    <p className="response-item-title">{camelCaseToWords(item)}</p>
                                    <p className="response-item-content">{feedbackData?.formData[item] || '-'}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </ol>
        )
    }

    const columnHelper = createColumnHelper<EventRegistrant>();
    const minimalColumns = [
        columnHelper.accessor('companyLogo' as any, {
            cell: (row) => {
                const clearbitFName = row?.row?.original?.clearbitData?.givenName;
                const clearbitLName = row?.row?.original?.clearbitData?.familyName;
                const name = clearbitFName && clearbitLName ? buildInitialsForPicture('', clearbitFName, clearbitLName) : row?.row?.original?.firstName ? buildInitialsForPicture('', row?.row?.original?.firstName, '') : buildInitialsForPicture('', row?.row?.original?.firstName, '');

                return (
                    <Avatar className="logo-avatar-img" src={row?.row?.original?.clearbitData && row?.row?.original?.clearbitData?.companyLogo ? row?.row?.original?.clearbitData?.companyLogo : ''}>
                        {!row?.row?.original?.clearbitData?.companyLogo ? name : ''}
                    </Avatar>
                );
            },
            size: 40,
            minSize: 40,
            maxSize: 40,
            header: '',
            enableSorting: false,
            id: 'companyLogo',
        }),
        columnHelper.accessor('name' as any, {
            cell: (row) => {
                return (
                    <div>
                        <p className="bolded-cellContent">{`${row.row.original.firstName} ${row.row.original?.lastName || ''}`}</p>
                        <p className="cellContent">{row.row.original?.email}</p>
                    </div>
                )
            },
            header: 'Name',
            size: 250,
            minSize: 250,
            maxSize: 250,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'firstName'
        }),
        columnHelper.accessor('icp', {
            cell: (row: any) => {
                const icpChipColor = 'blue';

                return (
                    <div>
                        <CustomTooltip title={row?.getValue()?.length > 0 && row?.getValue()?.map((value: string) => value).join(', ')}>
                            <div>
                                {row?.getValue()?.length > 0 ? (
                                    row?.getValue()?.length > 1 ? (
                                        <div className="table-chip-cell">
                                            <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                            <p className="table-chip-count">
                                                +{row?.getValue()?.length - 1}
                                            </p>
                                        </div>
                                    ) : (
                                        <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                    )
                                ) : null}
                            </div>
                        </CustomTooltip>
                    </div>
                );
            },
            header: 'ICP',
            enableSorting: false,
            id: 'icp',
        }),
        columnHelper.accessor('buyerPersona', {
            cell: (row: any) => {
                const icpChipColor = 'red';

                return (
                    <div>
                        <CustomTooltip title={row?.getValue()?.length > 0 && row?.getValue()?.map((value: string) => value).join(', ')}>
                            <div>
                                {row?.getValue()?.length > 0 ? (
                                    row?.getValue()?.length > 1 ? (
                                        <div className="table-chip-cell">
                                            <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                            <p className="table-chip-count">
                                                +{row?.getValue()?.length - 1}
                                            </p>
                                        </div>
                                    ) : (
                                        <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                    )
                                ) : null}
                            </div>
                        </CustomTooltip>
                    </div>
                );
            },
            header: 'Buyer Persona',
            enableSorting: false,
            id: 'buyerPersona',
        }),
        columnHelper.accessor('feedbackData', {
            cell: (row: any) => {
                return (
                    <div>
                        <p className="cellContent">{row?.row?.original?.feedbackData?.formData?.rateYourExperience}</p>
                    </div>
                );
            },
            header: 'NPS',
            enableSorting: false,
            id: 'nps',
        }),
    ];

    const tablePopupTabData =
    [
        {
            title: 'Response',
            data: <EventFeedbackResponses feedbackData={selectedNpsRegistrant?.feedbackData} />
        },
        {
            title: 'Details',
            data: <RegistrantDetailedInfo eventId={eventId} registrantData={selectedNpsRegistrant as EventRegistrant} setRegistrants={setRows} handlePopupClose={handlePopupClose} />
        }
    ];

    const fetchNpsCount = async () => 
    {
        try 
        {
            const feedbackCount = await getAllEventFeedbacksCount(eventId as string);
            const count = feedbackCount?.all;
            if (count) 
            {
                setNpsRegistrantCount(count);
                setIsEmpty(count == 0);
            }
        } 
        catch (error) 
        {
            console.error(error);
        }
    }

    const fetchNpsData = async () =>
    {
        try 
        {
            const data = await getAllEventFeedbacks(eventId as string, pageSize, currentPage - 1);
            if (data) 
            {
                setRows(data);
            }
        } 
        catch (error) 
        {
            console.error(error);
        }
    }

    useEffect(() => 
    {
        fetchNpsCount();
        fetchNpsData();
    }, [pageSize, currentPage, eventId]);

    const popupHeader = (): React.ReactElement =>
    {
        const currentIndex = rows.findIndex((row) => row.id === selectedNpsRegistrant?.id) + 1;
        return (
            <div className='popup-header'>
                <div className='popup-navigator'>
                    <div className='popup-navigator-buttons'>
                        <CustomButton name='' startIcon={<FontAwesomeIcon icon={['fal', 'chevron-up']} />} onClick={handlePrevious} btnType='tertiary_outline' />
                        <CustomButton name='' startIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />} onClick={handleNext} btnType='tertiary_outline' />
                    </div>
                    <p className='popup-text'> {currentIndex}/{npsRegistrantCount} Feedbacks </p>
                </div>
                <div className='popup-close'>
                    <CustomButton name='' startIcon={ <FontAwesomeIcon cursor={'pointer'} icon={['fal', 'xmark']} />} onClick={handlePopupClose} btnType='tertiary_outline' />
                </div>
            </div>
        )
    }

    return (
        <div id='npsAnalytics'>
            {isEmpty ?
                <TableEmptyComponent
                    infoText={'No NPS Data Available'}
                />
                :
                <TablePopup
                    rows={rows}
                    columns={drawerOpen ? [minimalColumns[0], minimalColumns[1], minimalColumns[minimalColumns.length - 1]] : minimalColumns}
                    count={npsRegistrantCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    updatePageSize={updatePageSize}
                    updateCurrentPage={updateCurrentPage as any}
                    height={'calc(-210px + 100vh)'}
                    drawerOpen={drawerOpen}
                    onRowClick={(row) => handleMinimalViewRowClick(row)}
                    selectedRow={selectedNpsRegistrant}
                    rightPinnedColumns={['actions']}
                    popupContent={
                        <TablePopupDetails
                            popupHeader={popupHeader()}
                            title={`${selectedNpsRegistrant?.firstName} ${selectedNpsRegistrant?.lastName || ''}`}
                            subTitle={`${selectedNpsRegistrant?.clearbitData ? selectedNpsRegistrant?.clearbitData?.company : ''}`}
                            email={`${selectedNpsRegistrant?.email}`}
                            imageSrc={`${selectedNpsRegistrant?.clearbitData ? selectedNpsRegistrant?.clearbitData?.companyLogo : ''}`}
                            altImage={`${buildInitialsForPicture(selectedNpsRegistrant?.email, selectedNpsRegistrant?.firstName, selectedNpsRegistrant?.lastName || '')}`}
                            otherInitialBadges={registrantsBasedOnRating()}
                            otherInitialBadgesColor={registrantsBasedOnRatingColor()}
                            icp={selectedNpsRegistrant?.icp}
                            buyerPersona={selectedNpsRegistrant?.buyerPersona}
                            tabData={tablePopupTabData}
                        />
                    }
                />}
        </div>
    )
}

export default NpsAnalytics;