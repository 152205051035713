import { useEffect, useState, useMemo } from 'react';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import './styles.scss';
import { DefaultEventSocialShares, EventSocialShare } from '../../interfaces/event-social-share_interface';
import PopupDialog from '../../../../common/PopupDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventSocialShareTemplateType, EventSocialShareType } from '../../enum/event-social-share.enum';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { createSocialShareTemplate, deleteSocialShareTemplate, getAllDefaultTemplates, getAllEventSocialShareTemplates } from '../../../../scripts/apis/events';
import { MinimalViewComponent } from '../../../../common/MinimalView/MinimalViewComponent';
import { FormControlComponent, FormLabelComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import _ from 'lodash';
import { TableActions } from '../../../../common/TableActions';
import DeletePopup from '../../../../common/DeletePopup';

interface EventSocialSharePageProps {
    eventId: string | number;
}

interface SocialShareTemplateOptions {
    name: string;
    dimensions: string | React.JSX.Element;
    value: EventSocialShareTemplateType;
}

const EventSocialSharePage: React.FC<EventSocialSharePageProps> = ({ eventId }): React.JSX.Element => {
    const csrfTokenData = useSelector((state: any) => state['csrfTokenValue'].value.csrfToken);
    const navigate = useNavigate();

    const [templates, setTemplates] = useState<EventSocialShare[]>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [showTemplatePopup, setShowTemplatePopup] = useState<boolean>(false);
    const [showCustomTemplatePopup, setShowCustomTemplatePopup] = useState<boolean>(false);
    const [customTemplateFormValues, setCustomTemplateFormValues] = useState({ width: '', height: '' });
    const [defaultTemplates, setDefaultTemplates] = useState<DefaultEventSocialShares>();
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<EventSocialShare | null>(null);

    const templateOptions: SocialShareTemplateOptions[] = useMemo(() => [
        { 
            name: 'LinkedIn Post', dimensions: '1200 x 1200 px', value: EventSocialShareTemplateType.LINKEDIN_POST 
        },
        { 
            name: 'LinkedIn Single Image', dimensions: '1200 x 627 px', value: EventSocialShareTemplateType.LINKEDIN_SINGLE_IMAGE 
        },
        { 
            name: 'Twitter Post', dimensions: '1600 x 900 px', value: EventSocialShareTemplateType.TWITTER_POST 
        },
        { 
            name: 'Instagram Post', dimensions: '1080 x 1350 px', value: EventSocialShareTemplateType.INSTAGRAM_POST 
        },
        { 
            name: 'Instagram Story', dimensions: '1080 x 1920 px', value: EventSocialShareTemplateType.INSTAGRAM_STORY 
        },
        { 
            name: 'Meta Image', dimensions: '1200 x 628 px', value: EventSocialShareTemplateType.META_IMAGE 
        },
        { 
            name: 'Custom', dimensions: <FontAwesomeIcon icon={['fal', 'pen']} />, value: EventSocialShareTemplateType.CUSTOM 
        }
    ], []);

    const createSocialShare = async (templateType?: EventSocialShareTemplateType, type?: EventSocialShareType, referenceTemplateId?: string): Promise<void> => {
        if (templateType === EventSocialShareTemplateType.CUSTOM) {
            if (!customTemplateFormValues.width.trim() || !customTemplateFormValues.height.trim()) {
                toast.error('Please enter valid width and height');
                return;
            }
        }
        try {
            const socialShareData: any = {
                title: `Social Share ${templates.length + 1}`,
                templateType,
                type,
                referenceTemplateId,
                width: templateType === EventSocialShareTemplateType.CUSTOM ? customTemplateFormValues.width : undefined,
                height: templateType === EventSocialShareTemplateType.CUSTOM ? customTemplateFormValues.height : undefined,
            };

            const socialShareCreated = await createSocialShareTemplate(eventId, csrfTokenData, socialShareData as EventSocialShare);
            if (socialShareCreated) {
                setTemplates([...templates, socialShareCreated]);
                setShowTemplatePopup(false);
                navigate(`/events/${eventId}/social-share/${socialShareCreated.placidTemplateId}?view=editor`);
            }
        } catch (error) {
            console.log('Error creating social share', error);
            toast.error((error as Error)?.message);
        }
    };

    const deleteTemplate = async (): Promise<void> => {
        try {
            const templateDeleted = await deleteSocialShareTemplate(eventId, selectedTemplate?.placidTemplateId as string);
            if (templateDeleted) {
                setTemplates(templates.filter(template => template.placidTemplateId !== selectedTemplate?.placidTemplateId));
                toast.success('Social Share Template Deleted Successfully');
                setShowDeletePopup(false);
                setSelectedTemplate(null);
            }
        } catch (error) {
            console.log('Error deleting template', error);
            toast.error((error as Error)?.message || 'Failed to delete social share template');
        }
    };

    const fetchAllTemplatesData = async (): Promise<void> => {
        try {
            const templatesResponse = await getAllEventSocialShareTemplates(eventId);
            setTemplates(templatesResponse);
        } catch (error) {
            console.log('Error fetching templates', error);
        } finally {
            setShowSpinner(false);
        }
    };

    const fetchDefaultTemplates = async (): Promise<void> => {
        try {
            const templatesData = await getAllDefaultTemplates();
            if (templatesData) {
                setDefaultTemplates(templatesData);
            }
        } catch (error) {
            console.log('Error fetching default templates', error);
        }
    };

    useEffect(() => {
        fetchAllTemplatesData();
        fetchDefaultTemplates();
    }, []);

    const renderSelectTheme = (): React.JSX.Element => (
        <div className="select-theme-popup">
            <div className="title-container">
                <h3 className="title">{'Select Theme'}</h3>
                <CustomButton
                    name=''
                    btnType='secondary'
                    startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />}
                    onClick={() => setShowTemplatePopup(false)}
                />
            </div>
            <div className="theme-list">
                {templateOptions.map((template, index) => (
                    <div className="theme-card-container" key={index}>
                        <button className="theme-card">
                            {typeof template.dimensions === 'string' ? (
                                <>
                                    <p className="template-dimensions">{template.dimensions}</p>
                                    <div className="use-theme-button-overlay">
                                        <CustomButton
                                            btnType='primary'
                                            name='Use Theme'
                                            onClick={() => createSocialShare(template.value, EventSocialShareType.EVENT_SPEAKER)}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    {template.dimensions}
                                    <div className="use-theme-button-overlay">
                                        <CustomButton
                                            btnType='primary'
                                            name='Use Theme'
                                            onClick={() => {
                                                setShowTemplatePopup(false);
                                                setShowCustomTemplatePopup(true);
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </button>
                        <p className="template-name">{template.name}</p>
                    </div>
                ))}
            </div>

            {/* Default Templates */}
            <div className="default-theme-list-container">
                {defaultTemplates && Object.keys(defaultTemplates).map((key, index) => (
                    <div key={index} className="default-theme-container">
                        <h3 className="default-theme-title">{`${_.capitalize(key)} Templates`}</h3>
                        <div className="default-theme-list">
                            {defaultTemplates[key as keyof DefaultEventSocialShares]?.map((template, index) => {
                                const templateType = {
                                    speaker: EventSocialShareType.EVENT_SPEAKER,
                                    sponsor: EventSocialShareType.EVENT_SPONSOR,
                                    host: EventSocialShareType.EVENT_HOST,
                                    attendee: EventSocialShareType.EVENT_REGISTRANT,
                                }[key] || EventSocialShareType.EVENT_SPEAKER;

                                return (
                                    <div className="theme-card-container" key={index}>
                                        <button className="theme-card">
                                            <img src={template.socialShareTemplate} alt="template" />
                                            <div className="use-theme-button-overlay">
                                                <CustomButton
                                                    btnType='primary'
                                                    name='Use Template'
                                                    onClick={() => createSocialShare(undefined, templateType, template.placidTemplateId)}
                                                />
                                            </div>
                                        </button>
                                        <p className="template-name">{template.title}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    const renderTemplates = (): React.JSX.Element => (
        <div className="social-share-templates">
            {templates.map((template, index) => (
                <div className="social-share-template-card" key={index}>
                    <div className="template-image">
                        <img src={template.socialShareTemplate} alt="template" />
                    </div>
                    <div className="template-details">
                        <p className="template-title">{template.title}</p>
                        <TableActions
                            actions={[
                                {
                                    title: 'Edit',
                                    onClick: () => navigate(`/events/${eventId}/social-share/${template.placidTemplateId}?view=editor`)
                                },
                                {
                                    title: 'Delete',
                                    onClick: () => {
                                        setSelectedTemplate(template);
                                        setShowDeletePopup(true);
                                    }
                                }
                            ]}
                        />
                    </div>
                </div>
            ))}
        </div>
    );

    const renderSocialShareStandardView = (): React.JSX.Element => (
        <MinimalViewComponent
            title={'Social Share'}
            titleBarActions={[
                <CustomButton
                    name='Create Template'
                    btnType='primary'
                    onClick={() => setShowTemplatePopup(true)}
                />
            ]}
            component={renderTemplates()}
        />
    );

    const renderCustomTemplate = (): React.JSX.Element => (
        <div className="social-share-custom-template">
            <div className="title-container">
                <h3 className="title">{'Custom Size'}</h3>
                <CustomButton
                    name=''
                    btnType='secondary'
                    startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />}
                    onClick={() => setShowCustomTemplatePopup(false)}
                />
            </div>
            <div className="custom-template-form">
                <div>
                    <FormLabelComponent label={'Width in px'} />
                    <FormControlComponent
                        type='number'
                        value={customTemplateFormValues.width}
                        onChange={(e) => setCustomTemplateFormValues({ ...customTemplateFormValues, width: e.target.value })}
                    />
                </div>
                <div>
                    <FormLabelComponent label={'Height in px'} />
                    <FormControlComponent
                        type='number'
                        value={customTemplateFormValues.height}
                        onChange={(e) => setCustomTemplateFormValues({ ...customTemplateFormValues, height: e.target.value })}
                    />
                </div>
            </div>
            <div className="custom-template-actions">
                <CustomButton
                    name='Cancel'
                    btnType='secondary'
                    onClick={() => setShowCustomTemplatePopup(false)}
                    mainDivStyle={{ width: '100%' }}
                    style={{ width: '100%' }}
                />
                <CustomButton
                    name='Confirm'
                    btnType='primary'
                    onClick={() => createSocialShare(EventSocialShareTemplateType.CUSTOM)}
                    mainDivStyle={{ width: '100%' }}
                    style={{ width: '100%' }}
                />
            </div>
        </div>
    );

    return (
        <div id="eventSocialSharePage">
            {renderSocialShareStandardView()}

            {showTemplatePopup && (
                <PopupDialog
                    cancelClick={() => setShowTemplatePopup(false)}
                    show={showTemplatePopup}
                    modalId='select-theme'
                    modalContent={renderSelectTheme()}
                />
            )}

            {showCustomTemplatePopup && (
                <PopupDialog
                    cancelClick={() => setShowCustomTemplatePopup(false)}
                    show={showCustomTemplatePopup}
                    modalId='custom-theme'
                    modalContent={renderCustomTemplate()}
                />
            )}

            {showDeletePopup && (
                <DeletePopup
                    modalHeaderIcon='trash'
                    modalTitle='Delete Template'
                    modalContent={`Are you sure you want to delete the template?`}
                    show={showDeletePopup}
                    acceptBtn='Delete'
                    rejectBtn='Cancel'
                    acceptClick={deleteTemplate}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                        setSelectedTemplate(null);
                    }}
                />
            )}
        </div>
    );
};

export default EventSocialSharePage;