/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { FormControlLabel, Stack, Typography, createFilterOptions } from '@mui/material';
import { Form } from 'react-bootstrap';
import { CONTENT } from '../../../scripts/i18n';
import moment from 'moment-timezone';
import { addEvent } from '../../../redux/events/Events';
import { useDispatch, useSelector } from 'react-redux';
import APP_CONSTANTS from '../../../scripts/constants';
import { createEventRegistrationToolMap, getEventActivityLogs, getMapEvents, updateEvent } from '../../../scripts/apis/events';
import eventBus from '../../../scripts/event-bus';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreateEventCategory from '../EventCategory/CreateEventCategory';
import { getAllEventCategories } from '../../../scripts/apis/eventCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalStorage } from '../../../scripts/LocalStorage';
import { AutocompleteComponentWithFilterOption, FormControlComponent, FormLabelComponent, SelectComponent, TimezoneSelectComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { uniqueTimezoneArr } from '../timezoneGenerateFunction';
import { Event, EventCategory } from '../../../pages/Events/interfaces';
import { CustomFieldLocations, customFieldCountByLocation, customFieldsByLocation } from '../../../pages/Settings/CustomField/CustomField.helper';
import { IEditEventProps, IEventReduxValues, IEventsDispatch } from '../interfaces/create-events_interface';
import { eventLocationOptions, eventTypeOptions } from '../../../contexts/EventsPageContext';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import './styles.scss';
import { MapEvent } from '../../../pages/Events/interfaces/event-registration-tools_interface';
import { EditKey, EventTypes } from '../../../pages/Events/enum';
import { IntegrationTypes } from '../../../pages/Settings/enum/integrations.enum';
import { Integrations, IntegrationsState } from '../../../pages/Settings/interface/integration_interface';
import DateTimePicker from '../../../common/FormComponents/DateTimePicker';
import { combineDateTime, convertTimestampWithTimezone } from '../../../scripts/helpers';
import toast from 'react-hot-toast';
import CustomTooltip from '../../../common/Tooltip';
import { ActivityLogResponse } from '../../../interfaces/activity-log_interface';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import { eventsActivityLog } from '../../../scripts/activityLogGenerator/activitylog-generator';
import ActivityLogComponent from '../../../common/ActivityLog/ActivityLogComponent';

const getHeaderContent = (editKey: EditKey): { icon: any; heading: string; description: string } => {
    switch (editKey) {
        case EditKey.EVENT_NAME:
            return { icon: ['fal', 'edit'], heading: 'Edit Event Name', description: 'Modify the event name.' };
        case EditKey.DATE_AND_TIME:
            return { icon: ['fal', 'clock'], heading: 'Edit Date & Time', description: 'Modify the date and time settings.' };
        case EditKey.TYPE:
            return { icon: ['fal', 'tent-double-peak'], heading: 'Edit Event Type', description: 'Tailor your event type to your needs.' };
        case EditKey.CATEGORY:
            return { icon: ['fal', 'layer-group'], heading: 'Select Event Category', description: 'Update the event category for better organisation.' };
        case EditKey.EVENT_FORMAT:
            return { icon: ['fal', 'location-dot'], heading: 'Edit Event Format', description: 'Tailor your event format to your needs.' };
        case EditKey.EVENT_URL:
            return { icon: ['fal', 'link'], heading: 'Edit Event URL', description: 'Modify the event URL settings.' };
        case EditKey.APPROVAL_REQUIRED:
            return { icon: ['fal', 'user-check'], heading: 'Event Approval', description: 'Update the event approval status.' };
        case EditKey.EDIT_CATEGORY:
            return { icon: ['fal', 'layer-group'], heading: 'Edit Event Category', description: 'Update the event category for better organisation.' };
        case EditKey.CREATE_CATEGORY:
            return { icon: ['fal', 'layer-group'], heading: 'Create Event Category', description: 'Create the event category for better organisation.' };
        default:
            return { icon: ['fal', 'edit'], heading: 'Edit Event', description: 'Modify the event settings.' };
    }
};

const EditEventPopup = (props: IEditEventProps): React.JSX.Element => {

    const eventData = props.eventData;
    const editKey = props.editKey;
    const filter = createFilterOptions();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => {
        return state.events.value;
    });

    const integrations = useSelector((state: IntegrationsState): Integrations[] => {
        return state.integrations.value;
    });

    const csrfTokenData = useSelector((state): string => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const [eventTitleLength, setEventTitleLength] = useState(0);
    const [eventCategoryDetails, setEventCategoryDetails] = useState<EventCategory[]>([]);
    const [spinner, setSpinner] = useState(false);
    const [customFormState, setCustomFormState] = useState({});
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsSpinner, setCustomFieldsSpinner] = useState(false);
    const [mapEvents, setMapEvents] = useState<MapEvent[]>([]);
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    // const [integrations, setIntegrations] = useState<Integrations[]>([]);
    const isLumaIntegrated = integrations?.some((integration) => integration.type === IntegrationTypes.LUMA);
    const [showActivityLog, setShowActivityLog] = useState<boolean>(false);

    const orgLink = LocalStorage.get('@Org')?.link;

    const eventMapOptionLabel = mapEvents?.length > 0 && mapEvents?.reduce((acc, event) => {
        acc[event.id] = event.name;
        return acc;
    }, {});

    const { icon, heading, description } = getHeaderContent(props.editKey);

    const handleBackToPopup = (): void =>
    {
        setShowActivityLog(false);
    }

    const fetchEventsCustomFieldData = async (): Promise<void> => {
        try {
            setCustomFieldsSpinner(true);
            const cfcount = await customFieldCountByLocation(CustomFieldLocations.EVENT);
            if (cfcount) {
                try {
                    const customFields = await customFieldsByLocation(cfcount, 0, CustomFieldLocations.EVENT);
                    if (customFields) {
                        setCustomFields(customFields);
                        setCustomFieldsSpinner(false);
                    }
                }
                catch (error) {
                    console.error(error);
                    setCustomFieldsSpinner(false);
                }
            }

        }
        catch (error) {
            console.error(error);
            setCustomFieldsSpinner(false);
        }

    };

    const handleTimeZoneChange = (event: React.SyntheticEvent, newValue: string): void => {

        const timezoneForSelectedValue = _.find(uniqueTimezoneArr as any, function (item: { name: string; value: string }): boolean {
            return item.name === newValue;
        });
    
        formik.setFieldValue('timezone', timezoneForSelectedValue);
    };

    const handleEventType = (event: React.ChangeEvent<HTMLInputElement>): void => {
        formik.setFieldValue('type', event.target.value);
    };

    const handleEventLocation = (event: React.ChangeEvent<HTMLInputElement>): void => {
        formik.setFieldValue('locationType', Number(event.target.value));
    };

    const handleDrawerClose = (): void => {

        props?.handleEditPopupClose();
        if (props?.routeFromId) {
            navigate('/events');
        }
    };

    const handleEventCategoryChange = (event: React.SyntheticEvent, newValue: string): void => {
        if (newValue && newValue.includes('Add :')) 
        {
            const eventCategoryName = newValue.split('Add : ')[1];
            formik.setFieldValue('eventCategoryName', eventCategoryName);

            props?.handleEditPopupClose();
            if (props?.handleEditEvent) 
            {
                props.handleEditEvent(EditKey.CREATE_CATEGORY);
            }
        }
        else {

            // const selectedEventCategory = _.filter(eventCategoryDetails, function (group: EventCategory): boolean {
            //     return newValue === group.name;
            // })[0];
            const selectedEventCategory = _.find(eventCategoryDetails, { name: newValue });
            formik.setFieldValue('eventCategoryObject', selectedEventCategory || null);
            formik.setFieldValue('eventCategoryId', selectedEventCategory?.id || '');
        }
    };

    const categoryFilterOptions = (options: any, params: any): any[] => {
        const filtered = filter(options, params);

        if (params.inputValue !== '' && !options.includes(params.inputValue)) {
            filtered.push('Add : ' + params.inputValue);
        }

        return filtered;
    };

    const handleEditEventCategory = (): void => 
    {
        props?.handleEditPopupClose();
        if (props?.handleEditEvent) 
        {
            props.handleEditEvent(EditKey.EDIT_CATEGORY);
        }
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required')
            .max(100, 'Event Title length exceeded'),
        link: Yup.string().matches(/^[a-zA-Z0-9_-]+$/, 'Enter valid Public URL').test('isValidLink', 'Public URL is required', (value): boolean => {
            if (formik.values.type === 1) {
                if (!value) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        }),
        locationType: Yup.number()
            .required('Please select the Event location'),
        type: Yup.number()
            .required('Please select the Event Type'),
        timezone: Yup.object().shape({
            name: Yup.string().required('Timezone is required'),
            value: Yup.string().required('Timezone value is required')
        }),
        registrationType: Yup.number(),
        // eventStartDateTime: Yup.string()
        //     .required('Start Date is required'),
        // eventEndDateTime: Yup.string()
        //     .required('End Date is required'),
        status: Yup.number(),
        eventCategoryId: Yup.string()
            .required('Event Category is required'),
        eventMapId: Yup.string().notRequired(),
    });

    const currentTimezone = moment.tz.guess();

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            title: eventData?.title ? eventData?.title : '',
            link: eventData?.link ? eventData?.link : '',
            type: eventData?.type ? eventData?.type : 1,
            registrationType: eventData?.registrationType ? eventData?.registrationType : 1,
            locationType: eventData?.locationType ? eventData?.locationType : 1,
            timezone: { name: '', value: '' },
            // startDate: moment.unix(Number(eventData?.eventStartDateTime)).format('DD/MM/YYYY'),
            // endDate: moment.unix(Number(eventData?.eventEndDateTime)).format('DD/MM/YYYY'),
            // startTime: moment.unix(Number(eventData?.eventStartDateTime)).format('HH:mm'),
            // endTime: moment.unix(Number(eventData?.eventEndDateTime)).format('HH:mm'),
            startDate: moment.unix(Number(eventData?.eventStartDateTime)).tz(eventData?.timezone as string).format('DD/MM/YYYY'),
            endDate: moment.unix(Number(eventData?.eventEndDateTime)).tz(eventData?.timezone as string).format('DD/MM/YYYY'),
            startTime: moment.unix(Number(eventData?.eventStartDateTime)).tz(eventData?.timezone as string).format('HH:mm'),
            endTime: moment.unix(Number(eventData?.eventEndDateTime)).tz(eventData?.timezone as string).format('HH:mm'),
            status: eventData?.status ? eventData?.status : 0,
            eventMapId: eventData?.lumaEventId ? eventData?.lumaEventId : '',
            eventCategoryId: '',
            eventCategoryName: '',
            eventCategoryObject: null as EventCategory | null,
        },
        onSubmit: (): void => {
            const isEventDataChanged = prepareEventData();
            if(isEventDataChanged)
            {
                updateEventDetails();
            }
        }
    });

    const prepareEventData = (): boolean => 
    {
        const { title, link, type, locationType, registrationType, timezone } = formik.values;

        const startDateTimestamp = convertTimestampWithTimezone(combineDateTime(formik.values.startDate, formik.values.startTime), currentTimezone, formik.values?.timezone?.value);
        const endDateTimestamp = convertTimestampWithTimezone(combineDateTime(formik.values.endDate, formik.values.endTime), currentTimezone, formik.values?.timezone?.value);

        if (!(_.isEmpty((eventData)))) 
        {
            const isEventStartDateTimeChanged = startDateTimestamp !== Number(eventData?.eventStartDateTime);
            const isEventEndDateTimeChanged = endDateTimestamp !== Number(eventData?.eventEndDateTime);
            const isEventDataChanged = eventData.title !== title || eventData.link !== link || eventData.type !== type || eventData.locationType !== locationType || eventData.timezone !== formik.values.timezone.value || eventData.eventCategoryId !== eventReduxData?.eventCategoryId || eventData.registrationType !== registrationType || eventData.landingPageType !== eventReduxData?.landingPageType || customFormState;

            if (isEventStartDateTimeChanged || isEventEndDateTimeChanged || isEventDataChanged) {
                return true;
            }
        }
        return false;
    };

    const updateEventDetails = async (eventCategoryObjCreated?: EventCategory): Promise<void> => {
        const eventStartDateTime = combineDateTime(formik.values.startDate, formik.values.startTime, formik.values.timezone.value);
        const eventEndDateTime = combineDateTime(formik.values.endDate, formik.values.endTime, formik.values.timezone.value);

        if (Number(eventEndDateTime) < Number(eventStartDateTime)) {
            toast.error('Event End Timing should be Greater than Event Start Timing');
            return;
        }
        setSpinner(true);

        const eventDetails = {
            title: formik.values.title,
            ...(formik.values.type === EventTypes.HOST && { link: formik.values.link }),
            type: formik.values.type,
            locationType: formik.values.locationType,
            registrationType: formik.values.registrationType,
            timezone: formik.values.timezone.value,
            eventStartDateTime,
            eventEndDateTime,
            closingDateTime: eventEndDateTime,
            eventCategoryId: eventCategoryObjCreated ? eventCategoryObjCreated?.id : formik.values.eventCategoryObject?.id || Number(eventReduxData.eventCategoryId),
        };

        if (customFormState) {
            const newEventDetails = {
                ...eventDetails,
                ['additionalFieldData']: {
                    ...customFormState
                }
            };

            try {
                const eventsDataUpdated = await updateEvent(String(eventData?.id), newEventDetails);
                if (!eventsDataUpdated) return;

                if (eventsDataUpdated) {
                    dispatch(addEvent({
                        type: Number(eventsDataUpdated.type),
                        eventStartDateTime: eventsDataUpdated.eventStartDateTime,
                        eventEndDateTime: eventsDataUpdated.eventEndDateTime,
                        closingDateTime: eventsDataUpdated.eventEndDateTime,
                    }));
                }

                if (formik.values.eventMapId) {
                    const eventMapObj = {
                        type: 5,
                        mapEvent: [
                            {
                                eventId: eventData?.id,
                                integrationMapId: formik.values?.eventMapId
                            }
                        ]
                    };

                    try {
                        await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                    } catch (error) {
                        console.error('Error with mapping the event', error);
                    }
                }

                if (!props.fromOverview) {
                    props?.setRefresh(true);
                }
                toast.success('Event details updated successfully');

                eventBus.dispatch('event-overview-refreshed', { refresh: true });
                eventBus.dispatch('event-details-refreshed', {
                    refresh: true
                });
                eventBus.dispatch('Refresh-events-top-bar', { refresh: true });

                handleDrawerClose();
            }
            catch (error) {
                console.error(error);
                // toast.error((error as Error)?.message || 'Error updating event details');
            }
            finally {
                setSpinner(false);
            }
        }
        else {
            try {
                const eventsDataUpdated = await updateEvent(String(eventData?.id), eventDetails);
                if (!eventsDataUpdated) return;

                if (eventsDataUpdated) {
                    dispatch(addEvent({
                        type: Number(eventsDataUpdated.type),
                        eventStartDateTime: eventsDataUpdated.eventStartDateTime,
                        eventEndDateTime: eventsDataUpdated.eventEndDateTime,
                        closingDateTime: eventsDataUpdated.eventEndDateTime,
                    }));
                }

                if (formik.values.eventMapId) {
                    const eventMapObj = {
                        type: 5,
                        mapEvent: [
                            {
                                eventId: eventData?.id,
                                integrationMapId: formik.values?.eventMapId
                            }
                        ]
                    };

                    try {
                        await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                    } catch (error) {
                        console.error('Error with mapping the event', error);
                    }
                }

                if (!props.fromOverview) {
                    props?.setRefresh(true);
                }

                toast.success('Event details updated successfully');

                eventBus.dispatch('event-overview-refreshed', { refresh: true });
                eventBus.dispatch('event-details-refreshed', {
                    refresh: true
                });
                eventBus.dispatch('Refresh-events-top-bar', { refresh: true });

                handleDrawerClose();
            } catch (error) {
                console.error(error);
                toast.error((error as Error)?.message || 'Error updating event details');
            } finally {
                setSpinner(false);
            }
        }
    };

    const fetchMapEvents = async (): Promise<void> => {
        try {
            const mapEventsData = await getMapEvents(5);
            if (mapEventsData) {
                setMapEvents(mapEventsData?.mapEvents);
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    const fetchData = async (): Promise<void> => {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, (): void => {
            navigate(0);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data): void => {
            setSpinner(data.spinner);
        });

        let eventCategoryData;
        try {
            eventCategoryData = await getAllEventCategories();
            if (eventCategoryData) {
                setEventCategoryDetails([...eventCategoryData]);
            }
        }
        catch (error) {
            console.error(error);
        }

        if (!(_.isEmpty((eventData)))) {
            dispatch(addEvent({
                title: eventData.title,
                description: eventData.description,
                eventStartDate: moment.unix(eventData.eventStartDateTime).format('YYYY-MM-DD'),
                eventStartTime: moment.unix(eventData.eventStartDateTime).format('HH:mm'),
                eventEndDate: moment.unix(eventData.eventEndDateTime).format('YYYY-MM-DD'),
                eventEndTime: moment.unix(eventData.eventEndDateTime).format('HH:mm'),
                timeZone: eventData.timezone,
                type: eventData.type,
                registrationType: eventData.registrationType,
                link: eventData.link,
                eventCategoryId: eventData.eventCategoryId ? eventData.eventCategoryId : '',
                status: eventData.status ? eventData.status : 1,
                landingPageType: eventData?.landingPageType,
                eventStartDateTime: eventData.eventStartDateTime,
                eventEndDateTime: eventData.eventEndDateTime,
            }));
            // let words = eventData.description.split(" ");
            // let wordsLength = words.length;
            setEventTitleLength(eventData?.title?.length);

            formik.setFieldValue('eventMapId', eventData?.lumaEventId ? eventData?.lumaEventId : '');

            const defaultCategory = _.filter(eventCategoryData, function (elem): boolean {
                return elem.id === eventData?.eventCategoryId;
            });

            const defaultTimezone = _.filter(uniqueTimezoneArr as any, function (elem): boolean {
                return elem.value === eventData.timezone;
            });
    
            if (defaultTimezone) {
                formik.setFieldValue('timezone', defaultTimezone[0]);
            }

            if (defaultCategory) {
                formik.setFieldValue('eventCategoryObject', defaultCategory[0] ? defaultCategory[0] : '');
                formik.setFieldValue('eventCategoryId', defaultCategory[0]?.name ? defaultCategory[0]?.name : '');
            }
        }
    };

    const fetchEventActivityLogs = async (): Promise<void> => {
        try {
            const activityLogs = await getEventActivityLogs(eventData?.id);
            if (activityLogs) {
                setActivityLogs(activityLogs);
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    useEffect((): void => {
        fetchData();
        fetchEventsCustomFieldData();
        fetchMapEvents();
        if (eventData?.additionalFieldData) {
            setCustomFormState({
                ...eventData?.additionalFieldData
            });

        }

        if (eventData) fetchEventActivityLogs();

    }, []);

    const renderEventName = (): React.JSX.Element => (
        <div className="popup-container">
            <div className='label-wrapper'>
                <FormControlComponent
                    value={formik.values.title}
                    placeholder={'Name'}
                    type={'text'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        formik.setFieldValue('title', event.target.value);
                        setEventTitleLength(event.target.value.length);
                    }}
                    isInvalid={formik.errors.title && formik.touched.title}
                />
                <div className="title-length-error-cont">
                    {formik.errors.title && formik.touched.title ? <Typography className="error-msg">{formik.errors.title}</Typography> : <div />}
                    <Typography className="title-length">{eventTitleLength}/100</Typography>
                </div>
            </div>
        </div>
    );
    
    const renderDateAndTime = (): React.JSX.Element => (
        <div className="popup-container">
            <div className="event-timing-wrapper label-wrapper">
                <div className="event-timing-container">
                    <div className="start-timing">
                        <DateTimePicker 
                            dateValue={moment(formik.values.startDate, 'DD/MM/YYYY').unix()}
                            timeValue={formik.values.startTime}
                            onDateChange={(newValue): void => {
                                formik.setFieldValue('startDate', newValue);
                            }}
                            onTimeChange={(newValue): void => { 
                                formik.setFieldValue('startTime', newValue);
                            }}
                            dateFormat='ddd, MMM D'
                            showYearDropdown
                        />
                    </div>
                    <p>-</p>
                    <div className="end-timing">
                        <DateTimePicker
                            dateValue={moment(formik.values.endDate, 'DD/MM/YYYY').unix()}
                            timeValue={formik.values.endTime}
                            onDateChange={(newValue): void => {
                                formik.setFieldValue('endDate', newValue);
                            }}
                            onTimeChange={(newValue): void => {
                                formik.setFieldValue('endTime', newValue);
                            }}
                            dateFormat='ddd, MMM D'
                            minDate={moment(formik.values.startDate, 'DD/MM/YYYY').unix()}
                            maxDate={moment(formik.values.startDate, 'DD/MM/YYYY').add(30, 'days').unix()}
                            showYearDropdown
                        />
                    </div>
                </div>
                <div className="event-timezone-component">
                    <div className="w-100">
                        <TimezoneSelectComponent
                            defaultValue={formik.values?.timezone?.name}
                            value={formik.values?.timezone?.name}
                            optionsArr={uniqueTimezoneArr as any[]}
                            onChange={handleTimeZoneChange}
                            placeholder="Select a timezone"
                        />
                        {formik.errors.timezone && formik.touched.timezone ? <Typography className="error-msg">{formik.errors.timezone.name}</Typography> : null}
                    </div>
                </div>
            </div>
        </div>
    );
    
    const renderEventFormat = (): React.JSX.Element => (
        <div className="popup-container">
            <div className='label-wrapper'>
                <FormLabelComponent label={'Event Format'} required={true} />
                <SelectComponent options={eventLocationOptions} value={Number(formik.values.locationType)} onChange={handleEventLocation} optionLabels={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EVENT_LOCATION_OPTIONS} defaultPlaceholder={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.EVENT_LOCATION_SELECT_BTN_LABEL} />
                {formik.errors.locationType && formik.touched.locationType ? <Typography className="error-msg">{formik.errors.locationType}</Typography> : null}
            </div>
        </div>
    );
    
    const renderCategory = (): React.JSX.Element => (
        <div className="popup-container">
            <div className='label-wrapper'>
                <div className="tooltip-box">
                    <FormLabelComponent label={'Event Category'} required={true} />
                    <CustomTooltip
                        title="Categorize events based on your preference"
                    >
                        <FontAwesomeIcon
                            icon={['fal', 'info-circle']}
                            className="info-icon"
                        />
                    </CustomTooltip>
                </div>
                <div className="event-category-container">
                    <div style={{ width: '100%' }}>
                        <AutocompleteComponentWithFilterOption value={formik.values.eventCategoryObject?.name || ''} defaultValue={formik.values.eventCategoryObject?.name || ''}  onChange={handleEventCategoryChange} filterOptions={categoryFilterOptions} keyToShow='name' optionsArr={eventCategoryDetails} placeholder={eventCategoryDetails.length > 0 ? CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.PLACEHOLDER.EVENT_CATEGORY_GROUP_SELECT : CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.PLACEHOLDER.EVENT_CATEGORY_GROUP_EMPTY_SELECT} />
                    </div>
                    {formik.values?.eventCategoryId && <CustomButton btnType='secondary' name='Edit' onClick={handleEditEventCategory} />}
                </div>
                {formik.errors.eventCategoryId && formik.touched.eventCategoryId ? <Typography className="error-msg">{formik.errors.eventCategoryId}</Typography> : null}
            </div>
        </div>
    );

    const renderEventCategory = (): React.JSX.Element => (
        formik.values.eventCategoryName ? 
        <CreateEventCategory setEventCategoryDetails={setEventCategoryDetails} eventCategoryName={formik.values.eventCategoryName} handleEditPopupClose={props?.handleEditPopupClose} formik={formik} updateEventDetails={updateEventDetails} /> :
        <CreateEventCategory setEventCategoryDetails={setEventCategoryDetails} eventCategoryName={formik.values.eventCategoryId} isEdit eventCategoryId={eventReduxData?.eventCategoryId} handleEditPopupClose={props?.handleEditPopupClose} formik={formik} />
    );
    
    const renderEventType = (): React.JSX.Element => (
        <div className="popup-container">
            <div className='label-wrapper'>
                <FormLabelComponent label={'Event Type'} required={true} />
                <SelectComponent options={eventTypeOptions} value={String(formik.values.type)} onChange={handleEventType} optionLabels={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EVENT_TYPE_OPTIONS} defaultPlaceholder={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.EVENT_TYPE_SELECT_BTN_LABEL} />
                {formik.errors.type && formik.touched.type ? <Typography className="error-msg">{formik.errors.type}</Typography> : null}
            </div>
        </div>
    );
    
    const renderEventURL = (): React.JSX.Element => (
        <div className="popup-container">
            <div className='label-wrapper'>
                <div className="tooltip-box">
                    <FormLabelComponent label={'Event URL'} required={true} />
                    <CustomTooltip
                        title="Host your event landing page in this URL"
                    >
                        <FontAwesomeIcon
                            icon={['fal', 'info-circle']}
                            className="info-icon"
                        />
                    </CustomTooltip>
                </div>
                <FormControlComponent
                    type={'text'}
                    value={formik.values.link}
                    placeholder='Public URL'
                    onChange={(event): void => {
                        formik.setFieldValue('link', event.target.value);
                    }}
                    isInvalid={formik.errors.link && formik.touched.link}
                />
                <Typography className="short-link-example">{`https://${orgLink}.eventhq.com/`}{formik.values.link}</Typography>
                {formik.errors.link && formik.touched.link ? <Typography className="error-msg">{formik.errors.link}</Typography> : null}
            </div>
        </div>
    );

    const renderApprovalRequired = (): React.JSX.Element => (
        <div className="popup-container">
            <div className="approval-container">
                <div className="approval-icon-label-container">
                    {/* <FontAwesomeIcon icon={['fal', 'user-check']} className="approval-icon" /> */}
                    <FormLabelComponent label={'Requires Approval to attend'} noBottomMargin />
                </div>
                <FormControlLabel
                    checked={formik.values.registrationType === 2}
                    control={<CustomSwitch
                        onChange={(event:  React.ChangeEvent<HTMLInputElement>): void => 
                        {
                            formik.setFieldValue('registrationType', event.target.checked ? 2 : 1);
                        }}
                    />}
                    label={''}
                />
            </div>
        </div>
    );

    const handleNestedSidebarOpen = (): void => {
        setShowActivityLog(true);
    };

    const renderHeader = (): React.JSX.Element => {
        return (
            <div className='popup-header'>
                <div className='popup-header-content'>
                    {showActivityLog ? (
                        <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} onClick={handleBackToPopup} />
                    ) : (
                        <div className='popup-header-icon'><FontAwesomeIcon icon={icon} /></div>
                    )}
                    <div className='popup-header-text'>
                        <h3>{showActivityLog ? 'Activity' : heading}</h3>
                        <p>{showActivityLog ? eventData?.title : description}</p>
                    </div>
                </div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => props.handleEditPopupClose()} />
            </div>
        )
    };

    const renderMainContent = (): React.JSX.Element => {

        if (showActivityLog) {
            return <ActivityLogComponent activityLog={eventsActivityLog(activityLogs, eventData as Event)} />;
        } else {
            switch (editKey) {
                case EditKey.EVENT_NAME:
                    return renderEventName();
                case EditKey.DATE_AND_TIME:
                    return renderDateAndTime();
                case EditKey.EVENT_FORMAT:
                    return renderEventFormat();
                case EditKey.CATEGORY:
                    return renderCategory();
                case EditKey.TYPE:
                    return renderEventType();
                case EditKey.EVENT_URL:
                    return renderEventURL();
                case EditKey.EDIT_CATEGORY:
                case EditKey.CREATE_CATEGORY:
                    return renderEventCategory();
                case EditKey.APPROVAL_REQUIRED:
                    return renderApprovalRequired();
                default:
                    return <></>;
            }
        }
    };

    const renderFooter = (): React.JSX.Element => {

        if (showActivityLog || editKey === EditKey.CREATE_CATEGORY || editKey === EditKey.EDIT_CATEGORY) 
        {
            return <></>;
        }

        return (
            <>
                {<div className="popup-footer">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        {eventData &&
                            <div className="events-activity-log-button">
                                <CustomButton
                                    name=""
                                    btnType="secondary"
                                    startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                                    style={{ width: '32px' }}
                                    onClick={handleNestedSidebarOpen}
                                />
                            </div>}
                        <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                            <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={'Cancel'} />
                            <CustomButton type="submit" loading={spinner} name={!(_.isEmpty(eventData)) ? 'Save' : 'Create Event'} btnType={'primary'} />
                        </Stack>
                    </Stack>
                </div>}
            </>
        )
    };

    return (
        <div id="editEventPopup">

            {renderHeader()}

            <Form noValidate autoComplete="off" onSubmit={(values: React.FormEvent<HTMLFormElement>): void => {
                return formik.handleSubmit(values);
            }}>

                {renderMainContent()}

                {renderFooter()}
            </Form >
        </div >
    );
};

export default EditEventPopup;