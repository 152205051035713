import React, { useContext, useEffect, useState } from "react";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import { createColumnHelper } from "@tanstack/react-table";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import './styles.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { EventTargetList } from "../../interfaces/event-target-list-interface";
import { EventTargetListType } from "../../enum/event-target-list.enum";
import { TableActions } from "../../../../common/TableActions";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import EditEventTargetList from "./Components/EditEventTargetList";
import DeletePopup from "../../../../common/DeletePopup";
import { EventTargetListContext } from "../../../../contexts/EventTargetList/EventTargetListContext";
import moment from 'moment';
import MuiChip from "../../../../common/FormComponents/MuiChip";
import TanstackToolbarWithServerFilter from "../../../../common/TanstackTable/TanstackToolbarWithServerFilter";
import { UserContext } from "../../../../contexts/UserContext";
import { UserViewType } from "../../../Settings/enum/users.enum";
import { MinimalViewComponent } from "../../../../common/MinimalView/MinimalViewComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColumnVisibilityDropdown from "../../../../common/TanstackTable/ColumnVisibilityDropdown";
import { sourceTypeMapping, sourceColorMapping, targetListFilterColoumns } from "./helpers/TargetListContants";
import CustomTooltip from "../../../../common/Tooltip";
import EventsHeaderBar from "../../../../common/EventsHeaderBar";

const EventTargetListPage: React.FC = (): React.JSX.Element =>
{
    const {
        eventId,
        isEmpty,
        eventTargetListDataCount,
        eventTargetList,
        deleteEventTargetListFromTable,
        setRefresh,
        pageSize,
        updateCurrentPage,
        updatePageSize,
        currentPage,
        showDeletePopup,
        showSpinner,
        selectedTargetList,
        setSelectedTargetList,
        setShowDeletePopup,
        setCurrentUrl,
    } = useContext(EventTargetListContext);

    const {
        userDetails,
    } = useContext(UserContext);

    const navigate = useNavigate();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const eventTargetListToolBarTabs = [{
        tabName: 'All',
        count: eventTargetListDataCount,
        navigation: () => {
            setCurrentUrl(`/events/${eventId}/target-list`);
            navigate(`/events/${eventId}/target-list`);
        },
        selectedTab: true
    }];

    const handleEdit = (row: EventTargetList, routeFromId?: boolean): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Edit List',
            hideCloseButton: true,
            component: <EditEventTargetList targetListdata={row} setRefresh={setRefresh} data-eventId={String(eventId)} />
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const handleRowClick = (row: any) => {
        navigate(`/events/${eventId}/target-list/${row.id}`);
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            if (filter.selectedColumn === 'source') {
                searchParams.append('sourcesArr', filter.inputValue);
            } else {
                searchParams.append(filter.selectedColumn, filter.inputValue);
            }
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const createList = () =>
    {
        navigate('details')
    }

    useEffect((): void => 
    {
        if(!pageSize)
        {
            updatePageSize(25);
        }
    }, [pageSize]);  
    
    const columnHelper = createColumnHelper<EventTargetList>();
    const eventTargetListColoumns = [
        columnHelper.accessor('name', {
            cell: (row) =>
            {
                return (<p className="bolded-cellContent">{ row.getValue() }</p>)
            },
            header: 'List Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('audienceIds', {
            cell: (row) => {
                const audienceIds = row.getValue();
                const targetListAudienceIds = row.row.original.targetListAudienceIds;
                const sumLength = audienceIds.length + targetListAudienceIds.length;
                return (<p className="cellContent">{sumLength}</p>);
            },
            header: 'List Size',
            id: 'audienceIds',
        }),
        columnHelper.accessor('type', {

            cell: (row) =>
            {
                const type = row.getValue();
                const typeString = type === EventTargetListType.STATIC ? 'Static' : 'Dynamic';
                return (<p className="cellContent">{ typeString }</p>)
            },
            header: 'Type',
            id: 'type',
        }),
        columnHelper.accessor('creator', {
            cell: (row) =>
            {
                return (<p className="cellContent">{ row.getValue() }</p>)
            },
            header: 'Creator',
            id: 'creator'
        }),
        columnHelper.accessor('modified', {
            cell: (row) =>
            {
                const date = moment(row.getValue());
                const formattedDate = date.format('MMM DD, YYYY hh:mm A');
                return <p className="cellContent">{ formattedDate }</p>;
            },
            header: 'Last Updated',
            id: 'modified',
        }),
        columnHelper.accessor('source', {
            cell: (row) => {
                const sources = row.getValue() || [];
                const sourceStrings = sources.map((sourceId) => ({
                    label: sourceTypeMapping[sourceId] || '',
                    color: sourceColorMapping[sourceId] || 'violet'
                }));
        
                const displayedSources = sourceStrings.slice(0, 1);
                const remainingSources = sourceStrings.slice(1);
                const remainingCount = remainingSources.length;
            
                return (
                    <div className="cellContent flex-row">
                        {displayedSources.map((source, index) => (
                            <MuiChip circleIcon key={index} label={source.label} chipColor={source.color} />
                        ))}
                        {remainingCount > 0 && (
                             <CustomTooltip title={remainingSources.map(source => source.label).join(', ')}>
                                <div>
                                <MuiChip key="more" label={`+${remainingCount}`} chipColor="grey" />
                                </div>
                            </CustomTooltip>
                        )}
                    </div>
                );
            },
            minSize: 165,
            header: 'Source',
            id: 'source',
        }),
        columnHelper.accessor('actions', {
            cell: (row) =>
            {
                return (
                    <TableActions
                        actions={ [
                            {
                                title: 'Edit',
                                onClick: (): void => 
                                {
                                    handleEdit(row.row.original);
                                }
                            },
                            {
                                title: 'Delete',
                                onClick: (): void => 
                                {
                                    setSelectedTargetList(row.row.original);
                                    setShowDeletePopup(true);
                                }
                            }] }
                    />
                );
            },
            header: '',
            size: 40,
            enableSorting: false,
            id: 'actions',
        })
    ];

    const [allColumns, setAllColumns] = useState(eventTargetListColoumns)

    const renderHeader = () =>
    {
        return (
            <EventsHeaderBar 
                title={'Target List'} 
                titleRightNode={<MuiChip label={eventTargetListDataCount || '0'} chipColor='grey' rounded />}
                showBorder={true}
            />
        )
    }

    const renderNormalViewForEventTargetList = (): React.JSX.Element => {
        return (
            <div id="eventTargetList">
                {renderHeader()}

                <div className="target-list-table">
                    {isEmpty ? (
                        <TableEmptyComponent
                            emptyImg={""}
                            infoText={"No Event Target List Yet"}
                            subInfoText={"Create Your First Event Target List"}
                        />
                    ) : (
                        <>
                            <TanstackToolbarWithServerFilter
                                columns={eventTargetListColoumns}
                                setColumns={setAllColumns}
                                tabs={eventTargetListToolBarTabs}
                                setCurrentUrl={setCurrentUrl}
                                filterColumns={targetListFilterColoumns}
                                handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                rightCornerButton={<CustomButton key={1} name={<FontAwesomeIcon icon={['fal', 'plus']} />} btnType='primary' onClick={createList} />}
                            />

                            <TanstackTable
                                initialColumns={allColumns}
                                data={eventTargetList}
                                rowCount={eventTargetListDataCount}
                                pageSize={pageSize}
                                currentPage={currentPage - 1}
                                updateCurrentPage={updateCurrentPage as any}
                                updatePageSize={updatePageSize}
                                showSpinner={showSpinner}
                                onRowClick={(row) => handleRowClick(row)}
                                rightPinnedColumns={["actions"]}
                                // height="calc(100vh - 250px)"
                            />
                        </>
                    )}
                </div>
            </div>
        )
    };

    const renderMinimalViewForEventTargetList = (): React.JSX.Element => {
        return (
            <div id="eventTargetListMinimalView">
                <MinimalViewComponent
                    title={renderHeader()}
                    titleBarStyle={{ padding: '0'}}
                    description="Manage your Targets here."
                    tabData={[
                        {
                            tabName: 'All',
                            count: eventTargetListDataCount as number,
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            key={0}
                            columns={eventTargetListColoumns}
                            filterColumns={targetListFilterColoumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown 
                            key={1}
                            columns={eventTargetListColoumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomButton
                            key={2}
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => createList()}
                        />
                    ]}
                    component={
                        <div className="minimalView-table-container">
                            {
                                isEmpty ?
                                    <TableEmptyComponent
                                        infoText={'No Target Lists'}
                                        subInfoText={'Create your first Target List'}
                                    />
                                    :
                                    <TanstackTable
                                        data={eventTargetList}
                                        initialColumns={allColumns}
                                        rowCount={eventTargetListDataCount as number}
                                        pageSize={pageSize}
                                        currentPage={currentPage - 1}
                                        updatePageSize={updatePageSize}
                                        updateCurrentPage={updateCurrentPage as any}
                                        // height='calc(100vh - 340px)'
                                        showBottomPadding={false}
                                        showSpinner={showSpinner}
                                        rightPinnedColumns={['actions']}
                                        onRowClick={(row) => handleRowClick(row)}
                                    />
                            }
                        </div>
                    }
                    componentType='table'
                />
            </div>
        )
    };

    return (
        <>
            {
                userDetails?.viewType === UserViewType.NORMAL ? renderNormalViewForEventTargetList() : renderMinimalViewForEventTargetList()
            }

            {showDeletePopup && (
                <DeletePopup
                    acceptBtn="Delete"
                    acceptClick={() =>
                        deleteEventTargetListFromTable(selectedTargetList as EventTargetList)
                    }
                    cancelClick={() => {
                        setShowDeletePopup(false);
                        setSelectedTargetList(null);
                    }}
                    modalContent={`Are you sure you want to delete ${selectedTargetList?.name}?`}
                    modalTitle="Delete Target List"
                    show={showDeletePopup}
                    rejectBtn="Cancel"
                    modalHeaderIcon='trash'
                />
            )}
        </>
    );
};

export default EventTargetListPage;