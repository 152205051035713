import React, { useContext, useEffect, useState } from 'react';
import { CustomFieldPageContext } from '../../../contexts/Settings/CustomFieldContext';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { CONTENT } from '../../../scripts/i18n';
import { ActionsDropdown } from '../../Events/EventBudget/EventBudgetDataGrid';
import { Box } from '@mui/material';
import TableEmptyComponent from '../../../common/TableEmptyComponent';
import CreateCustomField from './CreateCustomField';
import moment from 'moment';
import buyerPersonaEmptyImg from '../../../assets/icons/empty-state/ICPEmptyState.svg';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';
import { createColumnHelper } from '@tanstack/react-table';
import { CustomField } from '../../Registration/interfaces/custom-field_interface';
import HeaderBar from '../../../common/Headerbar';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import DeletePopup from '../../../common/DeletePopup';
import { stripHtmlTags } from '../../../scripts/helpers';
import { UserContext } from '../../../contexts/UserContext';
import { CustomFieldLocations, CustomFieldType } from '../../Registration/enum/custom-field.enum';
import { UserViewType } from '../enum/users.enum';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomSpinner from '../../../common/CustomSpinner';
import { MinimalViewComponent } from '../../../common/MinimalView/MinimalViewComponent';
import TanstackToolbarWithServerFilter from '../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import ColumnVisibilityDropdown from '../../../common/TanstackTable/ColumnVisibilityDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles.scss';
import { TableActions } from '../../../common/TableActions';

const CustomFieldPage = ():React.JSX.Element => 
{

    const searchParams = new URLSearchParams();
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const {
        userDetails
    } = useContext(UserContext);

    const { 
        isEmpty,
        rows,
        setRows,
        currentPage,
        selectedCustomField,
        setSelectedCustomField,
        setShowDeletePopup,
        showDeletePopup, 
        customFieldCount, 
        customFields, 
        setRefresh, 
        deleteCustomFieldFromTable, 
        pageSize, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner,
        setCurrentUrl 
    } = useContext(CustomFieldPageContext);

    // const columns = [
    //     {
    //         field: 'fieldName', headerName: 'Name', flex: 1, editable: false, disableColumnMenu: true, renderCell: (params):React.JSX.Element =>
    //         {
    //             return(
    //                 <div className="data-grid-bolded-cellContent" dangerouslySetInnerHTML={{
    //                     __html: params?.row?.label
    //                 }} />
    //             );
    //         }
    //     },
    //     {
    //         field: 'fieldLocations', headerName: 'Location', flex: 1, editable: false, disableColumnMenu: true, renderCell: (params):React.JSX.Element => 
    //         {
    //             return fieldLocation(params);
    //         }
    //     },
    //     {
    //         field: 'type', headerName: 'Type', flex: 1, editable: false, disableColumnMenu: true, sortable: false, renderCell: (params):React.JSX.Element => 
    //         {
    //             return fieldType(params);
    //         }
    //     },
    //     {
    //         field: 'createdBy', headerName: 'Created By', flex: 1, editable: false, disableColumnMenu: true, sortable: false, renderCell: (params):React.JSX.Element => 
    //         {
    //             return createdBy(params);
    //         }
    //     },
    //     {
    //         field: 'created', headerName: 'Created At', flex: 1, minWidth: 205, editable: false, disableColumnMenu: true, renderCell: (params):React.JSX.Element => 
    //         {
    //             return createdAt(params);
    //         }
    //     },
    //     {
    //         field: 'actions', headerName: '', editable: false, sortable: false, flex: 1, maxWidth: 40, disableColumnMenu: true, renderCell: (params):React.JSX.Element => 
    //         {
    //             return customFieldActions(params.row);
    //         }
    //     },
    // ];

    const columnHelper = createColumnHelper<CustomField>();
    const columns = 
    [
        columnHelper.accessor('fieldName', {
            cell: (row) => {
                return (
                    <div className="bolded-cellContent" dangerouslySetInnerHTML={{
                        __html: String(row?.row?.original?.label)
                    }} />
                )
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('fieldLocations', {
            cell: (row) => {
                return fieldLocation(row.row.original);
            },
            header: 'Location',
            size: 180,
            sortingFn: (rowA, rowB, columnId) => {
                const locationsA = (rowA.getValue(columnId) as string[]).map((loc: string) => CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[loc]).toString().toLowerCase();
                const locationsB = (rowB.getValue(columnId) as string[]).map((loc: string) => CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[loc]).toString().toLowerCase();
                return locationsA.localeCompare(locationsB);
            },
            id: 'fieldLocation'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return fieldType(row.row.original);
            },
            header: 'Type',
            size: 180,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'type'
        }),
        // columnHelper.accessor('createdBy', {
        //     cell: (row) => {
        //         return createdBy(row.row.original);
        //     },
        //     header: 'Created By',
        //     size: 200,
        //     sortingFn: 'alphanumericCaseSensitive',
        //     id: 'createdBy'
        // }),
        columnHelper.accessor('created', {
            cell: (row) => {
                return createdAt(row.row.original);
            },
            header: 'Created At',
            size: 250,
            sortingFn: (rowA, rowB, columnId) => {
                const dateA = moment(rowA.getValue(columnId), 'YYYY-MM-DDTHH:mm:ssZ');
                const dateB = moment(rowB.getValue(columnId), 'YYYY-MM-DDTHH:mm:ssZ');
                return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
            },
            id: 'createdAt'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return customFieldActions(row.row.original);
            },
            header: '',
            size: 40,
            enableSorting: false,
            enableResizing: false,
            id: 'actions'
        })
    ];

    const filterColumns = [
        {
            header: 'Name',
            id: 'fieldName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Location',
            id: 'fieldLocation',
            meta: {
                type: 'dropdown',
                filterOptions: 
                [
                    {
                        name: 'Events', value: CustomFieldLocations.EVENT
                    },
                    {
                        name: 'Speakers', value: CustomFieldLocations.SPEAKER
                    },
                    {
                        name: 'Sponsors', value: CustomFieldLocations.SPONSOR
                    },
                    {
                        name: 'Vendors', value: CustomFieldLocations.VENDOR
                    },
                    {
                        name: 'Registrations', value: CustomFieldLocations.REGISTRATION_FORM
                    }
                ]
            }
        },
        {
            header: 'Type',
            id: 'type',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    {
                        name: 'Single Line', value: CustomFieldType.SINGLE_LINE
                    },
                    {
                        name: 'Multi Line', value: CustomFieldType.MULTI_LINE
                    },
                    {
                        name: 'Number', value: CustomFieldType.NUMBER
                    },
                    {
                        name: 'Dropdown', value: CustomFieldType.DROPDOWN
                    },
                    {
                        name: 'Checkbox', value: CustomFieldType.CHECKBOXES
                    },
                    {
                        name: 'Radio Button', value: CustomFieldType.RADIO
                    },
                    {
                        name: 'Date', value: CustomFieldType.DATE
                    },
                    {
                        name: 'Header', value: CustomFieldType.HEADER
                    },
                    {
                        name: 'Paragraph', value: CustomFieldType.PARAGRAPH
                    }
                ]
            }
        },
        // {
        //     header: 'Created By',
        //     id: 'ownerName',
        //     meta: {
        //         type: 'string'
        //     }
        // }
    ];

    const [allColumns, setAllColumns] = useState(columns);

    const customFilterFunctions = {
        fieldLocations: (obj: CustomField, inputValue: string | number) => {
            if (obj.fieldLocations.length === 1) {
                return CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[obj.fieldLocations[0]].toLowerCase().includes(String(inputValue).toLowerCase());
            }
            else {
                const res = [];

                for (let i = 0; i < obj.fieldLocations.length; i++) {
                    res.push(CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[obj.fieldLocations[i]]);
                }

                return res.toString().toLowerCase().includes(String(inputValue).toLowerCase());
            }
        },
        type: (obj: CustomField, inputValue: string | number) => { 
            return CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_TYPE[obj.type as keyof typeof CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_TYPE].toLowerCase().includes(String(inputValue).toLowerCase());
        }
    };

    const fieldLocation = (row: CustomField):React.JSX.Element => 
    {
        if (row.fieldLocations.length === 1) 
        {
            return (
                <p className="cellContent">
                    {CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[row.fieldLocations[0]]}
                </p>
            );

        }
        else 
        {
            const res = [];

            for (let i = 0; i < row.fieldLocations.length; i++) 
            {
                res.push(CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[row.fieldLocations[i]]);

            }

            return (
                <p className="cellContent">
                    {res.toString()}
                </p>
            );
        }

    };

    const fieldType = (row: CustomField):React.JSX.Element => 
    {
        return (
            <p className="cellContent">
                {CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_TYPE[row.type as keyof typeof CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_TYPE]}
            </p>
        );
    };

    const createdBy = (row: CustomField):React.JSX.Element => 
    {
        return (
            <p className="cellContent">
                {row?.createdBy}
            </p>
        );
    };

    const createdAt = (row: CustomField):React.JSX.Element => 
    {
        const date = moment(row.created).format('MMMM Do YYYY, h:mm:ss a');
        return (
            <p className="cellContent">
                {date}
            </p>
        );
    };

    const openDrawer = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.SIDE_DRAWER.HEADING.CREATE,
            hideCloseButton: true,
            component: <CreateCustomField setRefresh={setRefresh} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const customFieldActions = (customFieldInfo: CustomField):React.JSX.Element => 
    {

        const editClick = ():void => 
        {
            handleViewEdit(customFieldInfo);
        };

        const deleteClick = ():void => 
        {
            setSelectedCustomField(customFieldInfo);
            setShowDeletePopup(true);
        };

        return <TableActions 
            actions={[
                {
                    title: 'Edit',
                    onClick: editClick
                },
                {
                    title: 'Delete',
                    onClick: deleteClick
                }
            ]}
        />
    };

    const handleViewEdit = (customFieldInfo: CustomField):void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.SIDE_DRAWER.HEADING.EDIT,
            component: <CreateCustomField setRefresh={setRefresh} customFieldData={customFieldInfo} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });

    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const renderNormalViewForCustomFields = (): React.JSX.Element => { 
        return (
            <Box id="customFieldPage">
                <HeaderBar 
                    title={CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.HEADING}
                    buttons={[
                        <CustomButton name="Create Property" btnType="secondary" onClick={openDrawer} />
                    ]}
                />
                <Box sx={{
                    flex: 1,
                    height: APP_CONSTANTS.GLOBAL_MODULES_COMPONENT_HEIGHT
                }}>
                    {isEmpty ? (<TableEmptyComponent emptyImg={buyerPersonaEmptyImg} openDrawer={openDrawer} infoText={'No Property'} subInfoText={'Add your first Property'} buttonName={'Add Property'} />) :
                        (
                            <div className="h-100">
                                <TanstackToolbarWithServerFilter 
                                    columns={columns}
                                    setColumns={setAllColumns}
                                    tabs={[
                                        {
                                            tabName: 'All',
                                            count: customFieldCount as number,
                                            navigation: () => {},
                                            selectedTab: true
                                        }
                                    ]}
                                    setCurrentUrl={setCurrentUrl}
                                    filterColumns={filterColumns}
                                    handleApplyFilters={handleApplyFilters}
                                />
                                <TanstackTable 
                                    initialColumns={allColumns}
                                    data={rows}
                                    rowCount={customFieldCount}
                                    showNoRowsOverlay={customFieldCount==0}
                                    pageSize={pageSize}
                                    currentPage={currentPage - 1}
                                    updateCurrentPage={updateCurrentPage as any}
                                    updatePageSize={updatePageSize}
                                    showSpinner={showSpinner}
                                    rightPinnedColumns={['actions']}
                                    // height={'calc(100% - 124px)'}
                                    onRowClick={(row) => handleViewEdit(row)}
                                />
                            </div>
                        )}
                </Box>
            </Box>
        );
    };

    const renderMinimalViewForCustomFields = (): React.JSX.Element => {
        return (
            <div id="customFieldPageMinimalView">
                {
                    showSpinner ? <CustomSpinner height="100%" /> :
                    <MinimalViewComponent
                        title="Properties"
                        description="Manage your Properties here."
                        tabData={[
                            {
                                tabName: 'All',
                                count: customFieldCount,
                                navigation: () => { },
                                selectedTab: true
                            }
                        ]}
                        actions={[
                            <TanstackToolbarWithServerFilter
                                key={1}
                                columns={columns}
                                filterColumns={filterColumns}
                                handleApplyFilters={handleApplyFilters}
                                setCurrentUrl={setCurrentUrl}
                                minimalView={true}
                            />,
                            <ColumnVisibilityDropdown 
                                key={2}
                                columns={columns}
                                setColumns={setAllColumns}
                                showIconButton={true}
                            />,
                            <CustomButton
                                key={3}
                                name=''
                                startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                                btnType='primary'
                                onClick={() => {
                                    openDrawer();
                                }}
                            />
                        ]}
                        component={
                            <div className="minimalView-table-container">
                                {
                                    isEmpty ?
                                        <div style={{ height: 'calc(100vh - 348px)' }}>
                                            <TableEmptyComponent
                                                emptyImg={''}
                                                infoText={'No Property'}
                                            />
                                        </div>
                                        :
                                        <TanstackTable
                                            data={customFields}
                                            initialColumns={allColumns}
                                            rowCount={customFieldCount}
                                            showNoRowsOverlay={customFieldCount==0}
                                            pageSize={pageSize}
                                            currentPage={currentPage - 1}
                                            updateCurrentPage={updateCurrentPage as any}
                                            updatePageSize={updatePageSize}
                                            showSpinner={false}
                                            // height={'calc(100vh - 348px)'}
                                            rightPinnedColumns={['actions']}
                                            onRowClick={(row) => {
                                                handleViewEdit(row)
                                            }}
                                            showBottomPadding={false}
                                        />
                                }

                            </div>
                        }
                        componentType='table'
                    />
                }
            </div>
        );
    };

    useEffect(() => {
        if (!pageSize) updatePageSize(25);
    }, [pageSize]);

    return (
        <>

            {
                userDetails?.viewType === UserViewType.NORMAL ? renderNormalViewForCustomFields() : renderMinimalViewForCustomFields()
            }

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteCustomFieldFromTable(selectedCustomField as CustomField)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedCustomField(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${stripHtmlTags(selectedCustomField?.fieldName as string)}?`}
                        modalTitle='Delete Property'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }

        </>
    );
};
export default CustomFieldPage;