export enum IntelligenceLimit
{
    UNLIMITED = 1,
    TEN = 2
}

export enum ProductType
{
    EVENTHQ = 1,
    INTELLIGENCE = 2
}

export enum EventHQEventLimit
{
    UNLIMITED = 1,
    THREE = 2
}