import React, { useEffect, useState } from 'react';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import LandingPageDetails from '../../../../components/Events/EventComponents/LandingPageDetails';
import _ from 'lodash';
import EventsHeaderBar from '../../../../common/EventsHeaderBar';
import { EventLandingPageTheme } from '../../enum';

import { 
    AgendaBased,
    BasicDarkOne,
    BasicLightOne,
    Nature ,
} from '@eventhq/event-page';
import { Event, EventPreview } from '../../interfaces';
import { getEventPreview } from '../../../../scripts/apis/events';
import useEventOverviewData from '../../../../hooks/eventOverviewData';
import CustomSpinner from '../../../../common/CustomSpinner';

// eslint-disable-next-line react/display-name
const LandingPagePreview = ({ eventId }: { eventId: Number }): React.JSX.Element => 
{

    const [eventDetails] = useEventOverviewData({ eventId: Number(eventId) });

    const [landingPageDetails, setLandingPageDetails] = useState<EventPreview>();
    const [loading, setLoading] = useState<boolean>(true);

    const fetchLandingPageData = async (): Promise<void> => {
        try 
        {
            const eventInfo = await getEventPreview(eventDetails?.link as string);
            if(eventInfo) {
                setLandingPageDetails(eventInfo);
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching event preview data', error);
        }
        finally
        {
            setLoading(false);
        }
    };

    useEffect(() => {
        if(eventDetails)
        {
            fetchLandingPageData();
        }
    }, [eventDetails]);

    const handleEditLandingPage = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Select Landing Page',
            component: <LandingPageDetails eventDetails={eventDetails as Event} />,
            hideCloseButton: true
        });
    
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const renderLandingPage = (): React.JSX.Element => {
        switch(eventDetails?.landingPageTheme) {
            case EventLandingPageTheme.STANDARD:
                return <AgendaBased eventData={landingPageDetails} />;
            case EventLandingPageTheme.LIGHT:
                return <BasicLightOne eventData={landingPageDetails} />;
            case EventLandingPageTheme.DARK:
                return <BasicDarkOne eventData={landingPageDetails} />;
            case EventLandingPageTheme.NATURE:
                return <Nature eventData={landingPageDetails} />;
            default:
                return <></>;
        }
    };

    return(
        <div id="overviewLandingPagePreview">
            <EventsHeaderBar
                title='Landing Page'
                showBorder={true}
                buttons={[
                    <CustomButton name={'Edit'} type='button' btnType='secondary' onClick={handleEditLandingPage} />
                ]}
            />
            {loading ? <CustomSpinner height='100lvh' /> : renderLandingPage()}
        </div>
    );
};

export default LandingPagePreview;


// Old code

// Logics
// const brandColor = CONTENT.BRAND_COLOR_OBJ[event?.brandColor];
// const [eventDateInfo, setEventDateInfo] = useState<string>('');
// const [eventTimeInfo, setEventTimeInfo] = useState<string>('');

// const RegisterButton = styled(Button)({
//     display: 'flex',
//     padding: '6px 12px',
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '8px',
//     alignSelf: 'stretch',
//     borderRadius: '8px',
//     color: '#FFF',
//     fontSize: '14px',
//     fontStyle: 'normal',
//     fontWeight: '500',
//     lineHeight: '20px',
//     height: '38px!important',
//     textTransform: 'none',
//     backgroundColor: `${brandColor?.ctaColor} !important`,

//     // '&.MuiButton-root:hover': {
//     //     backgroundColor: brandColor?.ctaHover
//     // }
// });    

// useEffect(():void => 
// {
//     if (event && event.eventStartDateTime && event.eventEndDateTime) 
//     {

//         if (moment.unix(Number(event.eventStartDateTime)).format('dddd, MMMM D') === moment.unix(Number(event.eventEndDateTime)).format('dddd, MMMM D')) 
//         {
//             setEventDateInfo(moment.unix(Number(event.eventStartDateTime)).format('dddd, MMMM D'));
//         }
//         else 
//         {
//             setEventDateInfo(`${moment.unix(Number(event.eventStartDateTime)).format('MMM D')} - ${moment.unix(Number(event.eventEndDateTime)).format('MMM D')}`);
//         }

//         const startTime = moment.unix(Number(event.eventStartDateTime));
//         const endTime = moment.unix(Number(event.eventEndDateTime));
//         // const startTimezoneValue = moment.tz(moment.unix(Number(event.eventStartDateTime)).format(), event.timezone).format('z');
//         const defaultTimezone = _.filter(uniqueTimezoneArr, function (elem):boolean 
//         {
//             return elem.value === event.timezone;
//         });
//         let timezone: string;
//         if (defaultTimezone && defaultTimezone[0]?.name && defaultTimezone[0]?.name !== '') 
//         {
//             timezone = defaultTimezone[0]?.name?.split(' ')[0];
//         }
//         // const endTimeZoneValue = moment.tz(moment.unix(Number(event.eventEndDateTime)).format(), event.timezone).format('z');

//         const formattedTimeRange = `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')} ${timezone}`;
//         setEventTimeInfo(formattedTimeRange);

//     }
// }, [event]);

{/* <Box className="landing-page-preview-container" style={{
    backgroundColor: brandColor?.cardBackground,
}}>
    <Box className="landing-page-event-detail-cont">
        <Typography className="event-title" style={{
            color: brandColor?.title
        }}>{event?.title}</Typography>
        <Box className="event-preview-venue-details">
            <Box className="event-preview-timing-cont">
                <Box style={{
                    border: `1px solid ${brandColor?.cardBorder}` 
                }} className="date-icon">
                    <FontAwesomeIcon style={{
                        color: brandColor?.title 
                    }} icon={['fal', 'calendar']} />
                </Box>
                <Box className="meetup-time">
                    <Box className="meetup-date"><Typography style={{
                        color: brandColor?.title 
                    }} className="meetups-date-text">{eventDateInfo}</Typography></Box>
                    <Box className="meetup-time"> <Typography style={{
                        color: brandColor?.title 
                    }} className="meetups-time-text">{eventTimeInfo}</Typography></Box>
                </Box>

            </Box>
            <Box className="event-preview-location-cont">
                <Box className="location-icon" style={{
                    border: `1px solid ${brandColor?.cardBorder}` 
                }}>
                    {event?.locationType === 2 ? <FontAwesomeIcon style={{
                        color: brandColor?.title 
                    }} icon={['fal', 'video']} /> : <FontAwesomeIcon style={{
                        color: brandColor?.title 
                    }} icon={['fal', 'location-dot']} />}

                </Box>
                <Box className="meetup-time">
                    <CustomTooltip title={event?.locationDetails?.name ? event?.locationDetails?.name : null}><p style={{
                        color: brandColor?.title 
                    }} className="meetups-venue-main-text">
                        {event?.locationDetails?.name ? <p>{event?.locationDetails?.name?.substring(0, 36)}{event?.locationDetails?.name?.length > 36 && '...'}</p> : '-'}
                    </p></CustomTooltip>
                    <Typography style={{
                        color: brandColor?.title 
                    }} className="meetups-venue-sub-text">{event?.country ? event?.country : '-'}</Typography>
                </Box>

            </Box>
        </Box>
        <Box className="event-preview-description">
            <Typography className="description-title">{'Overview'}</Typography>
            <Typography className="description">{event?.description}</Typography>
        </Box>
    </Box>
    <Box className="event-preview-banner-cont">
        {event?.eventBanner ? <img src={event?.eventBanner} className={event?.landingPageLayout === 1 ? 'banner-1-1' : 'banner-16-9'} /> : <div className="event-preview-banner-empty-div" />}
        <RegisterButton>Register</RegisterButton>
    </Box>
</Box> */}