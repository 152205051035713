import { useEffect, useMemo, useState, useRef } from "react";
import { CustomButton } from "../FormComponents/Buttons";
import { FormControlComponent } from "../FormComponents/ReusableFormComponents";
import { ColumnDef } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BpCheckbox } from "../CheckboxWrapper";
import APP_CONSTANTS from "../../scripts/constants";
import CustomTooltip from "../Tooltip";

const ColumnVisibilityDropdown: React.FC<{ columns: ColumnDef<any>[]; setColumns: React.SetStateAction<React.Dispatch<ColumnDef<any>[]>> | any; showIconButton?: boolean; }> = ({ columns, setColumns, showIconButton }): React.JSX.Element =>
{

    const buttonRef = useRef(null);
    const columnVisibilityRef = useRef(null);

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const filteredInitialColumns = useMemo(() => columns.filter(column => !APP_CONSTANTS.UNHIDEABLE_COLUMNS.includes(column.id as string)), [columns]);
    const [filteredColumns, setFilteredColumns] = useState<ColumnDef<any>[]>(filteredInitialColumns);
    const [columnVisibility, setColumnVisibility] = useState<{ [key: string]: boolean }>(
        columns?.reduce((acc, column) => {
            if (column?.meta && column?.meta?.initiallyHidden) {
                acc[column.id as string] = false;
            } else if (column.id === 'actions' || column.id === 'copyLink' || column.id === 'viewPin' || column.id?.includes('additionalData')) {
                acc[column.id as string] = true;
            } else {
                acc[column.id as string] = true;
            }
            return acc;
        }, {} as { [key: string]: boolean })
    );

    const hasInitialized = useRef(false);

    useEffect(() => {
        if (!hasInitialized.current) {
            setColumnVisibility(prevVisibility => {
                const newVisibility = { ...prevVisibility };
                columns.forEach(column => {
                    if (!(column?.id && column?.id in newVisibility)) {
                        newVisibility[column.id as string] = !APP_CONSTANTS.UNHIDEABLE_COLUMNS.includes(column?.id as string) || column.id?.includes('additionalData') ? true : false;
                    }
                });
                return newVisibility;
            });
            hasInitialized.current = true;
        }
    }, [columns]);

    const handleClick = () => 
    {
        setIsVisible(!isVisible);
    };

    const handleToggleChange = (columnId: string) => {
        setColumnVisibility(prev => ({
            ...prev,
            [columnId]: !prev[columnId]
        }));
    };

    const handleShowAll = () => {
        setColumnVisibility(columns?.reduce((acc, column) => {
            acc[column.id as string] = true;
            return acc;
        }, {} as { [key: string]: boolean }));
    };

    const handleHideAll = () => {
        const nonHideableColumns = columns.filter(column => APP_CONSTANTS.UNHIDEABLE_COLUMNS.includes(column.id as string));
    
        setColumnVisibility(columns?.reduce((acc, column) => {
            if (nonHideableColumns.some(nonHideableColumn => nonHideableColumn.id === column.id)) {
                acc[column.id as string] = true;
            } else {
                acc[column.id as string] = false;
            }
            return acc;
        }, {} as { [key: string]: boolean }));
    };

    const filterOutColumns = () => {
        const visibleColumns = columns.filter(column => columnVisibility[column.id as string]);
        setColumns(visibleColumns);
    };

    const handleSearchColumns = (event: React.ChangeEvent<HTMLInputElement>) => 
    {
        const value = event.target.value;
        setSearchValue(value);
        const filtered = filteredInitialColumns.filter(column => {
            if(typeof(column?.header) === 'string')
            {
                return (column.header as string).toLowerCase().includes(value.toLowerCase());
            }
            else
            {
                return (column?.meta?.headerName as string).toLowerCase().includes(value.toLowerCase());
            }
        });
        setFilteredColumns(filtered);
    };

    useEffect(() => {
        filterOutColumns();
    }, [columnVisibility]);

    useEffect(() => {
        setFilteredColumns(filteredInitialColumns);
    }, [filteredInitialColumns]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                columnVisibilityRef.current && 
                !columnVisibilityRef.current.contains(event.target as Node) &&
                buttonRef.current && 
                !buttonRef.current.contains(event.target as Node)
            ) {
                setIsVisible(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="column-visibility-dropdown">
                {/* {
                    showIconButton ?  */}
                    <CustomTooltip title="Columns">
                        <div ref={buttonRef}>
                            <CustomButton 
                                name={<FontAwesomeIcon color="#344054" icon={['fal', 'objects-column']} />}
                                btnType='secondary'
                                onClick={handleClick} 
                                style={{ width: '32px' }}
                            /> 
                        </div>
                    </CustomTooltip>
                    {/* : 
                    <CustomButton 
                        onClick={handleClick} 
                        name="Columns" 
                        btnType="tertiary_grey" 
                    />
                } */}
            {
                isVisible && 
                (
                    <div ref={columnVisibilityRef} className="tanstack-table-columnVisibility-content">
                        <div className="search-box-cont">
                            <FormControlComponent 
                                type="text"
                                placeholder="Search"
                                onChange={handleSearchColumns}
                                value={searchValue}
                            />
                        </div>
                        <div className="columns-container">
                            {
                                filteredColumns?.map((column, index) => {
                                    const optionLabel = typeof(column.header) === 'string' ? column.header : column?.meta?.headerName as string;
                                    return (
                                        <div key={index} className="switch-columnHeader-div">
                                            {/* <CustomSwitch 
                                                checked={columnVisibility[column.id as string]} 
                                                onChange={() => handleToggleChange(column.id as string)}
                                            /> */}
                                            <BpCheckbox 
                                                checked={columnVisibility[column.id as string]} 
                                                onChange={() => handleToggleChange(column.id as string)}
                                            />
                                            <span className="column-name">{optionLabel as React.ReactNode || ''}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* <div className="action-button-div">
                            <CustomButton name='Show All' btnType="secondary" onClick={handleShowAll} />
                            <CustomButton name='Hide All' btnType="secondary" onClick={handleHideAll} />
                        </div> */}
                    </div>
                )
            }
        </div>
    )
};

export default ColumnVisibilityDropdown;