import React, { useEffect, useState } from 'react';
import { CONTENT } from '../../../scripts/i18n';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import CustomSpinner from '../../../common/CustomSpinner';
import { resetFormComponents } from '../../../redux/registrationsForms/FormComponents';
import { getRegistrationFormById } from '../../../scripts/apis/registrationForm';
import { updateEvent } from '../../../scripts/apis/events';
import eventBus from '../../../scripts/event-bus';
import { AutocompleteComponent, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { RegistrationForm } from '../../../pages/Registration/interfaces/registration-form_interface';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import FormPreview from '../../../pages/Registration/Preview/FormPreview';
import { CopyBlock } from "react-code-blocks";
import CustomTooltip from '../../../common/Tooltip';
import { EventAdditionalForms } from '../../../pages/Events/interfaces';

const SelectRegistrationForm: React.FC<{formName: string; regForms: RegistrationForm[]; eventId: string; registrationFormId?: string; codeSnippet: string; npsFormId?: string; additionalForms?: EventAdditionalForms; heading: string; handlePopupClose: () => void; }> = ({formName, regForms, eventId, registrationFormId, codeSnippet, npsFormId, additionalForms, heading, handlePopupClose}): React.JSX.Element => 
{
    const [formValue, setFormValue] = useState(formName);
    const [selectedForm, setSelectedForm] = useState('');
    const [registrationFormData, setRegistrationFormData] = useState<RegistrationForm | null>(null);
    const [previewComponent, setPreviewComponent] = useState<React.JSX.Element>(<></>);
    const [spinner, setSpinner] = useState(false);
    const [showFormEmbed, setShowFormEmbed] = useState(false);  
    const dispatch = useDispatch();

    const formSubmit = async (event): Promise<void> => 
    {
        event.preventDefault();

        const regFormDetails = () =>
        {
            if(registrationFormId)
            {
                return {
                    registrationFormId: selectedForm,
                }
            }
            else
            {
                return {
                    additionalForms: {
                        npsFormId: selectedForm,
                        sponsorNominationFormId: additionalForms?.sponsorNominationForm,
                        speakerNominationFormId: additionalForms?.speakerNominationForm,
                    }
                }
            }
        };

        if (!selectedForm) 
        {
            toast.error('Please select a form');
            return;
        }

        if (selectedForm) 
        {
            try 
            {
                setSpinner(true);
                const formIdUpdated = await updateEvent(eventId, regFormDetails());
                if (formIdUpdated) 
                {
                    eventBus.dispatch('event-overview-refreshed', { refresh: true });
                    setSpinner(false);
                    handleDrawerClose();
                    toast.success('Event Form updated');
                }
            }
            catch (error) 
            {
                console.log(error);
                toast.error((error as Error)?.message || 'Error updating form');
            }
            finally
            {
                setSpinner(false);
            }
        }

    };

    const handleFormChange = (event, newValue): void => 
    {
        setFormValue(newValue);
        const selectedFormData = _.filter(regForms, function (form): boolean 
        {
            return newValue === form.name;
        })[0];
        if (selectedFormData) 
        {
            setSelectedForm(String(selectedFormData.id));
        }
        else 
        {
            setSelectedForm('');
        }
    };

    const fetchRegistrationFormData = async (): Promise<void> => 
    {
        try 
        {
            setPreviewComponent(<CustomSpinner height={'100%'} />);
            dispatch(resetFormComponents());
            const regFormData = await getRegistrationFormById(selectedForm);
            setRegistrationFormData(regFormData);
        }
        catch (error) 
        {
            setPreviewComponent(null);
            console.log(error);
        }
    };

    const handleIconClick = (selectedForm: any) => {
        handleDrawerClose();
        const url = `/registrations/${selectedForm}/form`;
        window.open(url, '_blank');
    };

    useEffect(():void => 
    {
        let selectedForm = registrationFormId ? registrationFormId : npsFormId;
        if(registrationFormId || npsFormId)
        {
            setSelectedForm(selectedForm as string);
        }
    },[]); 

    useEffect((): void => 
    {
        if (selectedForm !== null && selectedForm !== undefined && selectedForm !== '' && selectedForm !== 'null') 
        {
            fetchRegistrationFormData();
        }
        else 
        {
            setPreviewComponent(null);
        }
    }, [selectedForm]);

    useEffect((): void => 
    {
        if (registrationFormData) 
        {
            setPreviewComponent(
                <div className="previeMode">
                    <FormPreview formId={selectedForm} registrationFormData={{ registrationForm: registrationFormData }} width={'100%'} maxWidth={'100%'} margin={'20px 20px 0px 20px'} />
                </div>
            );
        }
    }, [registrationFormData]);

    const handleDrawerClose = (): void => 
    {
        handlePopupClose();
    };

    const codeBlock = (): React.JSX.Element => 
    {
        const codeSnippetScript = `<script src="${codeSnippet?.sdkUrl}"></script>\n<script>\n\tFormSDK.init({\n\t\ttoken: "${codeSnippet?.token}",\n\t\tonSuccess: (data) => console.log("Success:", data),\n\t\tonError: (error) => console.error("Error:", error),\n\t});\n</script>`;
        return <CopyBlock
        language="html"
        text={codeSnippetScript}
        codeBlock={true}
        showLineNumbers={false}
        customStyle={{fontSize: '14px', }}
      />;
    };

    const handleEmbedCodeClick = (): void =>
    {
        setShowFormEmbed(true);
    };

    const handleBackToPopup = (): void =>
    {
        setShowFormEmbed(false);
    }

    const renderHeader = (): React.JSX.Element => {
        return (
            <div className='popup-header'>
                <div className='popup-header-content'>
                    {showFormEmbed ? (
                        <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} onClick={handleBackToPopup} />
                    ) : (
                        <div className='popup-header-icon'><FontAwesomeIcon icon={['fal', 'file']} /></div>
                    )}

                    <div className='popup-header-text'>
                        <h3>
                            {showFormEmbed ? 'Form Embed' : heading}
                        </h3>
                        <p>
                            {showFormEmbed ? formName : 'Update the Form for the Event'}
                        </p>
                    </div>
                </div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />} onClick={() => handlePopupClose()} />
            </div>
        );
    }

    const renderMainContent = (): React.JSX.Element => {
        if (showFormEmbed) {
            return codeBlock();
        }
        else {
            return (
                <>
                    <div className="popup-container-spacing">
                        <div className="select-text">
                            <FormLabelComponent label="Select Form" required />
                        </div>
                        <AutocompleteComponent value={formValue} defaultValue={formValue} onChange={handleFormChange} optionsArr={regForms} keyToShow="name" placeholder={CONTENT.EVENTS_MODULE.EVENT_REG_FORM.FORM_SELECT_PLACEHOLDER} />
                    </div>
                    {selectedForm && previewComponent ?
                        <div className="popup-container-spacing">
                            <div className='tooltip-box'>
                                <FormLabelComponent label='Form Preview' />
                                <CustomTooltip title='Edit the Registration Form'><FontAwesomeIcon className='edit-icon' cursor={'pointer'} icon={['fal', 'edit']} onClick={() => handleIconClick(selectedForm)} /></CustomTooltip>
                                <CustomTooltip title='Embed Code'><FontAwesomeIcon className='code-icon' cursor={'pointer'} icon={['fal', 'square-terminal']} onClick={() => handleEmbedCodeClick()} style={{ marginLeft: 'auto' }} /></CustomTooltip>
                            </div>
                            <div className="show-form">
                                <div className="reg-form-preview">{previewComponent}</div>
                            </div>
                        </div>
                        : null}
                </>
            )
        }
    }

    const renderFooter = (): React.JSX.Element => {
        if (showFormEmbed) {
            return <></>
        }
        return (
            <div className="popup-footer">
                <CustomButton name='Cancel' onClick={handleDrawerClose} btnType='secondary' />
                <CustomButton name='Save' onClick={formSubmit} btnType='primary' />
            </div>
        )
    }

    return (
        <div id="selectRegForm">
            {renderHeader()}

            <div className='popup-container'>
                {renderMainContent()}
            </div>

            {renderFooter()}
        </div>
    );
};

export default SelectRegistrationForm;