import React, { useState } from 'react'
import { EventLeadCapture } from '../../../Events/interfaces/event-lead-capture_interface';
import { EventLeadCaptureStatus } from '../../../Events/enum/event-lead-capture.enum';
import './styles.scss'
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import CustomTooltip from '../../../../common/Tooltip';

interface LeadsDetailsProps 
{
    handlePopupClose: () => void;
    leadData: EventLeadCapture | null;
}

const LeadsDetails: React.FC<LeadsDetailsProps> = ({ handlePopupClose, leadData }) => 
{
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    
    const toggleExpand = (index : number) => 
    {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const attendedStatusOptions =
        [
            {
                id: EventLeadCaptureStatus.ACTIVE,
                name: 'Yes',
                optionChipColor: 'green'
            },
            {
                id: EventLeadCaptureStatus.INACTIVE,
                name: 'No',
                optionChipColor: 'red'
            },
        ];


    const handleCopy = (text: string | undefined): void => 
    {
        navigator.clipboard.writeText(text || 'No text to copy').then(() => {
            toast.success('Text copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    return (
        <div id='leadDetails'>
            {leadData && leadData.leadAudios && leadData.leadAudios.length > 0 ?  
            (leadData.leadAudios.map((audio, index) => (
                <div key={index} className='lead-audio-container'>
                    <div className="header-details-container">
                        <div className="header-texts">
                            <div className='head'>{audio.fileName}</div>
                            <div className='subtext'>Captured by {audio.capturedName}</div>
                        </div>

                    <CustomTooltip title='Copy text' placement='top'>
                        <div>
                            <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />} onClick={() => handleCopy(audio?.transcriptedText)} />
                        </div>
                    </CustomTooltip>  
                    </div>

                    <div className='audio-container'>
                        <audio controls className="custom-audio">
                            <source src={audio.audioUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>

                    <div className='audio-note' onClick={() => toggleExpand(index)}>
                        <div className={`subtext ${expandedIndex === index ? 'expanded' : ''}`}>
                            {audio.transcriptedText}
                        </div>
                    </div>
                </div>
            ))
        ) : (
            <div className='no-audio'>No lead audios available</div>
        )}
        </div>
    )
}

export default LeadsDetails